/*
 *Author: zhangmeng
 *Description: 综合评级
 *Date: 2022/04/29
*/

import React, { useMemo } from 'react';
import RadarChart from './components/RadarChart';
import BarChart from './components/BarChart';
import style from './index.less';

const level = [
  {
    name: 'A级',
    background: '#A6FFC2',
  },
  {
    name: 'AA级',
    background: '#17CDB5',
  },
  {
    name: 'AAA级',
    background: '#00CFFF',
  },
  {
    name: 'S级',
    background: '#008DC7',
  },
];

function ComprehensiveRating(props) {
  const { radarData, barData } = props;

  const radar = useMemo(() => (
    Object.keys(radarData).length > 0
    && radarData && <RadarChart data={radarData} />
  ), [radarData]);

  const bar = useMemo(() => (
    Object.keys(barData).length > 0
    && barData && <BarChart data={barData} />
  ), [barData]);

  return (
    <div className={style.chartsBox}>
      <div className={style.top}>
        <div className={style.chart1}>
          {radar}
        </div>
        <div className={style.chart2}>
          <div className={style.barChart}>
            {bar}
          </div>
          <div className={style.levelBox} style={{ display: Object.keys(barData).length > 0 && Object.keys(barData).length > 0 ? '' : 'none' }}>
            {
              level.map(item => (
                <div key={item.name} className={style.item}>
                  <div className={style.rect} style={{ background: `${item.background}` }} />
                  <div className={style.level}>{item.name}</div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComprehensiveRating;
