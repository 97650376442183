/*
 *Author: zhangmeng
 *Description: 企业详情页面
 *Date：2022/07/13
 */

import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Container from './components/Container';
import CompanyInfo from './components/CompanyInfo';
import BusinessInfo from './components/BusinessInfo';
import D3ThroughChart from './components/D3ThroughChart';
import ComprehensiveRating from './components/ComprehensiveRating';
import FiveIndexChart from './components/FiveIndexChart';
import NewsDynamic from './components/NewsDynamic';
import CertificationChart from './components/CertificationChart';
import TreeRadialChart2 from './components/TreeRadialChart2';
import BarAndTable from './components/BarAndTable';
import back from '../../assets/img/back.png';
import {
  getTitleInfo, getCommerceInfo,
  getRadarMap, getBarCharMap, getValuationEnterprise, getCorrelationNewsList,
  getQualityCertification, getPartnership, getPatentStatistics, getFindPatentList,
  getPolicyecRommend, getStockRightsPenetrate,
} from '../../api/companyDetail';
import style from './index.less';

function CompanyDetail(props) {
  const { location,history } = props;
  const oId = location.state ? location.state.companyId : location.search?.split('=')[1];
  const companyId = localStorage.getItem('keyId') || oId;
  const [titleInfo, setTitleInfo] = useState({});
  const [commerceInfo, setCommerceInfo] = useState({});
  const [stockRightsPenetrate, setStockRightsPenetrate] = useState({});
  const [radar, setRadar] = useState({});
  const [barChart, setBarChart] = useState({});
  const [valuationEnterprise, setValuationEnterprise] = useState({});
  const [correlationNewsList, setCorrelationNewsList] = useState({});
  const [qualityCertification, setQualityCertification] = useState({});
  const [partnership, setPartnership] = useState({});
  const [patentStatistics, setPatentStatistics] = useState({});
  const [patentList, setPatentList] = useState({});
  const [policyList, setPolicyList] = useState({});

  const getList = (p) => {
    getCorrelationNewsList({
      company_id: companyId,
      page_size: 5,
      ...p,
    }).then(res => {
      if (res.status === '00000') {
        setCorrelationNewsList(res.data);
      }
    });
  };

  const getPolicyList = (p) => {
    getPolicyecRommend({
      company_id: companyId,
      page_size: 5,
      page_number: 1,
      ...p,
    }).then(res => {
      if (res.status === '00000') {
        setPolicyList(res.data);
      }
    });
  };

  const getPatentList = (p) => {
    getFindPatentList({
      company_id: companyId,
      page_size: 6,
      page_number: 1,
      ...p,
    }).then(res => {
      if (res.status === '00000') {
        const patentTableColumns = res.data.columns.map(item => {
          let obj = {};
          if (item.dataIndex === 'industry') {
            obj = {
              ...item,
              align: 'left',
              ellipsis: true,
              width: '35%',
            };
          } else if (item.dataIndex === 'index') {
            obj = {
              ...item,
              align: 'left',
              width: '8%',
              ellipsis: true,
              render: (text) => (
                <div className={style.patent_name}>{text}</div>
              ),
            };
          } else if (item.dataIndex === 'patent_name') {
            obj = {
              ...item,
              align: 'left',
              width: '35%',
              ellipsis: true,
              // render: (text) => (
              //   <div className={style.patent_name}>{text}</div>
              // ),
            };
          } else if (item.dataIndex === 'notice_date') {
            obj = {
              ...item,
              align: 'left',
              ellipsis: true,
              render: (text) => (
                <div className={style.noticeDate}>{text}</div>
              ),
            };
          } else {
            obj = {
              ...item,
              align: 'left',
              ellipsis: true,
            };
          }
          return obj;
        });
        setPatentList({ ...res.data, columns: patentTableColumns });
      }
    });
  };

  useEffect(() => {
    getTitleInfo({
      company_id: companyId,
    }).then(res => {
      if (res.status === '00000') {
        setTitleInfo(res.data);
      }
    });
    getCommerceInfo({
      company_id: companyId,
    }).then(res => {
      if (res.status === '00000') {
        setCommerceInfo(res.data);
      }
    });
    getStockRightsPenetrate({
      company_id: companyId,
    }).then(res => {
      if (res.status === '00000') {
        setStockRightsPenetrate(res.data);
      }
    });
    getRadarMap({
      company_id: companyId,
    }).then(res => {
      if (res.status === '00000') {
        setRadar(res.data);
      }
    });
    getBarCharMap({
      company_id: companyId,
    }).then(res => {
      if (res.status === '00000') {
        setBarChart(res.data);
      }
    });
    getValuationEnterprise({
      company_id: companyId,
    }).then(res => {
      if (res.status === '00000') {
        setValuationEnterprise(res.data);
      }
    });
    getList({
      page_size: 5,
      page_number: 1,
    });
    getQualityCertification({
      company_id: companyId,
    }).then(res => {
      if (res.status === '00000') {
        setQualityCertification(res.data.data);
      }
    });
    getPartnership({
      company_id: companyId,
    }).then(res => {
      if (res.status === '00000') {
        setPartnership(res.data);
      }
    });
    getPatentStatistics({
      company_id: companyId,
    }).then(res => {
      if (res.status === '00000') {
        setPatentStatistics(res.data);
      }
    });
    getPatentList();
    getPolicyList();
  }, []);
  return (
    <div className={style.all}>
      <div className={style.header}>
        <span>企业详情</span>
        <span style={{cursor: 'pointer'}} onClick={() => {
          history.push({
            pathname: '/searchCompany',
            state: {
              searchContent: localStorage.getItem('osearchValue') || '',
            },
          });
          localStorage.removeItem('keyId');
          localStorage.removeItem('osearchValue');
        }}>
          <img src={back} alt="" />
        </span>
      </div>
      <div className={style.content}>
        {
          useMemo(() => (titleInfo && (<CompanyInfo data={titleInfo} />)), [titleInfo])
        }
        <div className={style.companyPortrait}>
          <div className={style.title}>企业画像</div>
          {
            useMemo(() => (commerceInfo && (
              <Container title="工商基本信息">
                <BusinessInfo data={commerceInfo} />
              </Container>
            )), [commerceInfo])
          }
          {
            useMemo(() => (Object.keys(stockRightsPenetrate).length > 0 && (
              <Container title="股权结构" height="610px">
                <D3ThroughChart
                  data={stockRightsPenetrate}
                  rootName={stockRightsPenetrate.downward.name}
                />
              </Container>
            )), [stockRightsPenetrate])
          }
          {
            useMemo(() => (
              <Container title="综合评级" height="300px">
                <ComprehensiveRating radarData={radar} barData={barChart} />
              </Container>
            ), [radar, barChart])
          }
          {
            useMemo(() => (valuationEnterprise && (
              <Container title="" height="165px">
                <FiveIndexChart data={valuationEnterprise} />
              </Container>
            )), [valuationEnterprise])
          }
          {
            useMemo(() => (Object.keys(correlationNewsList).length > 0
              && correlationNewsList.data_list && correlationNewsList.data_list.length > 0 && (
              <Container title="新闻动态">
                <NewsDynamic data={correlationNewsList} getList={getList} type="news" />
              </Container>
            )), [correlationNewsList])
          }
          {
            useMemo(() => (qualityCertification
              && qualityCertification.children && qualityCertification.children.length > 0 && (
              <Container title="资质认定" height="750px">
                <CertificationChart data={[qualityCertification]} orient="LR" />
              </Container>
            )), [qualityCertification])
          }
          {
            useMemo(() => (Object.keys(partnership).length > 0 && (
              <Container title="伙伴关系" height="750px">
                <TreeRadialChart2 data={[partnership]} />
              </Container>
            )), [partnership])
          }
          {
            useMemo(() => (
              patentList.dataSource && patentList.dataSource.length > 0 && (
                <Container title="专利分析" height="300px">
                  <BarAndTable
                    barData={patentStatistics}
                    listData={patentList}
                    getList={getPatentList}
                  />
                </Container>
              )
            ), [patentStatistics, getPatentList, patentList])
          }
          {
            useMemo(() => (Object.keys(policyList).length > 0 && (
              <Container title="政策推荐">
                <NewsDynamic data={policyList} getList={getPolicyList} type="policy" companyId={companyId}/>
              </Container>
            )), [policyList])
          }
        </div>
      </div>
    </div>
  );
}

export default withRouter(CompanyDetail);
