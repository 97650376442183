import { post } from '../utils/request';
import { ACTIVITY } from '../utils/url';

// 首页-行业活动信息
const getActivityInfoList = param => post(`${ACTIVITY}/industry`, {
  ...param,
});

export {
  getActivityInfoList,
};
