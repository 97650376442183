/*
 *Author: zhaochenyu
 *Description: 坐标系热力图
 *Date: 2020/12/22
*/

import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import ReactEcharts from 'echarts-for-react';
import { heatMapCOlor } from '../../../utils/color';
import { tooltipFormatter } from '../../../utils/charts';
import { addWaterMark } from '../../../utils/report';

const CoordHeatMap = forwardRef((props, ref) => {
  const [option, setOption] = useState({});
  const { data, color, title, source = '' } = props;
  const chartRef = useRef(null);

  const getHeatMapData = datas => {
    const { abscissa_list: xData, detail_list: yList } = datas;
    const yData = yList.map(item => item.target);
    const chartData = [];
    yList.forEach((item, index) => {
      item.ordinate.forEach((item1, index1) => {
        chartData.push({
          name: item.target,
          xName: xData[index1],
          value: [index1, index, item1],
          unit: item.unit,
        });
      });
    });
    return {
      xData,
      yData,
      chartData,
    };
  };

  useImperativeHandle(ref, () => ({
    getCanvas({ pixelRatio = 2 }) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: '#fff',
      }));
    },
    toDataURL({ pixelRatio = 2 }) {
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: '#fff',
      }), pixelRatio)));
    },
  }));

  useEffect(() => {
    const newData = getHeatMapData(data);
    const { xData, yData, chartData } = newData;
    const numList = chartData.filter(item => !!parseFloat(item.value[2]));
    const options = {
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (params) => {
          let str;
          const { data: { name, value, unit, xName } } = params;
          if (source) {
            str = '<div style="padding: 9px 9px 4px 9px">';
            if (title) {
              str += `<div style="margin-bottom: 10px; font-size: 14px; font-weight: bold; color: #3E5C76;letter-spacing: 1.75px;">${title}</div>`;
            }
            str += `<div style="margin-bottom: 10px; font-size: 14px; color: #3E5C76;letter-spacing: 1.75px;">${xName}</div>`;
            str += `<div style="display: flex; justify-content: space-between; color: #262D36;">
              <span style="margin-right: 60px">${name}</span>
              <span>
                <span style="color: #4E7293;font-weight: 600;font-size: 18px;margin-right: 4px;">${value[2]}</span>
                ${unit || ''}
              </span>
            </div>`;
            str += '</div>';
          } else {
            str = tooltipFormatter(params, { title });
          }
          return str;
        },
        backgroundColor: source ? '#fff' : 'rgba(65, 89, 76, 0.8)',
        extraCssText: source ? 'border: 1px solid #DCDCDC; border-radius: 4;box-shadow: 2px 5px 8px 0 rgba(108,117,125,0.32);'
          : 'border: 1px solid #a4c3b2; border-radius: 0',
      },
      grid: {
        top: 20,
        right: 25,
        bottom: source ? 10 : '5%',
        left: '2%',
        containLabel: true,
      },
      backgroundColor: '#fff',
      xAxis: {
        type: 'category',
        data: xData,
        axisLine: {
          lineStyle: {
            color: '#C4C4C4',
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          interval: 0,
          rotate: 45,
          color: '#919191',
          fontSize: 10,
        },
        splitArea: {
          show: true,
        },
      },
      yAxis: {
        type: 'category',
        data: yData,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        splitArea: {
          show: true,
        },
        axisLabel: {
          fontSize: 10,
          color: '#919191',
          formatter: (param) => (param.length > 6 ? `${param.slice(0, 6)}...` : param),
        },
      },
      visualMap: {
        show: false,
        min: Math.min(...numList.map(item => item.value[2])),
        max: Math.max(...numList.map(item => item.value[2])),
        splitNumber: 16,
        inRange: {
          color,
        },
      },
      dataZoom: [{
        type: 'slider',
        start: 0,
        end: 50,
        height: 6,
        handleIcon: 'M512 512m-208 0a6.5 6.5 0 1 0 416 0 6.5 6.5 0 1 0-416 0Z M512 192C335.264 192 192 335.264 192 512c0 176.736 143.264 320 320 320s320-143.264 320-320C832 335.264 688.736 192 512 192zM512 800c-159.072 0-288-128.928-288-288 0-159.072 128.928-288 288-288s288 128.928 288 288C800 671.072 671.072 800 512 800z',
        bottom: 5,
        showDetail: false,
        handleColor: '#aab6c6',
        borderColor: 'transparent',
        backgroundColor: '#e2e2e2',
        handleSize: 10,
        handleStyle: {
          borderColor: '#aab6c6',
          shadowBlur: 4,
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowColor: '#e5e5e5',
        },
      }],
      series: [{
        name: '',
        type: 'heatmap',
        data: chartData,
        label: {
          show: true,
          fontSize: 8,
        },
        itemStyle: {
          borderWidth: 1,
          borderColor: '#fff',
        },
      }],
    };
    if (source) {
      delete options.dataZoom;
    }
    setOption(options);
  }, [data]);

  return <ReactEcharts
    option={option}
    style={{ width: '100%', height: '100%' }}
    ref={chartRef}
  />;
});

export default CoordHeatMap;

CoordHeatMap.defaultProps = {
  color: heatMapCOlor,
};
