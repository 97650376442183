/**
 *Author:JCL
 *Description: 企业库搜索页面
 *Date：2022/07/11
 * */
import React, { useEffect, useState } from 'react';
import { Input, List, message, Pagination, Button } from 'antd';
import style from './index.less';
import CompanyItem from './components/CompanyItem';
import SuperSearch from './components/SuperSearch';
import {
  getCompanyList,
  getCompanySpecialTags,
  getCompanyStatus,
} from '../../../api/company';
import {
  getIndustryList,
  getAreaList,
} from '../../../api/industry';

const { Search } = Input;

function SearchCompany(props) {
  const [searchValue, setSearchValue] = useState(props.location.state?.searchContent || '');
  const [showLoading, setShowLoading] = useState(false);
  const [companyInfoList, setCompanyInfoList] = useState([]);
  const [companyTotalNum, setCompanyTotalNum] = useState(0);
  const [companyStatusList, setCompanyStatusList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  // 查询条件
  const [formPageNum, setFormPageNum] = useState(1);
  const [formPageSize, setFormPageSize] = useState(10);
  const [formCompanyName, setFormCompanyName] = useState('');
  const [formSpecialTagList, setFormSpecialTagList] = useState([]);
  const [formCompanyStauts, setFormCompanyStauts] = useState([]);
  const [formIndustry, setFormIndustry] = useState([]);
  const [formArea, setFormArea] = useState([]);
  const [formDate, setFormDate] = useState([]);
  const [formRegCapital, setFormRegCapital] = useState([0, 0]);

  function getCheckedSpecialTags() {
    let returnTagsList = [];
    const filterResult = formSpecialTagList.filter((tmpTag) => tmpTag.checked);
    if (filterResult.length > 0 && filterResult[0].value !== '不限') {
      returnTagsList = filterResult.map(tmpTag => tmpTag.value);
    }

    return returnTagsList;
  }

  const searchCompanys = (params) => {
    setShowLoading(true);
    getCompanyList({
      ...params,
      keyword: formCompanyName || searchValue,
      especial_tag_list: getCheckedSpecialTags(),
      company_status_list: formCompanyStauts,
      industry_code_list: formIndustry,
      area_code_list: formArea,
      register_date_list: formDate,
      company_register_capital_list: formRegCapital[1] === 0 ? [] : formRegCapital,
    }).then(res => {
      if (res.status === '00000') {
        // 获取成功
        setCompanyInfoList(res.data.data_list);
        setCompanyTotalNum(res.data.data_count);
        setShowLoading(false);
      } else {
        message.error(res.message);
        setShowLoading(false);
      }
    });
  };

  const onPageChange = (page, pageSize) => {
    setFormPageNum(page);
    setFormPageSize(pageSize);
    searchCompanys({
      page_num: page,
      page_size: pageSize,
    });
  };

  // 修正后端返回的数据以适配TreeSelect组件所需数据
  const resolveServerData = (data) => {
    const item = [];
    data.map((list) => {
      const newData = {};
      newData.title = list.title;
      newData.value = list.value;
      newData.children = list.children.length > 0 ? resolveServerData(list.children) : [];
      item.push(newData);
      return false;
    });
    return item;
  };

  useEffect(() => {
    searchCompanys();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formCompanyName]);

  useEffect(() => {
    searchCompanys();
    getCompanySpecialTags().then((res) => {
      if (res.status === '00000') {
        const tmpSpecialTags = Object.keys(res.data).map((item) => ({
          value: item,
          checked: false,
        }));
        tmpSpecialTags.unshift({
          value: '不限',
          checked: true,
        });
        setFormSpecialTagList(tmpSpecialTags);
      } else {
        message.error(res.message);
      }
    });
    getCompanyStatus().then((res) => {
      if (res.status === '00000') {
        const tmpStatusList = res.data.map((item) => ({
          value: item,
          lebel: item,
        }));
        setCompanyStatusList(tmpStatusList);
      } else {
        message.error(res.message);
      }
    });
    getIndustryList().then(res => {
      if (res.status === '00000') {
        const tmpIndustryList = res.data;
        setIndustryList(tmpIndustryList);
      }
    });
    getAreaList().then(res => {
      if (res.status === '00000') {
        const tmpAreaList = resolveServerData(res.data);
        setAreaList(tmpAreaList);
      }
    });
  }, []);

  const execSearchWork = (value) => {
    setFormPageNum(1);
    setFormPageSize(10);
    setFormCompanyName(value);
  };

  const resetForm = () => {
    // setFormCompanyName('');
    // setFormSpecialTagList([]);
    // setFormCompanyStauts('');
    // setFormIndustry('');
    // setFormArea('');
  };

  return (
    <div className={style.all}>
      <div className={style.searchMain}>
        <Search placeholder="请输入企业名称" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} enterButton="搜索" size="large" loading={showLoading} onSearch={execSearchWork} />
      </div>
      <div className={style.superSearch}>
        <SuperSearch
          formSpecialTagList={formSpecialTagList}
          setFormSpecialTagList={setFormSpecialTagList}
          companyStatusList={companyStatusList}
          setFormCompanyStauts={setFormCompanyStauts}
          setFormIndustry={setFormIndustry}
          industryList={industryList}
          areaList={areaList}
          setFormArea={setFormArea}
          setFormDate={setFormDate}
          setFormRegCapital={setFormRegCapital}
          formRegCapital={formRegCapital}
        />
        <div className={style.formOperate}>
          <Button type="primary" style={{ margin: '10px' }} onClick={() => searchCompanys()}>查询</Button>
          <Button style={{ margin: '10px' }} onClick={() => resetForm()}>重置</Button>
        </div>
      </div>
      <div className={style.resultList}>
        <List
          itemLayout="horizontal"
          split={false}
          loading={showLoading}
          dataSource={companyInfoList}
          renderItem={(item) => (
            <List.Item>
              <CompanyItem companyInfo={item} searchValue={searchValue}/>
            </List.Item>
          )}
        />
      </div>
      <div className={style.seletPage}>
        <Pagination
          onChange={onPageChange}
          total={companyTotalNum}
          current={formPageNum}
          defaultCurrent={formPageNum}
          defaultPageSize={formPageSize}
          showQuickJumper
        />
      </div>
    </div>
  );
}

export default SearchCompany;
