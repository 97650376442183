/*
 *Author: zhaochenyu
 *Description: 一些处理url的方法
 *Date: 2021/08/26
*/
import { projectName } from './url';
import { checkLogin } from './tool';
import { Modal } from 'antd';

function getCurUrlData(url, key) {
  const urlSearch = url.split('?').length > 0 ? url.split('?')[1]: '';
  if (!urlSearch) return '';
  const param = new URLSearchParams(urlSearch);
  return param.get(key);
}

function openNewPage(path, query) {
  if (!checkLogin()) {
    Modal.warning({
      centered: true,
      content: '您还没有登录！',
      okText: '去登录',
      keyboard: false,
      onOk: () => {
        window.location.href = `${window.location.href.split('#')[0]}#/login`;
        sessionStorage.clear();
      },
      width: 416,
    });
    return;
  }
  const a = [];
  let url = `${window.location.origin}/${process.env.NODE_ENV === 'development' ? '' : `${projectName}/`}#/${path}`;
  for (const key in query) {
    a.push(`${key}=${query[key]}`);
  }
  if (a.length > 0) {
    url += `?${a.join('&')}`;
  }
  window.open(url);
}

function changeSelfPage(path, query) {
  const a = [];
  let url = `${window.location.origin}/${process.env.NODE_ENV === 'development' ? '' : `${projectName}/`}#/${path}`;
  for (const key in query) {
    a.push(`${key}=${query[key]}`);
  }
  if (a.length > 0) {
    url += `?${a.join('&')}`;
  }
  window.location.href = url;
}

export {
  getCurUrlData,
  openNewPage,
  changeSelfPage,
};
