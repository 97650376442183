import { get, post } from '../utils/request';
import { INDUSTRYPOLICY } from '../utils/url';

// 获取政策 typeList
const getPolicyTypes = param => get(`${INDUSTRYPOLICY}/policy_contents`, {
  ...param,
});

// 获取政策 list
const getPolicyList = param => post(`${INDUSTRYPOLICY}/search`, {
  ...param,
});

// 获取政策 detail
const getPolicyDetail = param => get(`${INDUSTRYPOLICY}/detail`, {
  ...param,
});

// 获取相关政策
const getPolicyRelevantList = param => get(`${INDUSTRYPOLICY}/recommendation`, {
  ...param,
});

// 获取政策解读
const getPolicyUnscrambleRelevantList = param => get(`${INDUSTRYPOLICY}/unscramble/recommendation`, {
  ...param,
});

export {
  getPolicyTypes,
  getPolicyList,
  getPolicyDetail,
  getPolicyRelevantList,
  getPolicyUnscrambleRelevantList,
};
