/*
* Author: zhangmeng
* Description: '企业评价-条形图'
* Date: 2022/04/28
*/

import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';

function BarChart(props) {
  const { data } = props;
  const { abscissa_list: abscissaList,
    detail_list: detailList, abscissa_right_list: yAxis2 } = data;
  const [option, setOption] = useState({});

  useEffect(() => {
    const colorSelector = (params) => {
      let color1 = '';
      let color2 = '';
      if (params >= 80) {
        color1 = '#008DC7';
        color2 = '#008DC7';
      } else if (params < 80 && params >= 60) {
        color1 = '#3AA0FF';
        color2 = '#3AA0FF';
      } else if (params < 60 && params >= 40) {
        color1 = '#00CFFF';
        color2 = '#00CFFF';
      } else {
        color1 = '#17CDB5';
        color2 = '#17CDB5';
      }
      return {
        type: 'linear',
        x: 1,
        y: 0,
        x2: 0,
        y2: 0,
        colorStops: [{
          offset: 0, color: color1,
        }, {
          offset: 1, color: color2,
        }],
        global: false, // 缺省为 false
      };
    };

    const newAxis2 = yAxis2.map((item) => {
      const name = item.split('').splice(4);
      const head = item.split('').splice(0, 4).join('');
      const footer = item.split('').splice(-2).join('');
      const num1 = name.slice(0, -2).join('');
      return `${head}${num1}%${footer}`;
    });

    const newData = yAxis2.map((item, index) => {
      const name = item.split('').splice(4);
      const numArr = name.slice(0, -2);
      const num = parseInt(numArr.join(''));
      return {
        value: detailList[0].ordinate[index],
        itemStyle: {
          color: colorSelector(Number(num)),
        },
      };
    });

    const options = {
      tooltip: {
        trigger: 'axis',
        textStyle: {
          color: '#262D36',
          fontSize: 14,
        },
        backgroundColor: '#fff',
      },
      // legend: {
      //   bottom: 10,
      //   textStyle: {
      //     color: '#919191',
      //   },
      //   itemHeight: 8,
      //   itemWidth: 16,
      //   data: [detailList[0].target],
      // },
      grid: {
        top: '10',
        bottom: '10%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        offset: 20,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          fontSize: 12,
          color: '#AAAAAA',
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: [
        {
          type: 'category',
          inverse: true,
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: '#777A7A',
            fontSize: 12,
            formatter: params => {
              let name = '';
              if (params === '综合得分排名') {
                name = `{black|${params}}`;
              } else {
                name = params;
              }
              return name;
            },
            rich: {
              black: {
                fontSize: 12,
                fontWeight: 600,
                color: '#262D36',
              },
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
            },
          },
          data: abscissaList,
        },
        {
          type: 'category',
          offset: 50,
          inverse: true,
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: '#262D36',
            formatter: params => {
              const name = params.split('');
              const header = name.splice(0, 4).join('');
              const footer = name.splice(-2).join('');
              const num = name.join('');
              return `{word|${header}} {num|${num}} {word|${footer}}`;
            },
            rich: {
              num: {
                fontSize: 12,
                color: '#1492FF',
              },
              word: {
                fontSize: 12,
                color: '#AAAAAA',
              },
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
            },
          },
          data: newAxis2,
        },
      ],
      series: [
        {
          name: detailList[0].target,
          type: 'bar',
          barWidth: 24,
          label: {
            show: true,
            position: 'right',
            fontSize: 12,
            color: '#AAAAAA',
            distance: 5,
          },
          data: newData,
        },
      ],
    };
    setOption(options);
  }, [abscissaList, data, detailList, yAxis2]);

  return (
    <ReactEcharts
      option={option}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default BarChart;
