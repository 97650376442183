/**
 *Author: zhaochenyu
 *Description: 多圆环图
 *Date: 2020/11/04
*/

import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { areaPieColor } from '../../../utils/color';

function ManyRingPieChart(props) {
  const { dataSource, color, title } = props;
  const [options, setOption] = useState({});

  useEffect(() => {
    const total = dataSource.reduce((prev, cur) => (prev + cur.value), 0);
    const series = dataSource.map((item, index) => ({
      name: item.target,
      type: 'pie',
      hoverAnimation: true,
      hoverOffset: 6,
      minAngle: 10,
      radius: [`${16 + index * 10}%`, `${22 + index * 10}%`],
      center: ['50%', '44%'],
      label: {
        show: false,
      },
      data: [{
        name: item.target,
        value: item.value,
        unit: item.unit,
      }, {
        name: '',
        value: total - item.value,
        itemStyle: {
          color: 'rgba(0,0,0,0)',
          borderWidth: 0,
        },
        tooltip: {
          show: false,
        },
        hoverAnimation: false,
      }],
    }));
    const option = {
      legend: {
        show: true,
        icon: 'circle',
        left: 15,
        bottom: 4,
        selectedMode: false,
        data: dataSource.map(item => item.target),
        // itemGap: 15,
        itemHeight: 10,
        itemWidth: 10,
        textStyle: {
          fontSize: 12,
          color: '#919191',
        },
      },
      tooltip: {
        confine: true,
        formatter: (params) => {
          let str = '<div style="padding: 9px 9px 4px 9px">';
          if (title) {
            str += `<div style="margin-bottom: 10px; font-size: 14px; font-weight: bold; color: #3E5C76;letter-spacing: 1.75px;">${title}</div>`;
          }
          const { data: { name, value, unit } } = params;
          str += `<div style="display: flex; justify-content: space-between; color: #262D36;">
            <span style="margin-right: 60px">${name}</span>
            <span>
              <span style="color: #4E7293;font-weight: 600;font-size: 18px;margin-right: 4px;">${value}</span>
              ${unit}
            </span>
          </div>`;
          str += '</div>';
          return str;
        },
        backgroundColor: '#fff',
        extraCssText: 'border: 1px solid #DCDCDC; border-radius: 4;box-shadow: 2px 5px 8px 0 rgba(108,117,125,0.32);',
      },
      color,
      series,
    };
    setOption(option);
  }, [dataSource]);

  return <ReactEcharts
    option={options}
    style={{ width: '100%', height: '100%' }}
  />;
}

ManyRingPieChart.defaultProps = {
  color: areaPieColor,
};

export default ManyRingPieChart;
