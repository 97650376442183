/*
* Author: zhangmeng
* Description: '组件容器'
* Date: 2022/07/13
*/

import React from 'react';
import style from './index.less';

function Container(props) {
  const { title, children, width, height, bg } = props;

  return (
    <div className={style.all} style={{ width }}>
      {
        title && (
          <div className={style.titleBox}>
            <div className={style.line} />
            <span className={style.title}>{title}</span>
            <div className={style.line1} />
          </div>
        )
      }
      <div className={style.detail} style={{ height, background: bg }}>
        {children}
      </div>
    </div>
  );
}

export default Container;
