/*
 *Author: zhaochenyu
 *Description: 带分页的表格
 *Date: 2021/12/14
*/

import React from 'react';
import { Table, Pagination } from 'antd';
import style from '../../../AtlasDetail/Common/AltasTable/index.less';

function TableWIthPagination(props) {
  const { data, scrollY, hanldleCondition, curPagination, setCurPagination,
    getCompnayList, handleWithHead, industryTableChange } = props;
  const { tableHead, tableBody, count } = data;

  const paginationChange = (pageNumber, pageSize) => {
    hanldleCondition({
      page_number: pageNumber,
      page_size: pageSize,
    });
    setCurPagination({
      pageNum: pageNumber,
      pageSize,
    });
    getCompnayList();
  };

  return (
    <div className={style.industryChainTable}>
      <Table
        rowKey="index"
        columns={handleWithHead(tableHead)}
        dataSource={tableBody}
        pagination={false}
        onChange={typeof industryTableChange === 'function' ? industryTableChange : undefined}
        className={style.table}
        scroll={{ y: scrollY }}
      />
      {count > 0 && (
      <div className={style.pagination}>
        <Pagination
          showQuickJumper
          hideOnSinglePage
          total={count > 10000 ? 10000 : count}
          pageSizeOptions={[20, 30, 50]}
          current={curPagination.pageNum}
          pageSize={curPagination.pageSize}
          onChange={paginationChange}
        />
      </div>
      )}
    </div>
  );
}

export default TableWIthPagination;
