import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import styles from './index.less';

function CustomRadio({
  onChange,
  label,
  defaultType = 'text',
  isExtend = false,
  list = [],
  className,
}) {
  const [type, setType] = useState('');
  const [extendAll, setExtendAll] = useState(false);
  const [signIndex, setSignIndex] = useState(-1);
  useEffect(() => {
    if (isExtend) {
      let textLen = 0;
      let maxLen = 23;
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        textLen += item.name.length;
        if (textLen >= maxLen) {
          setSignIndex(i);
          break;
        }
      }
    }
  }, []);
  return (
    <div className={`${styles.customRadio} ${className} flex`}>
      <div
        className={styles.label}
        style={{ minWidth: label.length <= 2 ? '46px' : '70px' }}
      >
        {label && `${label}：`}
      </div>
      <div style={{ width: '100%' }}>
        {signIndex >= 0 ? (
          <div className="flex spaceBetween">
            <div>
              {list.map((item, index) => {
                if (extendAll || index <= signIndex) {
                  return (
                    <Button
                      type={type === item.value ? 'primary' : defaultType}
                      key={`${item?.value}-${index}`}
                      className={defaultType !== 'text' ? styles.spacing : ''}
                      onClick={() => {
                        setType(item?.value);
                        onChange && onChange(item?.value);
                      }}
                    >
                      {item?.name}
                    </Button>
                  );
                }
              })}
            </div>
            {extendAll ? (
              <Button type="link" onClick={() => setExtendAll(false)}>
                收起 <CaretUpOutlined />
              </Button>
            ) : (
              <Button type="link" onClick={() => setExtendAll(true)}>
                展开 <CaretDownOutlined />
              </Button>
            )}
          </div>
        ) : (
          list.map((item, index) => {
            return (
              <Button
                type={type === item.value ? 'primary' : defaultType}
                key={`${item?.value}-${index}`}
                className={defaultType !== 'text' ? styles.spacing : ''}
                onClick={() => {
                  setType(item?.value);
                  onChange && onChange(item?.value);
                }}
              >
                {item?.name}
              </Button>
            );
          })
        )}
      </div>
    </div>
  );
}

export default CustomRadio;
