// 公司详情tags颜色
const companyTabsColorMap = {
  产业类型: {
    borderColor: '#DEF0FF',
    background: '#DEF0FF',
    color: '#1492FF',
  },
  试点示范: {
    borderColor: '#DDFFEC',
    background: '#DDFFEC',
    color: '#00B572',
  },
  融资情况: {
    borderColor: 'rgb(255,234,212)',
    background: 'rgb(255,234,212)',
    color: 'rgb(224,173,143)',
  },
  资质认证: {
    borderColor: '#FFF7DD',
    background: '#FFF7DD',
    color: '#FDAD61',
  },
  官网地址: {
    borderColor: '#B8C7B2',
    background: '#DAE4D6',
    color: '#7d837a',
  },
  联系电话: {
    borderColor: '#EADBBB',
    background: '#FFF5E1',
    color: '#e6a71d',
  },
  融资阶段: {
    borderColor: '#FFE9D3',
    background: '#FFE9D3',
    color: '#F88C6B',
  },
  获奖情况: {
    borderColor: '#FFD9D9',
    background: '#FFD9D9',
    color: '#FF5A17',
  },
  市场准入: {
    borderColor: '#A5A58D',
    background: '#E0E0D5',
    color: '#5B5B48',
  },
  业务领域: {
    borderColor: '#EADBBB',
    background: '#FFF5E1',
    color: '#4D3B17',
  },
  产品信息: {
    borderColor: '#D7BAA7',
    background: '#EDDDD3',
    color: '#562020',
  },
  其他标签: {
    borderColor: '#B8C7B2',
    background: '#DAE4D6',
    color: '#2C3B3C',
  },
  other: {
    borderColor: '#A9BDD5',
    background: '#D4E0EE',
    color: '#0D3B66',
  },
  其他: {
    borderColor: '#A9BDD5',
    background: '#D4E0EE',
    color: '#0D3B66',
  },
  专利其他标签: {
    borderColor: '#A9BDD5',
    background: '#D4E0EE',
    color: '#0D3B66',
  },
  标签标注: {
    borderColor: '#CBFAFF',
    background: '#CBFAFF',
    color: '#00C2D7',
  },
};

const companyInfo1 = {
  address: '北京市海淀区海淀大街34号8层818室',
  companyName: '北京神州泰岳软件股份有限公司',
  logo: '北京神州',
  tags: [
    {
      type: '产业类型',
      tabs: [
        '电子信息',
        '数字经济',
        '人工智能',
        '现代服务业',
        '软件与信息服务',
        '仪器仪表',
        '互联网',
        '网络信息安全',
        '新一代信息技术',
        '物联网',
      ],
    },
    {
      type: '试点示范',
      tabs: [
        '北京市软件和信息服务业重点调度监测企业（2017）',
        '国家高新区瞪羚企业（2015）',
        '北京市企业研究开发项目（2015）',
        '北京市企业研究开发项目（2014）',
        '北京市企业研究开发项目（2013）',
        '北京市企业研究开发项目（2012）',
        '中关村瞪羚企业（2012）',
        '北京市企业研究开发项目（2011）',
        '中关村瞪羚企业（2011）',
        '国家规划布局内重点软件企业（2010）',
        '中关村瞪羚企业（2010）',
        '北京市自主创新产品（2009）',
      ],
    },
    {
      type: '资质认证',
      tabs: [
        '计算机信息系统集成资质',
        '高新技术企业',
      ],
    },
    {
      type: '融资阶段',
      tabs: [
        'IPO',
        '创业板上市',
      ],
    },
    {
      type: '获奖情况',
      tabs: [
        '人工智能企业百强（2020）',
        '人工智能企业综合实力百强（2019）',
      ],
    },
  ],
  rate: 'S',
  score: 88.38,
  tooltip: {},
};
// 工商信息
const gsxx = {
  registerCapital: '10000.0万元',
  setUpDate: '2018-01-31',
  companyType: '',
  representative: '闫小玲',
  status: '存续（在营、开业、在册）',
  industry: '电子信息,人工智能,科技服务,物联网,大健康,生命健康',
  businessScope: '技术推广服务；软件开发；计算机系统服务；数据处理；供应链管理；经济贸易咨询；企业管理咨询；健康咨询（须经审批的诊疗活动除外）；销售计算机、软件及辅助设备、I、II类医疗器械、日用品、化妆品；仓储服务；电脑图文设计；会议服务；承办展览展示活动；设计、制作、代理、发布广告；货物进出口、代理进出口、技术进出口；销售食品；销售第III类医疗器械；经营电信业务；互联网信息服务；保险代理业务；保险经纪业务。（市场主体依法自主选择经营项目，开展经营活动；销售食品、销售第III类医疗器械、经营电信业务、互联网信息服务、保险代理业务、保险经纪业务以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）',
};
// 股权结构
const gqjg = {
  desc: '',
  upward: {
    direction: 'upward',
    name: '华为技术有限公司',
    children: [
      {
        name: '华为投资控股有限公司',
        amount: 3990813.182,
        direction: 'upward',
        children: [
          {
            name: '华为投资控股有限公司工会委员会',
            amount: 0,
            direction: 'upward',
            children: [],
          },
          {
            name: '任正非',
            amount: 27606.7314,
            direction: 'upward',
            children: [],
          },
        ],
      },
    ],
  },
  downward: {
    direction: 'downward',
    name: '华为技术有限公司',
    children: [
      {
        name: '华为精密制造有限公司',
        amount: 0,
        direction: 'downward',
        children: [],
      },
      {
        name: '中电科技德清华莹电子有限公司',
        amount: 0,
        direction: 'downward',
        children: [
          {
            name: '中电科技德清华莹电子有限公司华南分公司',
            amount: 12012.66,
            direction: 'downward',
            children: [],
          },
        ],
      },
      {
        name: '北京华为数字技术有限公司',
        amount: 30000,
        direction: 'downward',
        children: [],
      },
      {
        name: '华为软件技术有限公司',
        amount: 0,
        direction: 'downward',
        children: [],
      },
      {
        name: '华为技术（天津）有限公司',
        amount: 0,
        direction: 'downward',
        children: [],
      },
      {
        name: '华为技术服务（辽宁）有限公司',
        amount: 0,
        direction: 'downward',
        children: [],
      },
      {
        name: '华为数字能源技术有限公司',
        amount: 0,
        direction: 'downward',
        children: [
          {
            name: '西安华为数字能源技术有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '上海华为数字能源技术有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '华为电动技术有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '华为数字技术（苏州）有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
        ],
      },
      {
        name: '苏州华为技术研发有限公司',
        amount: 0,
        direction: 'downward',
        children: [],
      },
      {
        name: '深圳哈勃科技投资合伙企业（有限合伙）',
        amount: 0,
        direction: 'downward',
        children: [
          {
            name: '杰华特微电子（杭州）有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '重庆鑫景特种玻璃有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '美芯晟科技（北京）有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '深圳开鸿数字产业发展有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '苏州晶拓半导体科技有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '重庆励颐拓软件有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '上海先普气体技术有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '深圳永动科技有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '湖南德智新材料有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '上海赛美特软件科技有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '武汉亘星智能技术有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '福建海创光电有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '强一半导体（苏州）有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '青岛天仁微纳科技有限责任公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '深迪半导体（上海）有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '东莞市天域半导体科技有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '深圳云英谷科技有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '北京知存科技有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '重庆物奇微电子有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '徐州博康信息化学品有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '费勉仪器科技（上海）有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
        ],
      },
      {
        name: '深圳市三维电器有限公司',
        amount: 0,
        direction: 'downward',
        children: [],
      },
      {
        name: '上海华为信息技术有限公司',
        amount: 0,
        direction: 'downward',
        children: [],
      },
      {
        name: '华为电动技术有限公司',
        amount: 25000,
        direction: 'downward',
        children: [],
      },
      {
        name: '深圳市红土善利私募股权投资基金合伙企业（有限合伙）',
        amount: 19000,
        direction: 'downward',
        children: [
          {
            name: '湖北九同方微电子有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '杰华特微电子（杭州）有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '上海立芯软件科技有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '杭州行芯科技有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '广州颖力土木科技有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '上海赛美特软件科技有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '苏州纳芯微电子股份有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '无锡飞谱电子信息技术有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '上扬软件（上海）有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
        ],
      },
      {
        name: '华为云计算技术有限公司',
        amount: 5000,
        direction: 'downward',
        children: [
          {
            name: '廊坊华为云计算技术有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '北京华为云计算技术有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '杭州华为云计算技术有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '西安华为云计算技术有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '深圳华为云计算技术有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '乌兰察布华为云计算技术有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '杭州讯盟科技有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
        ],
      },
      {
        name: '乌兰察布华为云计算技术有限公司',
        amount: 0,
        direction: 'downward',
        children: [],
      },
      {
        name: '华为（杭州）培训中心有限公司',
        amount: 3000,
        direction: 'downward',
        children: [],
      },
      {
        name: '上海海思技术有限公司',
        amount: 8000,
        direction: 'downward',
        children: [
          {
            name: '寰宇信任（北京）技术有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
        ],
      },
      {
        name: '华为终端（深圳）有限公司',
        amount: 16800,
        direction: 'downward',
        children: [
          {
            name: '深圳哈勃科技投资合伙企业（有限合伙）',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '华为终端（东莞）有限公司',
            amount: 50000,
            direction: 'downward',
            children: [],
          },
          {
            name: '华为终端有限公司',
            amount: 60000,
            direction: 'downward',
            children: [],
          },
        ],
      },
      {
        name: '东莞华为服务有限公司',
        amount: 20000,
        direction: 'downward',
        children: [],
      },
      {
        name: '中电科思仪科技股份有限公司',
        amount: 6606.6743,
        direction: 'downward',
        children: [
          {
            name: '中电科技集团电子可靠性工程技术有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
          {
            name: '中电科思仪科技（安徽）有限公司',
            amount: 10000,
            direction: 'downward',
            children: [],
          },
        ],
      },
      {
        name: '浙江华为通信技术有限公司',
        amount: 0,
        direction: 'downward',
        children: [],
      },
      {
        name: '深圳市讯联智付网络有限公司',
        amount: 0,
        direction: 'downward',
        children: [
          {
            name: '中兴通讯集团（简称：中兴通讯集团）',
            amount: 10000,
            direction: 'downward',
            children: [],
          },
        ],
      },
      {
        name: '成都华为技术有限公司',
        amount: 0,
        direction: 'downward',
        children: [],
      },
      {
        name: '华为机器有限公司',
        amount: 210000,
        direction: 'downward',
        children: [],
      },
      {
        name: '杭州华为企业通信技术有限公司',
        amount: 0,
        direction: 'downward',
        children: [],
      },
      {
        name: '上海华为技术有限公司',
        amount: 20000,
        direction: 'downward',
        children: [],
      },
      {
        name: '西安华为技术有限公司',
        amount: 0,
        direction: 'downward',
        children: [],
      },
      {
        name: '华为数字技术（苏州）有限公司',
        amount: 0,
        direction: 'downward',
        children: [],
      },
      {
        name: '深圳市海思半导体有限公司',
        amount: 60000,
        direction: 'downward',
        children: [
          {
            name: '苏州市海思半导体有限公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
        ],
      },
      {
        name: '华为技术服务有限公司',
        amount: 25000.5,
        direction: 'downward',
        children: [
          {
            name: '华为技术服务有限公司深圳分公司',
            amount: 0,
            direction: 'downward',
            children: [],
          },
        ],
      },
      {
        name: '深圳市华为技术服务有限公司',
        amount: 2000,
        direction: 'downward',
        children: [],
      },
      {
        name: '海思光电子有限公司',
        amount: 170000,
        direction: 'downward',
        children: [],
      },
    ],
  },
  count: 33,
};
const news = [
  {
    id: '6253eaf9c18b99de2220d5fc',
    title: '汉王科技获9家机构调研：公司会继续聚焦身份识别和智能视频分析两个领域（附调研问答）',
    tabs: [
      {
        type: 'other',
        tabs: [],
      },
      {
        type: '产业类型',
        tabs: [
          '新一代信息技术',
          '金融',
          '生物',
          '教育',
          '仓储运输',
          '大数据',
          '软件与信息服务',
          '装备工业',
          '数字经济',
          '机器人',
          '采矿业',
          '电子信息',
          '能源-石油及炼焦',
          '人工智能',
          '医药',
        ],
      },
    ],
    abstract: '汉王科技（002362）4月11日发布投资者关系活动记录表，公司于2022年4月7日接受9家机构单位调研，机构类型为保险公司、其他、基金公司、证券公司。投资者关系活动主要内容介绍：问：2021年公司收入有增长但净利没有增长是什么原因？答：主要是两方面的原因，第一，成本上升，导致毛利下降；公司各业务线的AI硬件产品占比较大，2021年，供应链紧张，芯片、屏模组等原材料价格大幅波动上涨，导致成本上升。第二，研发投入，为满足未来发展需要，在研发、人才等方面做储备；2021年，为满足国产化适配需求以及技术升级等在技术及产品持续投入、缺芯潮带来产品技术方案切换、布局新技术新业务领域的前期投入，导致研发投入上升；加之公司在人才储备、健全营销网络、国内多区域布局进行业务推广导致在管理配置上有所增加。另外，...',
    time: '2022-04-11 16:24:00',
    source: '同花顺',
    similar_count: 0,
    id_list: [],
    url: 'http://stock.10jqka.com.cn/20220411/c638202725.shtml',
    date: '2022-04-11 16:24:00',
    tags: [
      '新一代信息技术',
      '金融',
      '生物',
      '教育',
      '仓储运输',
      '大数据',
      '软件与信息服务',
      '装备工业',
      '数字经济',
      '机器人',
      '采矿业',
      '电子信息',
      '能源-石油及炼焦',
      '人工智能',
      '医药',
    ],
  },
  {
    id: '624fbe1cc18b99de22f2feb6',
    title: '汉王科技：与全球30多个主流汽车品牌保持多年合作',
    tabs: [
      {
        type: 'other',
        tabs: [],
      },
      {
        type: '产业类型',
        tabs: [
          '汽车',
          '装备工业',
        ],
      },
    ],
    abstract: '汉王科技（002362）在机构调研时表示，公司凭借领先的手写输入法、手写输入技术及产品化服务能力，与全球30多个主流汽车品牌保持多年合作，主要产品为手写识别、拼音等在内的标准化、定制化车机端输入法方案，并通过相关车型的出货量进行结算。关注同花顺财经（ths518），获取更多机会...',
    time: '2022-04-08 12:27:00',
    source: '同花顺',
    similar_count: 0,
    id_list: [],
    url: 'http://stock.10jqka.com.cn/20220408/c638157172.shtml',
    date: '2022-04-08 12:27:00',
    tags: [
      '汽车',
      '装备工业',
    ],
  },
  {
    id: '623400b9c18b99de22153cf6',
    title: '汉王科技领导一行参观张家口职业技术学院',
    tabs: [
      {
        type: 'other',
        tabs: [],
      },
      {
        type: '产业类型',
        tabs: [
          '人工智能',
          '数据中心',
          '软件与信息服务',
          '电子信息',
          '大数据',
          '教育',
          '文化体育',
          '新一代信息技术',
          '物联网',
        ],
      },
    ],
    abstract: '近日，汉王科技副总裁徐冬坚，汉王科技高级副总裁、河北汉王信息科技有限公司总经理李志峰，汉王科技副总裁、北京汉王数字科技有限公司总经理潘慧敏，河北汉王信息科技有限公司常务副总经理李景松一行前往张家口职业技术学院参观考察。职业技术学院党委书记冯印涛及学院党政办公室、信息工程与艺术设计学院、现代教育技术中心负责人接待，双方就校企合作、产教融合、人才培养、智慧校园等领域合作进行深入交流。汉王科技李志峰首先介绍了河北汉王的相关情况，并就双方推进相关专业领域合作提出具体意见建议。当前，河北汉王在张家口经济开发区业务已全面开展，在泰智会科技园区近6000平米包含各项工作及生活设施的办公园区也已建成投入使用。依托经开区产业发展优势、政策引导支持，双方将在智慧园区、智慧政务、智慧档案、智慧数据等领域开展多维深...',
    time: '2022-03-02 11:47:05',
    source: '汉王科技股份有限公司',
    similar_count: 0,
    id_list: [],
    url: 'https://www.hw99.com//index.php?m=content&c=index&a=show&catid=66&id=156',
    date: '2022-03-02 11:47:05',
    tags: [
      '人工智能',
      '数据中心',
      '软件与信息服务',
      '电子信息',
      '大数据',
      '教育',
      '文化体育',
      '新一代信息技术',
      '物联网',
    ],
  },
  {
    id: '623400b1c18b99de22153677',
    title: '福州市副市长黄建雄一行莅临汉王科技考察调研',
    tabs: [
      {
        type: 'other',
        tabs: [],
      },
      {
        type: '产业类型',
        tabs: [
          '高效节能',
          '人工智能',
          '电子信息',
          '大数据',
          '节能环保',
          '先进环保',
          '金融',
          '环保',
          '医药',
          '公共管理',
          '生物',
          '新一代信息技术',
          '大文化',
        ],
      },
    ],
    abstract: '2月23日上午，福州市副市长黄建雄、福州市工信局二级调研员谢竞红、福州市商务局二级调研员严周文等一行参观调研汉王科技。汉王科技总裁朱德永、董事刘秋童、副总裁黄磊、王坤、党政事务部主任钱唐霞等接待陪同。来访领导首先来到公司展厅，对汉王科技在人工智能领域的各大板块相关业务进行参观了解。在随后的座谈交流中，汉王科技总裁朱德永向福州市领导一行介绍了公司在人工智能、大数据与笔交互等几大业务线的情况。人脸及生物特征识别方向，汉王将自主研发的人脸及生物特征识别、智能视频分析、人形识别等人工智能技术与行业应用深度融合，当前已广泛应用于智慧城市、智慧园区、智慧工地、智慧赛事等领域。大数据方向，汉王科技总裁朱德永谈到，汉王以手写识别、OCR识别、自然语言处理以及知识图谱的构建为底层核心技术基础，目前已在智慧法院...',
    time: '2022-02-24 11:46:56',
    source: '汉王科技股份有限公司',
    similar_count: 0,
    id_list: [],
    url: 'https://www.hw99.com//index.php?m=content&c=index&a=show&catid=66&id=155',
    date: '2022-02-24 11:46:56',
    tags: [
      '高效节能',
      '人工智能',
      '电子信息',
      '大数据',
      '节能环保',
      '先进环保',
      '金融',
      '环保',
      '医药',
      '公共管理',
      '生物',
      '新一代信息技术',
      '大文化',
    ],
  },
  {
    id: '623400b7c18b99de22153a15',
    title: '智慧共生 与AI同行 | 汉王科技总裁新年致辞',
    tabs: [
      {
        type: 'other',
        tabs: [],
      },
      {
        type: '产业类型',
        tabs: [
          '人工智能',
          '电子信息',
          '金融',
          '文化体育',
          '医药',
          '公共管理',
          '碳中和',
          '新一代信息技术',
          '大文化',
        ],
      },
    ],
    abstract: '汉王科技总裁朱德永新年致辞2021悄然远去，2022阔步而来，面对世界百年未有之大变局和新冠肺炎疫情全球大流行交织影响，在过去的一年里，我们在努力前行中仍然赋予了2021丰厚而卓越的意义。响应政策，抢占”十四五规划“人工智能前沿领域发展先机，汉王服务国家需要，融入时代伟大进程：面对国家数字化建设如火如荼，汉王用OCR识别、手写体文本识别技术助力文化传承，以自然语言处理、知识图谱的构建突破为中华人文数字跃迁提供核心识别引擎，让AI在智慧法院、智慧图书馆、智慧档案、智慧医疗、智慧金融等众多的应用场景中落地生根。面对碳中和趋势扶摇直上，汉王的无纸化签批业务遍地开花，银行柜台，运营商终端，汉王无纸化签批业务的场景覆盖电子政务、医疗、公检法、部队、金融等各大行业。面对国内国际双循环发展新格局，夯实国内...',
    time: '2022-02-21 11:47:03',
    source: '汉王科技股份有限公司',
    similar_count: 0,
    id_list: [],
    url: 'https://www.hw99.com//index.php?m=content&c=index&a=show&catid=66&id=151',
    date: '2022-02-21 11:47:03',
    tags: [
      '人工智能',
      '电子信息',
      '金融',
      '文化体育',
      '医药',
      '公共管理',
      '碳中和',
      '新一代信息技术',
      '大文化',
    ],
  },
  {
    id: '620def32c18b99de22da072d',
    title: '汉王科技旗下三家子公司获北京市专精特新“小巨人”企业称号',
    tabs: [
      {
        type: 'other',
        tabs: [
          '正面',
          '投资动态',
        ],
      },
      {
        type: '产业类型',
        tabs: [
          '新一代信息技术',
          '人工智能',
          '电子信息',
          '大数据',
        ],
      },
    ],
    abstract: 'e公司讯，近日，北京市经济和信息化局发布通知，公示2021年度第二批拟认定北京市专精特新“小巨人”企业名单。汉王科技（002362）旗下三家子公司上榜，分别是北京汉王数字科技有限公司、北京汉王鹏泰科技股份有限公司及北京汉王影研科技有限公司。本次上榜的汉王科技旗下三家子公司，都主攻人工智能与大数据的各大细分领域。关注同花顺财经（ths518），获取更多机会...',
    time: '2022-02-17 14:05:00',
    source: '同花顺',
    similar_count: 0,
    id_list: [],
    url: 'http://stock.10jqka.com.cn/20220217/c636776912.shtml',
    date: '2022-02-17 14:05:00',
    tags: [
      '正面',
      '投资动态',
      '新一代信息技术',
      '人工智能',
      '电子信息',
      '大数据',
    ],
  },
  {
    id: '620def18c18b99de22da0058',
    title: '北京市专精特新“小巨人”企业，汉王科技旗下三家子公司上榜',
    tabs: [
      {
        type: 'other',
        tabs: [
          '正面',
          '技术创新',
        ],
      },
      {
        type: '产业类型',
        tabs: [
          '新一代信息技术',
          '公共管理',
          '电子信息',
          '人工智能',
          '金融',
          '数据中心',
          '大文化',
          '互联网',
          '工业互联网',
          '医药',
          '大数据',
        ],
      },
    ],
    abstract: '近日，北京市经济和信息化局发布通知，将 2021年度第二批拟认定北京市专精特新“小巨人”企业名单予以公示。汉王科技（002362）旗下三家子公 司上榜：北京汉王数字科技有限公司北京汉王鹏泰科技股份有限公司北京汉王影研科技有限公司专精特新“小巨人”按照工信部定义，「专精特新」政策旨在培育具有“专业化、精细化、特色化、新颖化”特征的中小企业。而专精特新“小巨人”企业是“专精特新”中小企业中的佼佼者，是专注于细分市场、创新能力强、市场占有率高、掌握关键核心技术、质量效益优的排头兵企业。当前，世界正处于新一轮科技周期上行期，我国正处于新旧动能转换的关键时刻，实现关键核心技术的自主可控，不仅是我国经济发展新动能的必然路径，也是我国迈向全球价值链中高端的必然之选。加强专精特新企业培育，可以有效破解卡脖子...',
    time: '2022-02-17 14:03:00',
    source: '同花顺',
    similar_count: 0,
    id_list: [],
    url: 'http://stock.10jqka.com.cn/20220217/c636776902.shtml',
    date: '2022-02-17 14:03:00',
    tags: [
      '正面',
      '技术创新',
      '新一代信息技术',
      '公共管理',
      '电子信息',
      '人工智能',
      '金融',
      '数据中心',
      '大文化',
      '互联网',
      '工业互联网',
      '医药',
      '大数据',
    ],
  },
  {
    id: '623400b8c18b99de22153b86',
    title: '北京市专精特新“小巨人”企业，汉王科技旗下三家子公司上榜',
    tabs: [
      {
        type: 'other',
        tabs: [],
      },
      {
        type: '产业类型',
        tabs: [
          '人工智能',
          '数据中心',
          '互联网',
          '电子信息',
          '大数据',
          '金融',
          '医药',
          '公共管理',
          '工业互联网',
          '新一代信息技术',
          '大文化',
        ],
      },
    ],
    abstract: '近日，北京市经济和信息化局发布通知，将 2021年度第二批拟认定北京市专精特新“小巨人”企业名单予以公示。汉王科技旗下三家子公司上榜：北京汉王数字科技有限公司北京汉王鹏泰科技股份有限公司北京汉王影研科技有限公司专精特新“小巨人”按照工信部定义，「专精特新」政策旨在培育具有“专业化、精细化、特色化、新颖化”特征的中小企业。而专精特新“小巨人”企业是“专精特新”中小企业中的佼佼者，是专注于细分市场、创新能力强、市场占有率高、掌握关键核心技术、质量效益优的排头兵企业。当前，世界正处于新一轮科技周期上行期，我国正处于新旧动能转换的关键时刻，实现关键核心技术的自主可控，不仅是我国经济发展新动能的必然路径，也是我国迈向全球价值链中高端的必然之选。加强专精特新企业培育，可以有效破解卡脖子难题。专精特新“小...',
    time: '2022-02-17 11:47:04',
    source: '汉王科技股份有限公司',
    similar_count: 0,
    id_list: [],
    url: 'https://www.hw99.com//index.php?m=content&c=index&a=show&catid=66&id=154',
    date: '2022-02-17 11:47:04',
    tags: [
      '人工智能',
      '数据中心',
      '互联网',
      '电子信息',
      '大数据',
      '金融',
      '医药',
      '公共管理',
      '工业互联网',
      '新一代信息技术',
      '大文化',
    ],
  },
  {
    id: '620106abc18b99de222fd2ff',
    title: '汉王科技：2022年员工持股计划累计购入11.55万股 成交均价15.82元',
    tabs: [
      {
        type: 'other',
        tabs: [
          '中立',
          '企业动态',
        ],
      },
      {
        type: '产业类型',
        tabs: [
          '电子信息',
          '电子设备及终端',
          '金融',
          '新一代信息技术',
        ],
      },
    ],
    abstract: '汉王科技(SZ 002362，收盘价：15.75元)2月7日晚间发布公告称，截至2022年2月7日，公司2022年员工持股计划证券账户已通过二级市场累计购入本公司股票11.55万股，约占公司总股本的0.05%，成交均价约为约15.82元/股，成交金额合计为约183万元(含印花税)。2021年1至6月份，汉王科技的营业收入构成为：计算机和通信和其他电子设备制造业占比97.09%。汉王科技的董事长是刘迎建，男，69岁，学历背景为本科；总经理是朱德永，男，51岁，学历背景为硕士。...',
    time: '2022-02-07 19:34:00',
    source: '东方财富网',
    similar_count: 0,
    id_list: [],
    url: 'http://finance.eastmoney.com/a/202202072268569821.html',
    date: '2022-02-07 19:34:00',
    tags: [
      '中立',
      '企业动态',
      '电子信息',
      '电子设备及终端',
      '金融',
      '新一代信息技术',
    ],
  },
  {
    id: '62006c0dc18b99de22cf64de',
    title: '汉王科技AI产品被应用于冬奥会人群检测分析',
    tabs: [
      {
        type: 'other',
        tabs: [
          '正面',
          '技术创新',
        ],
      },
      {
        type: '产业类型',
        tabs: [
          '新一代信息技术',
          '人工智能',
        ],
      },
    ],
    abstract: '北京商报讯(记者 石飞月)北京冬奥会各场馆、设施、各保障机构已正式进入冬奥赛时状态，实施“大闭环”管理。2月6日，据汉王科技(002362)官微公布的消息，该公司的AI产品热成像测温一体机和人证核验终端被应用于张家口崇礼赛区。据介绍，汉王热成像测温一体机可远距离多人群快速检测分析，准确识别和统计路过的人数，对人群中发热症状的人员进行排查和示警；人证核验终端在公安临时卡口可核验通行人员身份证信息，同时进行额温监测，并可实现黑名单布控，公安联防管控综合研判。汉王人脸识别技术和智能通行管控系列产品曾被应用在一带一路高峰论坛、北京半程马拉松、河北省园林博览会等多个大型会议活动中。...',
    time: '2022-02-07 08:16:00',
    source: '中金在线',
    similar_count: 0,
    id_list: [],
    url: 'http://news.cnfol.com/it/20220207/29421435.shtml',
    date: '2022-02-07 08:16:00',
    tags: [
      '正面',
      '技术创新',
      '新一代信息技术',
      '人工智能',
    ],
  },
];
// 股权穿透
const equityThrough1 = {
  title: '北京神州泰岳软件股份有限公司',
  level: 0,
  relation: '股东',
  children: [
    {
      title: '北京神州泰岳软件股份有限公司1',
      level: 1,
      relation: '股东',
      children: [
        {
          title: '北京神州泰岳软件股份有限公司3',
          level: 2,
          relation: '股东',
          children: [],
          count: 0,
        },
      ],
      count: 0,
    },
    {
      title: '北京神州泰岳软件股份有限公司2',
      level: 1,
      relation: '',
      children: [],
      count: 0,
    },
  ],
  count: 0,
};
// 资质认定
const qualification1 = {
  name: '资质认定',
  level: 0,
  children: [
    {
      name: '国家级',
      level: 1,
      children: [
        {
          name: '信息产业重大技术发明项目（2006）',
          level: 2,
          children: [
            {
              name: '汉王科技股份有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '国家规划布局内重点软件企业（2010）',
          level: 2,
          children: [
            {
              name: '汉王科技股份有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '国家高新区瞪羚企业（2016）',
          level: 2,
          children: [
            {
              name: '北京影研创新科技发展有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '国家高新区瞪羚企业（2018）',
          level: 2,
          children: [
            {
              name: '北京影研创新科技发展有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '国家高新区瞪羚企业（双创周特刊）（2016）',
          level: 2,
          children: [
            {
              name: '北京影研创新科技发展有限公司',
              level: 3,
              children: [],
            },
          ],
        },
      ],
    },
    {
      name: '省级',
      level: 1,
      children: [
        {
          name: '北京市企业研究开发项目（2014）',
          level: 2,
          children: [
            {
              name: '汉王科技股份有限公司',
              level: 3,
              children: [],
            },
            {
              name: '北京影研创新科技发展有限公司',
              level: 3,
              children: [],
            },
            {
              name: '北京汉王智远科技有限公司',
              level: 3,
              children: [],
            },
            {
              name: '北京汉王鹏泰科技有限公司',
              level: 3,
              children: [],
            },
            {
              name: '北京汉王智学科技有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '北京市专精特新“小巨人”企业（第二批）（2021）',
          level: 2,
          children: [
            {
              name: '北京汉王数字科技有限公司',
              level: 3,
              children: [],
            },
            {
              name: '北京汉王鹏泰科技股份有限公司',
              level: 3,
              children: [],
            },
            {
              name: '北京汉王影研科技有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '北京市自主创新产品（2009）',
          level: 2,
          children: [
            {
              name: '汉王科技股份有限公司',
              level: 3,
              children: [],
            },
            {
              name: '北京影研创新科技发展有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '北京市“专精特新”中小企业（第二批）（2020）',
          level: 2,
          children: [
            {
              name: '北京汉王鹏泰科技股份有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '北京市“专精特新”中小企业（第二批）（2021）',
          level: 2,
          children: [
            {
              name: '北京汉王数字科技有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '北京市中关村科技信贷和融资租赁支持资金拟支持企业（2021）',
          level: 2,
          children: [
            {
              name: '北京蜂鸟映像电子商务有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '北京市企业研究开发项目（2011）',
          level: 2,
          children: [
            {
              name: '汉王科技股份有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '北京市企业研究开发项目（2012）',
          level: 2,
          children: [
            {
              name: '汉王科技股份有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '北京市企业研究开发项目（2013）',
          level: 2,
          children: [
            {
              name: '汉王科技股份有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '北京市工程技术研究中心',
          level: 2,
          children: [
            {
              name: '汉王科技股份有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '北京市自主创新产品（2007）',
          level: 2,
          children: [
            {
              name: '汉王科技股份有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '广东省专精特新中小企业（2021）',
          level: 2,
          children: [
            {
              name: '深圳汉王友基科技有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '广东省专精特新企业（2021）',
          level: 2,
          children: [
            {
              name: '深圳汉王友基科技有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '江苏省专精特新小巨人复核拟通过企业（2021）',
          level: 2,
          children: [
            {
              name: '南京越博动力系统股份有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '江苏省服务型制造示范企业（2017）',
          level: 2,
          children: [
            {
              name: '南京越博动力系统股份有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '江苏省省级专精特新小巨人企业复核通过企业（2021）',
          level: 2,
          children: [
            {
              name: '南京越博动力系统股份有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '江苏省科技小巨人（2017）',
          level: 2,
          children: [
            {
              name: '南京越博动力系统股份有限公司',
              level: 3,
              children: [],
            },
          ],
        },
        {
          name: '江苏省隐形冠军企业（2018）',
          level: 2,
          children: [
            {
              name: '南京越博动力系统股份有限公司',
              level: 3,
              children: [],
            },
          ],
        },
      ],
    },
  ],
};
// 核心技术
const coreTechnology1 = {
  name: '比亚迪股份有限公司',
  level: 0,
  children: [
    {
      name: '客户端',
      level: 1,
      children: [
        {
          name: '一种互联网中为用户提供服务的方法和系统',
          level: 2,
          children: [],
        },
        {
          name: '状态通知方法及系统',
          level: 2,
          children: [],
        },
        {
          name: '实现UDP打洞的方法',
          level: 2,
          children: [],
        },
        {
          name: 'JAR升级方法及装置',
          level: 2,
          children: [],
        },
        {
          name: '用户行为数据上报方法和系统',
          level: 2,
          children: [],
        },
      ],
    },
    {
      name: 'IM客户端',
      level: 1,
      children: [
        {
          name: '对添加即时通信群成员的控制方法、服务器和客户端设备',
          level: 2,
          children: [],
        },
        {
          name: '一种浏览器客户端与IM客户端之间通信的方法和系统',
          level: 2,
          children: [],
        },
        {
          name: '一种在IM系统中建立热线的方法和一种IM系统',
          level: 2,
          children: [],
        },
        {
          name: '即时通信ＩＭ系统中的消息查找方法和ＩＭ客户端',
          level: 2,
          children: [],
        },
        {
          name: '一种基于IM群组的签名板实现方法和一种IM系统',
          level: 2,
          children: [],
        },
      ],
    },
    {
      name: '关键字',
      level: 1,
      children: [
        {
          name: '一种非法入网设备的检测方法及装置',
          level: 2,
          children: [],
        },
        {
          name: '一种通信工具中查找联系人的方法和系统',
          level: 2,
          children: [],
        },
        {
          name: '日志监控方法',
          level: 2,
          children: [],
        },
        {
          name: '一种内容搜索方法及装置',
          level: 2,
          children: [],
        },
        {
          name: '一种报表展现方法和装置',
          level: 2,
          children: [],
        },
      ],
    },
    {
      name: '报表数据库',
      level: 1,
      children: [
        {
          name: '一种报表展现方法和装置',
          level: 2,
          children: [],
        },
        {
          name: '一种报表查询方法和装置',
          level: 2,
          children: [],
        },
      ],
    },
    {
      name: '训练方法',
      level: 1,
      children: [
        {
          name: '词嵌入模型的训练方法及装置',
          level: 2,
          children: [],
        },
        {
          name: '一种命名实体识别方法、装置及系统',
          level: 2,
          children: [],
        },
        {
          name: '自然语言处理模型的训练方法及装置',
          level: 2,
          children: [],
        },
      ],
    },
    {
      name: '好友列表',
      level: 1,
      children: [
        {
          name: '即时通信客户端上的好友推荐方法和装置',
          level: 2,
          children: [],
        },
        {
          name: '客户端好友标签快捷通讯方法及装置',
          level: 2,
          children: [],
        },
        {
          name: '即时通信客户端数据共享方法和系统',
          level: 2,
          children: [],
        },
        {
          name: '即时通讯平台中添加好友的方法和系统',
          level: 2,
          children: [],
        },
        {
          name: '即时通讯工具中信息管理的方法和系统',
          level: 2,
          children: [],
        },
      ],
    },
    {
      name: '映射关系',
      level: 1,
      children: [
        {
          name: '基于APP映射库的智能终端用户行为分析方法和系统',
          level: 2,
          children: [],
        },
        {
          name: '一种表格数据结构的处理方法和装置',
          level: 2,
          children: [],
        },
        {
          name: '一种文件共享方法、装置和系统',
          level: 2,
          children: [],
        },
        {
          name: '一种抓取网络数据的方法及网络数据抓取调度装置',
          level: 2,
          children: [],
        },
        {
          name: '基于多主题语义标签信息映射的结构化处理方法及装置',
          level: 2,
          children: [],
        },
      ],
    },
    {
      name: '配置文件',
      level: 1,
      children: [
        {
          name: '一种大批量操作系统的安装状态监控方法、服务器和系统',
          level: 2,
          children: [],
        },
        {
          name: '一种基于SpringBoot在不同环境的数据源接入方法和装置',
          level: 2,
          children: [],
        },
        {
          name: '一种自动化测试方法和装置',
          level: 2,
          children: [],
        },
        {
          name: '一种在浏览器中显示网页资源的方法和装置',
          level: 2,
          children: [],
        },
        {
          name: '一种告警信息上报方法和装置',
          level: 2,
          children: [],
        },
      ],
    },
    {
      name: 'IM系统',
      level: 1,
      children: [
        {
          name: '一种在IM系统中建立热线的方法和一种IM系统',
          level: 2,
          children: [],
        },
        {
          name: '即时通信ＩＭ系统中的消息查找方法和ＩＭ客户端',
          level: 2,
          children: [],
        },
        {
          name: '一种基于IM群组的签名板实现方法和一种IM系统',
          level: 2,
          children: [],
        },
        {
          name: '一种IM系统的独立业务线程模型实现方法',
          level: 2,
          children: [],
        },
      ],
    },
    {
      name: 'LTE网络干扰状况',
      level: 1,
      children: [
        {
          name: 'LTE网络中基于栅格干扰的信号优化的方法及系统',
          level: 2,
          children: [],
        },
        {
          name: 'LTE网络中基于小区干扰总量的信号优化方法及系统',
          level: 2,
          children: [],
        },
      ],
    },
  ],
};

// 伙伴关系
const partnerShip1 = {
  name: '汉王科技股份有限公司',
  level: 0,
  children: [
    {
      name: '合作申请专利',
      level: 1,
      children: [
        {
          name: '企业',
          nature: '企业',
          level: 2,
          children: [
            {
              name: '宁波舜宇光电信息有限公司',
              nature: '高校',
              level: 3,
            },
            {
              name: '中国新闻出版研究院',
              level: 3,
            },
          ],
        },
      ],
    },
    {
      name: '共同起草标准',
      level: 1,
      children: [
        {
          name: '企业',
          nature: '企业',
          level: 2,
          children: [
            {
              name: '北京创原天地科技有限公司',
              nature: '企业',
              level: 3,
            },
            {
              name: '国家保密科技测评中心',
              nature: '企业',
              level: 3,
            },
            {
              name: '太极计算机股份有限公司',
              level: 3,
            },
            {
              name: '中安科技集团有限公司',
              level: 3,
            },
            {
              name: '深圳市迪威视讯股份有限公司',
              level: 3,
            },
            {
              name: '浪潮集团有限公司',
              level: 3,
            },
            {
              name: '上海市信息服务业行业协会',
              level: 3,
            },
            {
              name: '中国新闻出版研究院',
              level: 3,
            },
            {
              name: '广东煌朝电子科技有限公司',
              level: 3,
            },
            {
              name: '中国电子技术标准化研究院',
              level: 3,
            },
            {
              name: '人民出版社',
              level: 3,
            },
            {
              name: '汉王科技有限公司',
              level: 3,
            },
            {
              name: '首都图书馆',
              level: 3,
            },
            {
              name: '中国科学院文献情报中心',
              level: 3,
            },
            {
              name: '北京方正阿帕比技术有限公司',
              level: 3,
            },
            {
              name: '上海图书馆上海科学技术情报研究所',
              level: 3,
            },
            {
              name: '南京欢迎科技产品有限公司',
              level: 3,
            },
            {
              name: '东莞市索威计算机信息科技有限公司',
              level: 3,
            },
            {
              name: '国家康复辅具研究中心',
              level: 3,
            },
            {
              name: '北京市盲人学校',
              level: 3,
            },
            {
              name: '苍穹数码技术股份有限公司',
              level: 3,
            },
            {
              name: '北京旷视科技有限公司',
              level: 3,
            },
            {
              name: '中国—东盟信息港股份有限公司',
              level: 3,
            },
            {
              name: '北京未来科学城科技发展有限公司',
              level: 3,
            },
            {
              name: '北京智慧城市云网规划设计研究院有限公司',
              level: 3,
            },
            {
              name: '智慧互通科技有限公司',
              level: 3,
            },
            {
              name: '中国标准化研究院',
              level: 3,
            },
            {
              name: '中竞同创能源环境科技集团股份有限公司',
              level: 3,
            },
            {
              name: '北京超图软件股份有限公司',
              level: 3,
            },
            {
              name: '灵犀科技有限公司',
              level: 3,
            },
            {
              name: '同方股份有限公司',
              level: 3,
            },
            {
              name: '北京航天数据股份有限公司',
              level: 3,
            },
            {
              name: '航天科工智慧产业发展有限公司',
              level: 3,
            },
            {
              name: '云网智慧城市科技有限公司',
              level: 3,
            },
            {
              name: '北京无线电计量测试研究所',
              level: 3,
            },
          ],
        },
        {
          name: '高校',
          level: 2,
          children: [
            {
              name: '河北工业大学',
              nature: '高校',
              level: 3,
            },
            {
              name: '北方工业大学',
              nature: '高校',
              level: 3,
            },
          ],
        },
      ],
    },
  ],
};
// 综合评级
const radar1 = {
  abscissa_list: [
    '竞争实力',
    '创新能力',
    '融资能力',
    '经营能力',
    '信用风险',
  ],
  detail_list: [
    {
      ordinate: [
        80.18,
        92.5,
        94.5,
        77.0625,
        70,
      ],
    },
  ],
};
const bar1 = {
  abscissa_list: [
    '综合得分排名',
    '竞争实力',
    '经营能力',
    '创新能力',
    '融资能力',
    '信用风险',
  ],
  detail_list: [
    {
      ordinate: [
        83.88,
        80.18,
        77.06,
        82.58,
        54.5,
        30,
      ],
      target: '企业价值评级各维度得分情况',
      unit: '%',
    },
  ],
  abscissa_right_list: [
    '领先行业99.99企业',
    '领先行业99.99企业',
    '领先行业89.97企业',
    '领先行业79.99企业',
    '领先行业59.99企业',
    '领先行业39.81企业',
  ],
};
const gauge1 = {
  竞争实力: [
    {
      value: 80.18,
      name: '成绩评定',
    },
  ],
  创新能力: [
    {
      value: 92.57600000000001,
      name: '成绩评定',
    },
  ],
  融资能力: [
    {
      value: 94.5,
      name: '成绩评定',
    },
  ],
  经营能力: [
    {
      value: 77.0625,
      name: '成绩评定',
    },
  ],
  信用风险: [
    {
      value: 70,
      name: '成绩评定',
    },
  ],
};
// 专利分析
const patent1 = {
  title: '新增授权专利',
  type: 'heap_histogram_chart',
  data: {
    xAxis: [
      '2013',
      '2014',
      '2015',
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
      '2021',
      '2022',
    ],
    yAxis: [
      {
        stack: '0',
        name: '实用新型',
        unit: '个',
        data_key: '实用新型',
        value: [
          5,
          10,
          3,
          9,
          4,
          11,
          14,
          9,
          6,
          4,
        ],
      },
      {
        stack: '0',
        name: '发明',
        unit: '个',
        data_key: '发明',
        value: [
          70,
          46,
          42,
          65,
          24,
          23,
          11,
          13,
          10,
          2,
        ],
      },
      {
        stack: '0',
        name: '外观设计',
        unit: '个',
        data_key: '外观设计',
        value: [
          4,
          6,
          9,
          11,
          9,
          12,
          16,
          19,
          21,
          6,
        ],
      },
    ],
  },
  y_unit: '个',
};
const patentList1 = {
  count: 1265,
  columns: [
    {
      title: '序号',
      dataIndex: 'index',
    },
    {
      title: '专利名称',
      dataIndex: 'patent_name',
    },
    {
      title: '专利类型',
      dataIndex: 'patent_type',
    },
    {
      title: '产业分类',
      dataIndex: 'industry',
    },
    {
      title: '授权公告日',
      dataIndex: 'notice_date',
    },
  ],
  dataSource: [
    {
      id: '625645e88b1a313104702fd5',
      patent_name: '电子笔',
      patent_type: '实用新型',
      industry: '新一代信息技术,电子设备及终端,人工智能,数据中心,电子信息,数字经济,软件与信息服务,能源-电力,互联网,信息传输服务,网络信息安全,大数据,数字创意',
      notice_no: '216210939U',
      notice_date: '2022-04-05',
      patent_score: 52,
      index: '01',
    },
    {
      id: '625646ee8b1a31310473797e',
      patent_name: '人脸考勤机',
      patent_type: '外观设计',
      industry: '',
      notice_no: '307210493S',
      notice_date: '2022-03-29',
      patent_score: 50,
      index: '02',
    },
    {
      id: '625646ee8b1a31310473672b',
      patent_name: '飞行器机翼和飞行器',
      patent_type: '实用新型',
      industry: '装备工业,飞机制造,航空装备,航空',
      notice_no: '216140172U',
      notice_date: '2022-03-29',
      patent_score: 52,
      index: '03',
    },
    {
      id: '625646ee8b1a31310473798d',
      patent_name: '人脸门禁考勤终端',
      patent_type: '外观设计',
      industry: '',
      notice_no: '307210494S',
      notice_date: '2022-03-29',
      patent_score: 50,
      index: '04',
    },
    {
      id: '6221b949e133e6cd90c17fbc',
      patent_name: '一种文本定位方法及装置、文本识别方法及装置',
      patent_type: '发明',
      industry: '',
      notice_no: '109977762B',
      notice_date: '2022-02-22',
      patent_score: 65,
      index: '05',
    },
    {
      id: '6221b8eae133e6cd90c06848',
      patent_name: '人脸考勤机',
      patent_type: '外观设计',
      industry: '',
      notice_no: '307117659S',
      notice_date: '2022-02-18',
      patent_score: 50,
      index: '06',
    },
  ],
};
// 新闻动态
const news1 = {
  count: 8054,
  data: [
    {
      id: '62cd49e8c18b99de229b6fd4',
      title: '华为云郭婷：华为云助力金融机构核心系统、数据分析系统全面升级',
      tabs: [
        {
          type: 'other',
          tabs: [
            '正面',
            '企业动态',
          ],
        },
        {
          type: '产业类型',
          tabs: [
            '零售与批发',
            '零售业',
            '大数据',
            '电子信息',
            '数据中心',
            '新一代信息技术',
            '数字经济',
            '人工智能',
            '金融',
            '互联网',
          ],
        },
      ],
      abstract: '7月12日，由华为云与《清华金融评论》联合主办、清华五道口“数字中国”企业家课程项目学术支持的《中国金融机构数字化转型》专题研讨会在线上隆重举行，大会邀请产学研权威专家共同围绕“中国金融机构数字化转型”的方向与重点，展开了深入探讨。华为中国区金融云总经理郭婷出席本次研讨会，并分享了华为云在支持金融机构数字化转型的过程中遇到的问题和对应的解决方案。郭婷认为，金融机构的数字化转型，外因主要包括产业结构升级、生态联合运营，内因主要包括业务压力激增和用户体验升级，这些因素共同推动着金融行业的数字化转型升级。郭婷结合华为云长期服务于金融机构的实践经验指出，支撑金融机构数字化转型升级的基础能力主要包括：核心系统的升级，以及由此带来的基础架构和数据库的升级；数据分析系统的升级，以及由此带来的大数据和AI分...',
      time: '2022-07-12',
      source: '2kasmklskfdk',
      similar_count: 0,
      id_list: [],
      url: 'https://www.fecn.net/tech/kuaixun/2022/0712/0712258105258105.html',
    },
    {
      id: '62cd49f7c18b99de229b876c',
      title: '金融圈年中高端研讨，产学研专家携手华为云共议金融数字化转型',
      tabs: [
        {
          type: 'other',
          tabs: [
            '正面',
            '会展信息',
          ],
        },
        {
          type: '产业类型',
          tabs: [
            '日用化工',
            '大数据',
            '电子信息',
            '化工',
            '新一代信息技术',
            '数字经济',
            '人工智能',
            '金融',
          ],
        },
      ],
      abstract: '7月12日，由华为云与《清华金融评论》联合主办，清华五道口“数字中国”企业家课程项目提供学术支持的《中国金融机构数字化转型》专题研讨会在线上举办，并在央视财经等平台直播。研讨会邀请到多位产、学、研权威专家，围绕中国金融机构数字化转型的方向和重点展开了深入探讨，同时发布《中国金融机构数字化转型》白皮书。作为产业界代表，华为云深度参与了国内众多头部金融机构的数字化转型历程，在本次研讨会期间和与会嘉宾共同探讨了金融机构数字化转型过程中的趋势和挑战。华为云将加大对金融行业的支持力度华为中国区副总裁、华为云中国区总裁张修征指出，“移动优先，丰富数字化渠道；云化转型，平台与流程再造；数据驱动，实现数字化经营；场景创新，探索新商业模式”是金融机构数字化转型必将经历的主要阶段。同时金融机构在数字化转型过程中...',
      time: '2022-07-12',
      similar_count: 0,
      id_list: [],
      url: 'http://www.techweb.com.cn/internet/2022-07-12/2897854.shtml',
    },
    {
      id: '62cd5834c18b99de22a873d6',
      title: '华为还有一款Mate50E？或将搭载骁龙778G 4G平台',
      tabs: [
        {
          type: 'other',
          tabs: [
            '中立',
            '企业动态',
          ],
        },
        {
          type: '产业类型',
          tabs: [
            '新一代信息技术',
          ],
        },
      ],
      abstract: '有数码博主再度放出了一些关于华为Mate50系列的爆料，此次的Mate50系列中，有可能还有一款Mate50E机型。',
      time: '2022-07-12',
      similar_count: 0,
      id_list: [],
      url: 'https://finance.sina.com.cn/tech/2022-07-12/doc-imizirav3024833.shtml',
    },
    {
      id: '62cd3c24c18b99de228bafa4',
      title: '小康股份拟更名赛力斯 攀上华为后破除低端形象',
      tabs: [
        {
          type: 'other',
          tabs: [
            '中立',
            '企业动态',
          ],
        },
        {
          type: '产业类型',
          tabs: [
            '汽车',
            '新能源汽车',
            '家用电器及器具',
            '金融',
            '装备工业',
            '新能源',
            '能源',
          ],
        },
      ],
      abstract: '赛力斯因与华为合作知名度攀升，摇身一变从原来“土气十足”的传统车企新能源品牌成了炙手可热的造车新势力明星品牌。',
      time: '2022-07-12',
      similar_count: 0,
      id_list: [],
      url: 'http://www.eeo.com.cn/2022/0712/543031.shtml',
    },
    {
      id: '62cd3c98c18b99de228cd4e6',
      title: '疑似华为新平板Matepad12通过认证 支持22.5W充电',
      tabs: [
        {
          type: 'other',
          tabs: [
            '正面',
            '企业动态',
          ],
        },
        {
          type: '产业类型',
          tabs: [
            '新一代信息技术',
            '家用电器及器具',
          ],
        },
      ],
      abstract: '最近有博主爆料，华为会在8月底举办新品发布会，带来旗下新款旗舰手机Mate50系列。不过，从以往华为发布会的情况来推断，此次Mate50系列发布，应该也会有不少产品随之亮相。',
      time: '2022-07-12',
      similar_count: 0,
      id_list: [],
      url: 'https://finance.sina.com.cn/tech/2022-07-12/doc-imizirav2968379.shtml',
    },
  ],
};
// 政策推荐
const policy1 = {
  count: 4973,
  data: [
    {
      id: '62cf04f1c18b99de227cdf24',
      title: '广东省人民政府办公厅关于印发广东省发展绿色金融支持碳达峰行动实施方案的通知',
      tabs: [
        {
          type: 'other',
          tabs: [
            '省级',
            '财政、金融、审计',
            '政策文件-管理办法/实施方案',
            '广东省',
          ],
        },
        {
          type: '产业类型',
          tabs: [
            '造纸印刷',
            '纺织工业',
            '冶金',
            '农林牧渔',
            '非金属矿物',
            '电子信息',
            '数据中心',
            '高效节能',
            '现代服务业',
            '陶瓷制品',
            '金融',
            '汽车',
            '住宿餐饮',
            '农业',
            '光伏',
            '冶金-钢铁材料',
            '造纸',
            '环保',
            '大数据',
            '能源',
            '海洋经济',
            '发电与输变电装备',
            '装备工业',
            '新能源汽车',
            '先进环保',
            '节能环保',
            '新一代信息技术',
            '金属制品',
            '新能源',
            '建筑',
          ],
        },
      ],
      time: '2022-07-13',
      source: '广东省人民政府',
    },
    {
      id: '62cf04fdc18b99de227cf261',
      title: '深圳市商务局关于延长电子商务创新发展扶持计划（纾困政策-零售企业开设电商店铺补助项目）申报工作受理时间的通知',
      tabs: [
        {
          type: 'other',
          tabs: [
            '市级',
            '申报通知',
            '广东省',
            '深圳市',
          ],
        },
        {
          type: '产业类型',
          tabs: [
            '零售与批发',
            '新一代信息技术',
            '电子信息',
          ],
        },
      ],
      time: '2022-07-13',
      source: '深圳市商务局',
    },
    {
      id: '62cf04fec18b99de227cf2cf',
      title: '深圳市商务局关于延长电子商务创新发展扶持计划（纾困政策-零售企业开设电商店铺补助项目）申报工作受理时间的通知',
      tabs: [
        {
          type: 'other',
          tabs: [
            '市级',
            '申报通知',
            '广东省',
            '深圳市',
          ],
        },
        {
          type: '产业类型',
          tabs: [
            '零售与批发',
            '新一代信息技术',
            '电子信息',
          ],
        },
      ],
      time: '2022-07-13',
      source: '深圳市人民政府',
    },
    {
      id: '62cdaf91c18b99de22de5c31',
      title: '叶元龄率队赴贵州开展东西部产业协作调研交流',
      tabs: [
        {
          type: 'other',
          tabs: [
            '省级',
            '广东省',
          ],
        },
        {
          type: '产业类型',
          tabs: [
            '新能源',
            '能源',
            '电子信息',
            '工业互联网',
            '新一代信息技术',
            '食品加工业',
            '互联网',
            '电池',
            '动力电池',
          ],
        },
      ],
      time: '2022-07-12',
      source: '广东省工业和信息化厅',
    },
    {
      id: '62cc6119c18b99de22c90850',
      title: '市规划和自然资源局大鹏管理局关于坪西路辅道工程（鹏隽路-滨海二路）项目拟林木采伐公示的通告',
      tabs: [
        {
          type: 'other',
          tabs: [
            '园区级',
            '公示公告',
            '广东省',
            '深圳市',
          ],
        },
        {
          type: '产业类型',
          tabs: [
            '新一代信息技术',
            '仓储运输',
            '医药制造',
            '医药',
            '电子信息',
          ],
        },
      ],
      time: '2022-07-11',
      source: '大鹏新区',
    },
  ],
};
export { companyTabsColorMap, companyInfo1, gsxx, gqjg, news, equityThrough1, qualification1,
  coreTechnology1, partnerShip1, radar1, bar1, gauge1, patent1, patentList1, news1, policy1 };
