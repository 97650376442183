/*
 *Author: zhaochenyu
 *Description: 倒计时60s
 *Date: 2021/04/25
*/

import React, { useState, useEffect } from 'react';
import style from './index.less';

function CountDown(props) {
  const { changeSend } = props;
  const [count, setCount] = useState(60);

  useEffect(() => {
    let countNum = count;
    const timer = setInterval(() => {
      if (countNum - 1 > 0) {
        countNum -= 1;
        setCount(countNum);
      } else {
        clearInterval(timer);
        changeSend(false);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <span className={style.all}>
      {`已发送(${count})s`}
    </span>
  );
}

export default CountDown;
