import { get, post } from '../utils/request';
import { INDUSTRYCOMPANY } from '../utils/url';

// 企业信息
const getTitleInfo = param => get(`${INDUSTRYCOMPANY}/detail/title_info`, {
  ...param,
});

// 工商信息
const getCommerceInfo = param => get(`${INDUSTRYCOMPANY}/detail/industry_commerce_info`, {
  ...param,
});

// 股权结构
const getStockRightsPenetrate = param => get(`${INDUSTRYCOMPANY}/detail/stock_rights_penetrate`, {
  ...param,
});

// 雷达
const getRadarMap = param => get(`${INDUSTRYCOMPANY}/detail/radar_map`, {
  ...param,
});

// 雷达
const getBarCharMap = param => get(`${INDUSTRYCOMPANY}/detail/bar_char_map`, {
  ...param,
});

// 雷达
const getValuationEnterprise = param => get(`${INDUSTRYCOMPANY}/detail/valuation_of_enterprise`, {
  ...param,
});

// 新闻
const getCorrelationNewsList = param => get(`${INDUSTRYCOMPANY}/detail/correlation_news_list`, {
  ...param,
});

// 资质认定
const getQualityCertification = param => post(`${INDUSTRYCOMPANY}/detail/quality_certification`, {
  ...param,
});

// 资质认定
const getPartnership = param => post(`${INDUSTRYCOMPANY}/detail/partnership`, {
  ...param,
});

// 专利分析
const getPatentStatistics = param => post(`${INDUSTRYCOMPANY}/detail/patent_statistics`, {
  ...param,
});

// 专利分析
const getFindPatentList = param => post(`${INDUSTRYCOMPANY}/detail/find_patent_list`, {
  ...param,
});

// 政策推荐
const getPolicyecRommend = param => post(`${INDUSTRYCOMPANY}/detail/policy_recommendations`, {
  ...param,
});

export {
  getTitleInfo,
  getCommerceInfo,
  getStockRightsPenetrate,
  getRadarMap,
  getBarCharMap,
  getValuationEnterprise,
  getCorrelationNewsList,
  getQualityCertification,
  getPartnership,
  getPatentStatistics,
  getFindPatentList,
  getPolicyecRommend,
};
