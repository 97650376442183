/*
 *Author: zhaochenyu
 *Description: 可下钻地图
 *Date: 2021/12/01
*/

import React, { useState, useEffect, useRef, forwardRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts/lib/echarts';
import { get } from '../../../../utils/request';
import { mapCircleColor, mapColor } from '../../../../utils/color';
import { tooltipFormatter } from '../../../../utils/charts';

/**
 * 地图散点图组合组件
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{
 *   props: {data: Object, color: Object, title: String, mapCode: String,unit: String}
 * }> & React.RefAttributes<unknown>>}
 */
const DrillableMap = forwardRef((props) => {
  const { data, color, title, mapCode, unit, source, getAreaCompnayCount,
    setDrillCityCode, drillCityCodeList, setCurAreaKey, curAreaKey, drillCityKeyList,
    getAreaMapCount } = props;
  const [option, setOption] = useState({});
  const chartRef = useRef(null);

  const chartEvent = {
    click: (params) => {
      if (params.data && params.data.code) {
        drillCityCodeList.current.push(mapCode);
        drillCityKeyList.current.push(curAreaKey);
        setDrillCityCode(params.data.code);
        setCurAreaKey(params.data.code);
        getAreaMapCount({
          area_key: params.data.code,
        });
        getAreaCompnayCount({
          area_key: params.data.code,
          sort_column: 'company_count',
        });
      }
    },
    dblclick: () => {

    },
  };

  useEffect(() => {
    const generateOption = async () => {
      let mapName = 'china';
      if (mapCode) {
        mapName = mapCode === '000000' ? 'china' : mapCode;
        if (!echarts.getMap(mapName)) {
          // 不用项目名即可（wangbin）
          const geoJson = await get(`/city_json/${mapCode}.json`);
          // eslint-disable-next-line max-len
          // const geoJson = await get(`${process.env.NODE_ENV === 'development' ? '' : `/${projectName}`}/city_json/${mapCode}.json`);
          echarts.registerMap(mapName, geoJson);
        }
      } else if (!echarts.getMap('china')) {
        const geoJson = await get('/city_json/000000.json');
        echarts.registerMap('china', geoJson);
      }
      const maxValue = data.length > 0 ? Math.max(...data.map(item => item.value)) : 0;
      const minValue = data.length > 0 ? Math.min(...data.map(item => item.value)) : 0;
      const options = {
        tooltip: {
          trigger: 'item',
          confine: true,
        },
        backgroundColor: '#fff',
        visualMap: [{
          type: 'piecewise',
          min: minValue,
          max: maxValue,
          left: 14,
          bottom: 10,
          splitNumber: 8,
          inRange: {
            color: mapColor,
          },
          seriesIndex: 0,
          itemWidth: 22,
          itemHeight: 6,
          itemGap: 3,
          text: ['高', '低'],
          textStyle: {
            color: '#008DC7',
            fontSize: 12,
          },
          // showLabel: false,
        }],
        geo: [{
          map: mapName,
          zoom: 1.2,
          itemStyle: {
            normal: {
              borderColor: '#008DC7',
            },
          },
          emphasis: {
            itemStyle: {
              areaColor: '#008DC7',
              borderColor: '#fff',
              borderWidth: 2,
              shadowColor: 'rgba(0,141,199,0.7)',
            },
            label: {
              color: '#fff',
            },
          },
          label: {
            show: true,
            // color: '#fff',
          },
        }],
        series: [
          {
            // name: '',
            type: 'map',
            selectedMode: false,
            geoIndex: 0,
            map: mapName,
            tooltip: {
              formatter: (params) => {
                let str;
                if (source) {
                  str = '<div style="padding: 9px 9px 4px 9px">';
                  if (title) {
                    str += `<div style="margin-bottom: 10px; font-size: 14px; font-weight: bold; color: #3E5C76;letter-spacing: 1.75px;">${title}</div>`;
                  }
                  str += `<div style="margin-bottom: 10px; font-size: 14px; color: #3E5C76;letter-spacing: 1.75px;">${params.name}</div>`;
                  str += `<div style="display: flex; justify-content: space-between; color: #262D36;">
                    <span>
                      <span style="color: #4E7293;font-weight: bold;font-size: 18px;margin-right: 4px;">${(params.data && params.data.value) || 0}</span>
                      ${unit}
                    </span>
                  </div>`;
                  str += '</div>';
                } else {
                  str = tooltipFormatter(params, { title, valueWrap: true });
                }
                return str;
              },
              backgroundColor: source ? '#fff' : 'rgba(65, 89, 76, 0.8)',
              extraCssText: source ? 'border: 1px solid #DCDCDC; border-radius: 4;box-shadow: 2px 5px 8px 0 rgba(108,117,125,0.32);'
                : 'border: 1px solid #a4c3b2; border-radius: 0',
            },
            data,
          },
          // scatterSeries,
        ],
      };
      setOption(options);
    };
    generateOption();
  }, [data, color, mapCode]);

  return <ReactEcharts
    ref={chartRef}
    option={option}
    onEvents={chartEvent}
    style={{ width: '100%', height: '100%' }}
  />;
});

DrillableMap.defaultProps = {
  color: mapCircleColor[0],
};

export default DrillableMap;
