import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Tag, Divider } from 'antd';
import { LogoutOutlined, EyeOutlined, HeartOutlined } from '@ant-design/icons';
import { getPublicSentimentDetail } from '@api/IndustryNews';
import styles from './index.less';

function IndustryNewsDetail(props) {
  const { history } = props;
  const news_id = props?.match?.params?.id;
  const [detailData, setDetailData] = useState({});
  useEffect(() => {
    getPublicSentimentDetail({ news_id }).then(res => {
      const { status, data } = res;
      if (status === '00000') {
        setDetailData(data);
      }
    });
  }, [news_id]);
  return (
    <div className={`${styles.all} flex directionColumn alignCenter`}>
      <div className={`${styles.breadcrumb} flex alignCenter spaceBetween`}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/homePage">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{detailData?.title}</Breadcrumb.Item>
        </Breadcrumb>
        <LogoutOutlined
          className="cursorPointer"
          onClick={() => history.go(-1)}
        />
      </div>
      <div
        className={`${styles.detailTitle} flex directionColumn alignCenter mb12`}
      >
        <div className="title">{detailData?.title}</div>
        <div className="flex alignCenter">
          <div>{detailData?.author}</div>
          <Divider type="vertical" />
          <div>{detailData?.time?.slice(0, 19)}</div>
          {/* <Divider type="vertical" />
          <div>
            <EyeOutlined className="mr10" />
            {detailData?.confidence}
          </div>
          <Divider type="vertical" />
          <div className="cursorPointer">
            <HeartOutlined className="mr10" />
            收藏
          </div> */}
        </div>
      </div>
      {detailData?.raw_abstract ? (
        <div className={`${styles.detailAbstract} flex alignBaseLine mb12`}>
          <Tag color="blue">摘要</Tag>
          <div>{detailData.raw_abstract}</div>
        </div>
      ) : null}

      <div
        className={styles.detailContent}
        dangerouslySetInnerHTML={{ __html: detailData?.content }}
      ></div>
    </div>
  );
}

export default IndustryNewsDetail;
