/*
* Author: zhangmeng
* Description: '产业龙头'
* Date: 2021/12/01
*/

import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { Table, Spin } from 'antd';
import TreeRadialChart from './TreeRadialChart';
import { getIndustryLeader, getIndustryLeaderTable } from '../../../api/atlasDetail';
import { openNewPage } from '../../../utils/urlMethods';
import style from './index.less';

function IndustryLeader(props) {
  const { industry, area, areaName } = props;
  const [chart, setChart] = useState([]);
  const [table, setTable] = useState({});
  const [areaCount, setAreaCount] = useState({});
  const [sorder, setSorder] = useState('desc');
  const [scrollY, setScrollY] = useState(0);
  const loadings = useRef({
    chart: false,
    table: false,
  });

  const tableRef = useCallback((node) => {
    if (node) {
      setScrollY(node.scrollHeight - 39);
    }
  }, []);

  const getCompanyDetail = id => {
    openNewPage('companyDetail', { id });
  };

  const getList = (sort) => {
    loadings.current.table = true;
    getIndustryLeaderTable({
      industry_name: industry,
      area_key: area,
      sort,
    }).then(res => {
      if (res.status === '00000') {
        loadings.current.table = false;
        const { columns } = res.data[0].data;
        const arr = columns.map(item => {
          let obj;
          if (item.dataIndex === 'name') {
            obj = {
              ...item,
              width: '75%',
              ellipsis: true,
              render: (text, record) => (
                <div
                  title={text}
                  style={{ color: '#262D36', cursor: 'pointer' }}
                  className={style.companyName}
                  onClick={() => getCompanyDetail(record._id)}
                >
                  {text}
                </div>
              ),
            };
          } else
          if (item.dataIndex === 'possess_patent_sum') {
            obj = {
              ...item,
              width: '16%',
              ellipsis: true,
              defaultSortOrder: 'descend',
              sorter: true,
              // eslint-disable-next-line no-nested-ternary
              sortOrder: sort === '' ? '' : (sort === 'desc' ? 'descend' : 'ascend'),
              render: text => (
                <span className={style.patentNum}>{text}</span>
              ),
            };
          } else
          if (item.dataIndex === 'index') {
            obj = {
              ...item,
              width: '8%',
              ellipsis: true,
              className: 'index',
              render: text => <span>{text}</span>,
            };
          } else {
            obj = {
              ...item,
              ellipsis: true,
            };
          }
          return obj;
        });
        setTable({ ...res.data[0].data, columns: arr });
        setAreaCount(res.data[1]);
      }
    });
  };

  useEffect(() => {
    if (area === '') return;
    loadings.current.chart = true;
    getIndustryLeader({
      industry_name: industry,
      area_key: area,
    }).then(res => {
      loadings.current.chart = false;
      setChart([res.data]);
    });
    getList(sorder);
  }, [industry, area]);

  const industryTableChange = (_, filters, sorter) => {
    let order = '';
    if (sorter.order) {
      if (sorter.order.indexOf('asc') > -1) {
        order = 'asc';
      } else {
        order = 'desc';
      }
    }
    setSorder(order || '');
    getList(order);
  };

  return (
    <div className={style.all}>
      <div className={style.left}>
        <Spin tip="搜索中，请稍候....." spinning={loadings.current.chart}>
          {
            useMemo(() => (chart.length > 0
              && <TreeRadialChart data={chart} getDetail={getCompanyDetail} />
            ), [chart])
          }
        </Spin>
      </div>
      <div className={style.right}>
        <Spin tip="搜索中，请稍候....." spinning={loadings.current.table}>
          <div className={style.wordBox}>
            <div className={style.allCountry}>
              <span>全国龙头企业共计</span>
              &nbsp;&nbsp;
              <span className={style.bold}>{table.count || ''}</span>
              &nbsp;&nbsp;
              <span>家</span>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {
              (area !== 'all' && Object.keys(areaCount).length > 0) && (
                <div className={style.area}>
                  <span className={style.bold}>{areaName}</span>
                  <span>龙头企业共计</span>
                  &nbsp;&nbsp;
                  <span className={style.bold}>{areaCount.area_count || ''}</span>
                  &nbsp;&nbsp;
                  <span>家</span>
                </div>
              )
            }
          </div>
          <div className={style.tableBox} ref={tableRef}>
            <Table
              rowKey="index"
              pagination={false}
              scroll={{ y: scrollY }}
              columns={table.columns}
              dataSource={table.dataSource}
              onChange={industryTableChange}
              className={style.table}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default IndustryLeader;
