import { get, post } from '../utils/request';
import { NEWS } from '../utils/url';

// 获取舆情 typeList
const getPublicSentimentTypes = param => get(`${NEWS}/news_contents`, {
  ...param,
});

// 获取舆情列表
const getPublicSentimentList = param => post(`${NEWS}/search`, {
  ...param,
});

// 获取舆情 detail
const getPublicSentimentDetail = param => get(`${NEWS}/detail`, {
  ...param,
});

export {
  getPublicSentimentTypes,
  getPublicSentimentList,
  getPublicSentimentDetail,
};
