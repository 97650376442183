/*
* Author: zhangmeng
* Description: '申请试用'
* Date: 2021/1/07
*/

import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import CountDown from './CountDown';
import { userNote } from '../../../api/login';
import style from './index.less';

function UseApply(props) {
  const { cancelClick, onFinish, loading } = props;
  const [countDown, setcountDown] = useState(false);

  const onFinishFailed = (params) => {
    console.log(params, 'onFinishFailed');
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 4 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 20 },
      sm: { span: 20 },
    },
  };

  const getAuthCode = () => {
    if (countDown) return;
    const phone = document.querySelector('#phone').value;
    const checkPhone = new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/);
    if (!phone) {
      message.error('手机号不能为空');
    } else if (!checkPhone.test(phone)) {
      message.error('手机号码格式错误');
    } else {
      userNote({
        phone_no: phone,
        operate_type: 'apply_user',
      }).then(res => {
        if (res.status === '00000') {
          setcountDown(true);
        } else {
          message.error(res.message);
        }
      });
    }
  };

  return (
    <div className={style.all}>
      <div className={style.form}>
        <Form
          name="basic"
          labelAlign="left"
          onFinish={(p) => onFinish(p)}
          onFinishFailed={onFinishFailed}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...formItemLayout}
        >
          <Form.Item
            label=""
            name="display_name"
            rules={[
              {
                required: true,
                message: '请输入用户名',
              },
            ]}
          >
            <Input placeholder="请输入用户名" />
          </Form.Item>
          <Form.Item
            label=""
            name="password"
            className="password"
            rules={[
              {
                pattern: /^.{6,18}$/,
                message: '密码长度为6~18位！',
              },
              {
                required: true,
                message: '请设置密码',
              },
            ]}
          >
            <Input placeholder="请输入密码" type="password" autoComplete="new-password" />
          </Form.Item>
          {/* <Form.Item
            label=""
            name="confirm_password"
            className="password"
            rules={[
              {
                pattern: /^.{6,18}$/,
                message: '密码长度为6~18位！',
              },
              {
                required: true,
                message: '请再次输入密码',
              },
            ]}
          >
            <Input placeholder="请再次输入密码" type="password" />
          </Form.Item> */}
          <Form.Item
            label=""
            name="phone_no"
            className="phone"
            rules={[
              {
                pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                message: '手机号码格式错误',
              },
              {
                required: true,
                message: '请填写手机号码',
              },
            ]}
          >
            <Input placeholder="请输入真实有效的手机号码" id="phone" />
          </Form.Item>
          <Form.Item
            label=""
            name="captcha"
            className="auth_code"
            rules={[
              {
                required: true,
                message: '请填写验证码',
              },
            ]}
          >
            <Input placeholder="6位短信验证码" maxLength={6} style={{ width: 125 }} />
          </Form.Item>
          <div
            className={`${style.getAuthCode} ${countDown ? style.counting : undefined}`}
            onClick={() => getAuthCode()}
          >
            {countDown ? <CountDown changeSend={setcountDown} /> : '发送验证码'}
          </div>
          <Form.Item className="submit">
            <Button className="cancel-btn" onClick={() => cancelClick()}>
              取消
            </Button>
            <Button type="primary" htmlType="submit" className="submit-btn" loading={loading}>
              注册
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default UseApply;
