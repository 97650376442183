/*
* Author: zhangmeng
* Description: ''
* Date: 2021/12/01
*/

import React, { useCallback, useState } from 'react';
import { Table } from 'antd';
import style from './index.less';

function TableAndTitle(props) {
  const { title, data } = props;
  const [scrollY, setScrollY] = useState(0);
  const tableRef = useCallback((node) => {
    if (node) {
      setScrollY(node.scrollHeight - 39);
    }
  }, []);

  return (
    <div className={style.all}>
      <div className={style.title}>{title}</div>
      <div className={style.tableBox} ref={tableRef}>
        <Table
          rowKey="index"
          pagination={false}
          scroll={{ y: scrollY }}
          columns={data.tableHead}
          dataSource={data.tableBody}
          className={style.table}
        />
      </div>
    </div>
  );
}

export default TableAndTitle;
