/*
* Author: zhangmeng
* Description: '核心技术-树图'
* Date: 2021/08/19
*/

import React, { useEffect, useState, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';

const levelColor = ['#00659A', '#4D8CC4', '#B4B471', '#6ACCB6', '#D2741D', '#2DC4C8', '#E9C582', '#EE964B', '#D5671D', '#C54646', '#CB997E', '#E0C7C7', '#CCCCBA', '#D8E1E9', '#BCB3DF', '#CE7070', '#DBACBD'];
function TreeRadialChart(props) {
  const { data, orient, getDetail } = props;
  const [option, setOption] = useState({});
  const chartRef = useRef(null);
  const preZoom = useRef(1);
  const timer = useRef(null);
  /* eslint-disable no-param-reassign */
  function itemColor(params, borderColor) {
    params.forEach((item, index) => {
      item.symbol = 'circle';
      item.label = {
        color: item.mark ? '#EE964B' : '#333',
      };
      if (item.level === 0) {
        item.symbol = 'circle';
        item.itemStyle = {
          color: '#008CD6',
          // borderColor: '#CCE3DE',
          shadowBlur: 9,
          shadowColor: 'rgba(4,123,186,0.3)',
        };
        item.label = {
          color: '#fff',
          position: 'inside',
          rotate: 0,
        };
      } else if (item.level === 1) {
        item.itemStyle = {
          color: levelColor[index],
          borderColor: levelColor[index],
        };
        item.lineStyle = {
          color: levelColor[index],
        };
      } else
      if (item.level === 2 && item.children && item.children.length > 0) {
        item.itemStyle = {
          color: borderColor,
          borderColor,
        };
        item.lineStyle = {
          color: borderColor,
        };
      } else if (item.level === 4) {
        item.itemStyle = {
          color: borderColor,
          borderColor,
        };
        item.lineStyle = {
          color: borderColor,
        };
      } else {
        item.itemStyle = {
          color: borderColor,
          borderColor,
        };
        item.lineStyle = {
          color: borderColor,
        };
      }

      if (item.children && item.children && item.children.length > 0) {
        if (item.level === 3 || item.level === 4 || item.level === 5) {
          if (index % 2) {
            item.collapsed = true;
          } else {
            item.collapsed = false;
          }
        }
        itemColor(item.children, item.itemStyle.color);
      } else {
        item.collapsed = true;
        if (item.level === 0) {
          item.symbol = 'circle';
          item.children[0] = {
            level: 1,
            lineStyle: {
              width: 0,
            },
            itemStyle: {
              color: 'none',
              opacity: 0.1,
              borderColor: 'none',
            },
          };
        }
        item.symbol = 'emptyCircle';
        item.itemStyle = {
          color: borderColor,
          borderColor: borderColor || '',
        };
      }
    });
  }

  useEffect(() => {
    itemColor(data);
    const options = {
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        formatter: p => p.name,
      },
      series: [
        {
          type: 'tree',
          data,
          top: 0,
          left: 0,
          bottom: 0,
          layout: 'radial',
          right: 0,
          symbol: 'emptyCircle',
          roam: true,
          orient,
          symbolSize: (v, p) => {
            const { data: { level } } = p;
            if (level === 0) {
              return 60;
            }
            if (level === 1) {
              return 14;
            }
            return 12;
          },
          label: {
            color: '#333333',
            formatter: p => {
              const { data: d, name } = p;
              if (d.level === 0) {
                let n = '';
                const companyName = name.split('');
                while (companyName.length > 3) {
                  n += `${companyName.splice(0, 3).join('')}\n`;
                }
                return `{size0|${n}${companyName.join('')}}`;
              }
              if (d.level === 1 || (d.level === 2 && d.children && d.children.length > 0)) {
                let n = '';
                const companyName = name.split('');
                n += `${companyName.splice(0, 5).join('')}...`;
                return `{size1|${n}}`;
                // while (companyName.length > 4) {
                //   n += `${companyName.splice(0, 4).join('')}\n`;
                // }
                // return `{size1|${n}${companyName.join('')}}`;
              }
              return `{size2|${name}}`;
            },
            rich: {
              size0: {
                align: 'center',
                fontSize: 16,
                fontWeight: 700,
              },
              size1: {
                fontSize: 10,
              },
              size2: {
                fontSize: 10,
              },
            },
          },
          itemStyle: {
            borderWidth: 1,
          },
          scaleLimit: {
            min: 0.5,
            max: 12,
          },
          animation: true,
          emphasis: {
            focus: 'descendant',
          },
          initialTreeDepth: 5,
          expandAndCollapse: true,
          animationDuration: 750,
          animationDurationUpdate: 550,
        },
      ],
    };
    setOption(options);
  }, [data, orient]);

  const changeGraphFontSize = () => {
    const instance = chartRef.current.getEchartsInstance();
    const curOption = instance.getOption();
    const curZoom = curOption.series[0].zoom;
    if (curZoom !== preZoom.current) {
      preZoom.current = curZoom;
      const curFontSize = 10 * curZoom > 40 ? 40 : 10 * curZoom;
      const centerFontSize = 16 * curZoom > 40 ? 40 : 16 * curZoom;
      curOption.series[0].label.rich.size0.fontSize = centerFontSize;
      curOption.series[0].label.rich.size1.fontSize = curFontSize;
      curOption.series[0].label.rich.size2.fontSize = curFontSize;
      instance.setOption(curOption);
    }
  };

  const onEvents = {
    click: p => {
      if (p.data.company_id) {
        getDetail(p.data.company_id);
      }
      const instance = chartRef.current.getEchartsInstance();
      const curOption = instance.getOption();
      function collapsedChange(params) {
        params.forEach((item) => {
          if (item.name === p.name) {
            item.collapsed = !item.collapsed;
          }
          if (item.children && item.children && item.children.length > 0) {
            collapsedChange(item.children);
          }
        });
      }
      collapsedChange(curOption.series[0].data);
      instance.setOption(curOption);
    },
    finished: () => {
      chartRef.current.getEchartsInstance().getZr().on('mousewheel', () => {
        if (timer.current) {
          clearTimeout(timer.current);
          timer.current = null;
        }
        timer.current = setTimeout(() => {
          changeGraphFontSize();
        }, 50);
      });
    },
  };

  return (
    <ReactEcharts
      option={option}
      ref={chartRef}
      onEvents={onEvents}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default TreeRadialChart;
