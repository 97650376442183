/*
 *Author: zhaochenyu
 *Description: 投融资-图表
 *Date: 2021/04/19
*/

import React from 'react';
import HistogramChart from '../../../Model/HistogramChart';
import BarAndLineChart from '../../../Model/BarLineChart';
import LineChart from '../../../Model/LineChart';
import CoordHeatMap from '../../../Model/CoordHeatMap';
import { atlasChartColor, lineColor } from '../../../../utils/color';
import style from './index.less';

function Title(props) {
  const { title, subTitle } = props;
  return (
    <div className={style.title}>
      {title}
      <span className={style.subTitle}>{`(${subTitle})`}</span>
    </div>
  );
}

function BottomChart(props) {
  const { chartData } = props;
  const { title, dateRange, type, data, y_unit: yUnit = '', y_right_unit: yRUnit = '' } = chartData;
  const getChart = (chartType) => {
    let res = null;
    switch (chartType) {
      case 'heap_histogram_chart':
        res = <HistogramChart
          title={title}
          yUnit={yUnit}
          yRUnit={yRUnit}
          dataSource={data}
          color={atlasChartColor}
          type={chartType}
        />;
        break;
      // 折线图
      case 'line_chart':
        res = <LineChart
          data={data}
          title={title}
        />;
        break;
      case 'histogram_chart':
        res = <HistogramChart
          title={title}
          dataSource={data}
          color="#008CD6"
          xName={data.x_name || ''}
          yName={data.y_name || ''}
          yUnit={yUnit}
          type={chartType}
        />;
        break;
      case 'column_polyline_combination_diagram':
        res = <BarAndLineChart
          yUnit={yUnit}
          yRUnit={yRUnit}
          dataSource={data}
          title={title}
          barColor={atlasChartColor}
          lineColor={lineColor}
          source="atlas"
        />;
        break;
      case 'coords_heat_chart':
        res = <CoordHeatMap
          data={data}
          title={title}
          source="atlas"
        />;
        break;
      default: break;
    }
    return res;
  };
  return (
    <div className={style.all}>
      <Title
        title={title}
        subTitle={dateRange}
      />
      <div className={style.chart}>
        {getChart(type)}
      </div>
    </div>
  );
}

export default BottomChart;
