/*
 *Author: zhangmeng
 *Description: 企业评级-五仪表盘
 *Date: 2022/04/28
*/

import React, { useMemo } from 'react';
import GaugeChart from './GaugeChart';

function FiveIndexChart(props) {
  const { data } = props;

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between' }}>
      {
        useMemo(() => (
          Object.keys(data).length > 0 && Object.keys(data).map((item) => (
            <div key={item} style={{ height: '100%', width: '19.5%', background: '', border: '' }}>
              <div style={{ height: 'calc(100% - 25px)', position: 'relative' }}>
                <GaugeChart data={data[item]} />
                <div
                  style={{
                    position: 'absolute',
                    left: '50%',
                    bottom: '7px',
                    transform: 'translate(-50%)',
                    width: '8px',
                    height: '5px',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    background: '#008DC7',
                  }}
                />
              </div>
              <div
                style={{
                  height: '25px',
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#008DC7',
                  textAlign: 'center',
                  lineHeight: '25px',
                }}
              >
                {item}
              </div>
            </div>
          ))
        ), [data])
      }
    </div>
  );
}

export default FiveIndexChart;
