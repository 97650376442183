/*
 *Author: zhaochenyu
 *Description: 区域画像预览页
 *Date: 2021/11/17
*/

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Spin, Button, message } from 'antd';
// import dayjs from 'dayjs';
import {
  RollbackOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import cloneDeep from 'lodash/cloneDeep';
import { resetTemplateReport, resetTemplateReportNew, download, downloadSystemTemplateReport } from '../../api/office';
import AreaPortraitReport from './AreaPortraitReport';
import CountryPortraitReport from './CountryPortraitReport';
import IndustryPortraitReport from './IndustryPortraitReport';
import CompanyPortraitReport from './CompanyPortraitReport';
import IndustryPortraitCountryReport from './IndustryPortraitCountryReport';
import InvestmentPromotionReport from './InvestmentPromotionReport';
import PreciseInvestReport from './PreciseInvestmentReport';
import EquityInvestmentReport from './EquityInvestmentReport';
import SpecialNewsReport from './SpecialNewsReport';
import SpecialNewsReportCountry from './SpecialNewsReportCountry';
import HighTechnologyReport from './HighTechnologyReport';
import HighTechnologyReportCountry from './HighTechnologyReportCountry';
import IntellectualPropertyReport from './IntellectualPropertyReport';
import IntellectualPropertyReportCountry from './IntellectualPropertyReportCountry';
// import downloadComplete from '../../assets/images/report/downloadCompleted.png';
import style from './index.module.less';
import { base64toFile } from '../../utils/report';

const defaultTip = '计算模型分析中，请稍后...';

const allCitys = [
  '北京市',
  '天津市',
  '河北省',
  '山西省',
  '内蒙古自治区',
  '辽宁省',
  '吉林省',
  '黑龙江省',
  '上海市',
  '江苏省',
  '浙江省',
  '安徽省',
  '福建省',
  '江西省',
  '山东省',
  '河南省',
  '湖北省',
  '湖南省',
  '广东省',
  '广西壮族自治区',
  '海南省',
  '重庆市',
  '四川省',
  '贵州省',
  '云南省',
  '西藏自治区',
  '陕西省',
  '甘肃省',
  '青海省',
  '宁夏回族自治区',
  '新疆维吾尔自治区',
];

function RegionalPortraitReport(props) {
  const { history } = props;
  const [chartConditions, setChartConditions] = useState([]);
  const [chartTitles, setChartTitles] = useState([]);
  const [loading, setLoading] = useState(false); // 页面loading
  const [tip, setTip] = useState(defaultTip); // 页面loading提示信息
  const [reportId, setReportId] = useState(''); // 下载report_id
  // const [loadingComplete, setLoadingComplete] = useState(false);
  const [buyStatus, setBuyStatus] = useState(false);
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);

  const refList = useRef([]);
  const chartTitlesRef = useRef([]);
  const mainRef = useRef(null);
  const chartReady = useRef(null);
  const timer = useRef(null);
  const timer1 = useRef(null);
  const timer2 = useRef(null);
  const timer3 = useRef(null);
  // const commonRef = useRef({ blockUnregister: null, nextLocation: {}, lastChartCount: 0 });
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const urlReportMsg = JSON.parse(window.decodeURIComponent(window.atob(params.get('msg') || '')) || '{}');
  // console.log(urlReportMsg, 3333);
  const pushToAchor = (name) => {
    const achorName = document.getElementById(name);
    if (achorName) {
      let dom = achorName;
      let y = 0;
      do {
        y += dom.offsetTop;
        dom = dom.parentElement;
      } while (dom.parentElement);
      mainRef.current.scrollTo({
        top: y,
        behavior: 'smooth',
      });
    }
  };

  const getChartFinishedPercent = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  // const beforeunloadListener = (e) => {
  //   // console.log(e);
  //   e.preventDefault();
  //   e.returnValue = '';
  // };
  const getCurReport = () => {
    const reportType = urlReportMsg.curTemplateId;
    let res = null;
    switch (reportType) {
      case 16:
        res = <AreaPortraitReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          allCitys={allCitys}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case 23:
        res = <CountryPortraitReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          getChartFinishedPercent={getChartFinishedPercent}
          pushToAchor={pushToAchor}
          buyStatus={buyStatus}
        />;
        break;
      case 17:
        res = <IndustryPortraitReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          getChartFinishedPercent={getChartFinishedPercent}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          buyStatus={buyStatus}
        />;
        break;
      case 22:
        res = <IndustryPortraitCountryReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          getChartFinishedPercent={getChartFinishedPercent}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          buyStatus={buyStatus}
        />;
        break;
      case 18:
        res = <CompanyPortraitReport
          chartConditions={chartConditions}
          getChartFinishedPercent={getChartFinishedPercent}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          buyStatus={buyStatus}
        />;
        break;
      case 500:
        res = <InvestmentPromotionReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          getChartFinishedPercent={getChartFinishedPercent}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          buyStatus={buyStatus}
        />;
        break;
      case 499:
        res = <PreciseInvestReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          getChartFinishedPercent={getChartFinishedPercent}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          buyStatus={buyStatus}
        />;
        break;
      case 498:
        res = <EquityInvestmentReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          getChartFinishedPercent={getChartFinishedPercent}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          buyStatus={buyStatus}
        />;
        break;
      case 492:
        res = <SpecialNewsReportCountry
          chartConditions={chartConditions}
          getChartFinishedPercent={getChartFinishedPercent}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          buyStatus={buyStatus}
        />;
        break;
      case 493:
        res = <SpecialNewsReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          getChartFinishedPercent={getChartFinishedPercent}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          buyStatus={buyStatus}
        />;
        break;
      case 494:
        res = <HighTechnologyReportCountry
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          getChartFinishedPercent={getChartFinishedPercent}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          buyStatus={buyStatus}
        />;
        break;
      case 495:
        res = <HighTechnologyReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          getChartFinishedPercent={getChartFinishedPercent}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          buyStatus={buyStatus}
        />;
        break;
      case 496:
        res = <IntellectualPropertyReportCountry
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          getChartFinishedPercent={getChartFinishedPercent}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          buyStatus={buyStatus}
        />;
        break;
      case 497:
        res = <IntellectualPropertyReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          getChartFinishedPercent={getChartFinishedPercent}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          buyStatus={buyStatus}
        />;
        break;
      default:
        res = <AreaPortraitReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          getChartFinishedPercent={getChartFinishedPercent}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          buyStatus={buyStatus}
        />;
        break;
    }
    return res;
  };

  const getReportWord = async (urlData) => {
    setLoading(true);
    let imgList = [];
    const promises = [];
    const descs = [];
    const titles = [];
    const sizes = [];
    refList.current.forEach(item => {
      promises.push(item.current
        ? item.current.toDataURL() : Promise.resolve(null));
      descs.push(item.current ? item.current.getChartDesc() : '');
      sizes.push(item.current ? item.current.getChartSize() : { width: 0, height: 0 });
    });
    imgList = await Promise.all(promises);
    imgList.forEach((item, index) => {
      if (item && typeof item !== 'string') {
        sizes[index] = { width: 0, height: 0 };
      }
    });
    chartTitlesRef.current.forEach(item => {
      titles.push(item.current ? item.current.getChartTitle() : '');
    });
    const fileList = imgList.map((item, index) => base64toFile(item, chartConditions[index].title || ''));
    // 校验报告名称是否存在
    // const picName = selectMode.map(item => item.title).join(',');
    const selectModeList = chartConditions.map((item, index) => {
      let copyItem = cloneDeep(item);
      copyItem = {
        ...copyItem,
        before_describe_value_dict: descs[index].before_describe_value_dict || '',
        after_describe_value_dict: descs[index].after_describe_value_dict || '',
        title: titles[index],
        size: sizes[index],
      };
      return copyItem;
    });
    const reportMsg = {
      report_id: reportId,
      query_params: JSON.stringify(selectModeList),
      area_name: urlData.area || '',
      industry_name: urlData.industry || '',
      company_name: urlData.name || '',
      pic_list: fileList,
    };
    setTip('报告生成中，请稍后...');
    downloadSystemTemplateReport(reportMsg).then(res => {
      if (res.status === '00000') {
        message.destroy();
        setDownloadModalVisible(true);
      } else {
        message.error(res.message);
      }
    }).finally(() => {
      setLoading(false);
    });
  };


  const downloadReport = () => {
    download({
      report_id: reportId,
    }).then(res => {
      if (res.status === '00000') {
        message.destroy();
        window.open(res.data);
        setLoading(false);
        setDownloadModalVisible(false);
      } else {
        message.destroy();
        message.warning(res?.message);
      }
    });
  };

  useEffect(() => {
    setTip(defaultTip);
    setLoading(true);
    // 企业画像入参
    const params1 = {
      template_id: urlReportMsg.template_id,
      area_key_list: [urlReportMsg.area_key_list],
      // industry_name_list: [urlReportMsg.industry_name_list],
      industry_code_list: [urlReportMsg.industry_code_list],
    };
    // 股权投资入参
    const params2 = {
      template_id: urlReportMsg.template_id,
      // area_key_list: [urlReportMsg.area_key_list],
      // industry_name_list: [urlReportMsg.industry_name_list],
      industry_code_list: [urlReportMsg.industry_code_list],
      period: urlReportMsg.period,
    };
    const param = urlReportMsg.areaType === '股权投资' ? params2 : params1;
    resetTemplateReportNew(param).then(res => {
      if (res.status === '00000') {
        setBuyStatus(true);
        const curCharts = JSON.parse(res.data.query_params);
        setReportId(res.data?.report_id || '');
        const titles = curCharts.map(item => item.title);
        setChartTitles(titles);

        refList.current = curCharts.map(() => React.createRef());
        chartTitlesRef.current = curCharts.map(() => React.createRef());
        chartReady.current = curCharts.map(() => false);
        // 查询图表标题
        setChartConditions(curCharts);
        // setDownloadingDisable(false);
        // setEditDisable(false);
      } else {
        setBuyStatus(false);
        setLoading(false);
      }
    });
    // window.addEventListener('beforeunload', beforeunloadListener);
    return () => {
      clearTimeout(timer.current);
      clearTimeout(timer1.current);
      clearTimeout(timer2.current);
      clearTimeout(timer3.current);
      // window.removeEventListener('beforeunload', beforeunloadListener);
    };
  }, []);

  return (
    <div className={style.all} ref={mainRef}>
      {loading && (
      <div className={style.loadingCover}>
        <Spin spinning={loading} tip={tip} />
      </div>
      )}
      <div style={{ flex: 1 }} />
      <div style={{ height: '100vh', overflow: 'auto', width: 796 }}>
        {getCurReport()}
      </div>
      <div className={style.leftContent}>
        <div
          className={style.reportTitle}
          onClick={() => {
            history.push('/reportGeneration');
          }}
        >
          <RollbackOutlined style={{ marginRight: 12 }} />
          返回
        </div>
        <div
          className={style.reportDown}
          onClick={() => getReportWord(urlReportMsg)}
        >
          <VerticalAlignBottomOutlined style={{ marginRight: 12 }} />
          下载
        </div>
      </div>
      {
        downloadModalVisible && (
          <>
            <div className={style.modal}>
              <div className={style.top}>
                <div className={style.title}>报告已生成</div>
              </div>
              <div className={style.buttons}>
                <Button className={style.left} onClick={() => setDownloadModalVisible(false)}>稍后再试</Button>
                <Button
                  className={style.right}
                  onClick={() => {
                    setDownloadModalVisible(false);
                    downloadReport();
                  }}>
                  现在下载
                </Button>
              </div>
            </div>
            <div className={style.modalCover}></div>
          </>
        )
      }
    </div>
  );
}

export default RegionalPortraitReport;
