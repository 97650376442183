/*
* Author: zhangmeng
* Description: ''
* Date: 2022/04/29
*/

import React from 'react';
import { Space, Pagination } from 'antd';
import style from './index.less';

function PaginationComp(props) {
  const { total, paginationOnChange, pageSize, currPage } = props;

  return (
    <div className={style.all}>
      <div className={style.paginationBox} style={{ display: `${total > 0 ? '' : 'none'}` }}>
        <div className={style.pagination}>
          <Space>
            <div>
              {
                pageSize && <Pagination
                  size="middle"
                  showSizeChanger={false}
                  pageSize={pageSize}
                  current={currPage}
                  total={total}
                  showTotal={(t) => (
                    <span>
                      总共&emsp;
                      <span>{t}</span>
                      &emsp;个项目
                    </span>
                  )}
                  onChange={(page, pageSize1) => paginationOnChange(page, pageSize1)}
                />
              }
            </div>
          </Space>
        </div>
      </div>
    </div>
  );
}

export default PaginationComp;
