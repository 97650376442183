/*
* Author: zhangmeng
* Description: '仪表盘'
* Date: 2021/08/19
*/

import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';

function GaugeChart(props) {
  const { data } = props;
  const [option, setOption] = useState({});

  useEffect(() => {
    let lineColor = [];
    const num = Number(data[0].value.toFixed());
    if (num < 25) {
      lineColor = [
        [0.25, '#C54646'],
        [0.5, '#FFC559'],
        [0.75, '#00CFFF'],
        [1, '#51C886'],
      ];
    }
    if (num >= 25 && num < 50) {
      lineColor = [
        [0.25, '#C54646'],
        [0.5, '#FFC559'],
        [0.75, '#00CFFF'],
        [1, '#51C886'],
      ];
    }
    if (num >= 50 && num < 75) {
      lineColor = [
        [0.25, '#C54646'],
        [0.5, '#FFC559'],
        [0.75, '#00CFFF'],
        [1, '#51C886'],
      ];
    }
    if (num >= 75) {
      lineColor = [
        [0.25, '#C54646'],
        [0.5, '#FFC559'],
        [0.75, '#00CFFF'],
        [1, '#51C886'],
      ];
    }

    const options = {
      series: [{
        type: 'gauge',
        center: ['50%', '95%'],
        radius: '147%',
        startAngle: 180,
        zlevel: 11,
        endAngle: 0,
        min: 0,
        max: 100,
        splitNumber: 8,
        axisLine: {
          lineStyle: {
            width: 10,
            color: lineColor,
          },
        },
        pointer: {
          icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
          length: 10,
          width: 9,
          offsetCenter: [0, '-65%'],
          itemStyle: {
            color: '#008DC7',
          },
        },
        axisTick: {
          length: 5,
          distance: 2,
          lineStyle: {
            color: 'auto',
            width: 2,
          },
        },
        splitLine: {
          length: 6,
          distance: 2,
          lineStyle: {
            color: 'auto',
            width: 2,
          },
        },
        axisLabel: {
          show: true,
          color: '#262D36',
          fontSize: 12,
          distance: -25,
          formatter: (value) => {
            let level = '';
            if (value === 87.5) {
              level = '优';
            } else if (value === 62.5) {
              level = '中';
            } else if (value === 37.5) {
              level = '良';
            } else if (value === 12.5) {
              level = '差';
            }
            return level;
          },
        },
        title: {
          show: true,
          offsetCenter: [0, '-35%'],
          fontSize: 12,
        },
        detail: {
          offsetCenter: [0, '-12%'],
          valueAnimation: true,
          formatter: (value) => {
            const str = `{word|${Math.round(value)}} {fen|分}`;
            return str;
          },
          rich: {
            word: {
              fontSize: 14,
              color: '#008DC7',
              fontWeight: 700,
            },
            fen: {
              fontSize: 10,
              color: '#262D36',
            },
          },
          color: 'auto',
        },
        data,
      },
      {
        name: '内圈',
        type: 'gauge',
        zlevel: 2,
        radius: '90%',
        center: ['50%', '95%'],
        startAngle: 180,
        endAngle: 0,
        axisLine: {
          lineStyle: {
            color: [[1, '#EDF4FC']],
            width: 200,
          },
        },
        title: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        pointer: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        detail: {
          show: false,
        },
      },
      ],
    };
    setOption(options);
  }, [data]);

  return (
    <ReactEcharts
      option={option}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default GaugeChart;
