/*
 *Author: zhaochenyu
 *Description: 图标引用
 *Date: 2020/11/19
*/

import { createFromIconfontCN } from '@ant-design/icons';

// 项目对应生产的iconfont库
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2213518_0r0nbtlvpo4o.js',
});

// 数据搜索tab的图标
const tabMap = {
  SQList: 'icondeploymentunit',
  recommend: 'iconicon_fabu',
  industryChain: 'iconguizeyinqing',
  chart: 'iconshujukanban',
  industry: 'iconchengshi',
  patent: 'iconshiyongwendang',
  institution: 'iconzuzhiqunzu',
  policy: 'iconicon_bangzhuwendang',
  sentiment: 'icontubiao-qiapian',
  // macro: 'iconshortcut',
  financing: 'icontouzi',
  standard: 'icontags',
  atlas: 'icondeploymentunit',
  research_report: 'iconyanbao',
  macro: 'iconshouyi',
  // macro_economy: 'iconshouyi',
}

const labelColorMap = {
  'first': {
    background: '#D4E0EE',
    color: '#0D3B66'
  },
  'second': {
    background: '#CCE3DE',
    color: '#415249'
  },
  'three': {
    background: '#FFF5E1',
    color: '#4D3B17'
  },
  'four': {
    background: '#EAD1C1',
    color: '#562020'
  },
  'fif': {
    background: '#E0C7C7',
    color: '#562020'
  },
  'six': {
    background: '#CBC7DB',
    color: '#40395A'
  },
  'seven': {
    background: '#CCCCBA',
    color: '#3E3E33'
  },
  'eight': {
    background: '#9CACAD',
    color: '#2C3B3C'
  },
  'nine': {
    background: '#B5BBC4',
    color: '#0D3B66'
  }
}

export { IconFont, tabMap, labelColorMap };
