/*
 *Author: zhaochenyu
 *Description: 数字园区接口
 *Date: 2021/12/20
*/

import { get, post, protoGet } from '../utils/request';
import { FIGUREPARK, INTERACTION } from '../utils/url';

// 数字园区-产业筛选框
const getIndustrySelect = () => post(`${FIGUREPARK}/park_attention_industry`, {});

// 产业链图-产业链
const getIndustryLink = params => get(`${FIGUREPARK}/industry_link`, {
  ...params,
});

// 产业链图-企业列表
const getIndustryLinkCompnayList = params => get(`${FIGUREPARK}/industry_link_company_list`, {
  ...params,
});

// 情报推送-新闻列表
const getNewsListData = params => get(`${FIGUREPARK}/news_list`, {
  ...params,
});

// 情报推送-政策列表
const getPolicyListData = params => get(`${FIGUREPARK}/policy_list`, {
  ...params,
});

// 情报推送-新增企业
const getNewsCompnayCharts = params => get(`${FIGUREPARK}/new_company_chart`, {
  ...params,
});

// 情报推送-新增专利、投融资、标准
const getNewsBottomChart = params => get(`${FIGUREPARK}/new_patent_investment_standard`, {
  ...params,
});

// 双链融合-创新链关系图
const getInnvoteChainChart = params => get(`${FIGUREPARK}/innovation_chain_relationship_diagram`, {
  ...params,
});

// 双链融合-企业列表
const getInnvoteCompnayList = params => get(`${FIGUREPARK}/company_list`, {
  ...params,
});

// 双链融合-企业列表数量
const getInnvoteCompnayCount = params => get(`${FIGUREPARK}/company_number`, {
  ...params,
});

// 双链融合-省内创新资源
const getProvinceInnvote = params => get(`${FIGUREPARK}/provincial_innovation_resources`, {
  ...params,
});

// 双链融合-省内创新资源总数
const getProvinceCount = params => get(`${FIGUREPARK}/desc_of_recommended_innovation_resources`, {
  ...params,
});

// 双链融合-推荐专利
const getRecommandPatent = params => get(`${FIGUREPARK}/the_patent_is_recommended`, {
  ...params,
});

// 双链融合-筛选产业链环节
const getAllLinkNode = params => get(`${INTERACTION}/figure_park_industry_like_filter`, {
  ...params,
});

// 双链融合-研究机构类型筛选
const getInstitutionType = params => get(`${INTERACTION}/figure_park_research_institution_type`, {
  ...params,
});

// 产业运行-产业链环节穿透图
const getIndustryLinkPierceGraph = params => get(`${FIGUREPARK}/industry_link_through_the_figure`, {
  ...params,
});

// 产业运行-龙头企业列表
const getLeadingEnterprise = params => get(`${FIGUREPARK}/list_of_leading_enterprises`, {
  ...params,
});

// 产业运行-发展潜力榜
const getDevPotential = params => get(`${FIGUREPARK}/developing_business_lists`, {
  ...params,
});

// 产业运行-融资引力榜
const getFinancingAppeal = params => get(`${FIGUREPARK}/list_of_attracting_financing`, {
  ...params,
});

// 产业运行-创新能力榜
const getCreation = params => get(`${FIGUREPARK}/innovation_ability_list`, {
  ...params,
});

// 精准招商-技术实力
const getTechnicalStrengthList = params => get(`${FIGUREPARK}/technical_strength_list`, {
  ...params,
});

// 精准招商-加强布局
const getStrengthenLayout = params => get(`${FIGUREPARK}/strengthen_layout`, {
  ...params,
});

// 精准招商-扩张意图
const getExpansionOfIntention = params => get(`${FIGUREPARK}/expansion_of_intention`, {
  ...params,
});

// 精准招商-链主企业
const getMainChainEnterprise = params => get(`${FIGUREPARK}/main_chain_enterprise`, {
  ...params,
});

// 精准招商-龙头企业
const getLeadingEnterprises = params => get(`${FIGUREPARK}/leading_enterprises`, {
  ...params,
});

// 精准招商-省级龙头企业
const getProvincialLeadingEnterprise = params => get(`${FIGUREPARK}/provincial_leading_enterprise`, {
  ...params,
});

// 精准招商-项目挖掘
const getMiningProject = params => get(`${FIGUREPARK}/mining_project`, {
  ...params,
});

// 产业运行-企业数量占比
const getCompanyIndustryDistribution = params => get(`${FIGUREPARK}/company_industry_distribution_diagram_fenxi`, {
  ...params,
});

// 产业运行-专利数量占比
const getParkPatentDistribution = params => get(`${FIGUREPARK}/park_patent_distribution_diagram_fenxi`, {
  ...params,
});

// 产业运行-专利数量占比
const getpPrkInstitutionDistribution = params => get(`${FIGUREPARK}/park_institution_distribution_diagram_fenxi`, {
  ...params,
});

// 产业运行-企业名单
const getCompanyListInParFenxi = params => get(`${FIGUREPARK}/company_list_in_park_fenxi`, {
  ...params,
});

// 园区边界
const getRegionBorder = params => get(`${FIGUREPARK}/region_border_in_park_fenxi`, {
  ...params,
});

// 省边界
const getPRegionBorder = params => get(`${FIGUREPARK}/find_region_border`, {
  ...params,
});

// 园区散点
const getDotCluster = params => protoGet(`${FIGUREPARK}/dot_cluster_map_in_park_fenxi`, {
  ...params,
});

// 画像诊断-龙头企业描述
const getDescOfLeadingEnterprises = params => get(`${FIGUREPARK}/desc_of_leading_enterprises`, {
  ...params,
});

export {
  getInnvoteCompnayCount,
  getProvinceCount,
  getIndustrySelect,
  getInstitutionType,
  getAllLinkNode,
  getIndustryLink,
  getIndustryLinkCompnayList,
  getNewsListData,
  getPolicyListData,
  getNewsCompnayCharts,
  getNewsBottomChart,
  getInnvoteChainChart,
  getInnvoteCompnayList,
  getProvinceInnvote,
  getRecommandPatent,
  getIndustryLinkPierceGraph,
  getLeadingEnterprise,
  getDevPotential,
  getFinancingAppeal,
  getCreation,
  getTechnicalStrengthList,
  getStrengthenLayout,
  getExpansionOfIntention,
  getMainChainEnterprise,
  getLeadingEnterprises,
  getProvincialLeadingEnterprise,
  getMiningProject,
  getCompanyIndustryDistribution,
  getParkPatentDistribution,
  getpPrkInstitutionDistribution,
  getCompanyListInParFenxi,
  getRegionBorder,
  getPRegionBorder,
  getDotCluster,
  getDescOfLeadingEnterprises,
};
