/*
* Author: zhangmeng
* Description: '密码登录组件'
* Date: 2020/12/03
*/

import React, { useMemo } from 'react';
import { Form, Input, Button } from 'antd';
import style from './index.less';
import { oauthIp } from '../../../utils/url';

function PasswordLogin(props) {
  const { onFinish, uid,
    isLoading, captchaImgClick, useApply, forgetPassword } = props;

  const img = useMemo(() => <img src={`${oauthIp}/captcha?key=${uid}`} alt="加载中..." />, [uid]);

  // const onFinishFailed = (params) => {
  //   // console.log(params, 'onFinishFailed');
  // };

  // const forgetPassword = (params) => {
  //   console.log(params, '忘记密码');
  // };

  return (
    <div className={style.all}>
      <Form
        // onValuesChange={(changedValue, allValue) => onValuesChange(changedValue, allValue)}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // initialValues={initialValues}
      >
        <Form.Item
          label=""
          name="username"
          className="username"
          rules={[
            // {
            //   pattern: /^[\u4e00-\u9fa5]{1,7}$|^[\dA-Za-z_]{1,14}$/,
            //   message: '最长不得超过7个汉字或14个字节',
            // },
            {
              required: true,
              message: '请输入登录账号',
            },
          ]}
        >
          <Input placeholder="请输入登录账号" />
        </Form.Item>

        <Form.Item
          label=""
          name="password"
          className="password"
          rules={[
            {
              pattern: /^.{6,18}$/,
              message: '密码长度为6~18位！',
            },
            {
              required: true,
              message: '请输入密码',
            },
          ]}
        >
          <Input placeholder="请输入密码" type="password" autoComplete="new-password" />
          {/* <div */}
          {/*  className="forgetPassword" */}
          {/*  onClick={() => forgetPassword()} */}
          {/*  style={{ display: 'none' }} */}
          {/* > */}
          {/*  忘记密码 */}
          {/* </div> */}
        </Form.Item>

        <Form.Item
          label=""
          className="captchaBox"
        >
          <Form.Item
            label=""
            name="captcha"
            className="captcha"
            rules={[
              {
                required: true,
                message: '请输入验证码',
              },
            ]}
          >
            <Input placeholder="请输入验证码" />
          </Form.Item>
          <div className="getCaptcha" onClick={() => captchaImgClick()}>
            {img}
          </div>
        </Form.Item>
        <Form.Item className="submit">
          <Button type="primary" htmlType="submit" loading={isLoading}>
            登录
          </Button>
          <div className={style.bottom}>
            {/* <div
              className={style.register}
              onClick={() => useApply()}
              // style={{ display: 'none' }}
            >
              没有账号？去注册 &gt;
            </div> */}
            <div className={style.register} style={{ display: 'none' }} onClick={() => forgetPassword()}>
              忘记密码
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default PasswordLogin;
