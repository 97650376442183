import React, { useEffect, useState } from 'react';
import { DownCircleOutlined } from '@ant-design/icons';
import { Select, TreeSelect, Spin, DatePicker, InputNumber } from 'antd';
import { formatDate } from '../../../../../utils/tool';
import style from './index.less';

const { RangePicker } = DatePicker;

function SuperSearch(props) {
  const {
    formSpecialTagList,
    setFormSpecialTagList,
    companyStatusList,
    setFormCompanyStauts,
    industryList,
    setFormIndustry,
    areaList,
    setFormArea,
    setFormDate,
    formRegCapital,
    setFormRegCapital,
  } = props;

  const [isUnLimitCapital, setIsUnLimitCapital] = useState(true);

  const { Option } = Select;

  const changeTagCheckState = (key) => {
    const newTagList = formSpecialTagList.map(tmpTag => {
      const newTmpTag = {
        value: tmpTag.value,
        checked: tmpTag.checked,
      };
      if (key === '不限' && tmpTag.value !== '不限') {
        newTmpTag.checked = false;
      }
      if (key === '不限' && tmpTag.value === '不限') {
        newTmpTag.checked = true;
      }
      if (key === tmpTag.value && key !== '不限') {
        newTmpTag.checked = !newTmpTag.checked;
      }
      if (tmpTag.value === '不限' && key !== '不限') {
        newTmpTag.checked = false;
      }

      return newTmpTag;
    });
    setFormSpecialTagList(newTagList);
  };

  const handleChange = (value) => {
    setFormCompanyStauts(value);
  };

  const handleIndustryChange = value => {
    setFormIndustry(value);
  };

  const handleAreaChange = value => {
    setFormArea(value);
  };

  const handleDateChange = value => {
    setFormDate([formatDate(value[0]._d), formatDate(value[1]._d)]);
  };

  const handleRegCapitalMinChange = value => {
    setFormRegCapital([value, formRegCapital[1]]);
  };

  const handleRegCapitalMaxChange = value => {
    setFormRegCapital([formRegCapital[0], value]);
  };

  const unLimitCapital = () => {
    setFormRegCapital([0, 0]);
  };

  useEffect(() => {
    if (formRegCapital[1] === 0) {
      setIsUnLimitCapital(true);
    } else {
      setIsUnLimitCapital(false);
    }
  }, [formRegCapital]);

  return (
    <div className={style.all}>
      <div className={style.specialTags}>
        特色标签：
        {
          formSpecialTagList.map((item, index) => (
            <div
              key={index}
              className={item.checked ? style.checkedTag : style.tag}
              onClick={() => changeTagCheckState(item.value)}
            >
              {item.value}
            </div>
          ))
        }
      </div>
      <div className={style.divider} />
      <div className={style.middleForm}>
        <div className={style.area}>
          区域：
          {
            areaList.length > 0 ? (
              <TreeSelect
                style={{ width: 240 }}
                multiple
                allowClear
                dropdownClassName={style.conditionSelect}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={areaList}
                placeholder="不限"
                listHeight={320}
                onChange={handleAreaChange}
              />
            ) : (<Spin />)
          }
        </div>
        <div className={style.industry}>
          产业：
          {
            industryList.length > 0 ? (
              <TreeSelect
                placeholder="不限"
                style={{
                  width: 320,
                }}
                multiple
                allowClear
                suffixIcon={<DownCircleOutlined />}
                dropdownClassName={style.conditionSelect}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={industryList}
                listHeight={320}
                onChange={handleIndustryChange}
              />
            ) : (<Spin />)
          }
        </div>
        <div className={style.companyState}>
          企业状态：
          {
            companyStatusList.length > 0 ? (
              <Select
                mode="multiple"
                defaultValue={[]}
                placeholder="不限"
                style={{
                  width: 320,
                }}
                onChange={handleChange}
              >
                {
                  companyStatusList.map((tmpStatus, index) => (
                    <Option value={tmpStatus.value} key={`${tmpStatus.value}-${index}`}>
                      {tmpStatus.label}
                    </Option>
                  ))
                }
              </Select>
            ) : (<Spin />)
          }
        </div>
      </div>
      <div className={style.divider} />
      <div className={style.bottomForm}>
        <div className={style.establishDate}>
          成立时间：
          <RangePicker onChange={handleDateChange} />
        </div>
        <div className={style.regCapital}>
          注册资本：
          <div
            className={isUnLimitCapital ? style.noFormat : style.unNoFormat}
            onClick={unLimitCapital}
          >
            不限
          </div>
          <InputNumber
            min={0}
            max={999999}
            defaultValue={0}
            onChange={handleRegCapitalMinChange}
            value={formRegCapital[0]}
          />
          <div style={{ display: 'inline-block', margin: '0 10px' }}>
            至
          </div>
          <InputNumber
            min={formRegCapital[0]}
            defaultValue={0}
            onChange={handleRegCapitalMaxChange}
            value={formRegCapital[1]}
          />
          <div style={{ display: 'inline-block', margin: '0 10px' }}>
            万人民币
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperSearch;
