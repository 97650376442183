/*
* Author: zhangmeng
* Description: ''
* Date: 2021/09/28
*/

import React from 'react';
import TableComp from './TableComp/index';
import BarChart from './BarChart/index';
import style from './index.less';

function BarAndTable(props) {
  const { listData, barData, getList } = props;

  return (
    <div className={style.all}>
      <div className={style.bar}>
        {
          Object.keys(barData).length > 0
          && barData.data.yAxis
          && barData.data.yAxis.length > 0
          && <BarChart data={barData} />
        }
      </div>
      <div className={style.list}>
        {Object.keys(listData).length > 0 && <TableComp data={listData} getList={getList} />}
      </div>
    </div>
  );
}

export default BarAndTable;
