import React, { useEffect, useState } from 'react';
import { Table, Spin, message, Popconfirm } from 'antd';
import style from './index.less';
import {
  getUserCompanyRecord,
  updateCompanyRecord,
  deleteCompanyRecord,
  cleanCompanyRecord,
} from '../../../../api/company';

function RecordCompany() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const refreshData = () => {
    setLoading(true);
    getUserCompanyRecord().then(res => {
      if (res.status === '00000') {
        setData(res.data.records);
        setLoading(false);
      } else {
        message.error(res.message);
      }
    });
  };

  useEffect(() => {
    refreshData();
  }, []);

  const collectOrCancel = (status, parms) => {
    updateCompanyRecord({
      id: parms.id,
      status,
    }).then(res => {
      if (res.status === '00000') {
        refreshData();
        message.success('操作成功！');
      } else {
        message.error(res.message);
      }
    });
  };

  const columns = [
    {
      title: '名称',
      dataIndex: 'company_name',
    },
    {
      title: '浏览时间',
      dataIndex: 'create_time',
    },
    {
      title: '操作',
      dataIndex: '',
      key: 'x',
      render: (p) => (
        <div style={{ color: '#008DC7', cursor: 'pointer' }}>
          {
            p.status === 0 ? (
              <div onClick={() => collectOrCancel(1, p)}>
                关注
              </div>
            ) : (
              <div onClick={() => collectOrCancel(0, p)}>
                取消关注
              </div>
            )
          }
        </div>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const hasData = data.length > 0;

  const deleteRecord = () => {
    if (hasSelected) {
      deleteCompanyRecord({
        ids: selectedRowKeys,
      }).then(res => {
        if (res.status === '00000') {
          message.success('删除成功！');
          refreshData();
        } else {
          message.error(res.message);
        }
      });
    }
  };

  const cleanRecord = () => {
    if (hasData) {
      cleanCompanyRecord().then(res => {
        if (res.status === '00000') {
          refreshData();
        } else {
          message.error(res.message);
        }
      });
    }
  };

  return (
    <div className={style.all}>
      <div className={style.tableHead}>
        <div className={style.operate}>
          <Popconfirm
            title="是否删除已选中的浏览记录？"
            onConfirm={deleteRecord}
            okText="删除"
            cancelText="取消"
          >
            <div
              className={hasSelected ? style.deleteOK : style.deleteNo}
            >
              删除
            </div>
          </Popconfirm>
          <Popconfirm
            title="是否清空所有的浏览记录？"
            onConfirm={cleanRecord}
            okText="删除"
            cancelText="取消"
          >
            <div
              className={hasData ? style.cleanAll : null}
            >
              清空记录
            </div>
          </Popconfirm>
        </div>
      </div>
      <Spin spinning={loading}>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          rowKey={record => record.id}
          pagination={{ pageSize: 5, position: ['bottomCenter'] }}
        />
      </Spin>
    </div>
  );
}

export default RecordCompany;
