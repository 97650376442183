import React, { useEffect, useState } from 'react';
import { Input, Button, Pagination, Empty, message } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import CustomRadio from '@component/customRadio';
import {
  getPublicSentimentTypes,
  getPublicSentimentList,
} from '@api/IndustryNews';
import dayjs from 'dayjs';
import { checkLogin } from '../../utils/tool';
import styles from './index.less';

function IndustryNews(props) {
  const {
    history: { push },
  } = props;
  const timeList = [
    { name: '不限', value: '' },
    { name: '今天', value: '0' },
    { name: '近一周', value: '7' },
    { name: '近一月', value: '30' },
    { name: '近一年', value: '365' },
    { name: '近三年', value: '1095' },
  ];

  const [type, setType] = useState([]);
  const [origin, setOrigin] = useState([]);
  const [sortType, setSortType] = useState('asc');
  const [queryValue, setQueryValue] = useState({
    keyword: props.location.state?.searchContent || '',
    page_num: 1,
    page_size: 10,
  });
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [dataList, setDataList] = useState([]);

  const query = data => {
    const param = data || queryValue;
    const { time, ...otherParam } = param;
    const timeParam = {};
    if (time && time !== '') {
      timeParam.start_time = dayjs()
        .subtract(time, 'day')
        .startOf('date')
        .format('YYYY-MM-DD HH:mm:ss');
      timeParam.end_time = dayjs().endOf('date').format('YYYY-MM-DD HH:mm:ss');
    }
    getPublicSentimentList({
      ...otherParam,
      ...timeParam,
      keyword: param.keyword || '',
    }).then(res => {
      const { status, data } = res;
      if (status === '00000') {
        const { data_count, data_list } = data;
        setTotal(data_count);
        setDataList(data_list);
      }
    });
  };

  const setQueryVal = (data, isQuery = true) => {
    setQueryValue(data);
    if (isQuery) {
      query(data);
    }
  };

  const openDetail = (url) => {
    if (!checkLogin()) {
      message.warning('请先登录');
      push('/login');
      return;
    }
    window.open(url);
  };

  useEffect(() => {
    getPublicSentimentTypes({ industry_code: 'INB1335' }).then(res => {
      const { status, data } = res;
      if (status === '00000') {
        const { origin, type } = data;
        const typeArr = [{ name: '全部', value: '' }];
        if (type && Array.isArray(type) && type.length > 0) {
          type.forEach(item => {
            typeArr.push({ name: item, value: item });
          });
        }
        const originArr = [{ name: '不限', value: '' }];
        if (origin && Array.isArray(origin) && origin.length > 0) {
          origin.forEach(item => {
            originArr.push({ name: item, value: item });
          });
        }
        setType(typeArr);
        setOrigin(originArr);
      }
    });
    query();
  }, []);
  return (
    <div className={`${styles.all} flex directionColumn alignCenter`}>
      <div className={`${styles.queryBox} flex spaceCenter`}>
        <div className={`${styles.query}`}>
          <div className={`${styles.search} flex mb22`}>
            <Input
              placeholder="请输入关键字"
              value={queryValue?.keyword}
              onChange={e =>
                setQueryVal(
                  {
                    ...queryValue,
                    keyword: e.target.value,
                  },
                  false
                )
              }
              onPressEnter={() => query(false)}
            />
            <Button type="primary" onClick={() => query(false)}>
              搜索
            </Button>
          </div>
          <CustomRadio
            className="mb12"
            label="分类"
            list={type}
            onChange={val =>
              setQueryVal({
                ...queryValue,
                news_type: val,
              })
            }
          />
          <CustomRadio
            className="mb12"
            label="时间"
            list={timeList}
            onChange={val =>
              setQueryVal({
                ...queryValue,
                time: val,
              })
            }
          />
          <CustomRadio
            className="mb22"
            label="来源"
            list={origin}
            onChange={val =>
              setQueryVal({
                ...queryValue,
                origin: val,
              })
            }
          />
        </div>
      </div>
      <div className={`${styles.total} flex spaceBetween alignCenter`}>
        <div>为您找到相关结果约 {total} 个</div>
        <div
          className="flex alignCenter cursorPointer"
          onClick={() => {
            const sort = sortType === 'asc' ? 'desc' : 'asc';
            setSortType(sort);
            setQueryVal({
              ...queryValue,
              sort_list: [['time', sort]],
            });
          }}
        >
          <div className="mr10">发布日期</div>
          <div className="flex directionColumn alignCenter">
            <CaretUpOutlined
              style={sortType === 'desc' ? { color: '#00000040' } : {}}
            />
            <CaretDownOutlined
              style={sortType === 'asc' ? { color: '#00000040' } : {}}
            />
          </div>
        </div>
      </div>
      <div className={styles.listBox}>
        {dataList && Array.isArray && dataList.length === 0 ? (
          <Empty />
        ) : (
          dataList &&
          Array.isArray &&
          dataList.map(item => {
            return (
              <div className={`${styles.item} mb12`} key={item?._id}>
                <div className="type">{item.type}</div>
                <div className="flex spaceBetween alignCenter mb12">
                  {
                    item.client_tag && (
                      <div
                        className="text title cursorPointer"
                        onClick={() =>
                          item?._id ? push(`/industryNews/${item?._id}`) : null
                        }
                      >
                        {item.title}
                      </div>
                    )
                  }
                  {
                    !item.client_tag && (
                      <a
                        className="text title cursorPointer"
                        target="_blank"
                        onClick={() => openDetail(item?.link)}
                      >
                        {item.title}
                      </a>
                    )
                  }
                  <div className="flex">
                    {item?.source ? (
                      <div className="text2 mr30">来源：{item.source}</div>
                    ) : null}
                    <div className="text2">
                      {item?.time ? dayjs(item.time).format('YYYY-MM-DD') : ''}
                    </div>
                  </div>
                </div>
                <div className="text content">{item?.raw_abstract}</div>
              </div>
            );
          })
        )}
      </div>
      <div className={styles.page}>
        <Pagination
          showQuickJumper
          hideOnSinglePage
          current={queryValue?.page_num}
          pageSize={queryValue?.page_size}
          total={total}
          onChange={(page, size) => {
            if (pageSize === size) {
              setQueryVal({
                ...queryValue,
                page_num: page,
                page_size: pageSize,
              });
            } else {
              setPageSize(size);
            }
          }}
          onShowSizeChange={(current, size) => {
            setQueryVal({
              ...queryValue,
              page_num: 1,
              page_size: size,
            });
          }}
        />
      </div>
    </div>
  );
}

export default IndustryNews;
