/*
 *Author: zhaochenyu
 *Description: 表格
 *Date: 2021/04/20
*/

import React, { useCallback } from 'react';
import { Table } from 'antd';
import { openNewPage } from '../../../../utils/urlMethods';
import style from './index.less';

function RelationTable(props) {
  const { data, size, scrollY, getCompnayChartsData, choseCompnayId, setChoseCompnayId } = props;
  const { tableHead, tableBody } = data;

  const pushToCompany = useCallback(
    (id) => {
      if (typeof getCompnayChartsData === 'function') {
        setChoseCompnayId(id);
        getCompnayChartsData(id);
      } else {
        openNewPage('companyDetail', { id });
      }
    },
    [],
  );

  const handleWithHead = (head) => (
    head.map(item => {
      let res;
      if (item.dataIndex === 'name') {
        res = {
          ...item,
          width: size === 'small' ? '66%' : '54%',
          render: (text, record) => (
            <div
              title={text}
              style={{ color: '#262D36', cursor: 'pointer', fontWeight: choseCompnayId === record.id ? 700 : 400 }}
              className={style.companyName}
              onClick={() => pushToCompany(record.id)}
            >
              {text}
            </div>
          ),
        };
      } else if (item.dataIndex === 'score') {
        res = {
          ...item,
          ellipsis: true,
          render: text => (
            <div className={style.score}>{text}</div>
          ),
        };
      } else if (item.dataIndex === 'index') {
        res = {
          ...item,
          width: size === 'small' ? '14%' : '12%',
          ellipsis: true,
        };
      } else {
        res = {
          ...item,
          ellipsis: true,
        };
      }
      return res;
    })
  );
  return (
    <Table
      rowKey="index"
      columns={handleWithHead(tableHead)}
      dataSource={tableBody}
      pagination={false}
      className={style.table}
      scroll={{ y: scrollY }}
    />
  );
}

RelationTable.defaultProps = {
  scrollY: 390,
  size: 'small',
};

export default RelationTable;
