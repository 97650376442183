/*
* Author: zhangmeng
* Description: '潜力榜单'
* Date: 2021/12/02
*/

import React, { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { getNewList, getIpoList, getInvestList } from '../../../api/atlasDetail';
import style from './index.less';
import TableAndTitle from './TableAndTitle';
import { openNewPage } from '../../../utils/urlMethods';

function PotentialList(props) {
  const { industry, area } = props;
  const [newL, setNew] = useState({});
  const [ipoL, setIpoL] = useState({});
  const [investL, setInvestL] = useState({});
  const loading = useRef({
    newL: false,
    ipoL: false,
    investL: false,
  });

  const getCompanyDetail = id => {
    openNewPage('companyDetail', { id });
  };

  const tableHeadHandle = (h) => {
    const arr = h.map(item => {
      let obj = {};
      if (item.dataIndex === 'name') {
        obj = {
          ...item,
          ellipsis: true,
          width: '70%',
          render: (text, record) => (
            <div
              title={text}
              style={{ color: '#262D36', cursor: 'pointer' }}
              className={style.companyName}
              onClick={() => getCompanyDetail(record._id)}
            >
              {text}
            </div>
          ),
        };
      } else if (item.dataIndex === 'index') {
        obj = {
          ...item,
          width: '10%',
          ellipsis: true,
        };
      } else {
        obj = {
          ...item,
          ellipsis: true,
        };
      }
      return obj;
    });
    return arr;
  };

  useEffect(() => {
    if (area === '') return;
    loading.current = {
      newL: true,
      ipoL: true,
      investL: true,
    };
    getNewList({
      industry_name: industry,
      area_key: area,
    }).then(res => {
      if (res.status === '00000') {
        loading.current.newL = false;
        const { tableHead } = res.data;
        setNew({ ...res.data, tableHead: tableHeadHandle(tableHead) });
      } else {
        loading.current.newL = false;
      }
    });
    getIpoList({
      industry_name: industry,
      area_key: area,
    }).then(res => {
      if (res.status === '00000') {
        loading.current.ipoL = false;
        const { tableHead } = res.data;
        setIpoL({ ...res.data, tableHead: tableHeadHandle(tableHead) });
      } else {
        loading.current.ipoL = false;
      }
    });
    getInvestList({
      industry_name: industry,
      area_key: area,
    }).then(res => {
      if (res.status === '00000') {
        loading.current.investL = false;
        const { tableHead } = res.data;
        const arr = tableHead.map(item => {
          let obj = {};
          if (item.dataIndex === 'name') {
            obj = {
              ...item,
              ellipsis: true,
              width: '30%',
              render: (text, record) => (
                <div
                  title={text}
                  style={{ color: '#262D36', cursor: 'pointer' }}
                  className={style.companyName}
                  onClick={() => getCompanyDetail(record._id)}
                >
                  {text}
                </div>
              ),
            };
          } else if (item.dataIndex === 'index') {
            obj = {
              ...item,
              width: '10%',
              ellipsis: true,
            };
          } else if (item.dataIndex === 'amount') {
            obj = {
              ...item,
              width: '20%',
              className: 'invest',
              ellipsis: true,
              align: 'center',
              render: t => <span style={{ color: '#0D3B66' }}>{t}</span>,
            };
          } else if (item.dataIndex === 'unit') {
            obj = {
              ...item,
              ellipsis: true,
              align: 'center',
            };
          } else if (item.dataIndex === 'investor') {
            obj = {
              ...item,
              ellipsis: true,
              align: 'center',
              render: t => <span title={t}>{t.split(',')[0]}</span>,
            };
          } else {
            obj = {
              ...item,
              ellipsis: true,
            };
          }
          return obj;
        });
        setInvestL({ ...res.data, tableHead: arr });
      } else {
        loading.current.investL = false;
      }
    });
  }, [industry, area]);

  return (
    <div className={style.all}>
      <div className={style.side}>
        <Spin tip="搜索中，请稍候....." spinning={loading.current.newL}>
          <TableAndTitle data={newL} title="专精特新潜力企业" />
        </Spin>
      </div>
      <div className={style.middle}>
        <Spin tip="搜索中，请稍候....." spinning={loading.current.ipoL}>
          <TableAndTitle data={ipoL} title="北交所上市潜力企业" />
        </Spin>
      </div>
      <div className={style.side}>
        <Spin tip="搜索中，请稍候....." spinning={loading.current.investL}>
          <TableAndTitle data={investL} title="投资价值榜单企业" />
        </Spin>
      </div>
    </div>
  );
}

export default PotentialList;
