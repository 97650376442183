import React, { useEffect, useState } from 'react';
import { Input, Button, Divider, Tag, Pagination, Empty, message } from 'antd';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  SmallDashOutlined,
} from '@ant-design/icons';
import CustomRadio from '@component/customRadio';
import { getPolicyTypes, getPolicyList } from '@api/IndustryPolicy';
import { checkLogin } from '../../utils/tool';
import styles from './index.less';

const key2Text = {
  policy_level_list: '政策级别',
  policy_type_list: '政策类型',
  policy_support_behavior_list: '支持行为',
  policy_support_object_list: '支持对象',
  policy_support_way_list: '支持方式',
};

function IndustryPolicy(props) {
  const {
    history: { push },
  } = props;
  const [policyTypes, setPolicyTypes] = useState([]);
  const [sortType, setSortType] = useState('asc');
  const [queryValue, setQueryValue] = useState({
    keyword: props.location.state?.searchContent || '',
    page_num: 1,
    page_size: 10,
  });
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [dataList, setDataList] = useState([]);

  const query = data => {
    const param = data || queryValue;
    getPolicyList({ ...param, keyword: param.keyword || '' }).then(res => {
      const { status, data } = res;
      if (status === '00000') {
        const { data_count, data_list } = data;
        setTotal(data_count);
        setDataList(data_list);
      }
    });
  };

  const setQueryVal = (data, isQuery = true) => {
    setQueryValue(data);
    if (isQuery) {
      query(data);
    }
  };

  useEffect(() => {
    getPolicyTypes({ industry_code: 'INB1335' }).then(res => {
      const { status, data } = res;
      if (status === '00000') {
        const keys = Object.keys(data);
        const dataList = [];
        for (let i = 0; i < keys.length; i++) {
          const itemKey = keys[i];
          const arr = [{ name: '不限', value: '' }];
          const type = data[itemKey];
          if (type && Array.isArray(type) && type.length > 0) {
            type.forEach(item => {
              arr.push({ name: item, value: item });
            });
          }
          dataList.push({
            title: key2Text[`policy_${itemKey}_list`],
            key: `policy_${itemKey}_list`,
            list: arr,
          });
        }
        setPolicyTypes(dataList);
      }
    });
    query();
  }, []);
  return (
    <div className={`${styles.all} flex flex directionColumn alignCenter`}>
      <div className={`${styles.search} flex mb40`}>
        <Input
          placeholder="请输入关键字"
          value={queryValue?.keyword}
          onChange={e =>
            setQueryVal(
              {
                ...queryValue,
                keyword: e.target.value,
              },
              false
            )
          }
          onPressEnter={() => query(false)}
        />
        <Button type="primary" onClick={() => query(false)}>
          搜索
        </Button>
      </div>
      <div className={`${styles.query}`}>
        {policyTypes &&
          Array.isArray(policyTypes) &&
          policyTypes.length > 0 &&
          policyTypes.map((item, index) => (
            <div key={`${item?.key}`}>
              <CustomRadio
                isExtend
                label={item?.title}
                list={item?.list}
                defaultType="default"
                onChange={val =>
                  setQueryVal({
                    ...queryValue,
                    [item.key]: val,
                  })
                }
              />
              {index !== policyTypes.length - 1 ? <Divider dashed /> : null}
            </div>
          ))}
      </div>
      <div className={`${styles.total} flex alignCenter flowReverse`}>
        <div
          className="flex alignCenter cursorPointer"
          onClick={() => {
            const sort = sortType === 'asc' ? 'desc' : 'asc';
            setSortType(sort);
            setQueryVal({
              ...queryValue,
              sort_list: [['publish_code.keyword', sort]],
            });
          }}
        >
          <div className="mr10">发布日期</div>
          <div className="flex directionColumn alignCenter">
            <CaretUpOutlined
              style={sortType === 'desc' ? { color: '#00000040' } : {}}
            />
            <CaretDownOutlined
              style={sortType === 'asc' ? { color: '#00000040' } : {}}
            />
          </div>
        </div>
      </div>
      <div
        className={`${styles.listBox} flex directionColumn alignCenter mb40`}
      >
        {dataList && Array.isArray && dataList.length === 0 ? (
          <Empty />
        ) : (
          dataList &&
          Array.isArray &&
          dataList.map(item => {
            return (
              <div className={`${styles.item} mb12`} key={item?._id}>
                <div className="type">{item?.report_type}</div>
                <div className="flex spaceBetween alignCenter mb12">
                  <div
                    className="text cursorPointer"
                    onClick={() =>{
                      if (!checkLogin()) {
                        message.warning('请先登录');
                        push('/login');
                        return;
                      }
                      item?._id ? push(`/industryPolicy/${item?._id}`) : null
                    }}
                  >
                    {item?.title}
                  </div>
                  <div className="flex">
                    <div className="text2 mr30">{item?.source}</div>
                    <div className="text2">{item?.publish_date}</div>
                  </div>
                </div>
                <div>
                  {item?.tags?.industry_tag?.map(
                    (element, index) =>
                      index < 5 && (
                        <Tag
                          key={element.tag_code}
                          color="blue"
                          className="mb8"
                        >
                          {element.tag_name}
                        </Tag>
                      )
                  )}
                  <SmallDashOutlined className="ml15"/>
                </div>
              </div>
            );
          })
        )}
        <Pagination
          className="mt34"
          showQuickJumper
          hideOnSinglePage
          current={queryValue?.page_num}
          pageSize={queryValue?.page_size}
          total={total}
          onChange={(page, size) => {
            if (pageSize === size) {
              setQueryVal({
                ...queryValue,
                page_num: page,
                page_size: pageSize,
              });
            } else {
              setPageSize(size);
            }
          }}
          onShowSizeChange={(current, size) => {
            setQueryVal({
              ...queryValue,
              page_num: 1,
              page_size: size,
            });
          }}
        />
      </div>
    </div>
  );
}

export default IndustryPolicy;
