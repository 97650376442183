/*
 *Author: zhangmeng
 *Description: 产业图谱-桑基图
 *Date: 2020/09/08
 */
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import cloneDeep from 'lodash/cloneDeep';
import { addWaterMark } from '../../utils/report';

const MulberryChart = forwardRef((props, ref) => {
  const { data, type, chartClick, firstlevelColor, colorList, chosedColor, selectNode } = props;
  const [option, setOption] = useState({});
  const chartRef = useRef(null);
  const name = useRef([]);
  const link = useRef([]);

  useEffect(() => {
    name.current = [];
    link.current = [];
    let maxLevel = 0;

    function findMaxLevel(param) {
      param.forEach(item => {
        maxLevel = item.level > maxLevel ? item.level : maxLevel;
        if (item.children) {
          findMaxLevel(item.children);
        }
      });
    }

    const newData = cloneDeep(data);

    findMaxLevel(newData);

    // 递归收集link所需数据
    function linkRecursion(params) {
      params.forEach((item) => {
        if (item.children.length) {
          item.children.forEach((item2) => {
            link.current.push({
              source: item.value,
              target: item2.value,
              value: item.level === 0 ? 2 : 1, // 14
            });
          });
          linkRecursion(item.children);
        }
      });
    }

    // 递归收集图表data数据
    function dataRecursion(params) {
      params.forEach((item, index) => {
        function childrenColor(params1) {
          params1.forEach(child => {
            maxLevel = child.level > maxLevel ? child.level : maxLevel;
            if (child.isBright === true) {
              /* eslint-disable no-param-reassign */
              if (child.is_company_link) {
                child.color = '#A4C3B2';
                child.borderColor = '#3E5C76';
              } else if (child.is_other_search) {
                child.color = '#A4C3B2';
                child.borderColor = '#C8FFFA';
              } else if (child.value === selectNode) {
                child.color = chosedColor || '#54EAC4';
                child.borderColor = '#fff';
                child.shadowBlur = 6;
                child.shadowColor = 'rgba(46, 86, 123, 0.71)';
              } else {
                child.color = colorList[index];
              }
            } else {
              child.color = '#D2D2D2';
              if (child.is_company_link) {
                child.borderColor = '#3E5C76';
              } else if (child.is_other_search) {
                child.borderColor = '#C8FFFA';
              }
            }
            if (child.children.length) {
              childrenColor(child.children);
            }
          });
        }
        if (item.level === 0) {
          // eslint-disable-next-line no-unused-expressions
          // '#A4C3B2'
          item.color = firstlevelColor;
        } else if (item.level === 1) {
          if (item.isBright === true) {
            // if (item.title === currLinkName) {
            //   item.color = '#A4C3B2';
            // } else {
            if (item.is_company_link) {
              item.color = '#A4C3B2';
              item.borderColor = '#3E5C76';
            } else if (item.is_other_search) {
              item.color = '#A4C3B2';
              item.borderColor = '#C8FFFA';
            } else if (item.value === selectNode) {
              item.color = chosedColor || '#54EAC4';
              item.borderColor = '#fff';
              item.shadowBlur = 6;
              item.shadowColor = 'rgba(46, 86, 123, 0.71)';
            } else {
              item.color = colorList[index];
            }
            // }
          } else {
            item.color = '#D2D2D2';
            if (item.is_company_link) {
              item.borderColor = '#3E5C76';
            } else if (item.is_other_search) {
              item.borderColor = '#C8FFFA';
            }
          }
          if (item.children.length) {
            // 从level=1开始递归子节点，附上level=1的父节点的颜色
            childrenColor(item.children);
          }
        }

        name.current.push({
          name: item.value,
          labelName: item.title,
          companyCount: (item.link_data_count && item.link_data_count.count) || 0,
          label: {
            color: item.isBright === false ? '#D2D2D2' : '#262D36',
            formatter: (param) => {
              const { data: { depth, labelName } } = param;
              // console.log(param);
              let cutLen = depth > (maxLevel - 1) ? 22 : 12;
              if (cutLen === 12 && type === 'atlas') {
                cutLen = 7;
              }
              if (cutLen === 22 && type === 'atlas') {
                cutLen = 12;
              }
              return labelName.length > cutLen ? `${labelName.slice(0, cutLen)}...` : labelName;
            },
          },
          depth: item.level,
          itemStyle: {
            color: item.color,
            borderColor: item.borderColor || 'transparent',
            shadowBlur: item.shadowBlur || 0,
            shadowOffsetX: item.shadowOffsetX || 0,
            shadowColor: item.shadowColor || 'transparent',
            shadowOffsetY: item.shadowOffsetY || 0,
          },
        });
        if (item.children.length) {
          dataRecursion(item.children);
        }
      });
    }
    dataRecursion(newData);
    linkRecursion(newData);

    const options = {
      tooltip: {
        trigger: 'item',
        formatter: (param) => {
          const { dataType, data: { labelName } } = param;
          if (dataType === 'node') {
            return labelName;
          }
          return '';
          // console.log(name);
        },
      },
      series: {
        type: 'sankey',
        top: type === 'component' ? 5 : 50,
        right: type === 'component' ? '25%' : 300,
        bottom: type === 'component' ? 5 : 35,
        // nodeGap: 2,
        draggable: false,
        // selectedMode: 'single',
        // focusNodeAdjacency: false,
        animation: false,
        data: name.current,
        links: link.current,
        layoutIterations: 0,
        emphasis: {
          focus: 'adjacency',
        },
        lineStyle: {
          curveness: 0.5,
          color: 'source',
          opacity: 0.49,
        },
        label: {
          fontSize: type === 'component' ? 10 : 12,
        },
      },
    };
    if (type === 'atlas') {
      options.series.right = '20%';
    }
    setOption(options);
  }, [data, chosedColor, selectNode]);

  // 图表label事件
  const onEvents = {
    click: (params) => {
      if (params.dataType === 'node'
      && params.data.itemStyle.color !== '#D2D2D2'
      && params.name !== selectNode) {
        if (type === 'atlas') {
          chartClick(params.data);
        }
        // else if (typeof chartClick === 'function') {
        //   chartClick(params.data.name);
        // }
      }
    },
  };

  useImperativeHandle(ref, () => ({
    toDataURL({ pixelRatio = 2 }) {
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: '#fff',
      }), pixelRatio)));
    },
  }));

  return (
    <ReactEcharts
      option={option}
      onEvents={onEvents}
      notMerge
      style={{ width: '100%', height: '100%' }}
      ref={chartRef}
    />
  );
});

MulberryChart.defaultProps = {
  colorList: ['#4E7293', '#C5D5EA', '#A4C3B2', '#CCE3DE', '#EAD1C1', '#CBC7DB', '#E9C582', '#EE964B', '#D5671D', '#C54646', '#CB997E', '#E0C7C7'],
  firstlevelColor: '#0D3B66',
};

export default MulberryChart;
