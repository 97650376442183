/*
 *Author: zhaochenyu
 *Description: 产业图谱详情页
 *Date: 2021/04/16
*/

import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Affix, Spin, TreeSelect, message } from 'antd';
import { getBasicInfo, getAreaAndCode, getScreenLabelList } from '../../api/atlasDetail';
import AchorTitle from './AchorTitle';
import News from './News';
import Scanning from './Scanning';
import { getSelectArea, floatAreaArray } from '../../utils/tool';
import IndustryChain from './IndustryChain';
import KeyEnterprisesTest from './KeyEnterprises';
import Financing from './Financing';
import Portrait from './Portrait';
import IndustryLeader from './IndustryLeader';
import PotentialList from './PotentialList';
import style from './index.less';
import { checkLogin } from '../../utils/tool';
import { useHistory } from 'react-router-dom';

const achor1 = ['产业链图', '产业总览', '区域分布', '竞争格局', '上市公司', '推荐企业', '融资榜单', '最新动态'];

function AtlasDetail(props) {
  const history = useHistory();
  // const { iconKey } = props;
  // console.log(iconKey, 'iconKey');
  const [fix, setFix] = useState(false);
  const [selectAchor, setAchor] = useState('最新动态');
  const [descData, setDescData] = useState({});
  const [fixArea, setFixArea] = useState(false);
  const [selectAreaData, setSelectAreaData] = useState([]);
  const [areaKey, setAreaKey] = useState(''); // 区域key
  // const [cityCode, setCityCode] = useState(''); // 区域code码
  const [loading, setLoading] = useState(false);
  const [screenLabelList, setScreenLabelList] = useState([]);
  const [areaFilter, setAreaFilter] = useState([]);
  const topRef = useRef(187); // 锚点高度
  const areaRef = useRef(115); // 地区高度
  const timer = useRef(null);
  const timer1 = useRef(null);
  const positionRef = useRef([]); // 位置数组
  const mainRef = useRef();
  const flatAreaData = useRef([]); // 扁平化处理后的区域数据
  const [modelLoading, setModelLoading] = useState(true);
  const allCityData = useRef([]); // 全国地市数据
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const curIndustry = window.decodeURIComponent(window.atob(params.get('industry') || 'JUU0JUJBJUJBJUU1JUI3JUE1JUU2JTk5JUJBJUU4JTgzJUJE'));
  const permission = JSON.parse(sessionStorage.getItem('pm'));

  const achorPush = useCallback((item) => {
    const achorName = document.getElementById(item);
    if (achorName) {
      // const y = achorName.offsetTop - 70;
      document.documentElement.scrollTo({
        top: achorName.offsetTop - 55,
        behavior: 'smooth',
      });
      // props.history.push({
      //   pathname: 'atlasDetail',
      //   search: `${props.location.search}&achor=${item}`,
      // });
      // achorName.scrollIntoView({
      //   behavior: 'smooth',
      // });
    }
  }, []);

  const selectAreaChange = useCallback((value) => {
    if (!checkLogin()) {
      message.warning('请先登录');
      history.push('/login');
      return;
    }
    setAreaKey(value);
    setAreaFilter(getSelectArea(allCityData.current, value || '') || []);
    // const area = flatAreaData.current.find(item => item.key === value);
    // if (area.code) setCityCode(area.code);
  }, []);

  // const changeAchor = useCallback(() => {
  //   const scroll = document.documentElement.scrollTop;
  //   setFix(scroll >= topRef.current);
  //   setFixArea(scroll >= areaRef.current);
  //   const len = positionRef.current.length;
  //   for (let i = 0; i < len; i += 1) {
  //     if (i === (len - 1) && (positionRef.current[i] <= scroll)) {
  //       setAchor(achor1[i]);
  //       break;
  //     } else if ((positionRef.current[i] <= scroll)
  //     && (positionRef.current[i + 1] > scroll)) {
  //       setAchor(achor1[i]);
  //       break;
  //     } else if ((scroll < positionRef.current[0])) {
  //       setAchor(achor1[0]);
  //       break;
  //     }
  //   }
  // }, []);

  // const scrollLisener = useCallback(() => {
  //   if (!timer.current) {
  //     timer.current = setTimeout(() => {
  //       changeAchor();
  //       timer.current = null;
  //     }, 40);
  //   }
  // }, []);

  // const getCurPostion = () => {
  //   const arr = [];
  //   achor1.forEach(item => {
  //     arr.push(document.getElementById(item).offsetTop - 55);
  //   });
  //   positionRef.current = arr;
  //   // 高度变化后重新计算当前位置所在区间
  //   changeAchor();
  // };

  // const heightListener = useCallback(
  //   () => {
  //     if (timer1.current) {
  //       clearTimeout(timer1.current);
  //       timer1.current = null;
  //     }
  //     timer1.current = setTimeout(() => {
  //       getCurPostion();
  //     }, 30);
  //   },
  //   [],
  // );

  useEffect(() => {
    setLoading(true);
    getBasicInfo({
      industry_name: curIndustry,
    }).then(res => {
      if (res.status === '00000') {
        setDescData(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
    getScreenLabelList().then(res => {
      if (res.status === '00000') {
        setScreenLabelList(res.data);
      }
    });
    setModelLoading(true);
    getAreaAndCode().then(res => {
      if (res.status === '00000') {
        setSelectAreaData(res.data);
        allCityData.current = res.data || [];
        setAreaFilter(getSelectArea(allCityData.current, res.data[0].key || '') || []);
        // setCityCode(res.data[0].key);
        setAreaKey(res.data[0].key);
        flatAreaData.current = floatAreaArray(res.data);
        setModelLoading(false);
      } else {
        setModelLoading(false);
      }
    });
    // changeAchor();
    // 监听页面高度变化重新计算锚点高度
    // const resizeObserver = new ResizeObserver(heightListener);
    // resizeObserver.observe(mainRef.current);
    // 监听页面滚动事件，改变锚点选中状态
    // window.addEventListener('scroll', scrollLisener);
    return () => {
      // window.removeEventListener('scroll', scrollLisener);
      // resizeObserver.unobserve(mainRef.current);
      // clearTimeout(timer.current);
      // clearTimeout(timer1.current);
    };
  }, []);
  return (
    <div className={style.all}>
      <div className={style.main} ref={mainRef}>
        <Spin spinning={loading}>
          <header className={style.bottomHeader}>
            <div className={style.enName}>{descData.enName || ''}</div>
            <div className={style.labelName}>
              {descData.name || ''}
              <div className={style.label}>
                {(descData.label || []).map((item, index) => (
                  <div
                    key={`${index + item}`}
                    className={style.labelItem}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </header>
        </Spin>
        {/* <div className={style.achorTab} style={{ display: fix ? '' : 'none', opacity: 0 }}>
          {achor1.map(item => (
            <div
              key={item}
              className={style.achorItem}
              onClick={() => achorPush(item)}
            >
              {item}
            </div>
          ))}
        </div>
        <div className={fix ? style.fix : style.achorTab}>
          {achor1.map(item => (
            <div
              key={item}
              className={selectAchor === item ? style.selectAchor : style.achorItem}
              onClick={() => achorPush(item)}
            >
              {item}
            </div>
          ))}
        </div> */}
        <Affix offsetTop={0}>
          <div className={style.achorTab}>
            {
              achor1.map(item => (
                <div
                  key={item}
                  className={style.achorItem}
                  onClick={() => achorPush(item)}
                >
                  {item}
                </div>
              ))
            }
          </div>
        </Affix>
        <div className={style.selectArea} style={{ display: fixArea ? '' : 'none', opacity: 0 }}>
          <Spin spinning={modelLoading} tip="">
            <div className={style.areaLabel}>
              产业地域
            </div>
            <TreeSelect
              style={{ width: '100%' }}
              value={areaKey}
              dropdownClassName={style.allCitySelect}
              treeDefaultExpandedKeys={[selectAreaData.length > 0 && selectAreaData[0].key]}
              dropdownStyle={{ height: 640, overflow: 'auto' }}
              treeData={selectAreaData}
              listHeight={610}
              onChange={selectAreaChange}
            />
          </Spin>
        </div>
        <div className={fixArea ? style.fixArea : style.selectArea}>
          <Spin spinning={modelLoading} tip="">
            <div className={style.areaLabel}>
              产业地域
            </div>
            <TreeSelect
              style={{ width: '100%' }}
              value={areaKey}
              dropdownClassName={style.allCitySelect}
              treeDefaultExpandedKeys={[selectAreaData.length > 0 && selectAreaData[0].key]}
              dropdownStyle={{ height: 640, overflow: 'auto' }}
              treeData={selectAreaData}
              listHeight={610}
              onChange={selectAreaChange}
            />
          </Spin>
        </div>
        <AchorTitle name="产业链图" />
        <div className={style.industryChain}>
          <Spin spinning={modelLoading} tip="搜索中，请稍后....">
            {!modelLoading && (
              <IndustryChain
                industry={curIndustry || ''}
                areaKey={areaKey}
                screenLabelList={screenLabelList}
                areaFilter={areaFilter}
                chainUpType={permission && permission.atd
                  && permission.atd.idc && permission.atd.idc.up}
              />
            )}
          </Spin>
        </div>
        <AchorTitle name="产业总览" />
        <div className={style.scanning}>
          <Spin spinning={modelLoading} tip="搜索中，请稍后....">
            {!modelLoading && (
              <Scanning industry={curIndustry || ''} areaKey={areaKey} />
            )}
          </Spin>
        </div>
        <AchorTitle name="区域分布" />
        <div className={style.portrait}>
          <Spin spinning={modelLoading} tip="搜索中，请稍后....">
            {!modelLoading && (
              <Portrait
                industry={curIndustry || ''}
                areaKey={areaKey}
                cityCode={areaKey}
                screenLabelList={screenLabelList}
                selectAreaData={selectAreaData}
              />
            )}
          </Spin>
        </div>
        <AchorTitle name="竞争格局" />
        <div className={style.industryTop}>
          <Spin spinning={modelLoading} tip="搜索中，请稍后....">
            {!modelLoading && (
              <IndustryLeader
                industry={curIndustry || ''}
                area={areaKey}
                areaName={flatAreaData.current.find(item => item.key === areaKey)?.title || ''}
              />
            )}
          </Spin>
        </div>
        <AchorTitle name="上市公司" />
        <div className={style.keyEnterprises}>
          <Spin spinning={modelLoading} tip="搜索中，请稍后....">
            {!modelLoading && (
              <KeyEnterprisesTest industry={curIndustry || ''} areaKey={areaKey} />
            )}
          </Spin>
        </div>
        <AchorTitle name="推荐企业" />
        <div className={style.potential}>
          <Spin spinning={modelLoading} tip="搜索中，请稍后....">
            {!modelLoading && (
              <PotentialList industry={curIndustry || ''} area={areaKey} />
            )}
          </Spin>
        </div>
        <AchorTitle name="融资榜单" />
        <div className={style.financing}>
          <Spin spinning={modelLoading} tip="搜索中，请稍后....">
            {!modelLoading && (
              <Financing industry={curIndustry || ''} areaKey={areaKey} />
            )}
          </Spin>
        </div>
        <AchorTitle name="最新动态" />
        <div className={style.news}>
          <Spin spinning={modelLoading} tip="搜索中，请稍后....">
            {!modelLoading && (
              <News industry={curIndustry || ''} areaKey={areaKey} />
            )}
          </Spin>
        </div>
      </div>
    </div>
  );
}

// AtlasDetail.defaultProps = {
//   iconKey: 'atlas',
// };

export default AtlasDetail;
