/*
 *Author: zhaochenyu
 *Description: 区域分布企业名单表格
 *Date: 2021/12/01
*/

import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Table, Spin } from 'antd';
import { IconFont } from '../../../../utils/mapIcon';
import { getIndustryCompany } from '../../../../api/atlasDetail';
import IndustryChainTable from '../IndustryChainTable';
import { getSelectArea } from '../../../../utils/tool';
import style from '../AltasTable/index.less';

function AreaDistributionTable(props) {
  const { data, scrollY, industry, screenLabelList, curAreaKey, getAreaCompnayCount,
    allAreaData } = props;
  const { tableHead, tableBody } = data;
  const [cardShow, setCardShow] = useState(false);
  const [curDetailName, setCurDetailName] = useState(''); // 区域名称
  const [curDetailLabelName, setCurDetailLabelName] = useState(''); // 分类名称
  const [detailTableLoading, setDetailTableLoading] = useState(false); // Loading状态
  const [compnayData, setCompnayData] = useState({});
  const [label, setLabel] = useState('');
  const [curPagination, setCurPagination] = useState({ pageNum: 1, pageSize: 20 });
  const [sorder, setSorder] = useState('desc');
  const [areaFilter, setAreaFilter] = useState([]);
  const tableCondition = useRef({
    industry_name: industry,
    area_key: '',
    sort_order: 'desc',
    page_number: 1,
    page_size: 20,
    second_special_tag: 'all', // 二级筛选标签
    first_special_tag: '', // 一级标签筛选
  });
  const [sortInfo, setSortInfo] = useState({
    order: 'descend',
    field: 'company_count',
  });

  const hanldleCondition = useCallback((obj) => {
    tableCondition.current = {
      ...tableCondition.current,
      ...obj,
    };
  }, []);

  const getCompnayList = useCallback((obj) => {
    let curCondition = {
      ...tableCondition.current,
    };
    if (obj) {
      curCondition = {
        ...curCondition,
        ...obj,
      };
    }
    if (curCondition.sort_order === '') {
      delete curCondition.sort_order;
    }
    setDetailTableLoading(true);
    getIndustryCompany({
      ...curCondition,
    }).then(res => {
      if (res.status === '00000') {
        setCompnayData(res.data);
        setDetailTableLoading(false);
      } else {
        setDetailTableLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    setCardShow(false);
    setSortInfo({
      order: 'descend',
      field: 'company_count',
    });
  }, [curAreaKey]);

  useEffect(() => {
    // 关闭弹出框后，重置原有筛选条件
    if (!cardShow) {
      hanldleCondition({
        first_special_tag: '',
        area_key: '',
        sort_order: 'desc',
        page_number: 1,
        page_size: 20,
        second_special_tag: 'all', // 二级筛选标签
      });
      setLabel((screenLabelList.length > 0 && screenLabelList[0].value) || '');
      setCurPagination({ pageNum: 1, pageSize: 20 });
      setSorder('desc');
    }
  }, [cardShow, screenLabelList]);

  const tableChange = (_, filters, sorter) => {
    setSortInfo({
      ...sorter,
    });
    console.log(sorter);
    let obj = {
      area_key: curAreaKey,
    };
    if (sorter.field && sorter.order) {
      obj = {
        ...obj,
        sort_column: sorter.field,
      };
    }
    getAreaCompnayCount(obj);
  };

  const getDetailData = (titleItem, rowData) => {
    console.log(titleItem, rowData);
    // 查看其他列详情数据时，重置回默认条件
    hanldleCondition({
      first_special_tag: titleItem.dataIndex,
      area_key: rowData.area_key,
      sort_order: 'desc',
      page_number: 1,
      page_size: 20,
      second_special_tag: 'all', // 二级筛选标签
    });
    setAreaFilter(getSelectArea(allAreaData, rowData.area_key || '') || []);
    setLabel((screenLabelList.length > 0 && screenLabelList[0].value) || '');
    setCurPagination({ pageNum: 1, pageSize: 20 });
    setSorder('desc');
    setCardShow(true);
    setCurDetailName(rowData.area);
    setCurDetailLabelName(titleItem.title);
    getCompnayList();
  };

  const handleWithHead = (head) => (
    head.map(item => {
      let res;
      if (item.dataIndex === 'index') {
        res = {
          ...item,
          width: '10%',
          ellipsis: true,
          render: (text, record, index) => (
            <div className={index > 2 ? style.otherStyle : style.topStyle}>{text}</div>
          ),
        };
      } else if (item.dataIndex === 'area') {
        res = {
          ...item,
          width: '16%',
          ellipsis: true,
        };
      } else {
        res = {
          ...item,
          // ellipsis: true,
          sorter: true,
          sortDirections: ['descend'],
          sortOrder: sortInfo.field === item.dataIndex && sortInfo.order,
          render: (text, record) => (
            <div className={style.numDetail}>
              {text}
              <IconFont
                type="iconfilesearch"
                className={style.iconNum}
                onClick={() => getDetailData(item, record)}
              />
            </div>
          ),
        };
      }
      return res;
    })
  );

  const table = useMemo(() => (
    Object.keys(compnayData).length > 0 && <IndustryChainTable
      data={compnayData}
      scrollY={530}
      hanldleCondition={hanldleCondition}
      label={label}
      setLabel={setLabel}
      curPagination={curPagination}
      setCurPagination={setCurPagination}
      sorder={sorder}
      setSorder={setSorder}
      screenLabelList={screenLabelList}
      getCompnayList={getCompnayList}
      areaFilter={areaFilter}
    />
  ), [compnayData, hanldleCondition, label, setLabel, curPagination, areaFilter,
    setCurPagination, sorder, setSorder, screenLabelList, getCompnayList]);

  return (
    <div className={style.areaTable}>
      <Table
        rowKey="index"
        columns={handleWithHead(tableHead)}
        onChange={tableChange}
        dataSource={tableBody}
        pagination={false}
        className={style.table}
        scroll={{ y: scrollY }}
      />
      <div className={style.modalCard} style={{ display: cardShow ? '' : 'none' }}>
        <div className={style.cardTitle}>
          <span style={{ fontWeight: 'blod', color: '#262D36', marginRight: '6px' }}>{curDetailName}</span>
          {industry}
          {curDetailLabelName}
          列表
        </div>
        <div className={style.close} onClick={() => setCardShow(false)}>
          x
        </div>
        <div className={style.tableBody}>
          <Spin spinning={detailTableLoading} tip="搜索中，请稍后....">
            {table}
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default AreaDistributionTable;
