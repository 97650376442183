import React, { useEffect, useState, useContext, useRef } from 'react';
import { Row, Col, Button, Avatar, Dropdown, Menu } from 'antd';
import { withRouter } from 'react-router-dom';
import { Context, menus } from '../utils/context';
import style from './page.less';
// import logoBlue from '../assets/img/logoBlue.png';

function Header(props) {
  const [menuKey, setMenuKey] = useState('homePage');
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState({ userId: 1, userName: '欢迎', userImg: '' });
  const [logined, setLogined] = useState(false);
  const { state, dispatch } = useContext(Context);
  const menuKeyRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [dropMenu, setDrop] = useState(false);
  const searchType1 = useRef();
  const searchContent1 = useRef();
  const searchType2 = useRef();
  const searchContent2 = useRef();
  // const timer = useRef(null);
  const { location, history } = props;

  function urlChange(pathname) {
    const path = pathname || location.pathname;
    if (location.pathname === '/') {
      setMenuKey('homePage');
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      return;
    }
    const keys = path.split('/');
    if (keys.length > 1 && keys[1] !== '') {
      setMenuKey(keys[1]);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      const [key] = keys;
      menuKeyRef.current = key;
      dispatch({
        type: 'setState',
        payload: {
          key: keys[1],
          key2: keys[2],
        },
      });
    }
  }

  useEffect(() => {
    // 页面刷新或初始化
    urlChange();
    if (sessionStorage.getItem('token')) {
      const imgUtl = sessionStorage.getItem('headImg');
      setUser({
        userId: sessionStorage.getItem('userId'),
        userName: sessionStorage.getItem('displayName'),
        userImg: imgUtl,
      });
      setLogined(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    // setMenuKey(state.key);
    menuKeyRef.current = state.key;
  }, [state.key]);

  // const userName = sessionStorage.getItem('user_name');

  const handleMenuClick = (item) => {
    if (item) {
      setMenuKey(item);
      history.push(`/${item}`);
    }
  };

  const handleSignClick = (formType) => {
    sessionStorage.clear();
    history.push({
      pathname: '/login',
      state: {
        formType,
      },
    });
  };

  const handleSearchClick = (clickType) => {
    setMenuKey('');
    if (logined) {
      switch (clickType) {
        case 1:
          history.push({
            pathname: `/${searchType1.current.value}`,
            state: {
              searchContent: searchContent1.current.value,
            },
          });
          break;

        case 2:
          history.push({
            pathname: `/${searchType2.current.value}`,
            state: {
              searchContent: searchContent2.current.value,
            },
          });
          break;
        default:
          break;
      }
    } else {
      handleSignClick('login');
    }
  };

  const handleOpenPersonalCenter = () => {
    history.push({
      pathname: '/personalCenter',
    });
  };

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <div onClick={() => handleOpenPersonalCenter()}>
              个人中心
            </div>
          ),
        },
        {
          key: '2',
          label: (
            <div
              onClick={() => {
                sessionStorage.clear();
                handleSignClick('login');
              }}
            >
              退出登录
            </div>
          ),
        },
      ]}
    />
  );

  return (
    <div className={menuKey === 'homePage' ? style.header : style.otherHeader}>
      <div style={{ display: menuKey === 'homePage' ? 'block' : 'none' }}>
        <Row justify="left" align="bottom">
          <Col span={10}>
            {/* <div className={style.title}>北京人工智能产业大脑</div> */}
          </Col>
          <Col span={10}>
            <div className={style.menus}>
              {
                menus.map((item, index) => (
                  <div
                    className={item.key === menuKey ? style.selectKy : style.menuItem}
                    key={`${index}${item.key}menu`}
                    onClick={() => handleMenuClick(item.key)}
                  >
                    {item.name}
                  </div>
                ))
              }
            </div>
          </Col>
          {
            !logined ? (
              <Col span={3} offset={1}>
                <Button type="text" style={{ color: '#e0faff' }} onClick={() => handleSignClick('login')}>登录</Button>
                {/* <Button type="primary" style={{ color: '#E6F9FF', backgroundColor: '#008CD6' }} onClick={() => handleSignClick('apply')}>注册</Button> */}
              </Col>
            ) : null
          }
        </Row>
        <Row>
          <Col span={7} offset={14} justify="right">
            <Row justify="start" align="middle">
              <Col>
                <Row className={style.select}>
                  <Col>
                    <select defaultValue="SearchNews" className={style.items} ref={searchType1}>
                      <option value="industryNews">资讯</option>
                      <option value="industryReport">研报</option>
                      <option value="SearchCompany">企业</option>
                      <option value="industryPolicy">政策</option>
                    </select>
                  </Col>
                  <Col>
                    <input
                      placeholder="请输入关键词"
                      className={style.input}
                      ref={searchContent1}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button type="primary" style={{ color: '#E6F9FF', backgroundColor: '#008CD6' }} onClick={() => handleSearchClick(1)}>搜索</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div style={{ display: menuKey === 'homePage' ? 'none' : 'block' }}>
        <Row justify="left" align="center">
          <Col span={6}>
            <div className={style.titleGroup}>
              {/* <img src={logoBlue} alt="" /> */}
              <div className={style.title}>北京人工智能产业大脑</div>
            </div>
          </Col>
          <Col span={10}>
            <div className={style.menus}>
              {
                menus.map((item, index) => (
                  <div
                    className={item.key === menuKey ? style.selectKy : style.menuItem}
                    key={`${index}${item.key}menu`}
                    onClick={() => handleMenuClick(item.key)}
                  >
                    {item.name}
                  </div>
                ))
              }
            </div>
          </Col>
          <Col span={5}>
            <div className={style.select}>
              <select defaultValue="SearchNews" className={style.items} ref={searchType2}>
                <option value="industryNews">资讯</option>
                <option value="industryReport">研报</option>
                <option value="SearchCompany">企业</option>
                <option value="industryPolicy">政策</option>
              </select>
              <input
                placeholder="请输入关键词"
                className={style.input}
                ref={searchContent2}
              />
              <Button onClick={() => handleSearchClick(2)}>搜索</Button>
            </div>
          </Col>
          {
            logined ? (
              <Col span={2} offset={1}>
                <Dropdown overlay={menu} placement="bottomLeft" arrow>
                  <div className={style.user}>
                    <div className={style.headImg}>
                      <Avatar src={user.userImg} >
                        {user.userName.slice(0, 1)}
                      </Avatar>
                    </div>
                    <div className={style.name}>
                      {user.userName}
                    </div>
                  </div>
                </Dropdown>
              </Col>
            ) : (
              <Col span={3}>
                <Button type="text" style={{ marginLeft: '20px' }} onClick={() => handleSignClick('login')}>登录</Button>
                <Button type="primary" style={{ color: '#FFFFFF', backgroundColor: '#008CD6', marginLeft: '10px' }} onClick={() => handleSignClick('apply')}>注册</Button>
              </Col>
            )
          }
        </Row>
      </div>
    </div>
  );
}
export default withRouter(Header);
