/*
* Author: zhangmeng
* Description: '伙伴关系-树图'
* Date: 2021/05/17
*/

import React, { useEffect, useState, useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { cloneDeep } from 'lodash';

const legendColor = ['#FFC58E', '#70BCFF', '#53D3E6', '#215371', '#31758B', '#398E9A', '#5999A2', '#7EB89D', '#96B28C', '#C2CC9E', '#D3C78D'];
function TreeRadialChart(props) {
  const { data, orient } = props;
  const [option, setOption] = useState({});
  const dataCp = useMemo(() => cloneDeep(data), [data]);

  /* eslint-disable no-param-reassign, prefer-destructuring */
  const recursionFunc = (params, index) => {
    params.forEach((item) => {
      let bc = '';
      if (index === 0) {
        item.itemStyle = {
          color: '#008DC7',
          borderColor: '#008DC7',
          borderWidth: 4,
          shadowBlur: 2,
          shadowColor: 'rgba(13, 59, 102, 0.3)',
        };
        item.label = {
          position: 'bottom',
          verticalAlign: 'middle',
          align: 'center',
          distance: 30,
          rotate: 360,
          fontSize: 14,
          fontWeight: 'bold',
          lineHeight: 25,
          textShadowColor: '#53D3E6',
          textShadowBlur: 15,
          formatter: p => {
            const { name } = p;
            let str = '';
            const arr = name.split('');
            while (arr.length > 8) {
              str += `${arr.splice(0, 8).join('')}\n`;
            }
            return `${str}${arr.join('')}`;
          },
        };
      } else if (index === 1) {
        item.itemStyle = {
          color: '#00ACA9',
          borderColor: '#00ACA9',
        };
        item.label = {
          position: 'bottom',
          verticalAlign: 'middle',
          align: 'center',
          distance: 20,
          rotate: 360,
          fontSize: 12,
          color: '#333333',
        };
        item.lineStyle = {
          color: '#00ACA9',
        };
      } else {
        if (item.nature && item.nature === '高校') {
          bc = legendColor[0];
        } else if (item.nature && item.nature === '企业') {
          bc = legendColor[1];
        } else {
          bc = legendColor[2];
        }
        item.itemStyle = {
          color: 'transparent',
          borderColor: bc,
        };
        item.lineStyle = {
          color: bc,
          type: 'dotted',
        };
      }
      if (item.children && item.children.length > 0) {
        recursionFunc(item.children, index + 1);
      } else {
        item.itemStyle = {
          color: 'transparent',
          borderColor: bc,
        };
      }
    });
  };
  recursionFunc(dataCp, 0);

  useEffect(() => {
    const options = {
      // tooltip: {
      //   trigger: 'item',
      //   triggerOn: 'mousemove',
      // },
      series: [
        {
          type: 'tree',
          data: dataCp,
          left: '15%',
          top: '15%',
          zoom: 0.85,
          bottom: 0,
          layout: 'radial',
          right: 0,
          symbol: 'circle',
          roam: 'move',
          orient,
          symbolSize: (v, p) => {
            const { data: { level } } = p;
            if (level === 0) {
              return 50;
            }
            if (level === 1) {
              return 20;
            }
            return 10;
          },
          label: {
            formatter: p => {
              const { data: d, name } = p;
              if (d.level === 0) {
                return `{size0|${name}}`;
              }
              if (d.level === 1) {
                return `{size1|${name}}`;
              }
              return `{size2|${name}}`;
            },
            rich: {
              size0: {
                fontSize: 14,
              },
              size1: {
                fontSize: 12,
              },
              size2: {
                fontSize: 12,
                color: '#333333',
              },
            },
          },
          itemStyle: {
            borderWidth: 1,
          },
          emphasis: {
            focus: 'descendant',
          },
          initialTreeDepth: 3,
          expandAndCollapse: true,
          animationDuration: 550,
          animationDurationUpdate: 750,
        },
      ],
    };
    setOption(options);
  }, [data, orient]);

  return (
    <ReactEcharts
      option={option}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default TreeRadialChart;
