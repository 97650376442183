/*
* Author: zhangmeng
* Description: '企业评价-雷达图'
* Date: 2020/11/30
*/

import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';

function RadarChart(props) {
  const { data } = props;
  const { abscissa_list: abscissaList, detail_list: detailList } = data;
  const [option, setOption] = useState({});

  useEffect(() => {
    const options = {
      radar: {
        name: {
          textStyle: {
            fontSize: 10,
            color: '#4E85E1',
          },
        },
        axisName: {
          fontSize: 12,
        },
        axisLabel: {
          fontSize: 12,
          color: '#BFBFBF',
        },
        splitNumber: 4,
        splitLine: {
          lineStyle: {
            width: 1,
            color: '#D2EAFF',
          },

        },
        splitArea: {
          show: true,
          areaStyle: {
            // color: ['#04072F', '#04072F'],
            color: 'rgba(210,234,255,0.3)',
          },
        },
        indicator: abscissaList.map((item, index) => (
          {
            name: item,
            // max: 1200,
            min: 0,
            max: 100,
            color: '#BFBFBF',
            axisLabel: { show: index === 0, fontSize: 10, color: '#BFBFBF' },
            axisTick: { show: index === 0 },
            axisLine: { lineStyle: { color: '#D2EAFF' } },
          }
        )),
      },
      series: [{
        type: 'radar',
        symbol: 'circle',
        symbolSize: 10,
        areaStyle: {
          color: '#1492FF',
          opacity: 0.3,
        },
        itemStyle: {
          color: '#1492FF',
        },
        lineStyle: {
          color: '#1492FF',
        },
        data: [
          {
            value: detailList[0].ordinate,
            name: detailList[0].target,
            label: {
              show: true,
              color: '#1492FF',
              fontSize: 10,
            },
            itemStyle: {
              color: '#fff',
              border: 1,
              borderColor: '#1492FF',
            },
          },
        ],
      }],
    };
    setOption(options);
  }, [abscissaList, data, detailList]);

  return (
    <ReactEcharts
      option={option}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default RadarChart;
