/*
 *Author: zhaochenyu
 *Description: 基本面扫描
 *Date: 2021/04/16
*/

import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Spin } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { getScanningChart, getScanningCount, getScanNewsCharts } from '../../../api/atlasDetail';
import Count from './Count';
import GetBottomChart from './GetBottomChart';
import FunnelChart from './FunnelChart';
import RosePie from './RosePie';
import style from './index.less';
import { ringPieColor } from '../../../utils/color';
import SingleAnnulusMap from '../../Model/SingleAnnulusMap';
import WordCloud from './WordCloud';

function Scanning(props) {
  const { industry, areaKey } = props;
  const [countData, setCountData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartData1, setChartData1] = useState([]);
  const [loadings, setLoadings] = useState([false, false, false]);
  const loadingRef = useRef([false, false, false]);

  const supplyCount = useCallback((data) => {
    let copy = [...data];
    if (data.length % 4 !== 0) {
      const len = 4 - (data.length % 4);
      const list = new Array(len);
      list.fill({}, 0, len);
      copy = copy.concat(list);
    }
    return copy;
  }, []);

  useEffect(() => {
    if (areaKey) {
      setLoadings([true, true, true]);
      loadingRef.current = [true, true, true];
      getScanningCount({
        industry_name: industry,
        area_key: areaKey,
      }).then(res => {
        if (res.status === '00000') {
          const newCount = supplyCount(res.data);
          setCountData(newCount);
          loadingRef.current[0] = false;
          setLoadings(cloneDeep(loadingRef.current));
        } else {
          loadingRef.current[0] = false;
          setLoadings(cloneDeep(loadingRef.current));
        }
      });

      getScanningChart({
        industry_name: industry,
        area_key: areaKey,
      }).then(res => {
        if (res.status === '00000') {
          loadingRef.current[1] = false;
          setLoadings(cloneDeep(loadingRef.current));
          setChartData(res.data);
        } else {
          loadingRef.current[1] = false;
          setLoadings(cloneDeep(loadingRef.current));
        }
      });

      getScanNewsCharts({
        industry_name: industry,
        area_key: areaKey,
      }).then(res => {
        if (res.status === '00000') {
          loadingRef.current[2] = false;
          setLoadings(cloneDeep(loadingRef.current));
          setChartData1(res.data);
        } else {
          loadingRef.current[2] = false;
          setLoadings(cloneDeep(loadingRef.current));
        }
      });
    }
  }, [areaKey]);

  const count = useMemo(() => (
    <Count data={countData} />
  ), [countData]);

  const chart = useMemo(() => (
    chartData.map((item, index) => (
      <div className={style.item} key={`${index}chartItem`}>
        {item && item.data && (<GetBottomChart
          chartData={item}
        />)}
      </div>
    ))
  ), [chartData]);

  const getChart = p => {
    let comp = null;
    switch (p.chart_id) {
      case 0:
        comp = <FunnelChart data={p.data} />;
        break;
      case 1:
        comp = <RosePie data={p.data} />;
        break;
      case 2:
        comp = <SingleAnnulusMap
          dataSource={p.data}
          title={p.title}
          color={ringPieColor}
        />;
        break;
      case 3:
        comp = <WordCloud data={p.data} />;
        break;
      default:
        break;
    }
    return comp;
  };

  const chart1 = useMemo(() => (
    chartData1.map((item, index) => (
      <div className={style.item} key={`${index}chartItem1`}>
        <div className={style.title}>{item.title}</div>
        <div className={style.chart}>
          {getChart(item)}
        </div>
      </div>
    ))
  ), [chartData1]);
  return (
    <div className={style.all}>
      <div className={style.total}>
        <Spin tip="搜索中，请稍候....." spinning={loadings[0]}>
          {count}
        </Spin>
      </div>
      <div className={style.content}>
        <Spin tip="搜索中，请稍候....." spinning={loadings[1]}>
          {chart}
        </Spin>
      </div>
      <div className={style.content}>
        <Spin tip="搜索中，请稍候....." spinning={loadings[2]}>
          {chart1}
        </Spin>
      </div>
    </div>
  );
}

export default Scanning;
