/*
 *Author: zhaochenyu
 *Description: 最新动态组件
 *Date: 2021/04/19
*/

import React from 'react';
import HistogramChart from '../../../Model/HistogramChart';
import BarAndLineChart from '../../../Model/BarLineChart';
import TableComponent from '../TableComponent';
import { lineColor } from '../../../../utils/color';
import style from './index.less';

function Title(props) {
  const { title, subTitle } = props;
  return (
    <div className={style.title}>
      {title}
      <span className={style.subTitle}>{`(${subTitle})`}</span>
    </div>
  );
}

function BottomChart(props) {
  const { chartData } = props;
  const { title, dateRange, type, data, y_unit: yUnit, y_right_unit: yRUnit } = chartData;
  const getChart = (chartType) => {
    let res = null;
    switch (chartType) {
      case 'heap_histogram_chart':
        res = <HistogramChart
          title={title}
          yUnit={yUnit}
          yRUnit={yRUnit}
          dataSource={data}
          color={['#55B5DD', '#5297D6', '#52D6C4']}
          type={chartType}
        />;
        break;
      case 'column_polyline_combination_diagram':
        res = <BarAndLineChart
          yUnit={yUnit}
          yRUnit={yRUnit}
          dataSource={data}
          title={title}
          barColor={['#FFCE69', '#E59831']}
          lineColor={lineColor}
          source="atlas"
        />;
        break;
      case 'invest_title_table':
        res = <TableComponent
          data={data}
        />;
        break;
      default: break;
    }
    return res;
  };
  return (
    <div className={style.all}>
      <Title
        title={title}
        subTitle={dateRange}
      />
      <div className={style.chart}>
        {getChart(type)}
      </div>
    </div>
  );
}

export default BottomChart;
