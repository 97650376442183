import { post, get } from '../utils/request';
import { COMPANY, USER } from '../utils/url';

// 搜索企业并获得企业列表
const getCompanyList = param => post(`${COMPANY}/search`, {
  ...param,
});

// 获取企业舆论状态
const getCompanyDetail = param => get(`${COMPANY}/search`, {
  ...param,
});

// 获取企业特色标签列表
const getCompanySpecialTags = param => get(`${COMPANY}/especial_tag`, {
  ...param,
});

// 获取企业状态列表
const getCompanyStatus = param => get(`${COMPANY}/status`, {
  ...param,
});

// 添加用户的浏览记录
const addUserCompanyRecord = param => post(`${USER}/history/company/add`, {
  ...param,
});

// 获取用户的浏览记录
const getUserCompanyRecord = param => get(`${USER}/history/company`, {
  ...param,
});

// 关注/取消关注
const updateCompanyRecord = param => post(`${USER}/history/company/update`, {
  ...param,
});

// 删除浏览记录
const deleteCompanyRecord = param => post(`${USER}/history/company/delete`, {
  ...param,
});

// 清空浏览记录
const cleanCompanyRecord = param => post(`${USER}/history/company/clean`, {
  ...param,
});

export {
  getCompanyList,
  getCompanyDetail,
  getCompanySpecialTags,
  getCompanyStatus,
  addUserCompanyRecord,
  getUserCompanyRecord,
  updateCompanyRecord,
  deleteCompanyRecord,
  cleanCompanyRecord,
};
