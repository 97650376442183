/*
 *Author: zhaochenyu
 *Description: 常规柱状图
 *Date: 2021/04/19
*/

import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';

function HistogramChart(props) {
  const { type, dataSource, xName = '', xUnit = '', yName = '', yUnit = '', color, title, bottom, showNum, chartGrid } = props;
  const [barOption, setBar] = useState({});

  useEffect(() => {
    const { abscissa_list: xData, detail_list: yData } = dataSource;
    const series = yData.map((item, index) => ({
      name: item.target,
      type: 'bar',
      stack: item.stack ? item.stack : item.target,
      data: item.ordinate,
      barMinHeight: 12,
      label: {
        show: showNum,
        position: 'right',
      },
      color: typeof color === 'string' ? color : color[index],
      barMaxWidth: 20,
      // barWidth: 14,
    }));
    let chartBottomDIstance = 25;
    if (type === 'histogram_chart' || type === 'heap_histogram_chart') {
      if (xData.some(item => item.length > 5)) {
        chartBottomDIstance = 45;
      }
    }
    const option = {
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: (params) => {
          let str = '<div style="padding: 9px 9px 4px 9px">';
          if (title) {
            str += `<div style="margin-bottom: 10px; font-size: 14px; font-weight: bold; color: #3E5C76;letter-spacing: 1.75px;">${title}</div>`;
          }
          if (params.length > 0) {
            str += `<div style="margin-bottom: 10px; font-size: 14px; color: #3E5C76;letter-spacing: 1.75px;">${params[0].name}</div>`;
          }
          params.forEach(item => {
            const { seriesName, value, seriesIndex } = item;
            str += `<div style="display: flex; justify-content: space-between; color: #262D36;">
              <span style="margin-right: 60px">${seriesName}</span>
              <span>
                <span style="color: #4E7293;font-weight: 600;font-size: 18px;margin-right: 4px;">${value}</span>
                ${yData[seriesIndex].unit}
              </span>
            </div>`;
          });
          str += '</div>';
          return str;
        },
        backgroundColor: '#fff',
        extraCssText: 'border: 1px solid #DCDCDC; border-radius: 4;box-shadow: 2px 5px 8px 0 rgba(108,117,125,0.32);',
      },
      grid: {
        top: 30,
        left: 15,
        right: 15,
        bottom: bottom || chartBottomDIstance,
        containLabel: true,
        ...chartGrid,
      },
      legend: {
        itemWidth: 10,
        itemHeight: 7,
        selectedMode: false,
        bottom: 0,
        left: 'center',
        data: yData.map(item => item.target),
        textStyle: {
          color: '#919191',
          fontSize: 12,
        },
      },
      xAxis: {
        type: 'category',
        name: `${xName + xUnit}`,
        data: xData,
        axisLabel: {
          color: '#919191',
          interval: 0,
          fontSize: xData.length > 25 ? 7 : 10,
          rotate: (type === 'histogram_chart' || type === 'heap_histogram_chart') && xData.some(item => item.length > 5) ? 45 : 0,
        },
        axisLine: {
          lineStyle: {
            color: '#333',
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        name: (yName && yUnit) ? `${yName}(${yUnit})` : `${yName}${yUnit}`,
        nameTextStyle: {
          color: '#919191',
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: '#919191',
          },
        },
        axisLabel: {
          formatter: '{value}',
          color: '#919191',
          fontSize: 12,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dotted',
            color: '#ebebeb',
          },
        },
      },
      series,
    };
    if (type === 'heap_bar_chart' || type === 'ordinary_bar_chart') {
      option.xAxis.type = 'value';
      option.yAxis.type = 'category';
      option.yAxis.axisLabel.formatter = (param) => {
        let res = '';
        const paramList = param.split('');
        while (paramList.length > 8) {
          res += `${paramList.splice(0, 8).join('')}\n`;
        }
        res += `${paramList.join('')}`;
        return res;
      };
      delete option.xAxis.data;
      option.yAxis.data = xData;
    }
    setBar(option);
  }, [dataSource, showNum]);

  return <ReactEcharts
    option={barOption}
    style={{ width: '100%', height: '100%' }}
  />;
}

export default HistogramChart;

HistogramChart.defaultProps = {
  chartGrid: {},
};
