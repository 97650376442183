/*
 *Author: JCL
 *Description: 报告生成
 *Date: 2020/11/19
*/

import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, TreeSelect, message, Select } from 'antd';
import style from './index.less';
import secondPage from '../../assets/img/report/secondPage.png';
import { resetTemplateReportAnalysis, getClientToken, getAreaList, getIndustryList, getReportList, getReportParam, getYearMonthReport } from '../../api/office';
import back from '../../assets/img/report/back1.png';
import coverEquity from '../../assets/img/report/cover_equity.png'; // cover_equity cover_couenter
import coverCouenter from '../../assets/img/report/cover_couenter.jpg';
import temp from '../../assets/img/report/temp.png';
import tempDirectory from '../../assets/img/report/tempDirectory.png';

import { checkLogin } from '../../utils/tool';

function ReportGeneration(props) {
  const { history } = props;
  const [industrySelect, setIndustrySelect] = useState([]);
  const [areaSelect, setAreaSelect] = useState([]); // 000000
  const [periodSelect, setPeriodSelect] = useState([]); // periodSelect
  const [industryLabelSelect, setIndustryLabelSelect] = useState([]);
  const [areaLabelSelect, setAreaLabelSelect] = useState([]); // 全国 setAreaTitle
  const [areaTitle, setAreaTitle] = useState('产业画像');
  const [cityData, setCityData] = useState([]);
  const paramFormRef = useRef(null);
  const [industryData, setIndustryData] = useState([]);
  const [currentReportParam, setCurrentReportParam] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [currentReport, setCurrentReport] = useState(null);
  const [currentReportName, setCurrentReportName] = useState(null);

  // 获取报告参数
  const getParam = (id) => {
    getReportParam({ report_id: id }).then(res => {
      setCurrentReportParam(res.data);
    });
  };
  // 页面加载初始化第一个报告的参数
  useEffect(() => {
    getReportList().then(res => {
      setReportList(res.data);
      if (res.data.length && res.data.length > 0) {
        setCurrentReport(res.data[0].id);
        setCurrentReportName(res.data[0].template_name);
        getParam(res.data[0].id);
      }
    });
  }, []);
  useEffect(() => {
    if (currentReport) {
      getAreaList({ report_id: currentReport }).then(res => {
        setCityData(res.data);
      });
      getIndustryList({ report_id: currentReport }).then(res => {
        setIndustryData(res.data);
      });
    }
  }, [currentReport]);

  // 产业下拉选事件
  const industryChange = (value, label) => {
    setIndustrySelect(value);
    setIndustryLabelSelect(label);
  };

  // 区域下拉选事件
  const areaChange = (value, label) => {
    setAreaSelect(value);
    setAreaLabelSelect(label);
  };
  // 时间区间下拉选事件
  const periodChange = (value) => {
    setPeriodSelect(value);
  };

  const getReport = () => {
    if (!checkLogin()) {
      message.warning('请先登录');
      history.push('/login');
      return;
    }
    paramFormRef.current.validateFields().then(() => {
      if (areaTitle !== '系统模版') {
        const obj = window.btoa(window.encodeURIComponent(JSON.stringify({
          curTemplateId: currentReport,
          area: areaLabelSelect,
          industry: industryLabelSelect,
          areaKey: areaSelect,
          industryKey: industrySelect,
          template_id: currentReport,
          area_key_list: areaSelect,
          industry_code_list: industrySelect,
          period: periodSelect,
          areaType: areaTitle,
        })));
        history.push(`/regionalPortraitReport?msg=${obj}`);
      } else if (areaTitle === '系统模版') {
        getClientToken().then(token => {
          resetTemplateReportAnalysis({
            token: token.data.access_token,
            template_id: currentReport,
            area_key_list: areaSelect,
            industry_code_list: industrySelect,
          }).then(res => {
            if (res.status === '00000') {
              const obj = window.btoa(window.encodeURIComponent(JSON.stringify({
                token: token.data.access_token,
                curTemplateId: currentReport,
                id: res.data,
                title: '月度监测报告',
                area: areaLabelSelect,
                areaKey: areaSelect,
                industry: industryLabelSelect,
                industryKey: industrySelect,
              })));
              window.open(`https://www.bjaibrain.com/jizhi_report/#/scheme?msg=${obj}`);
              // window.open(`http://localhost:8008/#/scheme?msg=${obj}`);
            } else {
              message.error(res.message);
            }
          });
        });
      }
    });
  };

  const reportClick = (id, title, _title) => {
    setCurrentReport(id);
    setCurrentReportName(title);
    getParam(id);
    setAreaTitle(_title);
  };
  const [yearMonth, setYearMonth] = useState([]);
  async function YearMonthReport() {
    const data = await getYearMonthReport({});
    setYearMonth(data.data);
  }
  useEffect(() => {
    YearMonthReport();
  }, []);
  return (
    <div className={style.all}>
      <div className={style.reportContent}>
        {reportList.length && reportList.length > 0 && reportList.map(item => (
          <div key={item.id}>
            <div className={style.reportTitle}>{item.template_name}</div>
            <div
              className={style.reportCard}
              style={{ marginBottom: 32 }}
              onClick={() => {
                reportClick(item.id, item.template_name, item.template_type);
              }}
            >
              { item.template_type === '产业画像' && <img src={back} alt="" className={style.reportBg} /> }
              { item.template_type === '股权投资' && <img src={coverEquity} alt="" className={style.reportBg} /> }
              { item.template_type === '系统模版' && <img src={temp} alt="" className={style.reportBg} /> }
            </div>
          </div>
        ))}
      </div>
      {areaTitle === '产业画像' && (
        <div className={style.content}>
          <img src={back} alt="" className={style.reportExample} />
          <img src={secondPage} alt="" className={style.reportExample} />
          <div className={style.area}>{currentReport === 17 ? '全国' : '海淀区'}</div>
        </div>)}
      {areaTitle === '股权投资' && (
        <div className={style.content}>
          <img src={coverEquity} alt="" className={style.reportExample} />
          <img src={coverCouenter} alt="" className={style.reportExample} />
        </div>)}
      {areaTitle === '系统模版' && (
        <div className={style.content}>
          <img src={temp} alt="" className={style.reportExample} />
          <img src={tempDirectory} alt="" className={style.reportExample} />
        </div>)}
      <div className={style.paramContent}>
        <div className={style.paramTitle}>{currentReportName}</div>
        <div className={style.paramCard}>
          <div className={style.plzSelectParam}>请选择参数</div>
          <Form
            ref={paramFormRef}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            {currentReportParam.includes('产业')
            && (<Form.Item
              label="产业"
              name="industry"
              labelAlign="left"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 20 }}
              colon={false}
              rules={[{ required: true, message: '请选择想要生成报告的产业' }]}
            >
              <TreeSelect
                placeholder="请选择想要生成报告的产业"
                style={{ width: '100%' }}
                value={industrySelect}
                dropdownClassName={style.conditionSelect}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={industryData}
                listHeight={320}
                onChange={industryChange}
                fieldNames={{
                  label: 'title',
                  value: 'key',
                }}
              />
              {/* eslint-disable-next-line react/jsx-closing-tag-location */}
            </Form.Item>)}
            {areaTitle === '股权投资' && (currentReportParam.includes('维度') || currentReportParam.includes('区域') || currentReportParam.includes('区县'))
            && (<Form.Item
              label="时间区间"
              labelAlign="left"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 20 }}
              colon={false}
              name="period"
              rules={[{ required: true, message: '请选择想要生成报告的时间区间' }]}
            >
              <Select
                style={{ width: '100%' }}
                value={periodSelect}
                listHeight={320}
                options={yearMonth}
                placeholder="请选择想要生成报告的区间"
                onChange={periodChange}
                fieldNames={{
                  label: 'title',
                  value: 'key',
                }}
              />
            </Form.Item>
            )}
            {areaTitle !== '股权投资' && (currentReportParam.includes('区域') || currentReportParam.includes('区县'))
            && (<Form.Item
              label="区域"
              labelAlign="left"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 20 }}
              colon={false}
              name="area"
              rules={[{ required: true, message: '请选择想要生成报告的地区' }]}
            >
              <TreeSelect
                style={{ width: '100%' }}
                value={areaSelect}
                treeDefaultExpandedKeys={[cityData.length > 0 && cityData[0].key]}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                listHeight={320}
                treeData={cityData}
                dropdownClassName={style.conditionSelect}
                placeholder="请选择想要生成报告的地区"
                onChange={areaChange}
                fieldNames={{
                  label: 'title',
                  value: 'key',
                }}
              />
              {/* eslint-disable-next-line react/jsx-closing-tag-location */}
            </Form.Item>
            )}
          </Form>
          <Button type="primary" block style={{ color: '#E6F9FF', backgroundColor: '#008CD6' }} onClick={() => getReport()}>查看报告</Button>
        </div>
      </div>
    </div>
  );
}

export default ReportGeneration;
