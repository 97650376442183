import { post, get } from '../utils/request';
import { USER, NEWS } from '../utils/url';

// 添加用户的浏览记录
const addUserNewsRecord = param => post(`${USER}/history/news/add`, {
  ...param,
});

// 获取用户的浏览记录
const getUserNewsRecord = param => get(`${USER}/history/news`, {
  ...param,
});

// 关注/取消关注
const updateNewsRecord = param => post(`${USER}/history/news/update`, {
  ...param,
});

// 删除浏览记录
const deleteNewsRecord = param => post(`${USER}/history/news/delete`, {
  ...param,
});

// 清空浏览记录
const cleanNewsRecord = param => post(`${USER}/history/news/clean`, {
  ...param,
});

// 获取行业动态信息
const getIndustryNews = param => get(`${NEWS}/industry_dynamics`, {
  ...param,
});

// 获取企业动态信息
const getCompanyNews = param => get(`${NEWS}/enterprise_dynamics`, {
  ...param,
});

export {
  addUserNewsRecord,
  getUserNewsRecord,
  updateNewsRecord,
  deleteNewsRecord,
  cleanNewsRecord,
  getIndustryNews,
  getCompanyNews,
};
