/*
 *Author: zhaochenyu
 *Description: 最新动态
 *Date: 2021/04/16
*/

import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Spin } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { getNewsList, getPolicyList, getNewIndustryChart, getNewPatentFinacingStandard } from '../../../api/atlasDetail';
import {
  getNewsListData,
  getPolicyListData,
  getNewsCompnayCharts,
  getNewsBottomChart,
} from '../../../api/digitalPark';
import {
  getMapNewsListData,
  getMapPolicyListData,
  getMapNewsCompnayCharts,
  getMapNewsBottomChart,
} from '../../../api/industryMap';
import NewsList from './Newslist';
import HistogramChart from '../../Model/HistogramChart';
import RadiusPie from '../../Model/RadiusPie';
import BottomChart from './BottomChart';
import style from './index.less';

function News(props) {
  const { industry, areaKey, digitalPark, industryMap } = props;
  const [loadings, setLoadings] = useState([false, false, false, false]);
  const [newsList, setNewsList] = useState([]);
  const [policyList, setPolicyList] = useState([]);
  const [addIndustry, setAddIndustry] = useState({});
  const [bottomData, setBottomData] = useState([]);
  const loadingRef = useRef([false, false, false, false]);

  useEffect(() => {
    if (areaKey) {
      setLoadings([true, true, true, true]);
      loadingRef.current = [true, true, true, true];
      let getnewsList;
      let getpoliyList;
      let industryChart;
      let bottomChart;
      if (digitalPark) {
        getnewsList = getNewsListData({ industry_name: industry });
        getpoliyList = getPolicyListData({ industry_name: industry });
        industryChart = getNewsCompnayCharts({ industry_name: industry });
        bottomChart = getNewsBottomChart({ industry_name: industry });
      } else if (industryMap) {
        getnewsList = getMapNewsListData({ industry_name: industry });
        getpoliyList = getMapPolicyListData({ industry_name: industry });
        industryChart = getMapNewsCompnayCharts({ industry_name: industry });
        bottomChart = getMapNewsBottomChart({ industry_name: industry });
      } else {
        getnewsList = getNewsList({
          industry_name: industry,
          area_key: areaKey,
        });
        getpoliyList = getPolicyList({
          industry_name: industry,
          area_key: areaKey,
        });
        industryChart = getNewIndustryChart({
          industry_name: industry,
          area_key: areaKey,
        });
        bottomChart = getNewPatentFinacingStandard({
          industry_name: industry,
          area_key: areaKey,
        });
      }

      // 新闻列表
      getnewsList.then(res => {
        if (res.status === '00000') {
          setNewsList(res.data);
          loadingRef.current[0] = false;
          setLoadings(cloneDeep(loadingRef.current));
        } else {
          loadingRef.current[0] = false;
          setLoadings(cloneDeep(loadingRef.current));
        }
      });
      // 政策列表
      getpoliyList.then(res => {
        if (res.status === '00000') {
          setPolicyList(res.data);
          loadingRef.current[1] = false;
          setLoadings(cloneDeep(loadingRef.current));
        } else {
          loadingRef.current[1] = false;
          setLoadings(cloneDeep(loadingRef.current));
        }
      });
      // 新增企业
      industryChart.then(res => {
        if (res.status === '00000') {
          setAddIndustry(res.data);
          loadingRef.current[2] = false;
          setLoadings(cloneDeep(loadingRef.current));
        } else {
          loadingRef.current[2] = false;
          setLoadings(cloneDeep(loadingRef.current));
        }
      });
      // 新增投融资等等
      bottomChart.then(res => {
        if (res.status === '00000') {
          setBottomData(res.data);
          loadingRef.current[3] = false;
          setLoadings(cloneDeep(loadingRef.current));
        } else {
          loadingRef.current[3] = false;
          setLoadings(cloneDeep(loadingRef.current));
        }
      });
    }
  }, [areaKey, industry]);

  const news = useMemo(() => (
    <NewsList data={newsList} type="new" industry={industry} />
  ), [newsList, industry]);

  const policy = useMemo(() => (
    <NewsList data={policyList} type="policy" industry={industry} />
  ), [policyList, industry]);

  const newIndustry = useMemo(() => {
    const { barData, pieData } = addIndustry;
    let res = null;
    if (barData && pieData) {
      res = (
        <>
          <div className={style.bar}>
            <div className={style.barTitle}>
              {barData.title}
              <span className={style.barSubTitle}>
                {barData.subTitle}
              </span>
            </div>
            <div className={style.barChart}>
              <HistogramChart
                color="#499AE3"
                title={barData.title}
                dataSource={barData.data}
                type="histogram_chart"
              />
            </div>
          </div>
          <div className={style.pie}>
            <div className={style.pieTitle}>{pieData.title}</div>
            <div className={style.pieChart}>
              <RadiusPie
                title={pieData.title}
                dataSource={pieData.data}
              />
            </div>
          </div>
        </>
      );
    }
    return res;
  }, [addIndustry]);

  const bottom = useMemo(() => (
    bottomData.map((item, index) => (
      <div
        className={`${style.bottom} ${index === bottomData.length - 1 ? style.bottom1 : undefined}`}
        key={`${index}bottom`}
      >
        {item && item.data && (<BottomChart
          chartData={item}
        />)}
      </div>
    ))
  ), [bottomData]);
  return (
    <div className={style.all}>
      <div className={style.news}>
        <div className={style.policy}>
          <Spin tip="搜索中，请稍候....." spinning={loadings[0]}>
            {news}
          </Spin>
        </div>
        <div className={style.policy}>
          <Spin tip="搜索中，请稍候....." spinning={loadings[1]}>
            {policy}
          </Spin>
        </div>
        <div className={style.line} />
      </div>
      <div className={style.addIndustry}>
        <Spin tip="搜索中，请稍候....." spinning={loadings[2]}>
          {newIndustry}
        </Spin>
      </div>
      <div className={style.bottomChart}>
        <Spin tip="搜索中，请稍候....." spinning={loadings[3]}>
          {bottom}
        </Spin>
      </div>
    </div>
  );
}

export default News;
