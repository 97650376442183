/*
 *Author: zhaochenyu
 *Description: 表格组件
 *Date: 2021/04/19
*/

import React, { useCallback } from 'react';
import { openNewPage } from '../../../../utils/urlMethods';
import style from './index.less';

function HotDomain(props) {
  const { data } = props;
  const { tableHead, tableBody } = data;

  const standardToDetail = useCallback(
    (key, rowData) => {
      if (key === 'name') {
        const { id } = rowData;
        openNewPage('standardDetail', {
          id,
        });
      }
    },
    [],
  );
  return (
    <div className={style.all}>
      <div className={style.box}>
        <div className={style.header}>
          {
            tableHead && tableHead.map((item) => (
              <div key={item.dataIndex} style={{ width: item.dataIndex === 'name' ? '55%' : `calc(45% / ${tableHead.length - 1})` }}>{item.title}</div>
            ))
          }
        </div>
        <div className={style.content}>
          {
            tableBody && tableBody.map((item, index) => (
              <div key={index} className={style.row}>
                {
                  tableHead && tableHead.map((item1) => (
                    <div
                      key={item1.title}
                      className={item1.dataIndex === 'name' ? style.first : undefined}
                      style={{ width: item1.dataIndex === 'name' ? '55%' : `calc(45% / ${tableHead.length - 1})` }}
                      // onClick={() => standardToDetail(item1.dataIndex, item)}
                    >
                      <span title={item[item1.dataIndex]}>{item[item1.dataIndex]}</span>
                    </div>
                  ))
                }
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default HotDomain;
