import React, { useState, useEffect } from 'react';
import { Row, Col, message } from 'antd';
import style from './index.less';
import {
  getActivityInfoList,
} from '../../../../api/activity';

function HomeActiveList() {
  const [industryActiveList, setIndustryActiveList] = useState([]);

  const openNewTab = (url) => {
    window.open(url);
  };

  useEffect(() => {
    getActivityInfoList().then(res => {
      if (res.status === '00000') {
        setIndustryActiveList(res.data.records || []);
      } else {
        message.error(res.message);
      }
    });
  }, []);

  return (
    <div className={style.tablePage}>
      <div className={style.listTitle}>
        <div className={style.menus}>
          <div className={style.selectKy}>
            行业活动
          </div>
        </div>
      </div>
      <div className={style.listContent}>
        <Row gutter={[24, 24]} justify="space-around">
          {
            industryActiveList.map((item, index) => (
              <Col span={8} key={`${index}${item._id}${item.title}table`}>
                <div
                  className={style.listItem}
                  onClick={() => openNewTab(item.link)}
                >
                  <div className={style.activeImg}>
                    <img src={item.cover_img_url} alt="" />
                  </div>
                  <div className={style.introduce}>
                    <Row>
                      <Col span={19}>
                        {item.title}
                      </Col>
                      <Col span={5}>
                        {item.date}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            ))
          }
        </Row>
      </div>
    </div>
  );
}

export default HomeActiveList;
