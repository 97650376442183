import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import style from './page.less';
import RQ1 from '../assets/img/RQ1.png';
import RQ2 from '../assets/img/RQ2.png';

function MyFooter() {
  const openNewTab = (url) => {
    window.open(url);
  };

  return (
    <div className={style.footer}>
      <div className={style.connectUs}>
        联系我们
      </div>
      <Row justify="space-around" align="middle">
        <Col span={12}>
          <div className={style.companyInfoGroup}>
            <div className={style.companyInfo}>
              联系地址：北京市海淀区知春路23号量子银座1309
            </div>
            <div className={style.companyInfo}>
              联系方式：010-82357175
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className={style.RQGroup}>
            <img src={RQ1} alt="" />
            <img src={RQ2} alt="" />
          </div>
        </Col>
      </Row>
      <div className={style.divider} />
      <Row justify="space-around" align="top">
        <Col span={4}>
          <div className={style.linkTitle}>
            友情链接
          </div>
        </Col>
        <Col span={7}>
          <div className={style.urlGroup} style={{ justifyContent: 'flex-start' }}>
            <div className={style.left}>
              指导单位：
            </div>
            <div className={style.right}>
              <div className={style.url} onClick={() => openNewTab('http://jxj.beijing.gov.cn/')}>
                <div>北京市经济和信息化局</div>
                <div>http://jxj.beijing.gov.cn</div>
              </div>
            </div>
          </div>
          <div className={style.urlGroup} style={{ justifyContent: 'flex-start' }}>
            <div className={style.left}>
              建设单位：
            </div>
            <div className={style.right}>
              <div style={{ textAlign: 'left', marginBottom: '22px' }}>
                <div>北京集智未来人工智能产业创新基地有限公司</div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div className={style.urlGroup}>
            <div className={style.left}>
              联合研发单位：
            </div>
            <div className={style.right}>
              <div className={style.url} onClick={() => openNewTab('http://www.cics-cert.org.cn')}>
                <div>国家工业信息安全发展研究中心</div>
                <div>http://www.cics-cert.org.cn</div>
              </div>
              <div className={style.url} onClick={() => openNewTab('http://www.baiia.org.cn')}>
                <div>北京人工智能产业联盟</div>
                <div>http://www.baiia.org.cn</div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={7}>
          <div className={style.urlGroup}>
            <div className={style.left}>
              承建单位：
            </div>
            <div className={style.right}>
              <div className={style.url} onClick={() => openNewTab('http://ishangqi.com')}>
                <div>北京上奇数字科技有限公司</div>
                <div>http://ishangqi.com/</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default withRouter(MyFooter);
