/*
* Author: zhangmeng
* Description: ''
* Date: 2021/01/21
*/

import React from 'react';
import { Select, Space, Pagination } from 'antd';
import style from './index.less';

const { Option } = Select;
const pages = [10, 20, 30, 40];

function PaginationComp(props) {
  const { total, selectOnChange, chartPages,
    paginationOnChange, pageSize, currPage, isNeedPages } = props;

  return (
    <div className={style.all}>
      <div className={style.paginationBox} style={{ display: `${total > 0 ? '' : 'none'}` }}>
        <div className={style.pagination}>
          <Space>
            <div style={{ display: isNeedPages ? '' : 'none' }}>
              <Select value={pageSize} onChange={(value) => selectOnChange(value)}>
                {
                  (chartPages || pages).map((item) => (
                    <Option value={item} key={`page${item}`}>
                      {item}
                      {' '}
                      条/页
                    </Option>
                  ))
                }
              </Select>
            </div>
            <div>
              {
                pageSize && <Pagination
                  showQuickJumper
                  hideOnSinglePage
                  showTotal={t => `总共 ${t} 个项目`}
                  showSizeChanger={false}
                  pageSize={pageSize}
                  current={currPage}
                  total={total}
                  onChange={(page, pageSize1) => paginationOnChange(page, pageSize1)}
                />
              }
            </div>
          </Space>
        </div>
      </div>
    </div>
  );
}

export default PaginationComp;
