/*
* Author: zhangmeng
* Description: '弹窗'
* Date: 2022/02/22
*/

import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import commitImg from '../../assets/img/accountSetting/commitImg.png';
import style from './index.less';

function CommonModal(props) {
  const { children, confirmClick } = props;

  return (
    <div className={style.all}>
      <div className={style.close}>
        <CloseOutlined onClick={() => confirmClick()} />
      </div>
      <div className={style.cnt}>
        <div className={style.left}>
          <img src={commitImg} alt="" />
        </div>
        <div className={style.right}>
          <div className={style.title}>{children}</div>
          <div className={style.desc}>
            客服将会即刻联系您
          </div>
          <div className={style.desc}>
            您也可联系我们，
            <br />
            联系电话172-0030-9680
          </div>
        </div>
      </div>
      <div className={style.btnGroup}>
        <div className={style.next} onClick={() => confirmClick()}>确认</div>
      </div>
    </div>
  );
}

export default CommonModal;
