import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Tag, Tooltip } from 'antd';
import style from './index.less';
import {
  addUserCompanyRecord,
} from '../../../../../api/company';

const companyTagColor = {
  is_branch: 'magenta',
  capital_type: 'blue',
  industry_tag: 'volcano',
  nei_code1: 'orange',
  nei_code2: 'gold',
  nei_code3: 'lime',
};

function CompanyItem(props) {
  const { companyInfo, history, searchValue } = props;
  const handleDetailClick = (company) => {
    addUserCompanyRecord({
      company_id: company._id,
      company_name: company.name,
    });
    localStorage.setItem('keyId', company._id);
    localStorage.setItem('osearchValue', searchValue);
    history.push({
      pathname: '/companyDetail',
      state: {
        companyId: company._id,
      },
    });
  };

  return (
    <div className={style.all}>
      <Row justify="space-between" align="bottom" className={style.displayLine}>
        <Col span={13}>
          <span className={style.name} onClick={() => handleDetailClick(companyInfo)}>
            {companyInfo.name}
          </span>
          <div className={style.score}>
            <div className={style.icon} />
            {companyInfo.tags?.score_tag?.total_point ? `${companyInfo.tags?.score_tag?.total_point?.toFixed(2)}分` : '无评分'}
          </div>
        </Col>
        <Col span={7}>
          <div className={style.detail}>
            <div className={style.establishDate}>
              成立日期：
              {companyInfo.establish_date}
            </div>
            <div className={style.regCapital}>
              注册资本：
              {companyInfo.reg_capital_std ? `${companyInfo.reg_capital_std}万元` : '暂无数据'}
            </div>
          </div>
        </Col>
      </Row>
      <Row justify="space-between" align="bottom" className={style.displayLine}>
        <Col span={17}>
          <div className={style.tags}>
            {
              companyInfo.tags.industry_tag
                ? (
                  <Tooltip title="产业标签">
                    <Tag color={companyTagColor.industry_tag}>
                      {companyInfo.tags.industry_tag?.[0]?.tag_name}
                    </Tag>
                  </Tooltip>
                ) : null
            }
            {
              companyInfo.tags.is_branch
                ? (
                  <Tooltip title="公司类型">
                    <Tag color={companyTagColor.is_branch}>
                      {companyInfo.tags.is_branch}
                    </Tag>
                  </Tooltip>
                ) : null
            }
            {
              companyInfo.tags.capital_type
                ? (
                  <Tooltip title="资本类型">
                    <Tag color={companyTagColor.capital_type}>
                      {companyInfo.tags.capital_type}
                    </Tag>
                  </Tooltip>
                ) : null
            }
            {
              companyInfo.tags.nei_code1
                ? (
                  <Tooltip title="一级产业类型">
                    <Tag color={companyTagColor.nei_code1}>
                      {companyInfo.tags.nei_code1.tag_name}
                    </Tag>
                  </Tooltip>
                ) : null
            }
            {
              companyInfo.tags.nei_code2
                ? (
                  <Tooltip title="二级产业类型">
                    <Tag color={companyTagColor.nei_code2}>
                      {companyInfo.tags.nei_code2.tag_name}
                    </Tag>
                  </Tooltip>
                ) : null
            }
            {
              companyInfo.tags.nei_code3
                ? (
                  <Tooltip title="三级产业类型">
                    <Tag color={companyTagColor.nei_code3}>
                      {companyInfo.tags.nei_code3.tag_name}
                    </Tag>
                  </Tooltip>
                ) : null
            }
          </div>
        </Col>
        <Col span={7}>
          <div className={style.status}>
            企业状态：
            {companyInfo.status_std}
          </div>
        </Col>
      </Row>
      <Row justify="space-between" align="bottom" className={style.displayLine}>
        <Col span={13}>
          <div className={style.location}>
            地址：
            {companyInfo.registered_add || '暂无数据'}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default withRouter(CompanyItem);
