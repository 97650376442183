/**
 * Author: tianye
 * Description: 饼图组件,
 * Date: 2020/12/17
 * */

import React, { useCallback, useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { atlasChartColor } from '../../../utils/color';

/**
 *
 * @param props {{color: [String],
 * dataSource: [{target: String, value: String, unit: String}, title] }}
 * @returns {JSX.Element}
 * @constructor
 */
const RadiusPie = (props) => {
  const { color, title, dataSource } = props;
  const [option, setOption] = useState({});

  const convertData = (data) => (data.map((item) => ({
    name: item.target,
    value: item.value,
    unit: item.unit,
  })));

  const getOption = useCallback((data) => ({
    tooltip: {
      show: true,
      confine: true,
      formatter: (params) => {
        let str = '<div style="padding: 9px 9px 4px 9px">';
        if (title) {
          str += `<div style="margin-bottom: 10px; font-size: 14px; font-weight: bold; color: #3E5C76;letter-spacing: 1.75px;">${title}</div>`;
        }
        const { data: { name, value, unit } } = params;
        str += `<div style="display: flex; justify-content: space-between; color: #262D36;">
          <span style="margin-right: 60px">${name}</span>
          <span>
            <span style="color: #4E7293;font-weight: 600;font-size: 18px;margin-right: 4px;">${value}</span>
            ${unit}
          </span>
        </div>`;
        str += '</div>';
        return str;
      },
      backgroundColor: '#fff',
      extraCssText: 'border: 1px solid #DCDCDC; border-radius: 4;box-shadow: 2px 5px 8px 0 rgba(108,117,125,0.32);',
    },
    color,
    backgroundColor: '#fff',
    legend: {
      position: 'bottom',
      bottom: 0,
      // padding: 16,
      icon: 'circle',
      itemWidth: 10,
      itemHeight: 10,
      symbolKeepAspect: true,
      textStyle: {
        fontSize: 12,
        fontWeight: 400,
        color: '#919191',
      },
    },
    series: {
      type: 'pie',
      clockwise: true,
      startAngle: 90,
      roseType: 'radius',
      center: ['50%', '45%'],
      radius: [0, '65%'],
      selectedOffset: 10,
      label: {
        position: 'outside',
        // alignTo: 'labelLine',
        // fontSize: 10,
        formatter: (params) => params.value,
      },
      data: convertData(data),
    },
  }), []);

  useEffect(() => {
    setOption(getOption(dataSource));
  }, [dataSource]);

  return <ReactEcharts
    style={{ width: '100%', height: '100%' }}
    option={option}
  />;
};

RadiusPie.defaultProps = {
  color: atlasChartColor,
};

export default RadiusPie;
