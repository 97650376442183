/*
 *Author: zhaochenyu
 *Description: 区域画像预览页组件
 *Date: 2021/11/18
*/

import React, { useState, useMemo, forwardRef, useImperativeHandle, useRef } from 'react';
import { getChart } from '../../../component/AnalysisEngineCharts';
import style from './index.module.less';

const ReportChart = forwardRef((props, ref) => {
  const { chartMsg, chartIndex, refList, title, chartHeight, getChartFinishedPercent } = props;
  const [chartDesc, setChartDesc] = useState({});
  const chartRef = useRef(null);

  const getChartReq = (res) => {
    if (res && res !== 'failed') {
      const desc = {};
      if (res && res.before_describe_value_dict) {
        desc.before = res.before_describe_value_dict;
      }
      if (res && res.after_describe_value_dict) {
        desc.after = res.after_describe_value_dict;
      }
      setChartDesc(desc);
    }
    getChartFinishedPercent(chartIndex);
  };

  useImperativeHandle(ref, () => ({
    getChartTitle() {
      return title;
    },
    // getChartSize() {
    //   return {
    //     width: chartRef.current.clientWidth,
    //     height: chartRef.current.clientHeight + 20,
    //   };
    // },
  }));

  const chart = useMemo(() => getChart(chartMsg, chartIndex, refList, getChartReq),
    [chartMsg, chartIndex, refList]);
  return (
    <div className={style.all}>
      <div className={style.chartDesc}>
        {(chartDesc.before && chartDesc.before.split('#line')
          .filter(item => item !== '#line').map((item, index) => (
            <div key={`${index}breakLine`} className={style.desc}>{item}</div>
          ))) || ''}
      </div>
      <div className={style.chartTitle}>
        {title}
      </div>
      <div className={style.chart} ref={chartRef} style={{ height: chartHeight ? `${chartHeight}px` : 'auto' }}>
        {chart}
      </div>
      <div className={style.chartDesc1}>
        {(chartDesc.after && chartDesc.after.split('#line')
          .filter(item => item !== '#line').map((item, index) => (
            <div key={`${index}breakLine`} className={style.desc}>{item}</div>
          ))) || ''}
      </div>
    </div>
  );
});

export default ReportChart;
