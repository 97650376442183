/*
 *Author: zhaochenyu
 *Description: onlyoffice文档接口
 *Date: 2021/10/28
*/

import { get, post, formDataRequestPost } from '../utils/request';
import { REPORT } from '../utils/url';
// 查询单个报告信息
const getReportMsg = param => get(`${REPORT}/find_report_info`, {
  ...param,
});

const getReportList = params => get(`${REPORT}/system_report_list`, {
  ...params,
});

const getAreaList = params => get(`${REPORT}/find_area_select_list`, {
  ...params,
});

const getIndustryList = params => get(`${REPORT}/find_industry_select_list`, {
  ...params,
});
const resetTemplateReport = params => post(`${REPORT}/system_template_reelect_param`, {
  ...params,
});
const resetTemplateReportNew = params => post(`${REPORT}/system_template_reelect_param_new`, {
  ...params,
});
const resetTemplateReportAnalysis = params => post(`${REPORT}/system_template_reelect_param_analysis`, {
  ...params,
});
const getClientToken = params => get(`${REPORT}/get_client_token`, {
  ...params,
});
// 查看报告时选择年--季度
const getYearMonthReport = params => get(`${REPORT}/system_report_period_select`, {
  ...params,
});
const getReportParam = params => get(`${REPORT}/find_system_report_params`, {
  ...params,
});

const downloadSystemTemplateReport = param => formDataRequestPost(`${REPORT}/save_report_by_system_template`, {
  ...param,
});

const download = params => get(`${REPORT}/download`, {
  ...params,
});

export {
  getReportParam,
  getReportList,
  getReportMsg,
  getAreaList,
  getIndustryList,
  resetTemplateReport,
  resetTemplateReportNew,
  resetTemplateReportAnalysis,
  getClientToken,
  getYearMonthReport,
  downloadSystemTemplateReport,
  download,
};
