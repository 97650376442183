import { get, post } from '../utils/request';
import { POLICY } from '../utils/url';

// 获取政策 typeList
const getPolicyTypes = param => get(`${POLICY}/policy_contents`, {
  ...param,
});

// 获取政策 list
const getPolicyList = param => post(`${POLICY}/search`, {
  ...param,
});

// 获取政策 detail
const getPolicyDetail = param => get(`${POLICY}/detail`, {
  ...param,
});

// 获取相关政策
const getPolicyRelevantList = param => get(`${POLICY}/recommendation`, {
  ...param,
});

// 获取政策解读
const getPolicyUnscrambleRelevantList = param => get(`${POLICY}/unscramble/recommendation`, {
  ...param,
});

export {
  getPolicyTypes,
  getPolicyList,
  getPolicyDetail,
  getPolicyRelevantList,
  getPolicyUnscrambleRelevantList,
};
