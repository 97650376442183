/*
*Author: JCL
*Description: 配置接口地址文件
*Date: 2022/07/07
*/
let baseIp;
let oauthIp;
let jizhiIp;

if (process.env.NODE_ENV === 'production') {
  let windowIp;
  if (window.location.origin){
    windowIp = window.location.origin;
  } else {
    windowIp = `${window.location.protocol}/${window.location.hostname}
    ${window.location.port ? ':' + window.location.port: ''}`;
  }
  oauthIp = `${windowIp}/oauth`;
  jizhiIp = `${windowIp}/jizhi`;
} else {
  jizhiIp = '/devproxy/jizhi'; // 本地环境
  oauthIp = '/devoauth/oauth'
}

const SMS = `${jizhiIp}/sms`; // 短信服务
const USER = `${jizhiIp}/user`;  // 用户信息管理
const COMPANY = `${jizhiIp}/company`;  // 企业
const INDUSTRYCOMPANY = `${jizhiIp}/industry_company`;  // 产业图谱企业详情
const INDUSTRYPOLICY = `${jizhiIp}/industry/policy`; // 产业 
const INDUSTRY = `${jizhiIp}/industry`; // 产业
const CITY = `${jizhiIp}/city`; // 城市
const NEWS = `${jizhiIp}/news`; // 舆情
const POLICY = `${jizhiIp}/policy`; // 政策
const RESEARCHREPORT = `${jizhiIp}/research_report`; // 研报
const ACTIVITY = `${jizhiIp}/activity`; // 活动
const REPORT = `${jizhiIp}/systemReport`; // 报告详情

const FIGUREPARK = `${baseIp}/figurePark`; // 数字园区接口
const INDUSTRYATLAS = `${baseIp}/industryAtlas`; // 产业地图
const INTERACTION = `${baseIp}/interaction`; // 公共接口

const projectName = 'jizhi_web'; // 项目名

export {
  REPORT,
  SMS,
  baseIp,
  oauthIp,
  USER,
  COMPANY,
  INDUSTRYCOMPANY,
  INDUSTRY,
  CITY,
  NEWS,
  POLICY,
  RESEARCHREPORT,
  ACTIVITY,
  jizhiIp,
  INDUSTRYPOLICY,
  FIGUREPARK,
  INDUSTRYATLAS,
  INTERACTION,
  projectName,
};
