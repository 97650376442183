/*
 *Author: zhaochenyu
 *Description: 企业画像报告
 *Date: 2022/01/05
*/

import React, { useMemo } from 'react';
import ReportChart from '../ReportChart';
import FrontCover from './FrontCover';
import BackCover from './BackCover';
import style from '../index.module.less';

function CompanyPortraitReport(props) {
  const { chartConditions, chartTitlesRef, refList, chartTitles, pushToAchor,
    getChartFinishedPercent, buyStatus } = props;
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const urlReportMsg = JSON.parse(window.decodeURIComponent(window.atob(params.get('msg') || '')) || '{}');
  return (
    <>
      {buyStatus ? (
        <>
          <div className={style.first}>
            {/* 首页封皮 */}
            <FrontCover name={urlReportMsg.name || ''} />
          </div>
          <div className={style.page}>
            <div className={style.catalogue}>目录</div>
            <div className={style.firstCata} onClick={() => pushToAchor('一、画像扫描')}>
              <div className={style.firstTitle}>一、画像扫描</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('二、行业实力')}>
              <div className={style.firstTitle}>二、行业实力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）分子公司')}>
              <div className={style.secondTitle}>（一）分子公司</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）荣誉资质')}>
              <div className={style.secondTitle}>（二）荣誉资质</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>4</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('三、创新能力')}>
              <div className={style.firstTitle}>三、创新能力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）主导标准')}>
              <div className={style.secondTitle}>（一）主导标准</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）所有专利')}>
              <div className={style.secondTitle}>（二）所有专利</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>8</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）创新平台')}>
              <div className={style.secondTitle}>（三）创新平台</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>12</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）合作关系')}>
              <div className={style.secondTitle}>（四）合作关系</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>13</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('四、融资能力')}>
              <div className={style.firstTitle}>四、融资能力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>17</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('五、成长潜力')}>
              <div className={style.firstTitle}>五、成长潜力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>18</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）关键技术')}>
              <div className={style.secondTitle}>（一）关键技术</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>18</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）成长速度')}>
              <div className={style.secondTitle}>（二）成长速度</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>19</div>
            </div>
          </div>
          {/* 第一页 */}
          <div className={style.page}>
            <div className={style.primaryTitle} id="一、画像扫描">
              一、画像扫描
            </div>
            {/* 公司基本信息 */}
            {chartConditions.length > 0 && (
            <ReportChart
              chartMsg={chartConditions[0]}
              chartIndex={0}
              ref={chartTitlesRef.current[0]}
              // chartHeight={330}
              refList={refList}
              title={`${chartTitles[0] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id="二、行业实力">二、行业实力</div>
            <div className={style.secondaryTitle} id="（一）分子公司">（一）分子公司</div>
            {/* 下钻三层股权穿透图 */}
            {chartConditions.length > 1 && (
            <ReportChart
              chartMsg={chartConditions[1]}
              chartIndex={1}
              ref={chartTitlesRef.current[1]}
              chartHeight={550}
              refList={refList}
              title={`图1 ${chartTitles[1] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 对外投资及控股企业数量 */}
            {chartConditions.length > 2 && (
            <ReportChart
              chartMsg={chartConditions[2]}
              chartIndex={2}
              ref={chartTitlesRef.current[2]}
              chartHeight={150}
              refList={refList}
              title={`表1 ${chartTitles[2] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 对外投资/分支机构全国布局 */}
            {chartConditions.length > 3 && (
            <ReportChart
              chartMsg={chartConditions[3]}
              chartIndex={3}
              ref={chartTitlesRef.current[3]}
              chartHeight={530}
              refList={refList}
              title={`图2 ${chartTitles[3] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 对外投资/分支机构数量 TOP3 省份分布情况 */}
            {chartConditions.length > 4 && (
            <ReportChart
              chartMsg={chartConditions[4]}
              chartIndex={4}
              ref={chartTitlesRef.current[4]}
              chartHeight={200}
              refList={refList}
              title={`表2 ${chartTitles[4] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）荣誉资质">（二）荣誉资质</div>
            {/* 所获资质标签名称及获得年份 */}
            {chartConditions.length > 5 && (
            <ReportChart
              chartMsg={chartConditions[5]}
              chartIndex={5}
              ref={chartTitlesRef.current[5]}
              chartHeight={420}
              refList={refList}
              title={`图3 ${chartTitles[5] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 按年份所获资质标签数量 */}
            {chartConditions.length > 6 && (
            <ReportChart
              chartMsg={chartConditions[6]}
              chartIndex={6}
              ref={chartTitlesRef.current[6]}
              chartHeight={250}
              refList={refList}
              title={`图4 ${chartTitles[6] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id="三、创新能力">三、创新能力</div>
            <div className={style.secondaryTitle} id="（一）主导标准">（一）主导标准</div>
            {chartConditions.length > 7 && (
            <ReportChart
              chartMsg={chartConditions[7]}
              chartIndex={7}
              ref={chartTitlesRef.current[7]}
              chartHeight={450}
              refList={refList}
              title={`图5 ${chartTitles[7] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {chartConditions.length > 8 && (
            <ReportChart
              chartMsg={chartConditions[8]}
              chartIndex={8}
              ref={chartTitlesRef.current[8]}
              chartHeight={660}
              refList={refList}
              title={`表3 ${chartTitles[8] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {chartConditions.length > 9 && (
            <ReportChart
              chartMsg={chartConditions[9]}
              chartIndex={9}
              ref={chartTitlesRef.current[9]}
              chartHeight={660}
              refList={refList}
              title={`表4 ${chartTitles[9] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）所有专利">（二）所有专利</div>
            {/* 公司参与起草相关产业标准类型分布 */}
            {chartConditions.length > 10 && (
            <ReportChart
              chartMsg={chartConditions[10]}
              chartIndex={10}
              ref={chartTitlesRef.current[10]}
              chartHeight={300}
              refList={refList}
              title={`图6 ${chartTitles[10] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 公司参与起草相关产业标准明细 */}
            {chartConditions.length > 11 && (
            <ReportChart
              chartMsg={chartConditions[11]}
              chartIndex={11}
              ref={chartTitlesRef.current[11]}
              chartHeight={280}
              refList={refList}
              title={`图7 ${chartTitles[11] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 公司最新起草标准详情 */}
            {chartConditions.length > 12 && (
            <ReportChart
              chartMsg={chartConditions[12]}
              chartIndex={12}
              ref={chartTitlesRef.current[12]}
              chartHeight={300}
              refList={refList}
              title={`图8 ${chartTitles[12] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 公司专利情况 */}
            {chartConditions.length > 13 && (
            <ReportChart
              chartMsg={chartConditions[13]}
              chartIndex={13}
              ref={chartTitlesRef.current[13]}
              chartHeight={450}
              refList={refList}
              title={`图9 ${chartTitles[13] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {chartConditions.length > 14 && (
            <ReportChart
              chartMsg={chartConditions[14]}
              chartIndex={14}
              ref={chartTitlesRef.current[14]}
              chartHeight={700}
              refList={refList}
              title={`表5 ${chartTitles[14] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 公司授权专利类型 */}
            {chartConditions.length > 15 && (
            <ReportChart
              chartMsg={chartConditions[15]}
              chartIndex={15}
              ref={chartTitlesRef.current[15]}
              chartHeight={320}
              refList={refList}
              title={`表6 ${chartTitles[15] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 公司发明专利主要 IPC 分类号分布 */}
            {chartConditions.length > 16 && (
            <ReportChart
              chartMsg={chartConditions[16]}
              chartIndex={16}
              ref={chartTitlesRef.current[16]}
              chartHeight={320}
              refList={refList}
              title={`表7 ${chartTitles[16] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（三）创新平台">（三）创新平台</div>
            {chartConditions.length > 17 && (
            <ReportChart
              chartMsg={chartConditions[17]}
              chartIndex={17}
              ref={chartTitlesRef.current[17]}
              chartHeight={720}
              refList={refList}
              title={`表8 ${chartTitles[17] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（四）合作关系">（四）合作关系</div>
            {chartConditions.length > 18 && (
            <ReportChart
              chartMsg={chartConditions[18]}
              chartIndex={18}
              ref={chartTitlesRef.current[18]}
              chartHeight={660}
              refList={refList}
              title={`图10 ${chartTitles[18] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {chartConditions.length > 19 && (
            <ReportChart
              chartMsg={chartConditions[19]}
              chartIndex={19}
              ref={chartTitlesRef.current[19]}
              chartHeight={700}
              refList={refList}
              title={`表9 ${chartTitles[19] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {chartConditions.length > 20 && (
            <ReportChart
              chartMsg={chartConditions[20]}
              chartIndex={20}
              ref={chartTitlesRef.current[20]}
              chartHeight={600}
              refList={refList}
              title={`图11 ${chartTitles[20] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {chartConditions.length > 21 && (
            <ReportChart
              chartMsg={chartConditions[21]}
              chartIndex={21}
              ref={chartTitlesRef.current[21]}
              chartHeight={660}
              refList={refList}
              title={`表10 ${chartTitles[21] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id="四、融资能力">四、融资能力</div>
            <div className={style.secondaryTitle} id="（一）融资历程">（一）融资历程</div>
            {chartConditions.length > 22 && (
            <ReportChart
              chartMsg={chartConditions[22]}
              chartIndex={22}
              ref={chartTitlesRef.current[22]}
              chartHeight={650}
              refList={refList}
              title={`表11 ${chartTitles[22] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id="五、成长潜力">五、成长潜力</div>
            <div className={style.secondaryTitle} id="（一）关键技术">（一）关键技术</div>
            {chartConditions.length > 23 && (
            <ReportChart
              chartMsg={chartConditions[23]}
              chartIndex={23}
              ref={chartTitlesRef.current[23]}
              chartHeight={480}
              refList={refList}
              title={`图12 ${chartTitles[23] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 公司产业标准网络合作类型分布 */}
            {chartConditions.length > 24 && (
            <ReportChart
              chartMsg={chartConditions[24]}
              chartIndex={24}
              ref={chartTitlesRef.current[24]}
              chartHeight={280}
              refList={refList}
              title={`图13 ${chartTitles[24] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）成长速度">（二）成长速度</div>
            {chartConditions.length > 25 && (
            <ReportChart
              chartMsg={chartConditions[25]}
              chartIndex={25}
              ref={chartTitlesRef.current[25]}
              chartHeight={280}
              refList={refList}
              title={`图14 ${chartTitles[25] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {chartConditions.length > 26 && (
            <ReportChart
              chartMsg={chartConditions[26]}
              chartIndex={26}
              ref={chartTitlesRef.current[26]}
              chartHeight={480}
              refList={refList}
              title={`图15 ${chartTitles[26] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote} style={{ bottom: '15px' }}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>
                  注：图 18 成长活跃曲线计分规则：
                </div>
                <div>
                  1.企业设立分公司加 2 分；
                </div>
                <div>
                  2.授权发明专利增长率&gt;5%加 3 分；
                </div>
                <div>
                  3.参与起草国际标准或国家标准加 2 分；
                </div>
                <div>
                  4.获得融资加 4 分；
                </div>
                <div>
                  5.获得国家级标签加 5 分，获得省级标签加 3 分，获得市级标签加 2 分，同年获得多个标签仅选择最大分值。
                </div>
              </div>
            </div>
          </div>

          <div className={style.last}>
            {/* 尾页 */}
            <BackCover />
          </div>
        </>
      ) : (
        <>
          <div className={style.first}>
            {/* 首页封皮 */}
            <FrontCover name={urlReportMsg.name || ''} />
          </div>
          <div className={style.page}>
            <div className={style.catalogue}>目录</div>
            <div className={style.firstCata} onClick={() => pushToAchor('一、画像扫描')}>
              <div className={style.firstTitle}>一、画像扫描</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('二、行业实力')}>
              <div className={style.firstTitle}>二、行业实力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）分子公司')}>
              <div className={style.secondTitle}>（一）分子公司</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）荣誉资质')}>
              <div className={style.secondTitle}>（二）荣誉资质</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>4</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('三、创新能力')}>
              <div className={style.firstTitle}>三、创新能力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）主导标准')}>
              <div className={style.secondTitle}>（一）主导标准</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）所有专利')}>
              <div className={style.secondTitle}>（二）所有专利</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>8</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）创新平台')}>
              <div className={style.secondTitle}>（三）创新平台</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>12</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）合作关系')}>
              <div className={style.secondTitle}>（四）合作关系</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>13</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('四、融资能力')}>
              <div className={style.firstTitle}>四、融资能力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>17</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('五、成长潜力')}>
              <div className={style.firstTitle}>五、成长潜力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>18</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）关键技术')}>
              <div className={style.secondTitle}>（一）关键技术</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>18</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）成长速度')}>
              <div className={style.secondTitle}>（二）成长速度</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>19</div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CompanyPortraitReport;
