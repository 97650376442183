/*
* Author: zhangmeng
* Description: '基本信息工商信息'
* Date: 2022/07/13
*/

import React, { useMemo } from 'react';
import Container from '../Container';
import style from './index.less';

function BusinessInfo(props) {
  const { companyId, companyName, data } = props;
  console.log(companyName, companyId);

  const content = useMemo(() => {
    console.log(data);
    if (Object.keys(data).length === 0) return null;
    const { registerCapital, setUpDate, companyType,
      representative, status, industry, businessScope } = data;
    return (
      <div className={style.all}>
        <div className={style.turn}>
          <div className={style.row}>
            <div className={style.label}>注册资本</div>
            <div className={style.value}>{registerCapital}</div>
          </div>
          <div className={style.row}>
            <div className={style.label}>成立日期</div>
            <div className={style.value}>{setUpDate}</div>
          </div>
          <div className={style.row}>
            <div className={style.label}>公司类型</div>
            <div className={style.value}>{companyType}</div>
          </div>
          <div className={style.row}>
            <div className={style.label}>法定代表人</div>
            <div className={style.value}>{representative}</div>
          </div>
          <div className={style.row}>
            <div className={style.label}>经营状态</div>
            <div className={style.value}>{status}</div>
          </div>
          <div className={style.row}>
            <div className={style.label}>行业</div>
            <div className={style.value}>{industry}</div>
          </div>
        </div>
        <div className={style.noTurn}>
          <div className={style.row}>
            <div className={style.label}>经营范围</div>
            <div className={style.value}>{businessScope}</div>
          </div>
        </div>
      </div>
    );
  }, [data]);

  return (
    <Container id="工商信息" num={data.count || 0}>
      {content}
    </Container>
  );
}

export default BusinessInfo;
