import { get, post, postFormLogin, getSource } from '../utils/request';
import { USER, oauthIp, SMS } from '../utils/url';

// 申请注册-短信验证码
const userNote = param => post(`${SMS}/captcha`, {
  ...param,
});

// 修改密码
const changePassword = param => post(`${USER}/change_password`, {
  ...param,
});

// 申请注册提交
const useApplySubmit = (params) => post(`${USER}/register`, {
  ...params,
});

// 短信登录-短信验证码
const getLoginNote = param => post(`${oauthIp}/captcha_sms`, {
  ...param,
});

// 获取token接口
const getToken = param => postFormLogin(`${oauthIp}/token`, {
  ...param,
});

// 获取用户信息
const getUserInfo = param => get(`${USER}/info`, {
  ...param,
});

// 获取用户资源列表
const getResource = () => getSource(`${USER}/find_role_resource_tree`, {});

// 更新用户登录状态
const changeUserLoginStatus = () => post(`${USER}/update_user_account_status`, {});

export {
  userNote,
  changePassword,
  changeUserLoginStatus,
  getLoginNote,
  getToken,
  getResource,
  getUserInfo,
  useApplySubmit,
};
