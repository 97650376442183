/*
 *Author: zhaochenyu
 *Description: 基本面扫描-数据统计
 *Date: 2021/04/20
*/

import React from 'react';
import { atlasCountColor } from '../../../../utils/color';
import style from './index.less';

function Count(props) {
  const { data } = props;
  return (
    <div className={style.all}>
      {data.map((item, index) => (
        <div
          className={style.countItem}
          style={{ backgroundImage: Object.keys(item).length > 0 ? `linear-gradient(90deg, ${atlasCountColor[index % 7][0]} 0%, ${atlasCountColor[index % 7][1]} 100%)` : 'none' }}
          key={`${index}count`}
        >
          <div className={style.name}>{item.name}</div>
          <div className={style.count}>{item.value}</div>
          <div className={style.unit}>{item.unit}</div>
        </div>
      ))}
    </div>
  );
}

export default Count;
