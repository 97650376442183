import React from 'react';
import style from './index.less';
import img from './404.png';

export default function NoPage() {
  return (
    <div className={style.index}>
      <img src={img} alt="404" />
    </div>
  );
}
