import React, { useEffect } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
// import { Context } from '../utils/context';
import style from './page.less';

import Header from './header';
import MyFooter from './myFooter';
import NoPage from '../component/NoPage';
import HomePage from '../pages/HomePage';
import IndustryReport from '../pages/IndustryReport';
import IndustryReportDetail from '../pages/IndustryReport/detail';
import AtlasDetail from '../component/AtlasDetail';
import IndustryNews from '../pages/IndustryNews';
import IndustryNewsDetail from '../pages/IndustryNews/detail';
import IndustryPolicy from '../pages/IndustryPolicy';
import IndustryPolicyDetail from '../pages/IndustryPolicy/detail';
import ReportGeneration from '../pages/ReportGeneration';
import RegionalPortraitReport from '../pages/RegionalPortraitReport';
import SearchCompany from '../pages/Search/SearchCompany';
import CompanyDetail from '../pages/CompanyDetail';
import IndustryCompanyDetail from '../pages/IndustryCompanyDetail';
import PolicyDetail from '../pages/PolicyDetail';
import INPolicyDetail from '../pages/IndustryPolicyDetail';
import PersonalCenter from '../pages/PersonalCenter';
import LoginPage from '../pages/Login';

function BasicLayout() {

  return (
    <Layout className={style.basic}>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route path="/">
          <div className={style.center}>
            <div className={style.context}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => <Redirect to="/homePage" push />}
                />
                <Route exact path="/homePage" component={HomePage} />
                <Route exact path="/industryGraph" component={AtlasDetail} />
                <Route exact path="/industryNews" component={IndustryNews} />
                <Route
                  exact
                  path="/industryNews/:id"
                  component={IndustryNewsDetail}
                />
                <Route
                  exact
                  path="/industryReport"
                  component={IndustryReport}
                />
                <Route
                  exact
                  path="/industryReport/:id"
                  component={IndustryReportDetail}
                />
                <Route
                  exact
                  path="/industryPolicy"
                  component={IndustryPolicy}
                />
                <Route
                  exact
                  path="/industryPolicy/:id"
                  component={IndustryPolicyDetail}
                />
                <Route
                  exact
                  path="/reportGeneration"
                  component={ReportGeneration}
                />
                <Route exact path="/searchCompany" component={SearchCompany} />
                <Route exact path="/companyDetail" component={CompanyDetail} />
                <Route exact path="/industryCompanyDetail" component={IndustryCompanyDetail} />
                <Route exact path="/policyDetail" component={PolicyDetail} />
                <Route exact path="/inPolicyDetail" component={INPolicyDetail} />
                <Route
                  exact
                  path="/personalCenter"
                  component={PersonalCenter}
                />
                <Route
                  exact
                  path="/regionalPortraitReport"
                  component={RegionalPortraitReport}
                />
                <Route
                  exact
                  path="/personalCenter"
                  component={PersonalCenter}
                />
                {/* 404页面 */}
                <Route component={NoPage} />
              </Switch>
            </div>
          </div>
          <Header />
          <MyFooter />
        </Route>
      </Switch>
    </Layout>
  );
}

export default withRouter(BasicLayout);
