/*
 *Author: zhaochenyu
 *Description: 精准招商报告-封皮
 *Date: 2022/02/23
*/

import React from 'react';
import style from './index.module.less';

function FrontCover(props) {
  const { industry } = props;
  const myDate = new Date();
  return (
    <div className={style.all}>
      <div className={style.coverLogo} />
      <div className={style.bigTitle} />
      <div className={style.word}>监测报告</div>
      <div className={style.word1}>季度</div>
      <div
        className={style.industry}
      >
        {industry}
      </div>
      <div className={style.subTitle}>
        {['产业图谱', '全量企业', '百强榜单', '重点推荐'].map((item, index) => (
          <div
            className={style.subItem}
            key={`${index}subItem`}
          >
            {item}
            <div className={style.splitLine} style={{ display: index === 3 ? 'none' : '' }} />
          </div>
        ))}
      </div>
      <div className={style.companyName}>
        {`${myDate.getFullYear()}年${myDate.getMonth() + 1}月`}
      </div>
    </div>
  );
}

export default FrontCover;
