/*
 *Author: zhaochenyu
 *Description: 产业链
 *Date: 2021/04/20
*/

import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { Spin, Alert, message } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { getIndustryChainData, getIndustryCompany } from '../../../api/atlasDetail';
import { getIndustryLink, getIndustryLinkCompnayList } from '../../../api/digitalPark';
import { getIndustryMapLink, getIndustryMapLinkCompnayList } from '../../../api/industryMap';
// import SingleAnnulusMap from '../../../Model/SingleAnnulusMap';
// import { atlasChartColor, ringPieColor } from '../../../../utils/color';
import IndustryChainLink from '../../MulberryChart';
import RelationTable from '../Common/IndustryChainTable';
// import UpgradeWindow from '../../UpgradeWindow';
import CommonModal from '../../CommonModal';
// import { userApply } from '../../../api/accountSetting';
// import { getCurUpgradeType } from '../../../utils/tool';
import { checkLogin } from '../../../utils/tool';
import { useHistory } from 'react-router-dom';
import style from './index.less';

const sankeyColor = ['#006BA0', '#5A96CD', '#AAD9BF', '#C5D5EA', '#CCE3DE', '#EAD1C1', '#E9C582', '#CBC7DB', '#EE964B', '#E9C582', '#EE964B', '#D5671D', '#C54646', '#CB997E', '#E0C7C7'];

function IndustryChain(props) {
  const history = useHistory();
  const { industry, areaKey, screenLabelList, digitalPark, industryMap, areaFilter,
    chainUpType } = props;
  const [chainData, setChainData] = useState({}); // 产业链数据
  const [chainCompany, setChainCompany] = useState({}); // 产业链企业名单
  const [chainCompanyNUm, setChainCompanyNUm] = useState(0); // 环节企业数量
  const [selectNode, setSelectNode] = useState(''); // 选中的产业链节点
  const [loadings, setLoadings] = useState([false, false]);
  const loadingRef = useRef([false, false]);
  // const [chainStatistics, setChainStatistics] = useState({}); // 产业链饼图
  // const [linkName, setLinkName] = useState(''); // 产业链一级环节
  const [label, setLabel] = useState('');
  const [curPagination, setCurPagination] = useState({ pageNum: 1, pageSize: 20 });
  const [sorder, setSorder] = useState('');
  const [upgradeWindowShow, setUpgradeWindowShow] = useState('');
  const [commonModalShow, setCommonModalShow] = useState(false);
  const [successPay] = useState(false);
  const tableCondition = useRef({
    sort_order: '',
    page_number: 1,
    page_size: 20,
    second_special_tag: 'all',
    // industry_name: '',
    industry_code: 'INB1335',
    area_key: '',
    link_code: '',
  });

  const hanldleCondition = useCallback((obj) => {
    if (!checkLogin() && obj.page_number > 1) {
      message.warning('请先登录');
      history.push('/login');
      return;
    }
    tableCondition.current = {
      ...tableCondition.current,
      ...obj,
    };
  }, []);

  const getCompnayList = useCallback((obj) => {
    let curCondition = {
      ...tableCondition.current,
    };
    if (obj) {
      curCondition = {
        ...curCondition,
        ...obj,
      };
    }
    if (curCondition.sort_order === '') {
      delete curCondition.sort_order;
    }
    loadingRef.current[1] = true;
    setLoadings(cloneDeep(loadingRef.current));
    let chainCompnayReq = getIndustryCompany;
    if (digitalPark) {
      chainCompnayReq = getIndustryLinkCompnayList;
    } else if (industryMap) {
      chainCompnayReq = getIndustryMapLinkCompnayList;
    }
    chainCompnayReq({
      ...curCondition,
    }).then(res => {
      if (res.status === '00000') {
        // if (industryMap) {
        setChainCompany({
          ...res.data,
          total: res.data.auth_count || res.data.total,
        });
        // } else {
        //   setChainCompany({
        //     ...res.data,
        //     total: chainUpType === 'up' ?
        // (res.data.auth_count || res.data.total) : res.data.auth_number * 20,
        //   });
        // }
        loadingRef.current[1] = false;
        setLoadings(cloneDeep(loadingRef.current));
      } else {
        loadingRef.current[1] = false;
        setLoadings(cloneDeep(loadingRef.current));
      }
    });
  }, [digitalPark, industryMap]);

  useEffect(() => {
    if (areaKey) {
      // 区域条件更新，表格请求条件全部重置为默认状态
      hanldleCondition({
        // industry_name: industry,
        industry_code: 'INB1335',
        area_key: areaKey,
        sort_order: '',
        page_number: 1,
        page_size: 20,
        second_special_tag: 'all',
        link_code: '',
      });
      setLabel((screenLabelList.length > 0 && screenLabelList[0].value) || '');
      setCurPagination({ pageNum: 1, pageSize: 20 });
      setSorder('');
      setSelectNode('');
      setChainCompanyNUm(0);
      setLoadings([true, true]);
      loadingRef.current = [true, true];
      let indsutryChainReq = getIndustryChainData;
      if (digitalPark) {
        indsutryChainReq = getIndustryLink;
      } else if (industryMap) {
        indsutryChainReq = getIndustryMapLink;
      }
      indsutryChainReq({
        // industry_name: industry, // industry_code
        industry_code: 'INB1335',
        area_key: areaKey,
      }).then(res => {
        if (res.status === '00000') {
          setChainData(res.data.industry_link);
          loadingRef.current[0] = false;
          setLoadings(cloneDeep(loadingRef.current));
        } else {
          loadingRef.current[0] = false;
          setLoadings(cloneDeep(loadingRef.current));
        }
      });
      getCompnayList();
    }
  }, [areaKey, industry, industryMap]);

  // useEffect(() => {
  //   if (Object.keys(chainData).length > 0) {
  //     setLinkName(chainData.industry_link.children[0].title);
  //   }
  // }, [chainData]);

  const linkClick = useCallback((node) => {
    if (!checkLogin()) {
      message.warning('请先登录');
      history.push('/login');
      return;
    }
    // console.log(node);
    if (node.depth === 0) return;
    // 节点更新，重置页码排序筛选信息
    hanldleCondition({
      link_code: node.name,
      sort_order: '',
      page_number: 1,
      page_size: 20,
      second_special_tag: 'all',
    });
    setLabel((screenLabelList.length > 0 && screenLabelList[0].value) || '');
    setCurPagination({ pageNum: 1, pageSize: 20 });
    setSorder('');
    setSelectNode(node.name);
    setChainCompanyNUm(node.companyCount);
    getCompnayList();
  }, [screenLabelList]);

  const chain = useMemo(() => (
    Object.keys(chainData).length > 0 && <IndustryChainLink
      data={[chainData]}
      type="atlas"
      firstlevelColor="#008DC7"
      colorList={sankeyColor}
      chartClick={linkClick}
      chosedColor="#54EAC4"
      selectNode={selectNode}
    />
  ), [chainData, selectNode]);

  const table = useMemo(() => (
    Object.keys(chainCompany).length > 0 && <RelationTable
      data={chainCompany}
      scrollY={(digitalPark || industryMap) ? 695 : 580}
      hanldleCondition={hanldleCondition}
      label={label}
      setLabel={setLabel}
      curPagination={curPagination}
      setCurPagination={setCurPagination}
      sorder={sorder}
      setSorder={setSorder}
      screenLabelList={screenLabelList}
      getCompnayList={getCompnayList}
      areaFilter={areaFilter}
      chainUpType={chainUpType}
      setUpgradeWindowShow={() => setUpgradeWindowShow('up')}
    />
  ), [chainCompany, hanldleCondition, label, setLabel, curPagination, digitalPark, chainUpType,
    setCurPagination, sorder, setSorder, screenLabelList, getCompnayList, areaFilter]);

  // 升级（试用账号）
  // const upgrade = () => {
  //   userApply({
  //     apply_type: '升级',
  //   }).then(res => {
  //     if (res.status === '00000') {
  //       setUpgradeWindowShow('');
  //       setCommonModalShow(true);
  //     } else {
  //       message.destroy();
  //       message.error(res.message);
  //     }
  //   });
  // };

  return (
    <div className={style.all}>
      <div className={style.chain}>
        <div className={style.industryChain} style={{ height: `${(chainData.max_level_count * 18) > 710 ? (chainData.max_level_count * 18) : 710}px` }}>
          <Spin tip="搜索中，请稍候....." spinning={loadings[0]}>
            {chain}
          </Spin>
        </div>
      </div>
      <div className={style.componay}>
        <div className={style.count}>
          {loadings[0] ? <Spin tip="搜索中，请稍候....." /> : (
            <>
              产业链共计
              <span className={style.num}>
                {chainData.link_count || 0}
              </span>
              环节
              企业共计
              <span className={style.num}>
                {chainData.industry_data_count || 0}
              </span>
              家
              <div style={{ display: chainCompanyNUm && chainCompanyNUm > 0 ? '' : 'none' }}>
                <span className={style.num}>
                  本环节
                </span>
                企业共计
                <span className={style.num}>
                  {chainCompanyNUm}
                </span>
                家
              </div>
            </>
          )}
        </div>
        {/* <div className={style.tableTitle}>
          <span className={style.tableName}>{`${linkName}`}</span>
          企业名单
        </div> */}
        <div className={style.tableBody}>
          <Spin tip="搜索中，请稍候....." spinning={loadings[1]}>
            {table}
          </Spin>
          {
            upgradeWindowShow && (
              <div className={style.upgradeWindow}>
                {/* <UpgradeWindow */}
                {/*  close={() => setUpgradeWindowShow('')} */}
                {/*  componentFrom={industryMap ? '产业地图-产业链-企业列表' : '产业图谱-产业链-企业列表'} */}
                {/*  upType={upgradeWindowShow} */}
                {/*  accountUpType={getCurUpgradeType(chainUpType)} */}
                {/*  industryChain */}
                {/*  upgrade={() => upgrade()} */}
                {/*  successPay={() => { */}
                {/*    setSuccessPay(true); */}
                {/*    setUpgradeWindowShow(''); */}
                {/*    setTimeout(() => { */}
                {/*      window.location.reload(); */}
                {/*    }, 2000); */}
                {/*    setTimeout(() => { */}
                {/*      setSuccessPay(false); */}
                {/*    }, 3000); */}
                {/*  }} */}
                {/* /> */}
              </div>
            )
          }
          {
            commonModalShow && (
              <CommonModal confirmClick={() => setCommonModalShow(false)}>
                信息提交成功
              </CommonModal>
            )
          }
        </div>
      </div>
      {successPay && (
      <div className={style.successAlert}>
        <Alert
          message={(
            <span>
              您已成功升级为
              <span style={{ fontWeight: 'bold', margin: '0 6px' }}>VIP会员</span>
              可享受更多权益，稍后短信也会发送给您
            </span>)}
          type="success"
          showIcon
        />
      </div>)}
      {/* <div className={style.ring}>
        <div className={style.count}>
          产业链共计
          <span className={style.num}>
            {chainData.industry_link && chainData.industry_link.link_count}
          </span>
          环节
          产业链环节覆盖率
          <span className={style.num}>
            {chainData.industry_link && chainData.industry_link.isGray_radio}
            %
          </span>
        </div>
        <div className={style.bottomChart}>
          {chart}
        </div>
      </div> */}
    </div>
  );
}

export default IndustryChain;
