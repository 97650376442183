import { createContext } from 'react';

export const Context = createContext();

export const menus = [
  {
    name: '首页',
    key: 'homePage'
  },
  {
    name: '产业图谱',
    key: 'industryGraph'
  },
  {
    name: '行业资讯',
    key: 'industryNews'
  },
  {
    name: '行业研报',
    key: 'industryReport'
  },
  {
    name: '产业政策',
    key: 'industryPolicy'
  },
  {
    name: '报告生成',
    key: 'reportGeneration'
  }
];
