import React, { useState, useEffect } from 'react';
import { Row, Col, message } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import style from './index.less';
import {
  getLatestResearchReport,
} from '../../../../api/report';
import { checkLogin } from '../../../../utils/tool';

const tableItemName = [
  {
    name: '推荐阅读',
    key: 'recommend',
  },
  {
    name: '最新研报',
    key: 'newReport',
  },
  // {
  //   name: '本月热读',
  //   key: 'hot',
  // },
];

function HomeReportList () {
  const history = useHistory();
  const [menuKey, setMenuKey] = useState('recommend');
  const [displayList, setDisplayList] = useState([]);
  const [recommndList, setRecommndList] = useState([]);
  const [newReportList, setNewReportList] = useState([]);
  const [hotList, setHotList] = useState([]);

  useEffect(() => {
    getLatestResearchReport().then(res => {
      if (res.status === '00000') {
        setRecommndList(res.data.data_list || []);
        setDisplayList(res.data.data_list || []);
      } else {
        message.error(res.message);
      }
    });
    getLatestResearchReport({
      industry_code: 'INB1335',
    }).then(res => {
      if (res.status === '00000') {
        setNewReportList(res.data.data_list || []);
      } else {
        message.error(res.message);
      }
    });
    // getLatestResearchReport().then(res => {
    //   if (res.status === '00000') {
    //     setHotList(res.data.data_list || []);
    //   } else {
    //     message.error(res.message);
    //   }
    // });
  }, []);

  const openDetail = (url) => {
    if (!checkLogin()) {
      message.warning('请先登录');
      history.push('/login');
      return;
    }
    window.open(url);
  };

  const handleMenuClick = (item) => {
    if (item) {
      setMenuKey(item);
      if (item === 'newReport') {
        setDisplayList(newReportList);
      } else if (item === 'hot') {
        setDisplayList(hotList);
      } else {
        setDisplayList(recommndList);
      }
    }
  };

  return (
    <div className={style.tablePage}>
      <div className={style.listTitle}>
        <div className={style.menus}>
          {
            tableItemName.map((item, index) => (
              <div
                className={item.key === menuKey ? style.selectKy : style.menuItem}
                key={`${index}${item.key}menu`}
                onClick={() => handleMenuClick(item.key)}
              >
                {item.name}
              </div>
            ))
          }
        </div>
        <div className={style.learnMore} onClick={() => {
          history.push('/industryReport');
        }}>
          了解更多
          <DoubleRightOutlined />
        </div>
      </div>
      <div className={style.listContent}>
        {
          displayList.map((item, index) => (
            <div key={`${index}${item._id}report`}>
              <Row
                className={style.listItem}
                align="top"
                onClick={() => openDetail(item.appendix_url)}
              >
                <Col span={4} className={style.publishOrgIcon}>
                  <div className={style.publishItem}>
                    {item.report_type}
                  </div>
                </Col>
                <Col span={14}>
                  <div className={style.content}>
                    {item.report_name}
                  </div>
                  {/* <div className={style.content}>
                    {item.abstract}
                  </div> */}
                </Col>
                <Col span={3} className={style.publishOrg}>
                  {item.institution}
                </Col>
                <Col span={3} className={style.date}>
                  {item.release_time.slice(0, 10)}
                </Col>
              </Row>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default HomeReportList;
