/*
 *Author: zhaochenyu
 *Description: 产业链图表格，less文件使用公共表格的less文件
 *Date: 2021/12/01
*/

import React, { useEffect, useCallback, useState } from 'react';
import { Table, Pagination, Tree } from 'antd';
import { openNewPage } from '../../../../utils/urlMethods';
import style from '../AltasTable/index.less';

function AreaNodeTree(props) {
  const { data, hanldleCondition, setCurPagination, getCompnayList, curPagination,
    setSelectArea } = props;
  const [selectKeys, setSelectKeys] = useState([]);

  const selectTreeKey = (selected) => {
    if (selected.length > 0) {
      setSelectArea(selected);
      setSelectKeys(selected);
      hanldleCondition({
        page_number: 1,
        area_key: selected[0],
      });
      setCurPagination({
        ...curPagination,
        pageNum: 1,
      });
      getCompnayList();
    }
  };

  useEffect(() => {
    setSelectArea(null);
  }, [data]);
  return (
    <Tree
      onSelect={selectTreeKey}
      selectedKeys={selectKeys}
      treeData={data}
      // selectable
      defaultExpandedKeys={data.length > 0 ? [data[0].key] : []}
    />
  );
}

function IndustryChainTable(props) {
  const { data, scrollY, hanldleCondition, label, setLabel, curPagination, setCurPagination,
    setSorder, screenLabelList, getCompnayList, areaFilter, setUpgradeWindowShow,
    chainUpType } = props;
  const { tableHead, tableBody, total, auth_number: authNumber } = data;
  const [selectArea, setSelectArea] = useState(null);

  const pushToCompany = useCallback(
    (id) => {
      openNewPage('industryCompanyDetail', { id });
    },
    [],
  );

  useEffect(() => {
    if (screenLabelList.length > 0) {
      hanldleCondition({
        second_special_tag: screenLabelList[0].value,
      });
      setLabel(screenLabelList[0].value);
    }
  }, [screenLabelList]);

  const paginationChange = (pageNumber, pageSize) => {
    if (pageNumber > authNumber && chainUpType === 'ol') {
      setUpgradeWindowShow();
      return;
    }
    hanldleCondition({
      page_number: pageNumber,
      page_size: pageSize,
    });
    setCurPagination({
      pageNum: pageNumber,
      pageSize,
    });
    getCompnayList();
  };

  const industryTableChange = (_, filters, sorter) => {
    console.log(sorter);
    let order = '';
    if (sorter.order) {
      if (sorter.order.indexOf('asc') > -1) {
        order = 'asc';
      } else {
        order = 'desc';
      }
    }
    hanldleCondition({
      sort_order: order,
    });
    setSorder(order || '');
    getCompnayList();
  };

  const handleWithHead = (head) => (
    head.map(item => {
      let res;
      if (item.dataIndex === 'name') {
        res = {
          ...item,
          width: '45%',
          render: (text, record) => (
            <div
              title={text}
              style={{ color: '#262D36', cursor: 'pointer' }}
              className={style.companyName}
              onClick={() => pushToCompany(record.id)}
            >
              {text}
            </div>
          ),
        };
      } else if (item.dataIndex === 'score') {
        res = {
          ...item,
          width: '16%',
          ellipsis: true,
          sorter: true,
          // defaultSortOrder: 'descend',
          render: text => (
            <div className={style.score}>{text}</div>
          ),
        };
      } else if (item.dataIndex === 'index') {
        res = {
          ...item,
          width: '8%',
          ellipsis: true,
        };
      } else if (item.dataIndex === 'special_tag') {
        res = {
          ...item,
          // width: '12%',
          ellipsis: true,
          filteredValue: label === 'all' ? null : label,
          filterDropdown: (
            <div className={style.areaDropdown}>
              { screenLabelList.length > 0 ? screenLabelList.map((item2, index) => (
                <div
                  className={label === item2.value ? style.selectArea : style.areaItem}
                  key={`${index}relying_unit`}
                  onClick={() => {
                    hanldleCondition({
                      page_number: 1,
                      second_special_tag: item2.value,
                    });
                    setLabel(item2.value);
                    setCurPagination({
                      ...curPagination,
                      pageNum: 1,
                    });
                    getCompnayList();
                  }}
                  title={item2.name}
                >
                  {item2.name}
                </div>
              )) : <div>暂无数据</div>}
            </div>
          ),
          // render: (text, record) => (
          //   <TagTableHover text={text} record={record} />
          // ),
        };
      } else if (item.dataIndex === 'in_area') {
        res = {
          ...item,
          // width: '12%',
          ellipsis: true,
          filteredValue: selectArea,
          filterDropdown: (
            <div className={style.linkNodeDropdown}>
              <AreaNodeTree
                data={areaFilter}
                hanldleCondition={hanldleCondition}
                setCurPagination={setCurPagination}
                getCompnayList={getCompnayList}
                curPagination={curPagination}
                setSelectArea={setSelectArea}
              />
            </div>
          ),
        };
      } else {
        res = {
          ...item,
          ellipsis: true,
        };
      }
      return res;
    })
  );
  return (
    <div className={style.industryChainTable}>
      <Table
        rowKey="index"
        columns={handleWithHead(tableHead)}
        dataSource={tableBody}
        pagination={false}
        onChange={industryTableChange}
        className={style.table}
        scroll={{ y: scrollY }}
      />
      <div className={style.pagination}>
        <Pagination
          showQuickJumper
          hideOnSinglePage
          total={total > 10000 ? 10000 : total}
          // pageSizeOptions={false}
          current={curPagination.pageNum}
          showSizeChanger={false}
          pageSize={curPagination.pageSize}
          onChange={paginationChange}
        />
      </div>
    </div>
  );
}

export default IndustryChainTable;
