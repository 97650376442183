/*
 *Author: zhaochenyu
 *Description: 锚点对应的标题
 *Date: 2021/04/16
*/

import React from 'react';
import style from './index.less';

function AchorTitle(props) {
  const { name } = props;
  return (
    <div className={style.all} id={name}>
      {name}
      <div className={style.line} />
    </div>
  );
}

export default AchorTitle;
