import { post, get } from '../utils/request';
import { USER, RESEARCHREPORT } from '../utils/url';

// 添加用户的浏览记录
const addUserReportRecord = param => post(`${USER}/history/research_report/add`, {
  ...param,
});

// 获取用户的浏览记录
const getUserReportRecord = param => get(`${USER}/history/research_report`, {
  ...param,
});

// 关注/取消关注
const updateReportRecord = param => post(`${USER}/history/research_report/update`, {
  ...param,
});

// 删除浏览记录
const deleteReportRecord = param => post(`${USER}/history/research_report/delete`, {
  ...param,
});

// 清空浏览记录
const cleanReportRecord = param => post(`${USER}/history/research_report/clean`, {
  ...param,
});

// 获取首页-最新研报信息
const getLatestResearchReport = param => get(`${RESEARCHREPORT}/latest`, {
  ...param,
});

export {
  addUserReportRecord,
  getUserReportRecord,
  updateReportRecord,
  deleteReportRecord,
  cleanReportRecord,
  getLatestResearchReport,
};
