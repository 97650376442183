/*
* Author: JCL
* Description: 首页
* Date: 2022/7/7
*/

import React, { useState, useEffect } from 'react';
import { Carousel, Row, Col } from 'antd';
import HomeTableList from './components/homeTableList/index';
import HomeActiveList from './components/homeActiveList/index';
import HomeReportList from './components/homeReportList/index';
import style from './index.less';
// import jizhiLogo from '../../assets/img/logo.png';
// import steoke1 from '../../assets/img/Steoke1.png';
import steoke2 from '../../assets/img/Steoke2.png';
import steoke3 from '../../assets/img/Steoke3.png';
import steoke4 from '../../assets/img/Steoke4.png';

function HomePage() {
  const [bannerWidth, setBannerWidth] = useState(`${window.screen.width}px`);
  const [bannerHeight, setBannerHeight] = useState(`${window.screen.availHeight}px`);
  const [contentTop, setContentTop] = useState(`${(window.screen.height - 447) / 2}px`);

  const resetSizeParms = () => {
    setBannerWidth(`${window.screen.width}px`);
    setBannerHeight(`${window.screen.availHeight}px`);
    setContentTop(`${(window.screen.height - 447) / 2}px`);
  };

  useEffect(() => {
    window.addEventListener('resize', resetSizeParms);
    return () => {
      window.removeEventListener('resize', resetSizeParms);
    };
  }, []);

  return (
    <div>
      {/* <Carousel autoplay dotPosition="right" className={style.carousel}> */}
        <div className={style.carousel}>
          <div className={style.dd1} style={{ height: bannerHeight }}>
            <Row justify="center" align="middle" style={{ position: 'absolute', top: contentTop, width: bannerWidth }}>
              <Col span={8}>
                <div className={style.left}>
                  {/* <img src={jizhiLogo} alt="" className={style.img1} /> */}
                  <div className={style.text}>北京人工智能产业大脑</div>
                  <img src={steoke4} alt="" className={style.img2} />
                  <img src={steoke2} alt="" className={style.img3} />
                </div>
              </Col>
              <Col span={3} offset={1}>
                <img src={steoke3} alt="" style={{ width: '70px', height: '70px' }} />
              </Col>
              <Col span={6}>
                <div className={style.right}>
                  共创发展
                  <br />
                  产业生态紧密合作
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* <div>
          <div className={style.dd1} style={{ height: bannerHeight }}>
            <Row justify="center" align="middle" style={{ position: 'absolute', top: contentTop, width: bannerWidth }}>
              <Col span={8}>
                <div className={style.left}>
                  <img src={jizhiLogo} alt="" className={style.img1} />
                  <img src={steoke1} alt="" className={style.img2} />
                  <img src={steoke2} alt="" className={style.img3} />
                </div>
              </Col>
              <Col span={3} offset={1}>
                <img src={steoke3} alt="" style={{ width: '70px', height: '70px' }} />
              </Col>
              <Col span={6}>
                <div className={style.right}>
                  共创发展
                  <br />
                  产业生态紧密合作
                </div>
              </Col>
            </Row>
          </div>
        </div> */}
      {/* </Carousel> */}
      <HomeTableList />
      <HomeReportList />
      <HomeActiveList />
    </div>
  );
}

export default HomePage;
