/**
* Author: tianye
* Description: 柱线图组件,
* Date: 2020/12/18
* */

import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts';
import { tooltipFormatter } from '../../../utils/charts';
import { addWaterMark } from '../../../utils/report';

/**
 * 柱线图组件
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{
 *   color: [String], barColor: String, lineColor: String, title: String, className: String,
 *   labelRotate: boolean,
 *   dataSource: [{abscissa_list: [String], abscissa_unit: String, detail_list: [Object]}]
 * }> & React.RefAttributes<unknown>>}
 */
const BarLineChart = forwardRef((props, ref) => {
  const {
    color, barColor, lineColor, title, className, dataSource, barWidth,
    dataSource: { abscissa_list: xAxis, detail_list: dataSet },
    yName = '', yUnit = '', yRName = '', yRUnit = '', source = '',
  } = props;
  const [option, setOption] = useState({});
  const chartRef = useRef(null);

  const convertData = (data) => {
    if (data && data instanceof Array) {
      const output = [];
      for (let i = 0, j = 0, k = 0; i < data.length; i += 1) {
        if (data[i].is_type === '0') {
          let _color;
          if (typeof barColor === 'string') {
            _color = barColor;
          } else if (barColor instanceof Array) {
            _color = barColor[j % barColor.length];
          }
          output.push({
            name: data[i].target,
            type: 'bar',
            dimensions: ['none', data[i].unit || 'none'],
            yAxisIndex: data[i].y_index,
            barMaxWidth: 24,
            barWidth,
            stack: data[i].stack,
            itemStyle: { color: _color },
            data: data[i].ordinate.map((value) => Number(value)),
          });
          j += 1;
        } else {
          let _color;
          if (typeof lineColor === 'string') {
            _color = lineColor;
          } else if (lineColor instanceof Array) {
            _color = lineColor[k % lineColor.length];
          }
          output.push({
            name: data[i].target,
            type: 'line',
            dimensions: ['category', data[i].unit || 'none'],
            yAxisIndex: data[i].y_index,
            symbolSize: 6,
            smooth: true,
            sampling: true,
            symbol: 'circle',
            lineStyle: {
              width: 2,
              color: _color,
            },
            itemStyle: {
              symbolSize: 10,
              color: _color,
              borderType: 'solid',
              borderColor: '#FFFFFF',
              borderWidth: 1,
            },
            data: data[i].ordinate.map((value) => Number(value)),
          });
          k += 1;
        }
      }
      return output;
    }
    return [];
  };

  const getOption = useCallback(() => {
    if (!dataSet || dataSet.length === 0) {
      return {};
    }
    // const getYAxisUnit = (data) => {
    //   const isZero = data.filter(item => item.is_type === '0');
    //   const isOne = data.filter(item => item.is_type === '1');
    //   const yAxisUnit = [];
    //   if (isZero.length > 0) {
    //     yAxisUnit.push(isZero[0].unit);
    //   }
    //   if (isOne.length > 0) {
    //     yAxisUnit.push(isOne[0].unit);
    //   }
    //   return yAxisUnit;
    // };
    // const yAxisUnit = getYAxisUnit(dataSet);
    return {
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: (params) => {
          let str;
          if (source) {
            str = '<div style="padding: 9px 9px 4px 9px">';
            if (title) {
              str += `<div style="margin-bottom: 10px; font-size: 14px; font-weight: bold; color: #3E5C76;letter-spacing: 1.75px;">${title}</div>`;
            }
            if (params.length > 0) {
              str += `<div style="margin-bottom: 10px; font-size: 14px; color: #3E5C76;letter-spacing: 1.75px;">${params[0].name}</div>`;
            }
            params.forEach(item => {
              const { seriesName, value, seriesIndex } = item;
              str += `<div style="display: flex; justify-content: space-between; color: #262D36;">
                <span style="margin-right: 60px">${seriesName}</span>
                <span>
                  <span style="color: #4E7293;font-weight: bold;font-size: 18px;margin-right: 4px;">${value}</span>
                  ${dataSet[seriesIndex].unit}
                </span>
              </div>`;
            });
            str += '</div>';
          } else {
            str = tooltipFormatter(params, { title });
          }
          return str;
        },
        backgroundColor: source ? '#fff' : 'rgba(65, 89, 76, 0.8)',
        extraCssText: source ? 'border: 1px solid #DCDCDC; border-radius: 4;box-shadow: 2px 5px 8px 0 rgba(108,117,125,0.32);'
          : 'border: 1px solid #a4c3b2; border-radius: 0',
      },
      legend: {
        show: true,
        position: 'bottom',
        type: 'scroll',
        itemWidth: 10,
        itemHeight: 7,
        // itemGap: 40,
        bottom: 0,
        // padding: [5, 10, 16, 10],
        symbolKeepAspect: false,
        textStyle: {
          color: '#919191',
          fontSize: 12,
        },
      },
      color,
      backgroundColor: '#fff',
      grid: {
        top: 30,
        left: 15,
        right: 15,
        bottom: (xAxis.length > 0 && xAxis.some(item => item.length > 5)) ? 45 : 25,
        containLabel: true,
      },
      xAxis: [
        {
          axisTick: { show: false },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#919191',
            },
          },
          axisLabel: {
            fontSize: 10,
            color: '#aaa',
            interval: 0,
            rotate: (xAxis.length > 0 && xAxis[0].length) > 5 ? 45 : 0,
          },
          type: 'category',
          data: xAxis,
          axisPointer: {
            type: 'line',
            // lineStyle: {
            //   color: '#a4c3b2',
            //   type: 'dashed',
            // },
          },
        },
      ],
      yAxis: [
        {
          name: `${yName + yUnit}`,
          nameLocation: 'end',
          nameTextStyle: {
            color: '#777A7A',
            fontSize: 12,
            align: 'right',
            padding: [10, 8, 0, 8],
          },
          type: 'value',
          splitNumber: 6,
          axisTick: { show: false },
          axisLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            color: '#AAA',
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
            },
            show: true, // 隐藏或显示
          },
        },
        {
          name: `${yRName + yRUnit}`,
          nameLocation: 'end',
          nameTextStyle: {
            color: '#777A7A',
            fontSize: 12,
            align: 'left',
            padding: [10, 8, 0, 8],
          },
          type: 'value',
          show: true,
          splitNumber: 6,
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            fontSize: 12,
            color: '#AAA',
          },
          splitLine: {
            lineStyle: {
              type: 'dotted',
            },
            show: false, // 隐藏或显示
          },
        },
      ],
      series: convertData(dataSet),
    };
  }, []);

  useImperativeHandle(ref, () => ({
    getCanvas({ pixelRatio = 2 }) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: '#fff',
      }));
    },
    toDataURL({ pixelRatio = 2 }) {
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: '#fff',
      }), pixelRatio)));
    },
  }));

  useEffect(() => {
    setOption(getOption());
  }, [dataSource]);

  return <ReactEchartsCore
    className={className}
    style={{ width: '100%', height: '100%' }}
    echarts={echarts}
    option={option}
    ref={chartRef}
  />;
});

export default BarLineChart;
