/*
 *Author: zhaochenyu
 *Description: 重点企业
 *Date: 2021/04/16
*/

import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { Spin } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { getKeyCompanyList, getCompnayCharts } from '../../../api/atlasDetail';
import TableWIthPagination from '../../DigitalPark/DoubleChainFusion/TableWIthPagination';
// import SelectTab from '../Common/SelectTab';
// import AtlasTable from '../Common/AltasTable';
import GetChart from './GetChart';
import style from './index.less';

// const tab = ['上奇评级TOP100', '盈利增长TOP100', '资本融资TOP100', '产业生态TOP100', '研发创新TOP100',
// '风险调控TOP100', '资质标准TOP100', '上市企业'];

// const tab = ['上市企业'];

function KeyEnterprisesTest(props) {
  const { industry, areaKey } = props;
  // const [selectTitle, setSelectTitle] = useState('上市企业');
  const [tableData, setTableData] = useState({});
  const [chartData, setChartData] = useState([]);
  const [loadings, setLoadings] = useState([false, false]);
  const [choseCompnayId, setChoseCompnayId] = useState('');
  const [curPagination, setCurPagination] = useState({ pageNum: 1, pageSize: 20 });
  const tableCondition = useRef({
    page_number: 1,
    page_size: 20,
    area_key: '',
    industry_name: '',
  });
  const loadingRef = useRef([false, false]);

  const hanldleCondition = useCallback((obj) => {
    tableCondition.current = {
      ...tableCondition.current,
      ...obj,
    };
  }, []);

  const getCompnayChartsData = useCallback(
    (compnayId) => {
      loadingRef.current[1] = true;
      setLoadings(cloneDeep(loadingRef.current));
      getCompnayCharts({
        company_id: compnayId,
      }).then(res => {
        if (res.status === '00000') {
          setChartData(res.data);
          loadingRef.current[1] = false;
          setLoadings(cloneDeep(loadingRef.current));
        } else {
          loadingRef.current[1] = false;
          setLoadings(cloneDeep(loadingRef.current));
        }
      });
    },
    [],
  );

  const getCompnayList = useCallback((obj) => {
    let curCondition = {
      ...tableCondition.current,
    };
    if (obj) {
      curCondition = {
        ...curCondition,
        ...obj,
      };
    }
    loadingRef.current[0] = true;
    setLoadings(cloneDeep(loadingRef.current));
    getKeyCompanyList(curCondition).then(res => {
      if (res.status === '00000') {
        setTableData(res.data);
        setLoadings(cloneDeep(loadingRef.current));
        if (res.data && res.data.tableBody && res.data.tableBody.length > 0) {
          getCompnayChartsData(res.data.tableBody[0].id);
          setChoseCompnayId(res.data.tableBody[0].id);
        } else {
          setChartData([]);
          loadingRef.current[0] = false;
          setLoadings(cloneDeep(loadingRef.current));
        }
        loadingRef.current[0] = false;
        setLoadings(cloneDeep(loadingRef.current));
      } else {
        loadingRef.current[0] = false;
        setLoadings(cloneDeep(loadingRef.current));
      }
    });
  }, []);

  const pushToCompany = useCallback(
    (id) => {
      setChoseCompnayId(id);
      getCompnayChartsData(id);
    },
    [],
  );

  const handleWithHead = (head) => (
    head.map(item => {
      let res;
      if (item.dataIndex === 'name') {
        res = {
          ...item,
          width: '54%',
          render: (text, record) => (
            <div
              title={text}
              style={{ color: '#262D36', cursor: 'pointer', fontWeight: choseCompnayId === record.id ? 700 : 400 }}
              className={style.companyName}
              onClick={() => pushToCompany(record.id)}
            >
              {text}
            </div>
          ),
        };
      } else if (item.dataIndex === 'PE_ration' || item.dataIndex === 'amount') {
        res = {
          ...item,
          ellipsis: true,
          render: text => (
            <div className={style.countNumber}>{text}</div>
          ),
        };
      } else if (item.dataIndex === 'index') {
        res = {
          ...item,
          width: '12%',
          ellipsis: true,
        };
      } else {
        res = {
          ...item,
          ellipsis: true,
        };
      }
      return res;
    })
  );

  useEffect(() => {
    if (areaKey) {
      hanldleCondition({
        area_key: areaKey,
        industry_name: industry,
      });
      setLoadings([true, true]);
      loadingRef.current = [true, true];
      setCurPagination({ pageNum: 1, pageSize: 20 });
      getCompnayList();
    }
  }, [areaKey]);

  // const tabs = useMemo(() => (
  //   <SelectTab list={tab} select={selectTitle} onChange={setSelectTitle} />
  // ), [selectTitle, tab]);

  // const table = useMemo(() => (
  //   Object.keys(tableData).length > 0 && <AtlasTable
  //     data={tableData}
  //     size="middle"
  //     scrollY={630}
  //     getCompnayChartsData={getCompnayChartsData}
  //     choseCompnayId={choseCompnayId}
  //     setChoseCompnayId={setChoseCompnayId}
  //   />
  // ), [tableData, getCompnayChartsData, choseCompnayId, setChoseCompnayId]);

  const table = useMemo(() => (
    Object.keys(tableData).length > 0 && (
    <TableWIthPagination
      data={tableData}
      scrollY={600}
      hanldleCondition={hanldleCondition}
      curPagination={curPagination}
      setCurPagination={setCurPagination}
      getCompnayList={getCompnayList}
      handleWithHead={handleWithHead}
    />)
  ), [tableData, hanldleCondition, curPagination, setCurPagination, getCompnayList,
    handleWithHead]);

  const chart = useMemo(() => (
    chartData.map((item, index) => (
      <div className={style.chartItem} key={`${index}chartItem`}>
        {item && item.data && (<GetChart chartData={item} i={index} />)}
      </div>
    ))
  ), [chartData]);
  return (
    <div className={style.all}>
      {/* <div className={style.tabList}>
        {tabs}
      </div> */}
      <div className={style.content}>
        <div className={style.table}>
          <Spin tip="搜索中，请稍候....." spinning={loadings[0]}>
            {table}
          </Spin>
        </div>
        <div className={style.chart}>
          <Spin tip="搜索中，请稍候....." spinning={loadings[1]}>
            {chart}
          </Spin>
        </div>
        {/* <div className={style.chart1}></div> */}
      </div>
    </div>
  );
}

export default KeyEnterprisesTest;
