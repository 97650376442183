/*
* Author: zhangmeng
* Description: ''
* Date: 2022/03/02
*/

import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { atlasChartColor } from '../../../../utils/color';

function RosePie(props) {
  const { data } = props;
  const [options, setOptions] = useState({});

  useEffect(() => {
    const newData = data.map(item => (
      {
        ...item,
        name: item.target,
      }
    ));
    const option = {
      color: atlasChartColor,
      tooltip: {
        show: true,
      },
      legend: {
        type: 'plain',
        bottom: 20,
        itemWidth: 6,
        itemHeight: 6,
        icon: 'circle',
        textStyle: {
          color: '#919191',
          fontSize: 12,
        },
      },
      series: [{
        type: 'pie',
        radius: ['0%', '50%'],
        center: ['50%', '40%'],
        data: newData,
        label: {
          show: true,
          textStyle: { color: '#919191', fontSize: 10 },
        },
        roseType: 'radius',
      }],
    };
    setOptions(option);
  }, [data]);

  return (
    <ReactEcharts
      option={options}
      // notMerge={true}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default RosePie;

RosePie.defaultProps = {
  data: [
    {
      name: '上市企业',
      value: '100',
    },
    {
      name: '高成长企业',
      value: '105',
    },
    {
      name: '高技术企业',
      value: '110',
    },
    {
      name: '全量企业',
      value: '115',
    },
    {
      name: '全量企业1',
      value: '120',
    },
    {
      name: '全量企业2',
      value: '125',
    },
    {
      name: '全量企业3',
      value: '130',
    },
    {
      name: '全量企业4',
      value: '135',
    },
  ],
};
