/*
* Author: zhangmeng
* Description: '多柱图'
* Date: 2022/04/29
*/

import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';

const colorList = [
  {
    offset0: '#1492FF',
    offset1: '#1492FF',
  },
  {
    offset0: '#67E6FF',
    offset1: '#67E6FF',
  },
  {
    offset0: '#00FFA3',
    offset1: '#00FFA3',
  },
  {
    offset0: '#5925FF',
    offset1: '#5925FF',
  },
  {
    offset0: '#FF5A17',
    offset1: '#FF5A17',
  },
  {
    offset0: '#F6FF3F',
    offset1: '#F6FF3F',
  },
];
function BarChart(props) {
  const { data: { data: { xAxis, yAxis } } } = props;
  const [options, setOptions] = useState({});

  useEffect(() => {
    const { unit } = yAxis[0];
    const legend = yAxis.map((item) => (
      {
        name: item.name,
      }
    ));
    const newSeries = yAxis.map((item, index) => (
      {
        name: item.name,
        data: item.value,
        // barWidth: 20,
        // label: {
        //   show: true,
        //   color: '#333333',
        //   fontSize: 12,
        //   position: 'top',
        // },
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [{
              offset: 0,
              color: colorList[index].offset0,
            }, {
              offset: 1,
              color: colorList[index].offset1,
            }],
          },
        },
        type: 'bar',
      }
    ));
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      color: colorList,
      grid: {
        top: 40,
        bottom: 80,
      },
      legend: {
        itemWidth: 16,
        itemHeight: 10,
        icon: 'rect',
        bottom: 10,
        textStyle: {
          color: '#777A7A',
          fontSize: 12,
        },
        data: legend,
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          fontSize: 12,
          color: '#777A7A',
          rotate: 45,
          interval: 0,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: '#333',
          },
        },
        splitArea: {
          show: false,
        },
        data: xAxis,
      },
      yAxis: {
        type: 'value',
        name: unit,
        nameTextStyle: {
          fontSize: 11,
          color: '#777A7A',
        },
        axisLabel: {
          fontSize: 11,
          color: '#777A7A',
        },
        splitArea: {
          show: false,
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: '#EEEEEE',
          },
        },
      },
      series: newSeries,
    };
    setOptions(option);
  }, [xAxis, yAxis]);

  return (
    <ReactEcharts
      option={options}
      // notMerge={true}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default BarChart;
