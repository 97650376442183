/*
 *Author: zhaochenyu
 *Description: 折线图
 *Date: 2020/12/23
*/

import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import ReactEcharts from 'echarts-for-react';
import { lineColor } from '../../../utils/color';
import { addWaterMark } from '../../../utils/report';

const LineChart = forwardRef((props, ref) => {
  const [option, setOption] = useState({});
  const { color, data, title } = props;
  const chartRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getCanvas({ pixelRatio = 2 }) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: '#fff',
      }));
    },
    toDataURL({ pixelRatio = 2 }) {
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: '#fff',
      }), pixelRatio)));
    },
  }));

  useEffect(() => {
    const { abscissa_list: xList, detail_list: yList } = data;
    const series = yList.map((item, index) => (
      {
        name: item.target,
        type: 'line',
        smooth: true,
        symbol: 'circle',
        symbolSize: 6,
        dimensions: [item.unit || 'none'],
        itemStyle: {
          color: typeof color === 'string' ? color : color[index],
          borderType: 'solid',
          borderColor: '#FFFFFF',
          borderWidth: 1,
        },
        data: item.ordinate,
      }
    ));
    const options = {
      grid: {
        top: '10%',
        left: '5%',
        right: '5%',
        containLabel: true,
      },
      backgroundColor: '#fff',
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: (params) => {
          let str = '<div style="padding: 9px 9px 4px 9px">';
          if (title) {
            str += `<div style="margin-bottom: 10px; font-size: 14px; font-weight: bold; color: #3E5C76;letter-spacing: 1.75px;">${title}</div>`;
          }
          if (params.length > 0) {
            str += `<div style="margin-bottom: 10px; font-size: 14px; color: #3E5C76;letter-spacing: 1.75px;">${params[0].name}</div>`;
          }
          params.forEach(item => {
            const { seriesName, value, seriesIndex } = item;
            str += `<div style="display: flex; justify-content: space-between; color: #262D36;">
              <span style="margin-right: 60px">${seriesName}</span>
              <span>
                <span style="color: #4E7293;font-weight: 600;font-size: 18px;margin-right: 4px;">${value}</span>
                ${yList[seriesIndex].unit}
              </span>
            </div>`;
          });
          str += '</div>';
          return str;
        },
        backgroundColor: '#fff',
        extraCssText: 'border: 1px solid #DCDCDC; border-radius: 4;box-shadow: 2px 5px 8px 0 rgba(108,117,125,0.32);',

      },
      legend: {
        type: 'scroll',
        bottom: 20,
        textStyle: {
          fontSize: 12,
          color: '#8C8C8C',
        },
      },
      xAxis: {
        axisTick: { show: false },
        axisLine: {
          lineStyle: {
            color: 'rgba(0,0,0,0.45)',
          },
        },
        axisLabel: {
          fontSize: 10,
          color: '#777A7A',
          interval: 0,
          rotate: xList.length > 0 && xList[0].length > 5 ? 45 : 0,
        },
        type: 'category',
        boundaryGap: true,
        data: xList,
      },
      yAxis: {
        type: 'value',
        axisTick: { show: false },
        axisLine: {
          show: false,
        },
        axisLabel: {
          fontSize: 12,
          color: '#777A7A',
        },
        name: yList.length > 0 ? yList[0].unit : '',
        nameLocation: 'end',
        nameTextStyle: {
          color: '#777A7A',
          fontSize: 10,
          align: 'right',
          padding: [10, 8, 0, 8],
        },
        splitLine: { // 网格线
          lineStyle: {
            color: '#ECECEC',
            type: 'solid', // 设置网格线类型 dotted：虚线   solid:实线
          },
          show: true, // 隐藏或显示
        },
      },
      series,
    };
    setOption(options);
  }, [data]);

  return <ReactEcharts
    option={option}
    style={{ width: '100%', height: '100%' }}
    ref={chartRef}
  />;
});

export default LineChart;

LineChart.defaultProps = {
  color: lineColor,
};
