/**
 *Author:JCL
 *Description: 登录页面
 *Date：2022/07/10
 * */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Tabs, message, Alert } from 'antd';
import style from './index.less';
// import jizhiLogo from '../../assets/img/logo.png';
import Password from './PasswordLogin';
import UseApply from './UseApply';
import ForgetPassword from './ForgetPassword';
import CodeLogin from './CodeLogin';
import { getToken,
  useApplySubmit,
  getUserInfo,
} from '../../api/login';

const { TabPane } = Tabs;

function Login(props) {
  const { location, history } = props;
  const [uid, setUid] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState(location.state ? location.state.formType : 'login');
  const [applyLoading, setApplyLoading] = useState(false); // 申请测试loading
  const [alertShow, setAlertShow] = useState(false);

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-bitwise
      const r = Math.random() * 16 | 0;
      // eslint-disable-next-line no-bitwise,no-mixed-operators
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  // 注册申请
  const applyOnFinish = (params) => {
    setApplyLoading(true);
    useApplySubmit({
      ...params,
      client_id: '3b9c340200df11ed8ca80242ac11000b',
    }).then(res => {
      if (res.status === '00000') {
        setApplyLoading(false);
        setAlertShow(true);
        setTimeout(() => {
          setAlertShow(false);
        }, 4000);
        clearTimeout();
        setType('login');
      } else if (res.status === 'B0000') {
        setApplyLoading(false);
        message.error(res.message);
      } else {
        setApplyLoading(false);
        message.error(res.message);
      }
    });
  };

  // 登录
  const onFinish = (params, curType) => {
    setIsLoading(true);
    const obj = {
      uuid: uid,
      grant_type: curType === 'password' ? 'password' : 'sms',
      ...params,
    };
    const loginFunction = getToken(obj);
    loginFunction.then(res => {
      if (res.access_token) {
        sessionStorage.setItem('token', `${res.token_type} ${res.access_token}`);
        sessionStorage.setItem('rft', res.refresh_token);
        getUserInfo({
          client_id: '3b9c340200df11ed8ca80242ac11000b',
        }).then(info => {
          if (info.status === '00000') {
            if (Object.keys(info.data).length === 0) return;
            const {
              username,
              // eslint-disable-next-line camelcase
              user_id,
              // eslint-disable-next-line camelcase
              role_name,
              // eslint-disable-next-line camelcase
              role_id,
              // eslint-disable-next-line camelcase
              phone_no,
              // eslint-disable-next-line camelcase
              head_img_url,
              // eslint-disable-next-line camelcase
              display_name,
            } = info.data;
            sessionStorage.setItem('userName', username);
            sessionStorage.setItem('displayName', display_name);
            sessionStorage.setItem('userId', user_id);
            sessionStorage.setItem('roleName', role_name);
            sessionStorage.setItem('roleId', role_id);
            sessionStorage.setItem('phone', phone_no);
            sessionStorage.setItem('headImg', head_img_url);
            message.success('登录成功!');
            history.replace({
              pathname: '/',
            });
          } else {
            sessionStorage.clear();
            setUid(uuidv4());
            setIsLoading(false);
            message.error(info.message);
          }
        });
      } else if (res.status === 'A0201') {
        setUid(uuidv4());
        setIsLoading(false);
        message.error(res.message);
        setTimeout(() => {
          setType('apply');
        }, 1000);
      } else {
        setUid(uuidv4());
        setIsLoading(false);
        message.error(res.message);
      }
    });
  };

  const renderComp = () => {
    let comp = null;
    switch (type) {
      case 'apply':
        comp = (
          <div className={style.applyForm}>
            <Tabs defaultActiveKey="apply">
              <TabPane tab="申请注册" key="apply">
                <UseApply
                  onFinish={(p) => applyOnFinish(p)}
                  cancelClick={() => setType('login')}
                  loading={applyLoading}
                />
              </TabPane>
            </Tabs>
          </div>
        );
        break;
      case 'forget':
        comp = (
          <Tabs defaultActiveKey="forget">
            <TabPane tab="修改密码" key="forget">
              <ForgetPassword editCallback={() => setType('login')} />
            </TabPane>
          </Tabs>
        );
        break;
      case 'login':
        comp = (
          <div>
            <div className={style.title}>欢迎登录</div>
            <Tabs defaultActiveKey="password">
              <TabPane tab="账号密码" key="password">
                <Password
                  onFinish={(value) => onFinish(value, 'password')}
                  uid={uid}
                  isLoading={isLoading}
                  captchaImgClick={() => setUid(uuidv4())}
                  useApply={() => setType('apply')}
                  forgetPassword={() => setType('forget')}
                />
              </TabPane>
              <TabPane tab="验证码登录" key="sms">
                <CodeLogin
                  onFinish={(value) => onFinish(value, 'phone')}
                  useApply={() => setType('apply')}
                  forgetPassword={() => setType('forget')}
                />
              </TabPane>
            </Tabs>
          </div>
        );
        break;
      default:
        break;
    }
    return comp;
  };

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className={style.Login}>
      {
        alertShow ? (
          <Alert
            message="注册交成功！"
            description="恭喜您，账号已开通，请登录！"
            type="success"
            showIcon
            closable
          />
        ) : null
      }
      <Row align="middle">
        <Col span={12}>
          <div className={style.leftImg}>
            <div className={style.companyInfo}>
              {/* <img src={jizhiLogo} alt="" /> */}
              <span>北京人工智能产业大脑</span>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className={style.rightForm}>
            {renderComp(type)}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default withRouter(Login);
