import cloneDeep from 'lodash/cloneDeep';

export function getSelectArea(arr, select) {
  if (!select || !arr) return [];
  let res = [];
  function getAreaData(curArr, selectKey) {
    curArr.forEach(item => {
      if (item.key === selectKey) {
        res = cloneDeep(item);
      }
      if (item.children) {
        getAreaData(item.children || [], selectKey);
      }
    });
  }
  getAreaData(arr, select);
  return [res];
}

export function floatAreaArray(array) {
  const a = [];
  function floatArr(arr) {
    arr.forEach(item => {
      a.push({
        title: item.title,
        key: item.key,
        code: item.code,
        parent: item.parent,
      });
      if (item.children) {
        floatArr(item.children);
      }
    });
  }
  floatArr(array);
  return a;
}

export function getCurUpgradeType(upType) {
  let res = 'none';
  if (upType && upType !== 'none') {
    res = upType === 'ol' ? 'online' : 'contactCustomerService';
  }
  // const role = sessionStorage.getItem('role');
  // if (role === 'auth_industry_engine_1') {
  //   res = 'online';
  // } else if (upType === 'up' && role !== 'auth_industry_engine_1') {
  //   res = 'contactCustomerService';
  // } else {
  //   res = 'none';
  // }
  return res;
}

export function getBindWxPermission(data) {
  const wxpage = data.find(item => item.metadata === 'accountSetting');
  let res = false;
  if (wxpage && wxpage.child.find(item => item.metadata === 'bindWX')) {
    res = true;
  }
  return res;
}

function findChildIsinClude(children, key) {
  const have = children?.find(item => item.metadata === key);
  return !!have;
}

function findUpgradeType(child) {
  const ol = findChildIsinClude(child, 'online');
  const cs = findChildIsinClude(child, 'contactCustomerService');
  const none = findChildIsinClude(child, 'none');
  let res = 'none';
  if (none) {
    res = 'none';
  } else if (ol) {
    res = 'ol';
  } else if (cs) {
    res = 'cs';
  }
  return res;
}

export function handleWidthRolePermission(data) {
  let res = {};
  data.forEach(item => {
    let perm = {};
    let bindWX = '';
    let back = '';
    let pushIndustry = '';
    let upGrade = ''; // 页面模块触发升级
    let upGrade1 = ''; // 产业链表格升级
    let upGrade2 = ''; // 专精特新升级
    switch (item.metadata) {
      case 'accountSetting':
        upGrade = findUpgradeType(item.child);
        bindWX = findChildIsinClude(item.child, 'bindWX');
        break;
      case 'knowledgeEngine':
      case 'reportGeneration':
      case 'vip':
        upGrade = findUpgradeType(item.child);
        break;
      case 'homePage':
        upGrade = findUpgradeType(item.child);
        upGrade2 = findUpgradeType(item.child.find(item => item.metadata === 'specializedNew')?.child || []);
        break;
      case 'mySelf':
      case 'digitalPark':
      case 'companyDetail':
      case 'industryCompanyDetail':
      case 'patentDetail':
      case 'institutionDetail':
      case 'policyDetail':
      case 'inPolicyDetail':
      case 'investmentDetail':
      case 'standardDetail':
      case 'researchReportDetail':
      case 'industryCompanyChart':
        break;
      case 'industryData':
      case 'industryMap':
        upGrade1 = findUpgradeType(item.child.find(item => item.metadata === 'industryChain').child);
        break;
      case 'atlasDetail':
        upGrade1 = findUpgradeType(item.child.find(item => item.metadata === 'industryChain').child);
        back = findChildIsinClude(item.child, 'backHomepage');
        pushIndustry = findChildIsinClude(item.child, 'toIndustryData');
        break;
      default: break;
    }
    if (bindWX) {
      perm.bd = true;
    }
    if (back) {
      perm.bk = true;
    }
    if (pushIndustry) {
      perm.ptid = true;
    }
    if (upGrade) {
      perm.up = upGrade;
    }
    if (upGrade1) {
      perm.idc = {
        up: upGrade1,
      }
    }
    if (upGrade2) {
      perm.spn = {
        up: upGrade2,
      }
    }
    switch (item.metadata) {
      case 'accountSetting':
        res.acst = perm;
        break;
      case 'homePage':
        res.hp = perm;
        break;
      case 'knowledgeEngine':
        res.kle = perm;
        break;
      case 'reportGeneration':
        res.rgt = perm;
        break;
      case 'vip':
        res.vip = perm;
        break;
      case 'mySelf':
        res.ms = perm;
        break;
      case 'digitalPark':
        res.dip = perm;
        break;
      case 'companyDetail':
        res.cod = perm;
        break;
      case 'industryCompanyDetail':
        res.cod = perm;
        break;
      case 'patentDetail':
        res.pad = perm;
        break;
      case 'institutionDetail':
        res.indl = perm;
        break; 
      case 'policyDetail':
        res.pod = perm;
        break;
      case 'inPolicyDetail':
        res.pod = perm;
        break;
      case 'investmentDetail':
        res.itd = perm;
        break;
      case 'standardDetail':
        res.std = perm;
        break;
      case 'researchReportDetail':
        res.red = perm;
        break;
      case 'industryCompanyChart':
        res.icc = perm;
        break;
      case 'industryData':
        res.ind = perm;
        break;
      case 'industryMap':
        res.imp = perm;
        break;
      case 'atlasDetail':
        res.atd = perm;
        break;
      default: break;
    }
  });
  return res;
}

export function getUrlPrefix() {
  let res = '/apisix';
  if (window.location.host === 'chanyeos.com') {
    res = '';
  }
  // const res = '';
  return res;
};

export function formatDate(date) {
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
}

export function checkLogin() {
  if (sessionStorage.getItem('token')) {
    return true;
  } else {
    return false;
  }
}
