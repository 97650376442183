import { get, post } from '../utils/request';
import { RESEARCHREPORT } from '../utils/url';

// 获取研报 typeList
const getResearchReportTypes = param => get(`${RESEARCHREPORT}/report_contents`, {
  ...param,
});

// 获取研报 list
const getResearchReportList = param => post(`${RESEARCHREPORT}/search`, {
  ...param,
});

// 获取研报 detail
const getReportDetail = param => get(`${RESEARCHREPORT}/detail`, {
  ...param,
});

export { getResearchReportTypes, getResearchReportList, getReportDetail };
