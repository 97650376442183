/*
 *Author: zhaochenyu
 *Description: 区域画像
 *Date: 2021/04/16
*/

import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Spin } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { getProCompanyCount, getAreaCompnayTotal } from '../../../api/atlasDetail';
import AreaDistributionTable from '../Common/AreaDistributionTable';
import DrillableMap from './DrillableMap';
import style from './index.less';

function Portrait(props) {
  const { industry, areaKey, screenLabelList, cityCode, selectAreaData } = props;
  const [areaTableData, setAreaTable] = useState({});
  const [drillCityCode, setDrillCityCode] = useState(''); // 下钻区域的地图code
  const [curAreaKey, setCurAreaKey] = useState(''); // 当前的地图key
  const [mapData, setMapData] = useState([]);
  const [loadings, setLoadings] = useState([false, false]);
  const loadingRef = useRef([false, false]);
  const drillCityCodeList = useRef([]); // 地区下钻code
  const drillCityKeyList = useRef([]); // 地区下钻key

  const getAreaMapCount = useCallback(
    (obj) => {
      loadingRef.current[0] = true;
      setLoadings(cloneDeep(loadingRef.current));
      getProCompanyCount({
        industry_name: industry,
        ...obj,
      }).then(res => {
        if (res.status === '00000') {
          setMapData(res.data || []);
          loadingRef.current[0] = false;
          setLoadings(cloneDeep(loadingRef.current));
        } else {
          loadingRef.current[0] = false;
          setLoadings(cloneDeep(loadingRef.current));
        }
      });
    },
    [industry],
  );

  const getAreaCompnayCount = useCallback(
    (obj) => {
      loadingRef.current[1] = true;
      setLoadings(cloneDeep(loadingRef.current));
      getAreaCompnayTotal({
        industry_name: industry,
        ...obj,
      }).then(res => {
        if (res.status === '00000') {
          setAreaTable(res.data || {});
          loadingRef.current[1] = false;
          setLoadings(cloneDeep(loadingRef.current));
        } else {
          loadingRef.current[1] = false;
          setLoadings(cloneDeep(loadingRef.current));
        }
      });
    },
    [industry],
  );

  const returnPrevLevel = () => {
    if (drillCityCodeList.current.length) {
      const backCityCode = drillCityCodeList.current.pop();
      const backCItyKey = drillCityKeyList.current.pop();
      setDrillCityCode(backCityCode);
      setCurAreaKey(backCItyKey);
      getAreaMapCount({
        area_key: backCItyKey,
      });
      getAreaCompnayCount({
        area_key: backCItyKey,
        sort_column: 'company_count',
      });
    }
  };

  useEffect(() => {
    if (cityCode) {
      setDrillCityCode(cityCode);
    }
  }, [cityCode]);

  useEffect(() => {
    if (areaKey) {
      setCurAreaKey(areaKey);
      getAreaMapCount({
        area_key: areaKey,
      });
      getAreaCompnayCount({
        area_key: areaKey,
        sort_column: 'company_count',
      });
    }
  }, [areaKey]);

  const mapScatter = useMemo(() => (
    (drillCityCode && !loadings[0]) && (
    <DrillableMap
      data={mapData}
      title="该产业企业数量"
      source="atlas"
      mapCode={drillCityCode}
      setDrillCityCode={setDrillCityCode}
      curAreaKey={curAreaKey}
      setCurAreaKey={setCurAreaKey}
      getAreaCompnayCount={getAreaCompnayCount}
      getAreaMapCount={getAreaMapCount}
      drillCityCodeList={drillCityCodeList}
      drillCityKeyList={drillCityKeyList}
      unit={mapData.length > 0 ? mapData[0].unit : ''}
    />)
  ), [mapData, drillCityCode, setDrillCityCode, curAreaKey, setCurAreaKey,
    getAreaCompnayCount, getAreaMapCount, loadings]);

  const areaTable = useMemo(() => (
    Object.keys(areaTableData).length > 0 && <AreaDistributionTable
      data={areaTableData}
      scrollY={570}
      industry={industry}
      screenLabelList={screenLabelList}
      curAreaKey={curAreaKey}
      getAreaCompnayCount={getAreaCompnayCount}
      allAreaData={selectAreaData}
    />
  ), [areaTableData, industry, curAreaKey, screenLabelList, getAreaCompnayCount, selectAreaData]);

  return (
    <div className={style.all}>
      <div className={style.map}>
        <Spin tip="搜索中，请稍候....." spinning={loadings[0]}>
          {mapScatter}
          <div
            className={style.back}
            onClick={() => returnPrevLevel()}
            style={{ display: drillCityCodeList.current.length > 0 ? '' : 'none' }}
          >
            返回
          </div>
        </Spin>
      </div>
      <div className={style.rank}>
        <Spin tip="搜索中，请稍候....." spinning={loadings[1]}>
          {areaTable}
        </Spin>
      </div>
    </div>
  );
}

export default Portrait;
