/**
 *Author: zhaochenyu
 *Description: 单环饼图
 *Date: 2020/11/04
*/

import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { areaPieColor } from '../../../utils/color';

function SingleRingPieChart(props) {
  const { dataSource, color, title } = props;
  const [options, setOption] = useState({});

  const convertData = (data) => (data.map((item) => ({
    name: item.target,
    value: item.value,
    unit: item.unit,
  })));

  useEffect(() => {
    const option = {
      title: {
        text: '总',
        textStyle: {
          fontSize: 12,
          color: '#919191',
          fontWeight: 'normal',
        },
        subtext: dataSource.reduce((pre, cur) => pre + cur.value, 0),
        subtextStyle: {
          fontSize: 18,
          color: '#0D3B66',
          fontWeight: 700,
        },
        top: '32%',
        left: 'center',
      },
      legend: {
        show: true,
        bottom: '0',
        left: 'center',
        itemGap: 10,
        itemHeight: 6,
        itemWidth: 6,
        textStyle: {
          color: '#919191',
          fontSize: 12,
          // padding: [0, 5, 0, 0],
        },
        icon: 'circle',
        selectedMode: false,
      },
      tooltip: {
        confine: true,
        formatter: (params) => {
          let str = '<div style="padding: 9px 9px 4px 9px">';
          if (title) {
            str += `<div style="margin-bottom: 10px; font-size: 14px; font-weight: bold; color: #3E5C76;letter-spacing: 1.75px;">${title}</div>`;
          }
          const { data: { name, value, unit } } = params;
          str += `<div style="display: flex; justify-content: space-between; color: #262D36;">
            <span style="margin-right: 60px">${name}</span>
            <span>
              <span style="color: #4E7293;font-weight: 600;font-size: 18px;margin-right: 4px;">${value}</span>
              ${unit}
            </span>
          </div>`;
          str += '</div>';
          return str;
        },
        backgroundColor: '#fff',
        extraCssText: 'border: 1px solid #DCDCDC; border-radius: 4;box-shadow: 2px 5px 8px 0 rgba(108,117,125,0.32);',
      },
      series: [
        {
          type: 'pie',
          color,
          legendHoverLink: false,
          hoverAnimation: true,
          radius: ['50%', '65%'],
          center: ['50%', '42%'],
          label: {
            color: '#0D3B66',
            formatter: (param) => `${param.percent}%`,
          },
          labelLine: {
            length: '2%',
            length2: '5%',
            lineStyle: {
              color: '#0D3B66',
              type: 'dotted',
            },
          },
          emphasis: {
            label: {
              color: '#0D3B66',
            },
            labelLine: {
              lineStyle: {
                color: '#0D3B66',
                type: 'dotted',
              },
            },
          },
          zlevel: 5,
          data: convertData(dataSource),
        },
      ],
    };
    setOption(option);
  }, [dataSource]);

  return <ReactEcharts
    option={options}
    style={{ width: '100%', height: '100%' }}
  />;
}

SingleRingPieChart.defaultProps = {
  color: areaPieColor,
};

export default SingleRingPieChart;
