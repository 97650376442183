import { Modal, message } from 'antd';
import mutex from './mutex';

// // 随机增加二级域名
// function handleWithSecondaryDomain(url) {
//   // 非接口请求直接返回
//   if (url.indexOf('http') === -1) return url;
//   let handleUrl = url;
//   const host = url.split('://')[1].split('/')[0];
//   let isIp = [];
//   host.split('.').forEach(item => {
//     const numItem = parseFloat(item);
//     isIp.push(typeof numItem === 'number' && !isNaN(numItem));
//   })
//   if (isIp.every(item => item)) {
//     handleUrl = url;
//   } else {
//     let splicinghost = host;
//     if (host.split('.').length > 2) {
//       splicinghost = host.split('.').slice(1).join('.');
//     }
//     // 随机拼接二级域名
//     const secondaryDomain = ['', 'one.', 'two.'];
//     handleUrl = url.replace(host, `${secondaryDomain[Math.round(Math.random() * (secondaryDomain.length - 1))]}${splicinghost}`)
//   }
//   return handleUrl;
// }

export default async function request(method, uri, body, getSource) {
  // let url = handleWithSecondaryDomain(uri);
  // if (uri.indexOf('login') > -1) {
  //   url = uri;
  // }
  let url = uri;
  // console.log(url);
  method = method.toUpperCase()
  if (method === 'GET') {
    const a = [];
    for (const key in body) {
      a.push(`${key}=${body[key]}`);
    }
    if (a.length > 0) {
      url += `?${a.join('&')}`;
    }
    body = undefined;
  } else {
    body = body && JSON.stringify(body);
  }

  let credentials = 'same-origin';

  if (process.env.NODE_ENV === 'production') {
    credentials = 'include';
  }

  await mutex.waitForUnlock();
  return fetch(url, {
    method,
    mode: 'cors',
    credentials,
    // credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json,text/plain,*/*',
      Authorization: sessionStorage.getItem('token') || 'Bearer n6XEue3ukvHCXrrFa8Ukeyj29ImZciV9pxze8cbnW6',
      // 'Authorization': localStorage.getItem('token') || ''
    },
    body,
  }).then((res) => {
    const datas = res.json().then(async (datas) => {
      // const token = res.headers.get('access-token');
      if (datas.status === 'A0311' || datas.status === 'A0317' || datas.status === 'A0303' || (!getSource && datas.status === 'A0207')) {
        if (!mutex.isLocked()) {
          if (sessionStorage.getItem('token')) {
            sessionStorage.setItem('token', '');
            Modal.warning({
              centered: true,
              content: '用户信息已过期，请重新登录！',
              okText: '重新登录',
              keyboard: false,
              onOk: () => {
                window.location.href = `${window.location.href.split('#')[0]}#/login`;
                sessionStorage.clear();
                Modal.destroyAll();
                release();
              },
              width: 416,
            });
          } else {
            Modal.warning({
              centered: true,
              content: '您还没有登录！',
              okText: '去登录',
              keyboard: false,
              onOk: () => {
                window.location.href = `${window.location.href.split('#')[0]}#/login`;
                sessionStorage.clear();
                Modal.destroyAll()
              },
              width: 416,
            });
          }
          return Promise.reject('Unauthorized.');
        }
      } else {
        return datas;
      }
    });
    return datas;
  }).catch((error) => ({ status: -1, message: '数据加载异常...' }));
}

export const get = (url, body) => request('GET', url, body);
export const getSource = (url, body) => request('GET', url, body, true);
export const post = (url, body) => request('POST', url, body);
export const put = (url, body) => request('PUT', url, body);
export const del = (url, body) => request('DELETE', url, body);

// FormData
export const request_form = (method, uri, body, fixed) => {
  let url = uri;
  method = method.toUpperCase()
  const formData = new FormData();
  for (let key in body) {
    formData.append(key, body[key]);
  }
  // body = formData;

  let header;

  if (fixed) {
    header = {
      Authorization: 'Basic cmJ1S3RtcWhDUnFVTDdnWlBIckRvUDdLOmdGVmxuamVUZ3BFVXdNYUh3VmdFbXZEaHRGOEpXamFDVWUwRUp0a2NPZUI5Z203aA=='
    };
  }

  return fetch(url, {
    method,
    mode: 'cors',
    headers: {
      // 'Content-Type': 'application/json',
      // Accept: 'application/json,text/plain,*/*',
      Authorization: sessionStorage.getItem('token') || '',
      // 'Authorization': localStorage.getItem('token') || ''
      ...header,
    },
    body: formData,
  }).then((res) => {
    const datas = res.json().then((datas) => {
      // const token = res.headers.get('access-token');
      if (datas.status === 'A0317' || datas.status === 'A0303' || datas.status === 'A0207') {
        if (sessionStorage.getItem('token')) {
          sessionStorage.setItem('token', '');
          Modal.warning({
            centered: true,
            content: '用户信息已过期，请重新登录！',
            okText: '重新登录',
            keyboard: false,
            onOk: () => {
              if (window.location.href.split('#')[1] === '/homePage') {
                window.location.reload();
              } else {
                window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
              }
              sessionStorage.clear();
              Modal.destroyAll();
            },
            width: 416,
          });
        }
        return Promise.reject('Unauthorized.');
      } else {
        return datas;
      }
    });
    return datas;
  }).catch((error) => ({ status: -1, message: '数据加载异常...' }));
}
export const postForm = (url, body) => request_form('POST', url, body);

export const postFormLogin = (url, body) => request_form('POST', url, body, 'fixed');

// 分析引擎新建报告
export const formData_request = (method, uri, body) => {
  let url = uri;
  method = method.toUpperCase()
  const formData = new FormData();
  body.pic_list.forEach(item => {
    formData.append('pic_list', item, item.name);
  });
  delete body.pic_list;
  for (let key in body) {
    formData.append(key, body[key]);
  }
  // body = formData;

//   const res = Array.from(formData.entries(), ([key, prop]) => (
//     {[key]:
//       typeof prop === "string"
//       ? prop.length
//       : prop.size
//     }));
// console.log(res);

  return fetch(url, {
    method,
    mode: 'cors',
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
    body: formData,
  }).then((res) => {
    const datas = res.json().then((datas) => {
      // const token = res.headers.get('access-token');
      if (datas.status === 'A0317' || datas.status === 'A0303' || datas.status === 'A0207') {
        if (sessionStorage.getItem('token')) {
          sessionStorage.setItem('token', '');
          Modal.warning({
            centered: true,
            content: '用户信息已过期，请重新登录！',
            okText: '重新登录',
            keyboard: false,
            onOk: () => {
              if (window.location.href.split('#')[1] === '/homePage') {
                window.location.reload();
              } else {
                window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
              }
              sessionStorage.clear();
              Modal.destroyAll();
            },
            width: 416,
          });
        }
        return Promise.reject('Unauthorized.');
      } else {
        return datas;
      }
    });
    return datas;
  }).catch((error) => ({ status: -1, message: '数据加载异常...' }));
}

export const formDataRequestPost = (url, body) => formData_request('POST', url, body);

export const request_binary = (method, uri, body) => {
  let url = uri;
  method = method.toUpperCase()
  if (method === 'GET') {
      var a = [];
      for (var key in body) {
          a.push(key.toString() + "=" + body[key].toString());
      }
      if (a.length > 0) {
          url += "?" + a.join("&");
      }
      body = undefined;
  } else {
      body = body && JSON.stringify(body);
  }

  // let credentials = 'same-origin';

  // if (process.env.NODE_ENV === 'production') {
  //   credentials = 'include';
  // }

  console.log(url);
  return fetch(url, {
      method,
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Accept': 'application/octet-stream,text/plain,*/*',
        Authorization: sessionStorage.getItem('token') || '',
      },
      body,
  }).then(res=>{
      if (res.status === 401) {
        if (sessionStorage.getItem('token')) {
          sessionStorage.setItem('token', '');
          Modal.warning({
            centered: true,
            content: '用户信息已过期，请重新登录！',
            okText: '重新登录',
            keyboard: false,
            onOk: () => {
              if (window.location.href.split('#')[1] === '/homePage') {
                window.location.reload();
              } else {
                window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
              }
              sessionStorage.clear();
              Modal.destroyAll();
            },
            width: 416,
          });
        }
        return Promise.reject('Unauthorized.');
      } else {
          return res.arrayBuffer();
      }
  });
}

export const protoGet = (url, body) => request_binary('GET', url, body);
export const protoPost = (url, body) => request_binary('POST', url, body);

export const requestFile = (method, uri, body, filename) => {
  message.loading('文件下载中...', 0);
  let url = uri;
  method = method.toUpperCase();
  if (method === 'GET') {
    const a = [];
    for (const key in body) {
      a.push(`${key.toString()}=${body[key].toString()}`);
    }
    if (a.length > 0) {
      url += `?${a.join('&')}`;
    }
    body = undefined;
  } else {
    body = body && JSON.stringify(body);
  }

  return fetch(url, {
    method,
    mode: 'cors',
    headers: {
      Accept: 'application/json,text/plain,*/*',
      Authorization: sessionStorage.getItem('token') || '',
    },
    body,
  }).then((res) => {
    if (res.status === 401) {
      if (sessionStorage.getItem('token')) {
        sessionStorage.setItem('token', '');
        Modal.warning({
          centered: true,
          content: '用户信息已过期，请重新登录！',
          okText: '重新登录',
          keyboard: false,
          onOk: () => {
            if (window.location.href.split('#')[1] === '/homePage') {
              window.location.reload();
            } else {
              window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
            }
            sessionStorage.clear();
            Modal.destroyAll();
          },
          width: 416,
        });
      }
      return Promise.reject('Unauthorized.');
    } if (res.status === 402) {
      return res.json();
    }
    res.blob().then((blob) => {
      // const objectUrl = window.URL.createObjectURL(blob);
      // var win = window.open(objectUrl, '_blank');
      // win.focus();
      // return;

      // document.open();
      // document.write(blob);
      // document.close();
      // return;

      const aLink = document.createElement('a');
      document.body.appendChild(aLink);
      aLink.style.display = 'none';
      const objectUrl = window.URL.createObjectURL(blob);
      aLink.href = objectUrl;
      aLink.download = filename;
      aLink.click();
      document.body.removeChild(aLink);
      message.destroy();
      message.success('下载成功！', 1);
    });
  });
};

export const request_html = (method, uri, body) => {
  let url = uri;
  method = method.toUpperCase();
  if (method === 'GET') {
    const a = [];
    for (const key in body) {
      a.push(`${key.toString()}=${body[key].toString()}`);
    }
    if (a.length > 0) {
      url += `?${a.join('&')}`;
    }
    body = undefined;
  } else {
    body = body && JSON.stringify(body);
  }

  return fetch(url, {
    method,
    mode: 'cors',
    headers: {
      Accept: 'application/json,text/plain,*/*',
      Authorization: sessionStorage.getItem('token') || '',
      // Authorization: localStorage.getItem('access_token') || '',
    },
    body,
  }).then((res) => {
    if (res.status === 401) {
      if (sessionStorage.getItem('token')) {
        sessionStorage.setItem('token', '');
        Modal.warning({
          centered: true,
          content: '用户信息已过期，请重新登录！',
          okText: '重新登录',
          keyboard: false,
          onOk: () => {
            if (window.location.href.split('#')[1] === '/homePage') {
              window.location.reload();
            } else {
              window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
            }
            sessionStorage.clear();
            Modal.destroyAll();
          },
          width: 416,
        });
      }
      return Promise.reject('Unauthorized.');
    } if (res.status === 402) {
      return res.json();
    }
    res.blob().then((blob) => {
      const objectUrl = window.URL.createObjectURL(blob);
      const win = window.open(objectUrl, '_blank');
      win.focus();
    });
  });
};

export const request_catpcha = (method, uri, body) => {
  let url = uri;
  method = method.toUpperCase();
  if (method === 'GET') {
    const a = [];
    for (const key in body) {
      a.push(`${key.toString()}=${body[key].toString()}`);
    }
    if (a.length > 0) {
      url += `?${a.join('&')}`;
    }
    body = undefined;
  } else {
    body = body && JSON.stringify(body);
  }

  return fetch(url, {
    method,
    mode: 'cors',
    headers: {
      Accept: 'application/json,text/plain,*/*',
    },
    body,
  }).then((res) => {
    if (res.status === 401) {
      if (sessionStorage.getItem('token')) {
        sessionStorage.setItem('token', '');
        Modal.warning({
          centered: true,
          content: '用户信息已过期，请重新登录！',
          okText: '重新登录',
          keyboard: false,
          onOk: () => {
            if (window.location.href.split('#')[1] === '/homePage') {
              window.location.reload();
            } else {
              window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
            }
            sessionStorage.clear();
            Modal.destroyAll();
          },
          width: 416,
        });
      }
      return Promise.reject('Unauthorized.');
    }
    return res;
    // res.blob().then(blob => {
    //     // const aLink = document.createElement('a');
    //     // document.body.appendChild(aLink);
    //     // aLink.style.display = 'none';
    //     // const objectUrl = window.URL.createObjectURL(blob);
    //     // aLink.href = objectUrl;
    //     // aLink.download = filename;
    //     // aLink.click();
    //     // document.body.removeChild(aLink);
    //     return blob;
    // });
  });
};

export const url_has_key = (props, key) => {
  const { search } = props.history.location;
  if (search.length > 0 && search[0] === '?') {
    const kvList = search.substr(1).split('&');
    for (let i = 0; i < kvList.length; ++i) {
      const kv = kvList[i].split('=');
      if (kv.length === 2 && kv[0] === key) {
        return true;
      }
    }
  }
  return false;
};

export const url_get_value = (props, key, defaultValue) => {
  const { search } = props.history.location;
  if (search.length > 0 && search[0] === '?') {
    const kvList = search.substr(1).split('&');
    for (let i = 0; i < kvList.length; ++i) {
      const kv = kvList[i].split('=');
      if (kv.length === 2 && kv[0] === key) {
        return kv[1];
      }
    }
  }
  return defaultValue;
};

export const url_get_int = (props, key, defaultValue = 0) => {
  const { search } = props.history.location;
  if (search.length > 0 && search[0] === '?') {
    const kvList = search.substr(1).split('&');
    for (let i = 0; i < kvList.length; ++i) {
      const kv = kvList[i].split('=');
      if (kv.length === 2 && kv[0] === key) {
        return parseInt(kv[1]);
      }
    }
  }
  return defaultValue;
};

export const form_url = (uri, obj) => {
  const a = [];
  for (const key in obj) {
    a.push(`${key.toString()}=${obj[key].toString()}`);
  }
  if (a.length > 0) {
    return `${uri}?${a.join('&')}`;
  }
  return uri;
};

export const getUserId = () => {
  // var userId = sessionStorage.getItem('star_user_id');
  const userId = localStorage.getItem('star_user_id');
  if (userId) {
    return userId;
  }
  return 0;
};

