/*
* Author: zhangmeng
* Description: '普通Table组件'
* Date: 2021/10/13
*/

import React, { useState } from 'react';
import { Table } from 'antd';
import PaginationComp from './Pagination/index';
import style from './index.less';

function TableComp(props) {
  const { data, getList } = props;
  const { count, columns, dataSource } = data;
  const [pageNum, setPageNum] = useState(1);

  // pagination-onChange
  const paginationOnChange = (page) => {
    setPageNum(page);
    getList({
      page_number: page,
      page_size: 6,
    });
  };

  return (
    <div className={style.tableBox}>
      <Table
        bordered={false}
        pagination={false}
        columns={columns}
        dataSource={dataSource}
      />
      {
        count > 0 && (
          <PaginationComp
            total={count}
            currPage={pageNum}
            pageSize={6}
            paginationOnChange={paginationOnChange}
          />
        )
      }
    </div>
  );
}

export default TableComp;
