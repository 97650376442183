/*
* Author: zhangmeng
* Description: '个人中心'
* Date: 2022/07/15
*/

import React, { useState, useEffect } from 'react';
import { Col, Row, Avatar, Button, Tabs } from 'antd';
import style from './index.less';
import Record from './Record';
import Collection from './collection';
import { useHistory } from 'react-router-dom';

function PersonalCenter() {
  const history = useHistory();
  const { TabPane } = Tabs;
  const [user, setUser] = useState({ userId: 1, userName: '欢迎', userImg: '' });

  const editPassword = () => {
    history.push({
      pathname: 'login',
      state: {
        formType: 'forget',
      },
    });
  };

  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      const displayName = sessionStorage.getItem('displayName');
      const imgUtl = sessionStorage.getItem('headImg');
      setUser({
        userId: sessionStorage.getItem('userName'),
        userName: displayName,
        userImg: imgUtl,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.all}>
      <div className={style.headCard}>
        <Row justify="space-between" align="middle">
          <Col span={3} style={{ textAlign: 'center' }}>
            <Avatar size={86} src={user.userImg} >
              {user.userName.slice(0, 1)}
            </Avatar>
          </Col>
          <Col span={17}>
            <div className={style.info}>
              账号：
              {user.userId}
            </div>
            <div className={style.info}>
              用户名：
              {user.userName}
            </div>
          </Col>
          <Col span={4}>
            <Button onClick={() => editPassword()}>
              修改密码
            </Button>
          </Col>
        </Row>
      </div>
      <div className={style.bodyCard}>
        <Tabs defaultActiveKey="record">
          <TabPane tab="浏览记录" key="record">
            <Record />
          </TabPane>
          <TabPane tab="我的收藏" key="collection">
            <Collection />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default PersonalCenter;
