import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Descriptions, Tag, Tabs } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import {
  getPolicyDetail,
  getPolicyRelevantList,
  getPolicyUnscrambleRelevantList,
} from '@api/IndustryPolicy';
import styles from './index.less';

const { TabPane } = Tabs;

function IndustryPolicyDetail(props) {
  const { history } = props;
  const policy_id = props?.match?.params?.id;
  const [detailData, setDetailData] = useState({});
  const [relevantList, setRelevantList] = useState([]);
  const [unscrambleRelevantList, setUnscrambleRelevantList] = useState([]);
  useEffect(() => {
    getPolicyDetail({ policy_id }).then(res => {
      const { status, data } = res;
      if (status === '00000') {
        setDetailData(data);
      }
    });
    getPolicyRelevantList({ policy_id }).then(res => {
      const { status, data } = res;
      if (status === '00000') {
        const { data_list } = data;
        if (data_list && Array.isArray(data_list) && data_list.length > 0) {
          setRelevantList(data_list);
        }
      }
    });
    getPolicyUnscrambleRelevantList({ policy_id }).then(res => {
      const { status, data } = res;
      if (status === '00000') {
        const { data_list } = data;
        if (data_list && Array.isArray(data_list) && data_list.length > 0) {
          setUnscrambleRelevantList(data_list);
        }
      }
    });
  }, [policy_id]);
  return (
    <div className={`${styles.all} flex directionColumn alignCenter`}>
      <div className={`${styles.breadcrumb} flex alignCenter spaceBetween`}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/homePage">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{detailData?.title}</Breadcrumb.Item>
        </Breadcrumb>
        <LogoutOutlined
          className="cursorPointer"
          onClick={() => history.go(-1)}
        />
      </div>
      <div
        className={`${styles.detailContent}  flex directionColumn alignCenter`}
      >
        <div className="title">{detailData?.title}</div>
        <div className="ministry mb12">{detailData?.ministry}</div>
        <div className="tags flex flexWrap spaceCenter alignCenter">
          {detailData?.tags?.industry_tag?.map(item => (
            <Tag key={item.tag_code} color="blue" className="mb8">
              {item.tag_name}
            </Tag>
          ))}
        </div>
        <div className="information mb12">
          <Descriptions>
            <Descriptions.Item label="发文单位">
              {detailData?.ministry}
            </Descriptions.Item>
            <Descriptions.Item label="政策级别">
              {detailData?.rank}
            </Descriptions.Item>
            <Descriptions.Item label="政策类型">
              {detailData?.type}
            </Descriptions.Item>
            <Descriptions.Item label="支持对象">
              {detailData?.tags?.support_object?.toString()}
            </Descriptions.Item>
            <Descriptions.Item label="支持行为">
              {detailData?.tags?.support_behavior?.toString()}
            </Descriptions.Item>
            <Descriptions.Item label="支持方式">
              {detailData?.tags?.support_way?.toString()}
            </Descriptions.Item>
            <Descriptions.Item label="适用范围">
              {detailData?.source}
            </Descriptions.Item>
          </Descriptions>
        </div>
        {detailData?.tags?.abstract ? (
          <div className="flex alignStart mb12">
            <Tag color="blue">摘要</Tag>
            <div className="abstract">{detailData?.tags?.abstract}</div>
          </div>
        ) : null}
        {/* {detailData?.content ? (
          <div className="flex alignStart mb12">
            <Tag color="blue">
              <div>政策</div>
              <div>要点</div>
            </Tag>
            <div className="abstract">{detailData?.content}</div>
          </div>
        ) : null} */}
        <div className="link flex">
          <div className="mr10">查看原文链接</div>
          <a href={detailData?.link} target="_blank">
            《{detailData?.title}》
          </a>
        </div>
        <div className="relevant">
          <div className="relevantTitle"></div>
          <Tabs defaultActiveKey="relevant">
            <TabPane tab="相关政策推荐" key="relevant">
              {relevantList.map(item => (
                <div
                  key={item?._id}
                  className="relevantItem flex spaceBetween alignCenter"
                >
                  <Link
                    className="relevantLink"
                    to={`/industryPolicy/${item?._id}`}
                  >
                    {item?.title}
                  </Link>
                  <div>
                    <span className="mr10">{item?.publish_date}</span>
                    <span>{item?.ministry}</span>
                  </div>
                </div>
              ))}
            </TabPane>
            <TabPane tab="政策解读推荐" key="unscrambleRelevantList">
              {unscrambleRelevantList.map(item => (
                <div
                  key={item?._id}
                  className="relevantItem flex spaceBetween alignCenter"
                >
                  <Link
                    className="relevantLink"
                    to={`/industryPolicy/${item?._id}`}
                  >
                    {item?.title}
                  </Link>
                  <div>
                    <span className="mr10">{item?.publish_date}</span>
                    <span>{item?.ministry}</span>
                  </div>
                </div>
              ))}
            </TabPane>
          </Tabs>
          {/* <Divider dashed /> */}
        </div>
      </div>
    </div>
  );
}

export default IndustryPolicyDetail;
