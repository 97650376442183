import { get } from '../utils/request';
import { INDUSTRY, CITY } from '../utils/url';

// 产业树
const getIndustryList = param => get(`${INDUSTRY}/tree`, {
  ...param,
});

// 产业图谱
const getIndustryAtlas = param => get(`${INDUSTRY}/industry_atlas`, {
  ...param,
});

// 城市列表
const getAreaList = param => get(`${CITY}/tree`, {
  ...param,
});

export {
  getIndustryList,
  getIndustryAtlas,
  getAreaList,
};
