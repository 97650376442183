/*
* Author: zhangmeng
* Description: '词云'
* Date: 2022/03/02
*/

import React, { useState, useEffect } from 'react';
import 'echarts-wordcloud';
import ReactEcharts from 'echarts-for-react';
import maskImage from '../../../../assets/img/cloud.png';
import { atlasChartColor } from '../../../../utils/color';

const fontSizes = [28, 26, 18, 18, 16, 16, 14, 14, 12, 12, 10, 10, 10, 10, 10, 10];
function WordCloud(props) {
  const { data } = props;
  const [options, setOptions] = useState({});

  useEffect(() => {
    const maskResource = new Image();
    maskResource.src = maskImage;
    maskResource.setAttribute('crossOrigin', '');
    maskResource.onload = function () {
      const newData = data.map((item, index) => (
        {
          ...item,
          textStyle: {
            fontSize: fontSizes[index > 15 ? Math.floor(Math.random() * 10) + 1 : index],
            color: atlasChartColor[index > 9 ? Math.floor(Math.random() * 9) : index],
          },
        }
      ));
      const option = {
        series: [
          {
            type: 'wordCloud',
            maskImage: maskResource,
            left: 'center',
            top: 'center',
            width: '95%',
            height: '95%',
            right: null,
            bottom: null,
            sizeRange: [10, 28],
            rotationRange: [0, 0],
            rotationStep: 0,
            gridSize: 1,
            drawOutOfBound: false,
            layoutAnimation: true,
            textStyle: {
              fontFamily: 'PingFangSC-Semibold',
              fontWeight: 700,
            },
            emphasis: {
              focus: 'self',
            },
            data: newData,
          },
        ],
      };
      setOptions(option);
    };
  }, [data]);

  return (
    <ReactEcharts
      option={options}
      notMerge={true}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default WordCloud;

WordCloud.defaultProps = {
  data: [
    {
      name: '1',
      value: 10,
    },
    {
      name: '2',
      value: 10,
    },
    {
      name: '3',
      value: 10,
    },
    {
      name: '4',
      value: 10,
    },
    {
      name: '5',
      value: 10,
    },
  ],
};
