/*
 *Author: zhaochenyu
 *Description: 投融资
 *Date: 2021/04/16
*/

import React, { useMemo, useState, useEffect } from 'react';
import { Spin } from 'antd';
import { getInvestmentChart } from '../../../api/atlasDetail';
import AtlasTable from '../Common/AltasTable';
import GetChart from './GetChart';
import style from './index.less';

function Financing(props) {
  const { industry, areaKey } = props;
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (areaKey) {
      setLoading(true);
      getInvestmentChart({
        industry_name: industry,
        area_key: areaKey,
      }).then(res => {
        if (res.status === '00000') {
          if (res.data.length > 0) {
            const tableList = res.data.shift();
            setTableData({
              tableHead: tableList.data.columns,
              tableBody: tableList.data.dataSource,
            });
            setChartData(res.data);
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
  }, [areaKey]);

  const table = useMemo(() => (
    Object.keys(tableData).length > 0 && <AtlasTable
      data={tableData}
      size="middle"
      scrollY={630}
    />
  ), [tableData]);

  const chart = useMemo(() => (
    chartData.map((item, index) => (
      <div className={style.item} key={`${index}finacingChart`}>
        {item && item.data && (<GetChart chartData={item} />)}
      </div>
    ))
  ), [chartData]);
  return (
    <div className={style.all}>
      <Spin tip="搜索中，请稍候....." spinning={loading}>
        <div className={style.content}>
          <div className={style.table}>
            {table}
          </div>
          <div className={style.chart}>
            {chart}
          </div>
          {/* <div className={style.chart1}></div> */}
        </div>
      </Spin>
    </div>
  );
}

export default Financing;
