import React, { useState } from 'react';
import style from './index.less';
import RecordCompany from '../Record/RecoedCompany';
import RecordNews from '../Record/RecoedNews';
import RecordReport from '../Record/RecoedReport';

const tableItemName = [
  {
    name: '企业',
    key: 'company',
  },
  {
    name: '资讯',
    key: 'news',
  },
  {
    name: '研报',
    key: 'report',
  },
];

function Collection() {
  const [menuKey, setMenuKey] = useState('company');

  function handleMenuClick(item) {
    if (item) {
      setMenuKey(item);
    }
  }

  return (
    <div className={style.all}>
      <div className={style.menus}>
        {
          tableItemName.map((item, index) => (
            <div
              className={item.key === menuKey ? style.selectKy : style.menuItem}
              key={`${index}${item.key}menu`}
              onClick={() => handleMenuClick(item.key)}
            >
              {item.name}
            </div>
          ))
        }
      </div>
      <div className={style.content}>
        <div style={{ display: menuKey === 'company' ? 'block' : 'none' }}>
          <RecordCompany />
        </div>
        <div style={{ display: menuKey === 'news' ? 'block' : 'none' }}>
          <RecordNews />
        </div>
        <div style={{ display: menuKey === 'report' ? 'block' : 'none' }}>
          <RecordReport />
        </div>
      </div>
    </div>
  );
}

export default Collection;
