/*
* Author: zhangmeng
* Description: '验证码登录组件'
* Date: 2020/12/03
*/

import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { getLoginNote } from '../../../api/login';
import style from './index.less';

function CodeLogin(props) {
  const { onFinish, useApply, forgetPassword } = props;
  const [btnValue, setBtnValue] = useState('发送验证码');
  const captchaRef = useRef({ timer: null });

  const onFinishFailed = (params) => {
    console.log(params, 'onFinishFailed');
  };

  const getCaptcha = (curValue) => {
    if (curValue === '发送验证码') {
      const phone = document.querySelector('#phone').value;
      const checkPhone = new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/);
      if (!phone) {
        message.error('手机号不能为空');
      } else if (!checkPhone.test(phone)) {
        message.error('手机号码格式错误');
      } else {
        getLoginNote({
          phone_no: phone,
        }).then(res => {
          if (res.status === '00000') {
            let second = 60;
            setBtnValue(`已发送(${second}s)`);
            if (captchaRef.current && captchaRef.current.timer) {
              clearInterval(captchaRef.current.timer);
            }
            captchaRef.current.timer = setInterval(() => {
              if (second > 0) {
                second -= 1;
                setBtnValue(`已发送(${second}s)`);
              } else {
                if (captchaRef.current) {
                  clearInterval(captchaRef.current.timer);
                }
                setBtnValue('发送验证码');
              }
            }, 1000);
          } else {
            message.error(res.message);
          }
        });
      }
    }
  };

  useEffect(() => () => {
    if (captchaRef.current && captchaRef.current.timer) {
      clearInterval(captchaRef.current.timer);
    }
  }, []);

  return (
    <div className={style.all}>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label=""
          name="phone_no"
          className="username"
          rules={[
            {
              pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
              message: '请输入正确的手机号',
            },
            {
              required: true,
              message: '请输入手机号码',
            },
          ]}
        >
          <Input placeholder="请输入手机号码" id="phone" />
        </Form.Item>

        <Form.Item label="" className="captchaBox">
          <Form.Item
            name="sms"
            className="captcha"
            rules={[{ required: true, message: '请输入验证码' }]}
          >
            <Input placeholder="6位短信验证码" maxLength={6} />
          </Form.Item>
          <div className="getCaptcha">
            <Button
              ref={captchaRef}
              type="text"
              onClick={() => getCaptcha(btnValue)}
              className={btnValue !== '发送验证码' ? 'btnClicked' : ''}
              disabled={btnValue !== '发送验证码'}
            >
              {btnValue}
            </Button>
          </div>
        </Form.Item>

        <Form.Item className="submit">
          <Button type="primary" htmlType="submit">
            登录
          </Button>
          <div className={style.bottom}>
            {/* <div
              className={style.register}
              onClick={() => useApply()}
              // style={{ display: 'none' }}
            >
              没有账号？去注册 &gt;
            </div> */}
            <div className={style.register} onClick={() => forgetPassword()}>
              忘记密码
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default CodeLogin;
