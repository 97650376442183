/*
 *Author: zhaochenyu
 *Description: 产业地图接口
 *Date: 2021/12/27
*/

import { post, get, protoGet } from '../utils/request';
import { INTERACTION, INDUSTRYATLAS } from '../utils/url';

const getIndustryMapCityTree = params => post(`${INTERACTION}/industry_atlas_tree`, {
  ...params,
});

const getAllIndustry = () => post(`${INDUSTRYATLAS}/industry_atlas_attention_industry`, {});

// 产业链图-产业链
const getIndustryMapLink = params => get(`${INDUSTRYATLAS}/industry_link`, {
  ...params,
});

// 产业链图-底部三图表
const getBottomChats = params => get(`${INDUSTRYATLAS}/industrial_chain_figure`, {
  ...params,
});

// 产业链图-企业列表
const getIndustryMapLinkCompnayList = params => get(`${INDUSTRYATLAS}/industry_link_company_list`, {
  ...params,
});

// 情报推送-新闻列表
const getMapNewsListData = params => get(`${INDUSTRYATLAS}/news_list`, {
  ...params,
});

// 情报推送-政策列表
const getMapPolicyListData = params => get(`${INDUSTRYATLAS}/policy_list`, {
  ...params,
});

// 情报推送-新增企业
const getMapNewsCompnayCharts = params => get(`${INDUSTRYATLAS}/new_company_chart`, {
  ...params,
});

// 情报推送-新增专利、投融资、标准
const getMapNewsBottomChart = params => get(`${INDUSTRYATLAS}/new_patent_investment_standard`, {
  ...params,
});

// 双链融合-创新链关系图
const getMapInnvoteChainChart = params => get(`${INDUSTRYATLAS}/innovation_chain_relationship_diagram`, {
  ...params,
});

// 双链融合-企业列表
const getMapInnvoteCompnayList = params => get(`${INDUSTRYATLAS}/company_list`, {
  ...params,
});

// 双链融合-企业列表数量
const getMapInnvoteCompnayCount = params => get(`${INDUSTRYATLAS}/company_number`, {
  ...params,
});

// 双链融合-省内创新资源
const getMapProvinceInnvote = params => get(`${INDUSTRYATLAS}/recommended_innovation_resources`, {
  ...params,
});

// 双链融合-省内创新资源总数
const getMapProvinceCount = params => get(`${INDUSTRYATLAS}/desc_of_recommended_innovation_resources`, {
  ...params,
});

// 双链融合-推荐专利
const getMapRecommandPatent = params => get(`${INDUSTRYATLAS}/the_patent_is_recommended`, {
  ...params,
});

// 产业运行-产业链环节穿透图
const getIndustryLinkPierceGraph = params => get(`${INDUSTRYATLAS}/industry_link_through_the_figure`, {
  ...params,
});

// 产业运行-龙头企业列表
const getLeadingEnterprise = params => get(`${INDUSTRYATLAS}/list_of_leading_enterprises`, {
  ...params,
});

// 产业运行-发展潜力榜
const getDevPotential = params => get(`${INDUSTRYATLAS}/developing_business_lists`, {
  ...params,
});

// 产业运行-融资引力榜
const getFinancingAppeal = params => get(`${INDUSTRYATLAS}/list_of_attracting_financing`, {
  ...params,
});

// 产业运行-创新能力榜
const getCreation = params => get(`${INDUSTRYATLAS}/innovation_ability_list`, {
  ...params,
});

// 精准招商-技术实力
const getTechnicalStrengthList = params => get(`${INDUSTRYATLAS}/technical_strength_list`, {
  ...params,
});

// 精准招商-加强布局
const getStrengthenLayout = params => get(`${INDUSTRYATLAS}/strengthen_layout`, {
  ...params,
});

// 精准招商-扩张意图
const getExpansionOfIntention = params => get(`${INDUSTRYATLAS}/expansion_of_intention`, {
  ...params,
});

// 精准招商-链主企业
const getMainChainEnterprise = params => get(`${INDUSTRYATLAS}/main_chain_enterprise`, {
  ...params,
});

// 精准招商-龙头企业
const getLeadingEnterprises = params => get(`${INDUSTRYATLAS}/leading_enterprises`, {
  ...params,
});

// 精准招商-省级龙头企业
const getProvincialLeadingEnterprise = params => get(`${INDUSTRYATLAS}/provincial_leading_enterprise`, {
  ...params,
});

// 精准招商-项目挖掘
const getMiningProject = params => get(`${INDUSTRYATLAS}/mining_project`, {
  ...params,
});

// 产业运行-企业数量占比
const getCompanyIndustryDistribution = params => get(`${INDUSTRYATLAS}/company_industry_distribution_diagram_fenxi`, {
  ...params,
});

// 产业运行-专利数量占比
const getParkPatentDistribution = params => get(`${INDUSTRYATLAS}/park_patent_distribution_diagram_fenxi`, {
  ...params,
});

// 产业运行-专利数量占比
const getpPrkInstitutionDistribution = params => get(`${INDUSTRYATLAS}/park_institution_distribution_diagram_fenxi`, {
  ...params,
});

// 产业运行-企业名单
const getCompanyListInParFenxi = params => get(`${INDUSTRYATLAS}/company_list_in_park_fenxi`, {
  ...params,
});
// 画像诊断-龙头企业描述
const getDescOfLeadingEnterprises = params => get(`${INDUSTRYATLAS}/desc_of_leading_enterprises`, {
  ...params,
});

// 省边界
const getPRegionBorder = params => get(`${INDUSTRYATLAS}/find_region_border`, {
  ...params,
});

// 产业散点
const getDotCluster = params => protoGet(`${INDUSTRYATLAS}/dot_cluster_map_in_park_fenxi`, {
  ...params,
});

export {
  getBottomChats,
  getIndustryMapCityTree,
  getAllIndustry,
  getIndustryMapLink,
  getIndustryMapLinkCompnayList,
  getMapNewsListData,
  getMapPolicyListData,
  getMapNewsCompnayCharts,
  getMapNewsBottomChart,
  getMapInnvoteChainChart,
  getMapInnvoteCompnayList,
  getMapInnvoteCompnayCount,
  getMapProvinceInnvote,
  getMapProvinceCount,
  getMapRecommandPatent,
  getIndustryLinkPierceGraph,
  getLeadingEnterprise,
  getDevPotential,
  getFinancingAppeal,
  getCreation,
  getTechnicalStrengthList,
  getStrengthenLayout,
  getExpansionOfIntention,
  getMainChainEnterprise,
  getLeadingEnterprises,
  getProvincialLeadingEnterprise,
  getMiningProject,
  getCompanyIndustryDistribution,
  getParkPatentDistribution,
  getpPrkInstitutionDistribution,
  getCompanyListInParFenxi,
  getPRegionBorder,
  getDotCluster,
  getDescOfLeadingEnterprises,
};
