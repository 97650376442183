/*
 *Author: zhaochenyu
 *Description: 精准招商报告
 *Date: 2022/02/23
*/

import React, { useMemo } from 'react';
import ReportChart from '../ReportChart';
import FrontCover from './FrontCover';
import BackCover from './BackCover';
import style from '../index.module.less';

function IndustryPortraitReport(props) {
  const { chartConditions, chartTitlesRef, refList, chartTitles, pushToAchor,
    getChartFinishedPercent, buyStatus } = props;
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const urlReportMsg = JSON.parse(window.decodeURIComponent(window.atob(params.get('msg') || '')) || '{}');
  return (
    <>
      {buyStatus ? (
        <>
          <div className={style.first}>
            <FrontCover industry={urlReportMsg.industry} />
          </div>

          <div className={style.page}>
            <div className={style.catalogue}>目录</div>
            <div className={style.firstCata} onClick={() => pushToAchor('一、产业图谱')}>
              <div className={style.firstTitle}>一、产业图谱</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('二、全量企业')}>
              <div className={style.firstTitle}>二、全量企业</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）企业数量')}>
              <div className={style.secondTitle}>（一）企业数量</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）结构分析')}>
              <div className={style.secondTitle}>（二）结构分析</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>3</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）产地地图')}>
              <div className={style.secondTitle}>（三）产业地图</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>4</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）区域分布')}>
              <div className={style.secondTitle}>（四）区域分布</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（五）注册资金')}>
              <div className={style.secondTitle}>（五）注册资金</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>6</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（六）成立时间')}>
              <div className={style.secondTitle}>（六）成立时间</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>7</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('三、重点榜单')}>
              <div className={style.firstTitle}>三、重点榜单</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>8</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）上市企业TOP30')}>
              <div className={style.secondTitle}>（一）上市企业TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>8</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）注册资金TOP30')}>
              <div className={style.secondTitle}>（二）注册资金TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>9</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）专利排名TOP30')}>
              <div className={style.secondTitle}>（三）专利排名TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>10</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）新增融资TOP30')}>
              <div className={style.secondTitle}>（四）新增融资TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>11</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（五）京津冀TOP30')}>
              <div className={style.secondTitle}>（五）京津冀TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>12</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（六）长三角TOP30')}>
              <div className={style.secondTitle}>（六）长三角TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>13</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（七）珠三角TOP30')}>
              <div className={style.secondTitle}>（七）珠三角TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>14</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('四、上奇推荐')}>
              <div className={style.firstTitle}>四、上奇推荐</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>15</div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id="一、产业图谱">一、产业图谱</div>
            {/* 产业产业链图 */}
            {chartConditions.length > 0 && (
            <ReportChart
              chartMsg={chartConditions[0]}
              chartIndex={0}
              ref={chartTitlesRef.current[0]}
              chartHeight={720}
              refList={refList}
              title={`图1 ${chartTitles[0] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.primaryTitle} id="二、全量企业">二、全量企业</div>
            <div className={style.secondaryTitle} id="（一）企业数量">（一）企业数量</div>
            {/* 存量企业数量变化图 */}
            {chartConditions.length > 1 && (
            <ReportChart
              chartMsg={chartConditions[1]}
              chartIndex={1}
              ref={chartTitlesRef.current[1]}
              chartHeight={400}
              refList={refList}
              title={`图2 ${chartTitles[1] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）结构分析">（二）结构分析</div>
            {/* 产业金字塔 */}
            {chartConditions.length > 2 && (
            <ReportChart
              chartMsg={chartConditions[2]}
              chartIndex={2}
              ref={chartTitlesRef.current[2]}
              chartHeight={450}
              refList={refList}
              title={`图3 ${chartTitles[2] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>上市企业：上交所、深交所、北交所上市的企业。</div>
                <div>高成长企业：近三年获得过融资的企业。</div>
                <div>高技术企业：拥有“国家高新技术企业”称号的企业。</div>
              </div>
            </div>
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（三）产业地图">（三）产业地图</div>
            {/* 企业地理空间分布 */}
            {chartConditions.length > 3 && (
            <ReportChart
              chartMsg={chartConditions[3]}
              chartIndex={3}
              ref={chartTitlesRef.current[3]}
              chartHeight={660}
              refList={refList}
              title={`图4 ${chartTitles[3] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>上市企业：上交所、深交所、北交所上市的企业。</div>
                <div>高成长企业：近三年获得过融资的企业。</div>
                <div>高技术企业：拥有“国家高新技术企业”称号的企业。</div>
              </div>
            </div>
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（四）区域分布">（四）区域分布</div>
            {/* 企业区域分布情况图 */}
            {chartConditions.length > 4 && (
            <ReportChart
              chartMsg={chartConditions[4]}
              chartIndex={4}
              ref={chartTitlesRef.current[4]}
              chartHeight={660}
              refList={refList}
              title={`图5 ${chartTitles[4] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（五）注册资金">（五）注册资金</div>
            {/* 企业注册资金分布 */}
            {chartConditions.length > 5 && (
            <ReportChart
              chartMsg={chartConditions[5]}
              chartIndex={5}
              ref={chartTitlesRef.current[5]}
              chartHeight={450}
              refList={refList}
              title={`图6 ${chartTitles[5] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（六）成立时间">（六）成立时间</div>
            {/* 企业成立时间结构图 */}
            {chartConditions.length > 6 && (
            <ReportChart
              chartMsg={chartConditions[6]}
              chartIndex={6}
              ref={chartTitlesRef.current[6]}
              chartHeight={450}
              refList={refList}
              title={`图7 ${chartTitles[6] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.primaryTitle} id="三、重点榜单">三、重点榜单</div>
            <div className={style.secondaryTitle} id="（一）上市企业TOP30">（一）上市企业TOP30</div>
            {/* 上市企业TOP30 */}
            {chartConditions.length > 7 && (
            <ReportChart
              chartMsg={chartConditions[7]}
              chartIndex={7}
              ref={chartTitlesRef.current[7]}
              chartHeight={760}
              refList={refList}
              title={`表1 ${chartTitles[7] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>注：营业收入取自为最新年报数据。</div>
              </div>
            </div>
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）注册资金TOP30">（二）注册资金TOP30</div>
            {/* 注册资金TOP30 */}
            {chartConditions.length > 8 && (
            <ReportChart
              chartMsg={chartConditions[8]}
              chartIndex={8}
              ref={chartTitlesRef.current[8]}
              chartHeight={840}
              refList={refList}
              title={`表2 ${chartTitles[8] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（三）专利排名TOP30">（三）专利排名TOP30</div>
            {/* 专利排名TOP30 */}
            {chartConditions.length > 9 && (
            <ReportChart
              chartMsg={chartConditions[9]}
              chartIndex={9}
              ref={chartTitlesRef.current[9]}
              chartHeight={760}
              refList={refList}
              title={`表3 ${chartTitles[9] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（四）新增融资TOP30">（四）新增融资TOP30</div>
            {/* 新增融资TOP30 */}
            {chartConditions.length > 10 && (
            <ReportChart
              chartMsg={chartConditions[10]}
              chartIndex={10}
              ref={chartTitlesRef.current[10]}
              chartHeight={760}
              refList={refList}
              title={`表4 ${chartTitles[10] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（五）京津冀TOP30">（五）京津冀TOP30</div>
            {/* 京津冀TOP30 */}
            {chartConditions.length > 11 && (
            <ReportChart
              chartMsg={chartConditions[11]}
              chartIndex={11}
              ref={chartTitlesRef.current[11]}
              chartHeight={760}
              refList={refList}
              title={`表5 ${chartTitles[11] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（六）长三角TOP30">（六）长三角TOP30</div>
            {/* 长三角TOP30 */}
            {chartConditions.length > 12 && (
            <ReportChart
              chartMsg={chartConditions[12]}
              chartIndex={12}
              ref={chartTitlesRef.current[12]}
              chartHeight={760}
              refList={refList}
              title={`表6 ${chartTitles[12] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（七）珠三角TOP30">（七）珠三角TOP30</div>
            {/* 珠三角TOP30 */}
            {chartConditions.length > 13 && (
            <ReportChart
              chartMsg={chartConditions[13]}
              chartIndex={13}
              ref={chartTitlesRef.current[13]}
              chartHeight={760}
              refList={refList}
              title={`表7 ${chartTitles[13] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id="四、上奇推荐">四、上奇推荐</div>
            {/* 重点推荐 */}
            {chartConditions.length > 14 && (
            <ReportChart
              chartMsg={chartConditions[14]}
              chartIndex={14}
              ref={chartTitlesRef.current[14]}
              chartHeight={760}
              refList={refList}
              title={`表8 ${chartTitles[14] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.last}>
            <BackCover />
          </div>
        </>
      ) : (
        <>
          <div className={style.first}>
            <FrontCover industry={urlReportMsg.industry} />
          </div>

          <div className={style.page}>
            <div className={style.catalogue}>目录</div>
            <div className={style.firstCata} onClick={() => pushToAchor('一、产业图谱')}>
              <div className={style.firstTitle}>一、产业图谱</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('二、全量企业')}>
              <div className={style.firstTitle}>二、全量企业</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）企业数量')}>
              <div className={style.secondTitle}>（一）企业数量</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）结构分析')}>
              <div className={style.secondTitle}>（二）结构分析</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>3</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）产地地图')}>
              <div className={style.secondTitle}>（三）产业地图</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>4</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）区域分布')}>
              <div className={style.secondTitle}>（四）区域分布</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（五）注册资金')}>
              <div className={style.secondTitle}>（五）注册资金</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>6</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（六）成立时间')}>
              <div className={style.secondTitle}>（六）成立时间</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>7</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('三、重点榜单')}>
              <div className={style.firstTitle}>三、重点榜单</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>8</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）上市企业TOP30')}>
              <div className={style.secondTitle}>（一）上市企业TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>8</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）注册资金TOP30')}>
              <div className={style.secondTitle}>（二）注册资金TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>9</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）专利排名TOP30')}>
              <div className={style.secondTitle}>（三）专利排名TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>10</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）新增融资TOP30')}>
              <div className={style.secondTitle}>（四）新增融资TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>11</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（五）京津冀TOP30')}>
              <div className={style.secondTitle}>（五）京津冀TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>12</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（六）长三角TOP30')}>
              <div className={style.secondTitle}>（六）长三角TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>13</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（七）珠三角TOP30')}>
              <div className={style.secondTitle}>（七）珠三角TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>14</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('四、上奇推荐')}>
              <div className={style.firstTitle}>四、上奇推荐</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>15</div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default IndustryPortraitReport;
