/*
 *Author: zhaochenyu
 *Description: 忘记密码
 *Date: 2021/04/27
*/

import React, { useState, useCallback } from 'react';
import { Input, Form, Button, message } from 'antd';
import { userNote, changePassword } from '../../../api/login';
import CountDown from '../UseApply/CountDown';
import style from './index.less';

function ForgetPassword({editCallback}) {
  const [countDown, setcountDown] = useState(false);
  const phone = sessionStorage.getItem('phone');

  const getAuthCode = () => {
    if (countDown) return;
    const checkPhone = new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/);
    if (!phone) {
      message.error('手机号不能为空');
    } else if (!checkPhone.test(phone)) {
      message.error('手机号码格式错误');
    } else {
      userNote({
        phone_no: phone,
      }).then(res => {
        if (res.status === '00000') {
          setcountDown(true);
        } else {
          message.error(res.message);
        }
      });
    }
  };

  const onFinish = useCallback(
    (value) => {
      changePassword(value).then(res => {
        if (res.status === '00000') {
          message.success('修改密码成功！');
          editCallback();
        } else {
          message.error(res.message);
        }
      });
    },
    [],
  );

  return (
    <div className={style.all}>
      <div className={style.changePassword}>
        <Form
          name="basic"
          onFinish={onFinish}
          initialValues={{
            phone_no: phone,
          }}
        >
          <Form.Item
            label=""
            name="phone_no"
          >
            <Input placeholder="请输入手机号码" disabled />
          </Form.Item>
          <Form.Item
            label=""
            name="new_password"
            rules={[
              {
                pattern: /^.{6,18}$/,
                message: '密码长度为6~18位！',
              },
              {
                required: true,
                message: '请输入6-18位新密码',
              },
            ]}
          >
            <Input.Password
              autoComplete="new-password"
              placeholder="请输入6-18位新密码"
            />
          </Form.Item>
          <Form.Item
            label=""
            name="confirm_password"
            rules={[
              {
                required: true,
                message: '请确认6-18位新密码',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject('两次密码不一致！');
                },
              }),
            ]}
          >
            <Input.Password
              autoComplete="new-password"
              placeholder="请输入6-18位新密码"
            />
          </Form.Item>
          <Form.Item
            name="captcha"
            rules={[
              { required: true, message: '请输入验证码' },
            ]}
          >
            <Input placeholder="请输入验证码" />
          </Form.Item>
          <div
            className={`${style.getAuthCode} ${countDown ? style.counting : undefined}`}
            onClick={() => getAuthCode(phone)}
          >
            {countDown ? <CountDown changeSend={setcountDown} /> : '发送验证码'}
          </div>
          <Form.Item className="submit">
            <Button type="primary" htmlType="submit">
              确认修改
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default ForgetPassword;
