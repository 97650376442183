/*
 *Author: zhangmeng
 *Description: 公司头部标签
 *Date: 2022/07/13
*/

import React, { useCallback, useState } from 'react';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { companyTabsColorMap } from '../../../../utils';
import style from './index.less';

function ComponayTabs(props) {
  const [height, setHeight] = useState(20);
  const [upDown, setUpDown] = useState(false);
  const { data } = props;

  const tabsRef = useCallback(node => {
    if (node) {
      setHeight(node.scrollHeight);
    }
  }, []);

  const upDownClick = (type) => {
    if (type === 'up') {
      setUpDown(false);
    } else {
      setUpDown(true);
    }
  };
  return (
    <div className={style.all}>
      <div className={style.type}>{data.type || ''}</div>
      <div
        className={style.tabsBox}
        ref={tabsRef}
        style={{
          height: upDown ? 'auto' : '26px',
        }}
      >
        {
          (data.tabs || []).map((item, index) => (
            <div
              className={style.tab}
              key={`${index}tab11`}
              style={{
                color: companyTabsColorMap[data.type].color,
                background: companyTabsColorMap[data.type].background,
                border: companyTabsColorMap[data.type].border,
                // boxShadow: companyTabsColorMap[data.type].boxShadow,
              }}
            >
              {item}
            </div>
          ))
        }
      </div>
      <div
        className={style.upDown}
        style={{ display: height > 32 ? '' : 'none' }}
      >
        {upDown && height > 56 ? (
          <div className={style.icon} onClick={() => upDownClick('up')}>
            <UpCircleOutlined />
          </div>
        ) : (
          <div className={style.icon} onClick={() => upDownClick('down')}>
            <DownCircleOutlined />
          </div>
        )}
      </div>
    </div>
  );
}

export default ComponayTabs;
