/*
 *Author: zhaochenyu
 *Description: 基本面扫描-底部图表
 *Date: 2021/04/20
*/

import React from 'react';
import HistogramChart from '../../../Model/HistogramChart';
import SingleAnnulusMap from '../../../Model/SingleAnnulusMap';
import { atlasChartColor, ringPieColor } from '../../../../utils/color';
import style from './index.less';

function GetBottomChart(props) {
  const { chartData } = props;
  const { title, type, data } = chartData;
  const getChart = (chartType) => {
    let res = null;
    switch (chartType) {
      case 'heap_histogram_chart':
        res = <HistogramChart
          title={title}
          dataSource={data}
          color={atlasChartColor}
          type={chartType}
        />;
        break;
      case 'heap_bar_chart':
        res = <HistogramChart
          title={title}
          dataSource={data}
          color={atlasChartColor}
          type={chartType}
          bottom={50}
        />;
        break;
      case 'histogram_chart':
        res = <HistogramChart
          title={title}
          dataSource={data}
          color="#5CA3E5"
          type={chartType}
        />;
        break;
      case 'single_annulus_map':
        res = <SingleAnnulusMap
          dataSource={data}
          title={title}
          color={data.length > 5 ? atlasChartColor : ringPieColor}
        />;
        break;
      default: break;
    }
    return res;
  };
  return (
    <div className={style.all}>
      <div className={style.title}>{title}</div>
      <div className={style.chart}>
        {getChart(type)}
      </div>
    </div>
  );
}

export default GetBottomChart;
