import { get } from '../utils/request';
import { POLICY } from '../utils/url';

// 获取产业政策信息
const getPolicyIndustry = param => get(`${POLICY}/industry`, {
  ...param,
});

// 获取政策详情
const getPolicyDetail = param => get(`${POLICY}/detail`, {
  ...param,
});

export {
  getPolicyIndustry,
  getPolicyDetail,
};
