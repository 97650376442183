/*
 *Author: zhaochenyu
 *Description: 图形颜色取值
 *Date: 2020/12/16
*/

const singleBarColor = ['#67A181', '#E9C582', '#4E7293', '#DB9E9E', '#BCB3DF'];
const multipleBarColor = {
  3: ['#41594C', '#67A181', '#8DB59F'],
  4: ['#41594C', '#4E8466', '#67A181', '#8DB59F'],
  5: ['#41594C', '#4E8466', '#67A181', '#73B28F', '#8DB59F'],
  10: ['#41594C', '#4E8466', '#67A181', '#73B28F', '#8DB59F', '#B5D8C0', '#C2D8B5', '#E1DE9F', '#F9F9A3', '#FFEDB0'],
};
const lineColor = ['#FDAD61', '#7FD1A4', '#E9C582', '#DB9E9E', '#4E7293', '#BCB3DF', '#E1DE9F', '#E39D75', '#839EDA', '#C54646', '#4E8466'];
const pieColors = [
  ['#67A181', '#4E7293', '#E9C582', '#DB9E9E', '#BCB3DF', '#839EDA', '#CE7070', '#C54646', '#E1DE9F', '#41594C'],
  ['#41594C', '#839EDA', '#E1DE9F', '#DB9E9E', '#BCB3DF', '#E9C582', '#CE7070', '#C54646', '#67A181', '#4E7293']
];
const barAndLineColor =['#E9C582', '#A4C3B2', '#DB9E9E', '#4E7293', '#A4C3B2'];
const mapColor =['rgba(0,141,199,0.2)', 'rgba(0,141,199,0.3)', 'rgba(0,141,199,0.4)', 'rgba(0,141,199,0.5)', 'rgba(0,141,199,0.6)', 'rgba(0,141,199,0.7)', 'rgba(0,141,199,0.8)', '#008DC7'];
const mapCircleColor =[
  {
    circleColor: 'rgba(233, 197, 130, 0.4)',
    fontColor: '#DE9815',
  },
  {
    circleColor: 'rgba(131, 158, 218, 0.4)',
    fontColor: '#556DA1'
  },
  {
    circleColor: 'rgba(219, 158, 158, 0.4)',
    fontColor: '#9B6464',
  },
  {
    circleColor: 'rgba(188, 179, 223, 0.4)',
    fontColor: '#8072B2',
  }];
const monochrome =  ['#8DB59F', '#0D3B66', '#A9BDD5', '#E9C582', '#DB9E9E', '#B2A8D6'];
const heatMapCOlor = ['#67A181', '#73B28F', '#94C5AA', '#B5D8C0', '#C2D8B5', '#DBE2B7', '#E1DE9F', '#F9F9A3', '#FFEDB0', '#FFDA90', '#E9C582', '#E8AE76', '#E39D75', '#D3866E', '#CE7070', '#C54646'];
const wordCloudColor = ['#A4C3B2', '#E9C582', '#E39D75', '#DB9E9E', '#839EDA', '#BCB3DF', '#E1DE9F', '#8FD9B1', '#CCE3DE', '#EAD1C1'];
const wordGraphColor = {
  innerCircle: wordCloudColor,
  outerBorder: ['#DAEAE6', '#FFE4B2', '#FFCEB2', '#FFC7C7', '#C2D5FF', '#E4DCFF', '#F6F5CC' , '#B6EFD0', '#E7FCF7', '#FFF5E1'],
  outerShadow: ['#6AB18A', '#E9C582', '#BC815F', '#B88282', '#839EDA', '#9B8FC7', '#ccc872', '#7FD4A5', '#9ED6CA', '#CEB6A5'],
};
const trendListColor = {
  backgroundColor: [['#5A7165', '#3E5C76'], ['#FFF5E1', '#D1E3D9'], ['#FFF8F8', '#E8F2FD']],
  dashColor: ['#698376', '#DAEAE6', '#DAEAE6'],
  titleColor: ['#FFF', '#67A181', '#7C6C6C'],
  contentColor: ['#B7DBC6', '#868686', '#868686'],
};
const industryNodeColor = {
  markerColor: ['#BCB3DF', '#CCE3DE', '#E1DE9F', '#8FD9B1', '#E39D75', '#DB9E9E', '#E9C582', '#4E7293', '#839EDA', '#EAD1C1'],
  markerBorderColor: ['#E4DCFF', '#E7FCF7', '#F6F5CC', '#B6EFD0', '#FFCEB2', '#FFC7C7', '#FFE4B2', '#8BB1D4', '#C2D5FF', '#FFF5E1'],
  shadowColor: ['#9B8FC7', '#9ED6CA', '#CCC872', '#7FD4A5', '#BC815F', '#B88282', '#E9C582', '#4E7293', '#839EDA', '#CEB6A5'],
};
const waterFallColor = ['#DAEAE6', '#BBDDD6', '#A4C3B2', '#8DB59F', '#6BA887', '#55A278', '#268852', '#116D3B', '#217F7D', '#759D9C', '#90BFBE',
  '#BFE2E1', '#92DEDC', '#48D1DD', '#3ED2DF', '#25CCDB', '#26CDDB', '#17C0CF', '#16B3C1', '#13A7B4', '#0E97A3', '#0C8893', '#087882', '#08656D', '#065158', '#043E43'];
const areaLineColor = ['#8DB59F', '#4E7293', '#A9BDD5', '#DB9E9E', '#E9C582', '#B2A8D6'];

const areaPieColor = ['#C5D5EA', '#839EDA', '#5E8DB8', '#35628A', '#0D3B66', '#042F58', '#B5F2E5', '#57BDA7', '#93D4ED',
'#A4C3B2', '#6B9080', '#5B7868', '#7EAA10', '#D2D278', '#30A170', '#1E6446', '#CB997E', '#FFC49B', '#EE964B', '#E0C7C7', '#CE7070 ', '#BCB3DF', '#9070AA'];

const atlasChartColor = ['#006BA0', '#5A96CD', '#AAD9BF', '#C5D5EA', '#CCE3DE', '#EAD1C1', '#E9C582', '#CBC7DB', '#EE964B'];
const ringPieColor = ['#5CA3E5', '#A4C3B2', '#CBC7DB', '#EAD1C1', '#E9C582'];
const atlasCountColor = [
  ['#FFFFFF', '#FFEDC4'],
  ['#E4ECF6', '#C5D5EA'],
  ['#E4ECF6', '#9DC8E3'],
  ['#DDFEFF', '#8EEEE6'],
  ['#E6F3FC', '#C9EDF8'],
  ['#E4ECF6', '#C5D5EA'],
  ['#D1E3D9', '#A4C3B2'],
];

const standardColorMap = {
  当前标准: '#3E5C76',
  国际标准: '#EAD1C1',
  国家标准: '#F4D394',
  行业标准: '#A4C3B2',
  地方标准: '#A9BDD5',
  团队标准: '#D3C9FA',
};

const companyTabsColorMap = {
  资质认证: {
    borderColor: '#A9BDD5',
    background: '#D4E0EE',
    color: '#0D3B66',
  },
  获奖情况: {
    borderColor: '#D7AFAF',
    background: '#ECDCDC',
    color: '#562020',
  },
  试点示范: {
    borderColor: '#AEA5CA',
    background: '#DBD8E8',
    color: '#40395A',
  },
  市场准入: {
    borderColor: '#A5A58D',
    background: '#E0E0D5',
    color: '#5B5B48',
  },
  产业类型: {
    borderColor: '#008CD6',
    background: 'rgba(0,140,214,0.1)',
    color: '#008CD6',
  },
  业务领域: {
    borderColor: '#EADBBB',
    background: '#FFF5E1',
    color: '#4D3B17',
  },
  产品信息: {
    borderColor: '#D7BAA7',
    background: '#EDDDD3',
    color: '#562020',
  },
  融资阶段: {
    borderColor: '#B0CDC7',
    background: '#DFEFEC',
    color: '#455B50',
  },
  其他标签: {
    borderColor: '#B8C7B2',
    background: '#DAE4D6',
    color: '#2C3B3C',
  },
  other: {
    borderColor: '#52C41A',
    background: 'rgba(82,196,26,0.1)',
    color: '#52C41A',
  },
  其他: {
    borderColor: '#A9BDD5',
    background: '#D4E0EE',
    color: '#0D3B66',
  },
  专利其他标签: {
    borderColor: '#A9BDD5',
    background: '#D4E0EE',
    color: '#0D3B66',
  },
};
const barChartColor = ['#0D3B66', '#4E7293', '#C5D5EA', '#A4C3B2', '#CCE3DE', '#EAD1C1', '#CBC7DB', '#E9C582', '#EE964B', '#D5671D', '#C54646', '#CB997E', '#E0C7C7', '#CCCCBA', '#D8E1E9', '#BCB3DF', '#CE7070', '#DBACBD', '#B56D2F', '#D5671D', '#FFC49B', '#D2D278', '#B4B471', '#30A170', '#5B7868', '#57BDA7', '#B5F2E5', '#93D4ED', '#839EDA', '#5540A9', '#9070AA', '#C474A3', '#E58080', '#8C6C6C', '#C8B0B0', '#E7E7E7', '#F2E5D1', '#D6D3B4', '#B4D2D6', '#B5BFBB'];

export {
  monochrome,
  singleBarColor,
  multipleBarColor,
  lineColor,
  pieColors,
  barAndLineColor,
  mapColor,
  mapCircleColor,
  heatMapCOlor,
  wordCloudColor,
  wordGraphColor,
  trendListColor,
  areaPieColor,
  industryNodeColor,
  waterFallColor,
  areaLineColor,
  atlasChartColor,
  ringPieColor,
  atlasCountColor,
  standardColorMap,
  companyTabsColorMap,
  barChartColor,
};
