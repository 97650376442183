/*
* Author: zhangmeng
* Description: '企业信息'
* Date: 2022/07/13
*/

import React, { useState } from 'react';
import { Select, Input, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import CompanyTab from './components/CompanyTab';
// import companyIcon from '../../images/companyIcon.png';
import style from './index.less';
// import { companyTabsColorMap } from '../../utils';

const { Option } = Select;
const { TextArea } = Input;

function Top(props) {
  const { data } = props;
  const { companyName, address, rate, score, tags, client_tag: clientTag } = data;
  const [showInput, setShowInput] = useState(false);
  const [addressInput, setAddressInput] = useState('');
  // const [tabs, setTabs] = useState(['+标签']);

  const provinceChange = p => {
    console.log(p, '12312');
  };

  const cityChange = p => {
    console.log(p, '12312');
  };

  const areaChange = p => {
    console.log(p, '12312');
  };
  function unique(arr) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr.length; i++) {
      // eslint-disable-next-line no-plusplus
      for (let j = i + 1; j < arr.length; j++) {
        if (arr[i].type === arr[j].type) {
          arr.splice(j, 1);
          // eslint-disable-next-line no-plusplus
          j--;
        }
      }
    }
    return arr;
  }
  const funAllTags = () => {
    const alltagsType = (tags && [...(tags || []), ...(clientTag || [])]) || [];
    const alltags = [];
    alltagsType.forEach((item, index) => {
      alltagsType.forEach((e, i) => {
        if (i > index && item.type === e.type) {
          alltags.push({
            type: item.type,
            tabs: [...item.tabs, ...e.tabs],
          });
        }
      });
    });
    return unique([...alltags, ...alltagsType]) || [];
  };
  return (
    <div className={style.all}>
      <div
        className={style.header}
      >
        <div className={style.iconTitle}>
          {/* <img src={companyIcon} alt="" /> */}
          <span className={style.title}>{companyName}</span>
        </div>
      </div>
      <div className={style.info}>
        <div className={style.left}>
          <div className={style.row}>
            <div className={style.label}>地&emsp; &emsp; 址</div>
            <div className={style.value}>{address}</div>
            <div className={style.edit} style={{ display: 'none' }}>
              <EditOutlined
                onClick={() => {
                  setShowInput(true);
                }}
              />
            </div>
          </div>
          <div className={style.tags}>
            {(funAllTags() || []).map((item, index) => (
              item.tabs && item.tabs.length > 0 && (
                <div className={style.companyTabs} key={`${index}tab`}>
                  <CompanyTab data={item} />
                </div>
              )
            ))}
            {/* {(clientTag || []).map((item, index) => (
              item.tabs && item.tabs.length > 0 && (
                <div className={style.companyTabs} key={`${index}tab`}>
                  <CompanyTab data={item} colors={false} />
                </div>
              )
            ))} */}
            {/* <div className={style.type} onClick={() => setTabs(['111'])}>标签标注</div> */}
            {/* <div */}
            {/*  className={style.tabsBox} */}
            {/* > */}
            {/*  { */}
            {/*    (tabs || []).map((item, index) => ( */}
            {/*      <div */}
            {/*        className={style.tab} */}
            {/*        key={`${index}tab11`} */}
            {/*        style={{ */}
            {/*          color: companyTabsColorMap['标签标注'].color, */}
            {/*          background: companyTabsColorMap['标签标注'].background, */}
            {/*          border: companyTabsColorMap['标签标注'].border, */}
            {/*        }} */}
            {/*      > */}
            {/*        {item} */}
            {/*      </div> */}
            {/*    )) */}
            {/*  } */}
            {/* </div> */}
            {/* <CompanyTab data={{ type: '标签标注', tabs: tabs }} /> */}
          </div>
        </div>
      </div>
      {
        showInput && (
          <div className={style.addressBox}>
            <div className={style.titleBox}>
              <div className={style.line} />
              <div className={style.title}>地址修改</div>
            </div>
            <div className={style.selectBox}>
              <div className={style.province}>
                <div>所&nbsp; 在 &nbsp;省</div>
                <Select size="small" style={{ width: 150 }} onChange={provinceChange}>
                  <Option value="jack">Jack</Option>
                </Select>
              </div>
              <div className={style.city}>
                <div>所&nbsp; 在 &nbsp;市</div>
                <Select size="small" style={{ width: 150 }} onChange={cityChange}>
                  <Option value="jack">Jack</Option>
                </Select>
              </div>
              <div className={style.area}>
                <div>所&nbsp; 在 &nbsp;区</div>
                <Select size="small" style={{ width: 150 }} onChange={areaChange}>
                  <Option value="jack">Jack</Option>
                </Select>
              </div>
              <div className={style.address}>
                <div>详细地址</div>
                <TextArea
                  value={addressInput}
                  onChange={p => setAddressInput(p.target.value)}
                  rows={2}
                  maxLength={30}
                />
              </div>
            </div>
            <div className={style.btnGroup}>
              <Button onClick={() => setShowInput(false)}>取消</Button>
              <Button type="primary">保存</Button>
            </div>
          </div>
        )
      }
      <div className={style.right}>
        <div className={style.levelDiv}>
          <div className={style.word}>企业评级</div>
          <div className={style.level}>{rate}</div>
        </div>
        <div className={style.scoreDiv}>
          <div className={style.word}>综合得分</div>
          <div className={style.score}>{score}</div>
        </div>
      </div>
    </div>
  );
}

export default Top;
