/*
* Author: zhangmeng
* Description: '资质认定-树图'
* Date: 2022/04/28
*/

import React, { useEffect, useState, useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { cloneDeep } from 'lodash';

const level1 = ['#008DC7', '#05B2F9', '#1492FF', '#FFA60B', '#FFD73F', '#92E18A', '#00FFFB', '#E2FF6B', '#48C2B6', '#C09BFC', '#FFB6A4', '#91A4FF'];
function CertificationChart(props) {
  const { data, orient } = props;
  const [option, setOption] = useState({});
  const dataCp = useMemo(() => cloneDeep(data), [data]);

  useEffect(() => {
    /* eslint-disable no-param-reassign */
    function itemColor(params, borderColor) {
      params.forEach((item, index) => {
        if (item.level === 0) {
          item.itemStyle = {
            color: '#008DC7',
            borderColor: '#008DC7',
            borderWidth: 1,
            // shadowBlur: 10,
            // shadowColor: '#e5e3e3',
          };
          item.label = {
            position: orient && orient === 'TB' ? 'bottom' : 'right',
          };
        } else if (item.level === 1) {
          item.itemStyle = {
            color: level1[index],
            borderColor: level1[index],
          };
          item.label = {
            position: orient === 'TB' ? 'bottom' : 'right',
          };
        } else if (item.level === 2) {
          item.itemStyle = {
            color: '#fff', // borderColor,
            borderColor,
            borderWidth: 2,
          };
          item.label = orient === 'TB' ? {
            position: 'bottom',
            rotate: -90,
            verticalAlign: 'middle',
            align: 'left',
          } : {
            position: 'right',
          };
        } else {
          item.itemStyle = {
            color: 'transparent',
            borderColor,
          };
          item.label = orient === 'TB' ? {
            position: 'bottom',
            rotate: -90,
            verticalAlign: 'middle',
            align: 'left',
          } : {
            position: 'right',
          };
        }
        if (item.children && item.children.length > 0) {
          itemColor(item.children, item.itemStyle.borderColor);
        } else {
          item.itemStyle = {
            color: 'transparent',
            borderColor,
          };
        }
      });
    }
    itemColor(dataCp, 0);

    const options = {
      // tooltip: {
      //   trigger: 'item',
      //   triggerOn: 'mousemove',
      // },
      series: [
        {
          type: 'tree',
          data: dataCp,
          left: '8%',
          top: '15%',
          zoom: 0.85,
          bottom: '5%',
          right: '20%',
          symbol: 'circle',
          orient,
          roam: true,
          symbolSize: (v, p) => {
            const { data: { level } } = p;
            if (level === 0) {
              return 24;
            }
            if (level === 1) {
              return 16;
            }
            return 14;
          },
          label: {
            // position: 'right',
            width: 325,
            overflow: 'truncate',
            ellipsis: '...',
            color: '#FFF',
            formatter: p => {
              const { data: d, name } = p;
              if (d.level === 0) {
                return `{size0|${name}}`;
              }
              if (d.level === 1) {
                return `{size1|${name}}`;
              }
              return `{size2|${name}}`;
            },
            rich: {
              size0: {
                fontSize: 12,
                color: '#333333',
                fontWeight: 600,
              },
              size1: {
                fontSize: 12,
                color: '#333333',
                fontWeight: 600,
              },
              size2: {
                fontSize: 12,
                color: '#333333',
                fontWeight: 600,
              },
            },
          },
          itemStyle: {
            borderWidth: 1,
          },
          lineStyle: {
            color: '#1492FF',
            width: 1,
            curveness: 0.8,
          },
          emphasis: {
            focus: 'descendant',
            label: {
              overflow: 'break',
              formatter: p => {
                const { data: d, name } = p;
                if (d.level === 0) {
                  return `{size0|${name}}`;
                }
                if (d.level === 1) {
                  return `{size1|${name}}`;
                }
                return `{size2|${name}}`;
              },
              rich: {
                size0: {
                  fontSize: 14,
                },
                size1: {
                  fontSize: 14,
                },
                size2: {
                  fontSize: 14,
                },
              },
            },
          },
          expandAndCollapse: true,
          animationDuration: 550,
          animationDurationUpdate: 750,
        },
      ],
    };
    setOption(options);
  }, [data, orient]);

  return (
    <ReactEcharts
      option={option}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default CertificationChart;
