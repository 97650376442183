/*
 *Author: zhaochenyu
 *Description: 新闻政策列表
 *Date: 2021/04/19
*/

import React, { useState, useEffect, useCallback } from 'react';
import { companyTabsColorMap } from '../../../../utils/color';
import { openNewPage } from '../../../../utils/urlMethods';
import style from './index.less';

function NewsList(props) {
  const { type, data, industry } = props;
  const [selectTab, setTab] = useState('');
  const [content, setContent] = useState([]);
  const [curKey, setCurKey] = useState('');
  const permission = JSON.parse(sessionStorage.getItem('pm'));

  const pushPage = useCallback((curType, condition) => {
    const search = window.btoa(window.encodeURIComponent(JSON.stringify({
      search: condition,
    })));
    switch (curType) {
      case 'new':
        openNewPage('industryData', {
          tab: 'sentiment',
          wd: industry,
          search,
        });
        break;
      case 'policy':
        openNewPage('industryData', {
          tab: 'policy',
          wd: industry,
          search,
        });
        break;
      default: break;
    }
  }, []);

  const changeContent = useCallback((news) => {
    setTab(news.type);
    setContent(news.news);
    setCurKey(news.key);
  }, []);

  useEffect(() => {
    if (data.length === 0) return;
    changeContent(data[0]);
  }, [data]);

  return (
    <div className={style.all}>
      <div className={style.title}>
        {data.map((item, index) => (
          <div
            onClick={() => changeContent(item)}
            className={selectTab === item.type ? style.selectItem : style.titleItem}
            key={`${item.type}${index}`}
          >
            {item.type}
          </div>
        ))}
      </div>
      <div className={style.content}>
        {content.map((item, index) => (
          <div
            key={`${item.title + index}`}
            className={style.contentItem}
          >
            <div className={style.top}>
              <div
                className={style.topLeft}
                onClick={() => { window.open(item.url); }}
                title={`摘要：${item.abstract}`}
              >
                {item.title}
              </div>
              <div className={style.topRight}>
                {item.time}
              </div>
            </div>
            <div className={style.bottom}>
              <div className={style.bottomLeft}>
                {(item.tabs || []).map((item1, index1) => (
                  item1.tabs.map((item2, index2) => (
                    <div
                      key={`${index}${index2}tabss${index1}`}
                      className={style.contentTab}
                      style={{ color: companyTabsColorMap[item1.type].color,
                        background: companyTabsColorMap[item1.type].background,
                        borderColor: companyTabsColorMap[item1.type].borderColor }}
                    >
                      {item2}
                    </div>
                  ))
                ))}
              </div>
              <div className={style.bottomRight} title={item.source}>
                {item.source}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={style.footer}>
        {permission && permission.atd && permission.atd.ptid && (
        <span className={style.more} onClick={() => pushPage(type, curKey)}>查看更多&gt;</span>)}
      </div>
    </div>
  );
}

export default NewsList;
