import { INDUSTRY, jizhiIp } from '../utils/url';
import { get, post } from '../utils/request';

// 产业基础信息
const getBasicInfo = param => get(`${INDUSTRY}/industry_basic_info`, {
  ...param,
});

// 获取区域信息接口
const getAreaAndCode = () => get(`${jizhiIp}/city/tree`, {});

// 筛选标签列表
const getScreenLabelList = () => get(`${INDUSTRY}/industry_link_company_tag_plug_marquee`, {});

// 关系图谱 产业链数据
// const getIndustryChainData = param => get(`${INDUSTRY}/industry_atlas`, {
//   ...param,
// });
const getIndustryChainData = param => post(`${INDUSTRY}/industry_atlas`, {
  ...param,
});

// 关系图谱 产业链相关企业列表
const getIndustryCompany = param => get(`${INDUSTRY}/company_list`, {
  ...param,
});

// 基本面扫描-统计数据
const getScanningCount = param => get(`${INDUSTRY}/basic_info_count`, {
  ...param,
});

// 基本面扫描-图表数据
const getScanningChart = param => get(`${INDUSTRY}/basic_info_chart`, {
  ...param,
});

// 基本信息-三图表
const getScanNewsCharts = params => get(`${INDUSTRY}/overview_last_four_chart`, {
  ...params,
});

// 区域画像-热力散点图
const getProCompanyCount = param => get(`${INDUSTRY}/province_company_count`, {
  ...param,
});

// 区域分布-右侧表格
const getAreaCompnayTotal = param => get(`${INDUSTRY}/area_distribution_company_rank`, {
  ...param,
});

// 行业龙头
const getIndustryLeader = (param) => get(`${INDUSTRY}/industry_bellwether`, {
  ...param,
});

// 行业龙头-表格
const getIndustryLeaderTable = param => get(`${INDUSTRY}/industry_bellwether_table`, {
  ...param,
});

// 上市公司列表
const getKeyCompanyList = param => get(`${INDUSTRY}/market_company_list`, {
  ...param,
});

// 上市公司-公司对应的图表
const getCompnayCharts = param => get(`${INDUSTRY}/company_market_finance`, {
  ...param,
});

// 潜力榜单-专精特新
const getNewList = (param) => get(`${INDUSTRY}/specialization_rank_list`, {
  ...param,
});

// 潜力榜单-北交所上市
const getIpoList = (param) => get(`${INDUSTRY}/market_rank_list`, {
  ...param,
});

// 潜力榜单-投资价值
const getInvestList = (param) => get(`${INDUSTRY}/investment_value_rank_list`, {
  ...param,
});

// 投融资图表数据
const getInvestmentChart = param => get(`${INDUSTRY}/investment_chart`, {
  ...param,
});

// 新闻列表
const getNewsList = param => get(`${jizhiIp}/news/news_list`, {
  ...param,
});

// 政策列表
const getPolicyList = param => get(`${jizhiIp}/policy/policy_list`, {
  ...param,
});

// 新增企业
const getNewIndustryChart = param => post(`${jizhiIp}/company/new_company_chart`, {
  ...param,
});

// 新增专利、投融资、标准
const getNewPatentFinacingStandard = param => get(`${INDUSTRY}/new_patent_investment_standard`, {
  ...param,
});

export {
  getScanNewsCharts,
  getAreaAndCode,
  getCompnayCharts,
  getAreaCompnayTotal,
  getNewsList,
  getScreenLabelList,
  getPolicyList,
  getNewIndustryChart,
  getNewPatentFinacingStandard,
  getScanningChart,
  getIndustryChainData,
  getInvestmentChart,
  getBasicInfo,
  getIndustryCompany,
  getKeyCompanyList,
  getScanningCount,
  getProCompanyCount,
  getIndustryLeader,
  getIndustryLeaderTable,
  getNewList,
  getIpoList,
  getInvestList,
};
