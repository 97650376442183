/**
 * 存放图表相关的一些工具函数
 */

let ctx;
export function getWordsWidth(words, wordStyle = {
  font: '10px sans-serif',
}) {
  if (!ctx) {
    const canvas = document.createElement('canvas');
    ctx = canvas.getContext('2d');
  }
  ctx.font = wordStyle.font;
  return ctx.measureText(words).width + 2;
}

export function commonStyleGenerator({
  colGap, titleStyle, colStyle,
  titleNoWrap, minRows, preHandleData,
}) {
  return function adaperDataWithStyle(resData) {
    if (typeof preHandleData === 'function') {
      preHandleData(resData);
    }
    const handledData = [];
    const titles = [];
    let beforeEndColumn = 1;

    const allColumn = colGap.reduce((prev, item) => {
      // eslint-disable-next-line no-param-reassign
      prev += item;
      return prev;
    }, 0);
    // title 表头 获取 并获取列的顺序
    const orderKey = [];
    for (let i = 0, L = resData.data.columns.length; i < L; i += 1) {
      const item = resData.data.columns[i];
      const curColStart = beforeEndColumn;
      const curColEnd = curColStart + colGap[i];
      beforeEndColumn = curColEnd;
      titles.push({
        // 四个字换个行
        text: item.title.length > 4 ? item.title.split('').reduce((
          prev, str, index, arr,
        ) => {
          let count = 4;
          if (titleNoWrap?.[i]) {
            count = 0;
          }
          if (index && (index + 1) === count && index !== arr.length - 1) {
            // eslint-disable-next-line no-param-reassign
            prev += (`${str}<br />`);
          } else {
            // eslint-disable-next-line no-param-reassign
            prev += (str);
          }
          return prev;
        }, '') : item.title,
        style: {
          gridColumnStart: curColStart,
          gridColumnEnd: curColEnd,
          gridRowStart: 1,
          gridRowEnd: 2,
          ...titleStyle[i],
        },
      });
      orderKey.push(item.dataIndex);
    }
    handledData.push(titles);
    const rowStyle = [
      {
        background: '#EFF6FF',
      },
      {
        background: '#fff',
      },
    ];
    // resData.data.dataSource.length = 1;
    for (let i = 0, L = resData.data.dataSource.length; i < L; i += 1) {
      const item = resData.data.dataSource[i];
      const temp = [];
      let curBeforeEndColumn = 1;
      orderKey.forEach((key, col) => {
        const curColStart = curBeforeEndColumn;
        const curColEnd = curColStart + colGap[col];
        curBeforeEndColumn = curColEnd;
        temp.push({
          text: item[key],
          style: {
            gridColumnStart: curColStart,
            gridColumnEnd: curColEnd,
            gridRowStart: i + 2,
            gridRowEnd: i + 3,
            ...colStyle[col],
            ...rowStyle[1 - (i % 2)],
          },
        });
      });
      handledData.push(temp);
    }
    let row = handledData.length;
    if (typeof minRows === 'number') {
      row = row > minRows ? row : minRows;
    }
    return {
      containerStyle: {
        gridTemplateColumns: `repeat(${allColumn}, 1fr)`,
        gridTemplateRows: `auto repeat(${row},1fr)`,
      },
      values: handledData,
    };
  };
}

function calcBlurInterval(rangeAbs, splitNumber) {
  // 该函数不严格遵守splitNumber，仅返回一个最佳的间隔值
  const range = Math.abs(rangeAbs);
  const split = Math.abs(splitNumber);
  const average = (range / split).toFixed(0);
  const length = average.toString().length - 1;
  const exponent = length < 0 ? 0 : length;
  return Math.ceil(average * Math.pow(10, -exponent)) * Math.pow(10, exponent);
}

// 取整计算间隔
function calcInterval(rangeAbs, splitNumber) {
  const range = Math.abs(rangeAbs);
  const split = Math.abs(splitNumber);
  return Math.ceil(range / split);
}

// 获取合适的轴线范围
function getAxisBoundary(min, max) {
  // 无符号整数
  const uiMax = Math.ceil(Math.abs(max));
  const uiMin = Math.ceil(Math.abs(min));
  const minLen = uiMin.toString().length - 1;
  const maxLen = uiMax.toString().length - 1;
  const exp = minLen > maxLen ? Math.pow(10, minLen) : Math.pow(10, maxLen);
  const minLimit = min > 0 ? 0 : Math.ceil(Math.abs(min) / exp) * (min < 0 ? -1 : 1) * exp;
  const maxLimit = Math.ceil(max / exp) * (max < 0 ? -1 : 1) * exp;
  return [minLimit, maxLimit];
}

// 获取最接近最大值的间隔线应取值
function getFitMax(min, max, interval) {
  return min + Math.ceil((max - min) / interval) * interval;
}

// 获取最接近最小值的间隔线应取值
function getFitMin(min, max, interval) {
  return max - Math.ceil((max - min) / interval) * interval;
}

function tooltipFormatter(params, option) {
  const opt = Object.assign({}, {
    title: '',
    valueAxis: 'y',
    valueWrap: false,
    useSeriesName: false,
  }, option);
  const { title, valueAxis, valueWrap, useSeriesName } = opt;
  let series = [];
  // console.log('params:', params);
  if (!(params instanceof Array)) {
    series = [params];
  } else {
    series = params;
  }
  const [item] = series;
  // console.log('item:', item);
  let str = '<div style="padding: 9px 9px 0 9px; font-family: Pingfang-Regular, Microsoft Yahei;">';
  if (title) {
    str += `<div style="margin-bottom: 10px; font-size: 16px; font-weight: bold;color: #fff">${title}</div>`;
  }
  // 饼图、散点、坐标热力图
  if (typeof item.data === 'object' && !(item.data instanceof Array) && !useSeriesName) {
    str += '<table><tbody>';
    for (let i = 0; i < series.length; i++) {
      const { data: {name, value, unit} } = series[i];
      str += `<tr><td style="padding-bottom: 10px; color: #daeae6;">${name}</td>`;
      if (value) {
        if (valueWrap) str += '</tr><tr></tr><td style="padding: 0 0 10px 0; color: #fff; font-weight: bold;">';
        else str += '<td style="padding: 0 0 10px 10px; color: #fff; font-weight: bold;">';
        str += (value instanceof Array ? value[2] : value);
        str += unit ? unit : '';
        str += '</td></tr>';
      } else {
        str += '</tr>';
      }

    }
    str += '</tbody></table></div>';
  } else {
    // 非饼图、地图散点
    str += `<div style="margin-bottom: 16px; font-size: 14px; color: #fff;">${item.name || ''}`;
    if (valueAxis === 'y') {
      str += item.dimensionNames[0] === 'none' || ['x', 'y', 'value', 'category'].includes(item.dimensionNames[0]) ? '' : item.dimensionNames[0];
    } else {
      str += item.dimensionNames[1] === 'none' || ['x', 'y', 'value', 'category'].includes(item.dimensionNames[1]) ? '' : item.dimensionNames[1];
    }
    str += '</div><table><tbody>';
    for (let i = 0; i < series.length; i++) {
      if (series[i].value) {
        str += `<tr><td style="padding-bottom: 10px; color: #daeae6;">${series[i].seriesName}</td>  <td style="padding: 0 0 10px 10px; color: #fff; font-weight: bold;">${series[i].value}`;
        if (valueAxis === 'y') {
          str += series[i].dimensionNames[1] === 'none' || ['x', 'y', 'value', 'category'].includes(item.dimensionNames[1]) ? '' : series[i].dimensionNames[1];
        } else {
          str += series[i].dimensionNames[0] === 'none' || ['x', 'y', 'value', 'category'].includes(item.dimensionNames[0]) ? '' : series[i].dimensionNames[0];
        }
        str += '</td></tr>';
      }
    }
    str += '</tbody></table></div>';
  }

  return str;
}


export {
  calcBlurInterval,
  calcInterval,
  getFitMax,
  getFitMin,
  tooltipFormatter,
  getAxisBoundary,
};
