import React, { useState, useEffect } from 'react';
import { Row, Col, message } from 'antd';
import { CaretRightOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import style from './index.less';
import {
  getPolicyIndustry,
} from '../../../../api/policy';
import {
  getIndustryNews,
  getCompanyNews,
} from '../../../../api/news';
import { checkLogin } from '../../../../utils/tool';

const tableItemName = [
  {
    name: '行业动态',
    key: 'industryNews',
  },
  {
    name: '企业动态',
    key: 'companyNews',
  },
  {
    name: '产业政策',
    key: 'industryPolicy',
  },
];

function HomeTableList() {
  const history = useHistory();
  const [menuKey, setMenuKey] = useState('industryNews');
  const [displayList, setDisplayList] = useState([]);
  const [industryNewsList, setIndustryNewsList] = useState([]);
  const [companyNewsList, setCompanyNewsList] = useState([]);
  const [industryPolicyList, setIndustryPolicyList] = useState([]);

  useEffect(() => {
    getPolicyIndustry().then(res => {
      if (res.status === '00000') {
        setIndustryPolicyList(res.data.data_list || []);
      } else {
        message.error(res.message);
      }
    });
    getIndustryNews().then(res => {
      if (res.status === '00000') {
        setIndustryNewsList(res.data.data_list || []);
        setDisplayList(res.data.data_list || []);
      } else {
        message.error(res.message);
      }
    });
    getCompanyNews().then(res => {
      if (res.status === '00000') {
        setCompanyNewsList(res.data.data_list || []);
      } else {
        message.error(res.message);
      }
    });
  }, []);

  const openDetail = (url) => {
    if (!checkLogin()) {
      message.warning('请先登录');
      history.push('/login');
      return;
    }
    window.open(url);
  };

  const handleMenuClick = (item) => {
    if (item) {
      setMenuKey(item);
      if (item === 'industryPolicy') {
        setDisplayList(industryPolicyList);
      } else if (item === 'companyNews') {
        setDisplayList(companyNewsList);
      } else {
        setDisplayList(industryNewsList);
      }
    }
  };

  return (
    <div className={style.tablePage}>
      <div className={style.listTitle}>
        <div className={style.menus}>
          {
            tableItemName.map((item, index) => (
              <div
                className={item.key === menuKey ? style.selectKy : style.menuItem}
                key={`${index}${item.key}menu`}
                onClick={() => handleMenuClick(item.key)}
              >
                {item.name}
              </div>
            ))
          }
        </div>
        <div className={style.learnMore} onClick={() => {
          if (menuKey !== 'industryPolicy') {
            history.push('/industryNews');
          } else {
            history.push('/industryPolicy');
          }
        }}>
          了解更多
          <DoubleRightOutlined />
        </div>
      </div>
      <div className={style.listContent}>
        <Row gutter={[24, 24]} justify="space-around">
          {
            displayList.map((item, index) => {
              if (menuKey !== 'industryPolicy') {
                return (
                  <Col span={8} key={`${index}${item._id}${item.title}table`}>
                    <div
                      className={style.listItem}
                    >
                      <div>
                        {item.time}
                      </div>
                      <div>
                        {item.title}
                      </div>
                      <div className={style.seeMore}>
                        <CaretRightOutlined />
                        <a onClick={() => openDetail(item.link)} target="_blank" rel="noreferrer" style={{ color: '#1E1E1E' }}>查看更多</a>
                      </div>
                    </div>
                  </Col>
                );
              }
              return (
                <Col span={8} key={`${index}${item._id}${item.title}table`}>
                  <div
                    className={style.listItem}
                  >
                    <div>
                      {item.publish_date}
                    </div>
                    <div>
                      {item.title}
                    </div>
                    <div className={style.seeMore}>
                      <CaretRightOutlined />
                      <a target="_blank" rel="noreferrer" style={{ color: '#1E1E1E' }} onClick={() => {
                        if (!checkLogin()) {
                          message.warning('请先登录');
                          history.push('/login');
                          return;
                        }
                        history.push(`/industryPolicy/${item?._id}`);
                      }}>
                        查看更多
                      </a>
                    </div>
                  </div>
                </Col>
              );
            })
          }
        </Row>
      </div>
    </div>
  );
}

export default HomeTableList;
